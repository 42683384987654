import { grpc } from '@improbable-eng/grpc-web';
import { ExecutorServiceClient, ResponseStream } from './tr_executor_service_pb_service';
import { promisify } from 'es6-promisify';
import * as executor_tr_executor_types_pb from './tr_executor_types_pb';
import {
  AddSymbolsRequest,
  CancelOrderRequest,
  CancelOrderResponse,
  ExecuteOrderRequest,
  ExecuteOrderResponse,
  ExecutorConfigsRequest,
  ExecutorConfigsResponse,
  ExecutorHealthStatusRequest,
  ExecutorHealthStatusResponse,
  ExecutorPNLRequest,
  ExecutorPNLResponse,
  GetOpenOrdersRequest,
  GetOpenOrdersResponse,
  GetPnlCalculatorMapsResponse,
  GetPnlCalculatorRequest,
  IgnoreSymbolsRequest,
  RemoveFromIgnoreSymbolsRequest,
} from './tr_executor_types_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import {
  GetRunningSymbolListRequest,
  GetRunningSymbolListResponse,
  RemoveSymbolRequest,
} from '../brain/tr_brain_types_pb';

export class ExecutorServiceClientPromisified extends ExecutorServiceClient {
  getExecutorHealthStatusPromise: (
    param1: ExecutorHealthStatusRequest,
    metadata?: grpc.Metadata,
  ) => Promise<ExecutorHealthStatusResponse | null> = promisify(this.getExecutorHealthStatus);

  getPNLPromise: (param1: ExecutorPNLRequest, metadata?: grpc.Metadata) => Promise<ExecutorPNLResponse | null> =
    promisify(this.getPNL);

  getRemoteConfigsPromise: (
    requestMessage: google_protobuf_empty_pb.Empty,
    metadata?: grpc.Metadata,
  ) => Promise<ExecutorConfigsResponse | null> = promisify(this.getRemoteConfigs);

  setOrUpdateRemoteConfigsPromise: (
    param1: ExecutorConfigsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.setOrUpdateRemoteConfigs);
  getRunningSymbolListPromise: (
    param1: GetRunningSymbolListRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetRunningSymbolListResponse | null> = promisify(this.getRunningSymbolList);
  getRunningWithoutDESymbolListPromise: (
    param1: GetRunningSymbolListRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetRunningSymbolListResponse | null> = promisify(this.getRunningWithoutDESymbolList);
  getDESymbolListPromise: (
    param1: GetRunningSymbolListRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetRunningSymbolListResponse | null> = promisify(this.getDESymbolList);
  removeSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeSymbols);
  removeDESymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeDESymbols);
  addSymbolsPromise: (
    param1: AddSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.addSymbols);
  addDESymbolsPromise: (
    param1: AddSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.addDESymbols);
  removeFutureSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFutureSymbols);
  addFutureSymbolsPromise: (
    param1: AddSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.addFutureSymbols);
  removeFromIgnoreSymbolsPromise: (
    param1: RemoveFromIgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFromIgnoreSymbols);
  ignoreSymbolsPromise: (
    param1: IgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.ignoreSymbols);
  removeFromFutureIgnoreSymbolsPromise: (
    param1: RemoveFromIgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFromFutureIgnoreSymbols);
  ignoreFutureSymbolsPromise: (
    param1: IgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.ignoreFutureSymbols);
  getPnlCalculatorPromise: (
    param1: GetPnlCalculatorRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetPnlCalculatorMapsResponse | null> = promisify(this.getPnlCalculator);
  executeOrderPromise: (param1: ExecuteOrderRequest, metadata?: grpc.Metadata) => Promise<ExecuteOrderResponse | null> =
    promisify(this.executeOrder);
  cancelOrderPromise: (param1: CancelOrderRequest, metadata?: grpc.Metadata) => Promise<CancelOrderResponse | null> =
    promisify(this.cancelOrder);
  getOpenOrdersPromise: (
    param1: GetOpenOrdersRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetOpenOrdersResponse | null> = promisify(this.getOpenOrders);
}
