import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PriceTickerResponse, PriceTickerStreamResponse } from '../../pkg/protobuf/v2/harvester/tr_harvester_types_pb';
import { RootState } from '../store';

export interface pricesInterface {
  response: PriceTickerResponse;
}

export interface priceInterface {
  response: PriceTickerStreamResponse;
}

export const initialState: any = {
  response: new PriceTickerResponse(),
};

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setPrices: (state, action: PayloadAction<pricesInterface>) => {
      state.pricesInterface = action.payload;
    },
    addPrice: (state, action: PayloadAction<priceInterface | undefined>) => {
      const newElement = action.payload;
      if (newElement?.response.priceTicker?.tradeType === 0) {
        console.log('no update skip, unknown trade type', action.payload);
        return;
      }
      const currentState = state.pricesInterface.response.futurepricemapMap;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      currentState[newElement?.response.symbol || ''] = newElement?.response.priceTicker;
      state.pricesInterface = currentState;
    },
    // removePrice: (state, action: PayloadAction<priceInterface | undefined>) => {
    //   let currentState = state.pricesInterface;
    //   if (currentState === undefined) currentState = [];
    //   if (!action.payload) {
    //     console.log('no update skip', action.payload);
    //     return;
    //   }
    //   const newElement = action.payload;
    //   state.pricesInterface = currentState.filter((o: any) => {
    //     return o.price.priceid !== newElement.price.priceid;
    //   });
    // },
  },
});

export const selectPrices = (state: RootState) => state.prices.pricesInterface;

export const { setPrices, addPrice } = pricesSlice.actions;

export default pricesSlice.reducer;
