import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { selectAccessToken } from '../../app/redux/authorization.slice.reducer';
import { logger } from '../../config/Logger';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import styles from './Symbol.module.scss';

const SymbolModal = (props: any) => {
  const userToken = useSelector(selectAccessToken);

  const onRemoveSymbol = (symbol: string) => {
    props.setRemovedSymbolsList([...props.removedSymbolsList, symbol]);
    const removedIndex = props.runningRenderedSymbolsList.indexOf(symbol);
    const removedList = props.runningRenderedSymbolsList.slice();
    removedList.splice(removedIndex, 1);
    const removedIndex2 = props.addedSymbolsList.indexOf(symbol);
    const removedList2 = props.addedSymbolsList.slice();
    removedList2.splice(removedIndex2, 1);
    props.setRenderedRunningSymbolsList(removedList);
    props.setAddedSymbolsList(removedList2);
  };

  const onRemoveFutureSymbol = (symbol: string) => {
    props.setRemovedFutureSymbolsList([...props.removedFutureSymbolsList, symbol]);
    const removedIndex = props.runningRenderedFutureSymbolsList.indexOf(symbol);
    const removedList = props.runningRenderedFutureSymbolsList.slice();
    removedList.splice(removedIndex, 1);
    const removedIndex2 = props.addedFutureSymbolsList.indexOf(symbol);
    const removedList2 = props.addedFutureSymbolsList.slice();
    removedList2.splice(removedIndex2, 1);
    props.setRenderedRunningFutureSymbolsList(removedList);
    props.setAddedFutureSymbolsList(removedList2);
  };

  const onAddSymbol = (symbol: string) => {
    const x = props.removedSymbolsList.slice();
    const index = x.indexOf(symbol);
    props.setRenderedRunningSymbolsList([...props.runningRenderedSymbolsList, symbol].sort());
    props.setAddedSymbolsList([...props.addedSymbolsList, symbol]);
    x.splice(index, 1);
    props.setRemovedSymbolsList(x);
  };

  const onAddFutureSymbol = (symbol: string) => {
    const x = props.removedFutureSymbolsList.slice();
    const index = x.indexOf(symbol);
    props.setRenderedRunningFutureSymbolsList([...props.runningRenderedFutureSymbolsList, symbol].sort());
    props.setAddedFutureSymbolsList([...props.addedFutureSymbolsList, symbol]);
    x.splice(index, 1);
    props.setRemovedFutureSymbolsList(x);
  };

  const onSave = async () => {
    const removeResponse = await ExecutorHelper.removeSymbols(
      props.account.executor,
      props.removedSymbolsList,
      userToken,
    );
    if (removeResponse) await BrainHelper.removeSymbols(props.account.brain, props.removedSymbolsList, userToken);

    const addResponse = await ExecutorHelper.addSymbols(props.account.executor, props.addedSymbolsList, userToken);
    if (addResponse) await BrainHelper.addSymbols(props.account.brain, props.addedSymbolsList, userToken);

    //future
    const removeFutureResponse = await ExecutorHelper.removeFutureSymbols(
      props.account.executor,
      props.removedFutureSymbolsList,
      userToken,
    );
    if (removeFutureResponse)
      await BrainHelper.removeFutureSymbols(props.account.brain, props.removedFutureSymbolsList, userToken);

    const addFutureResponse = await ExecutorHelper.addFutureSymbols(
      props.account.executor,
      props.addedFutureSymbolsList,
      userToken,
    );
    if (addFutureResponse)
      await BrainHelper.addFutureSymbols(props.account.brain, props.addedFutureSymbolsList, userToken);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <img src="/img/bitcoin.svg" alt="bitcoin" />
        <p>Lists of Symbols</p>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>Running</p>
            {props.synchronized ? (
              <p data-tip="Not synchronized" data-text-color="#FFFFFF">
                <img src="/img/error.svg" alt="error" />
              </p>
            ) : (
              ''
            )}
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.runningSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') props.setRenderedRunningSymbolsList(search);
                else props.setRenderedRunningSymbolsList(props.runningSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.runningRenderedSymbolsList.length > 0
              ? props.runningRenderedSymbolsList.map((symbol: string, i: number) => {
                  if (!props.removedSymbolsList.includes(symbol) || props.addedSymbolsList.includes(symbol))
                    return (
                      <div key={i} className={styles.coinsElement}>
                        <div>
                          <h5>{symbol}</h5>
                        </div>
                        <div className={styles.coinsOrigin}>
                          {props.brainSymbolsList.includes(symbol) ? <img src="/img/brain.svg" alt="brain" /> : ''}
                          {props.executorSymbolsList.includes(symbol) ? (
                            <img src="/img/executor.svg" alt="executor" />
                          ) : (
                            ''
                          )}
                        </div>
                        <button
                          onClick={(e) => {
                            if (e.nativeEvent.which === 1) onRemoveSymbol(symbol);
                          }}
                        >
                          <img src="/img/minus.svg" alt="minus" />
                        </button>
                      </div>
                    );
                })
              : ''}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Available from Harvester</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.symbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') props.setSearchedSymbolsList(search);
                else props.setSearchedSymbolsList(props.symbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.searchedSymbolsList.length > 0 &&
              props.searchedSymbolsList.map((symbol: string, i: number) => {
                if (
                  (!props.runningSymbolsList.includes(symbol) || props.removedSymbolsList.includes(symbol)) &&
                  !props.addedSymbolsList.includes(symbol)
                )
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1) onAddSymbol(symbol);
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>Futures Running</p>
            {props.synchronizedF ? (
              <p data-tip="Not synchronized" data-text-color="#FFFFFF">
                <img src="/img/error.svg" alt="error" />
              </p>
            ) : (
              ''
            )}
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.runningFutureSymbolsList.filter((s: any) =>
                  s.includes(e.target.value.toUpperCase()),
                );
                if (e.target.value.trim() !== '') props.setRenderedRunningFutureSymbolsList(search);
                else props.setRenderedRunningFutureSymbolsList(props.runningFutureSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.runningRenderedFutureSymbolsList.length > 0
              ? props.runningRenderedFutureSymbolsList.map((symbol: string, i: number) => {
                  if (!props.removedFutureSymbolsList.includes(symbol) || props.addedFutureSymbolsList.includes(symbol))
                    return (
                      <div key={i} className={styles.coinsElement}>
                        <div>
                          <h5>{symbol}</h5>
                        </div>
                        <div className={styles.coinsOrigin}>
                          {props.brainFutureSymbolsList.includes(symbol) ? (
                            <img src="/img/brain.svg" alt="brain" />
                          ) : (
                            ''
                          )}
                          {props.executorFutureSymbolsList.includes(symbol) ? (
                            <img src="/img/executor.svg" alt="executor" />
                          ) : (
                            ''
                          )}
                        </div>
                        <button
                          onClick={(e) => {
                            if (e.nativeEvent.which === 1) onRemoveFutureSymbol(symbol);
                          }}
                        >
                          <img src="/img/minus.svg" alt="minus" />
                        </button>
                      </div>
                    );
                })
              : ''}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Available from Harvester</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.futureSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') props.setSearchedFutureSymbolsList(search);
                else props.setSearchedFutureSymbolsList(props.futureSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.searchedFutureSymbolsList.length > 0 &&
              props.searchedFutureSymbolsList.map((symbol: string, i: number) => {
                if (
                  (!props.runningFutureSymbolsList.includes(symbol) ||
                    props.removedFutureSymbolsList.includes(symbol)) &&
                  !props.addedFutureSymbolsList.includes(symbol)
                )
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1) onAddFutureSymbol(symbol);
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <a
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) props.setEdit(false);
          }}
        >
          Cancel
        </a>
        <div>
          <a
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) onSave();
              props.setEdit(false);
            }}
          >
            Save
          </a>
        </div>
      </div>
      <ReactTooltip backgroundColor={'#242430'} />
    </div>
  );
};
export default SymbolModal;
