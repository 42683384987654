// @generated by protoc-gen-connect-web v0.3.0 with parameter "target=js+ts+dts"
// @generated from file brain/tr_brain_service.proto (package brain, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {AddSymbolsRequest, BrainConfigsRequest, BrainConfigsResponse, BrainHealthStatusRequest, BrainHealthStatusResponse, BrainSignalRequest, BrainSignalResponse, GetRedFlagsRequest, GetRedFlagsResponse, GetRunningSymbolListRequest, GetRunningSymbolListResponse, IgnoreSymbolsRequest, RemoveFromIgnoreSymbolsRequest, RemoveSymbolRequest} from "./tr_brain_types_pb.js";
import {Empty, MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service brain.BrainService
 */
export const BrainService = {
  typeName: "brain.BrainService",
  methods: {
    /**
     * Get exchange marketplace health status for a specific market
     *
     * @generated from rpc brain.BrainService.GetBrainHealthStatus
     */
    getBrainHealthStatus: {
      name: "GetBrainHealthStatus",
      I: BrainHealthStatusRequest,
      O: BrainHealthStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get last available signals
     *
     * @generated from rpc brain.BrainService.GetLastAvailableSignal
     */
    getLastAvailableSignal: {
      name: "GetLastAvailableSignal",
      I: BrainSignalRequest,
      O: BrainSignalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * set or update remote configs
     *
     * @generated from rpc brain.BrainService.SetOrUpdateRemoteConfigs
     */
    setOrUpdateRemoteConfigs: {
      name: "SetOrUpdateRemoteConfigs",
      I: BrainConfigsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * get remote configs
     *
     * @generated from rpc brain.BrainService.GetRemoteConfigs
     */
    getRemoteConfigs: {
      name: "GetRemoteConfigs",
      I: Empty,
      O: BrainConfigsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * get running list of symbols per quote coin
     *
     * @generated from rpc brain.BrainService.GetRunningSymbolList
     */
    getRunningSymbolList: {
      name: "GetRunningSymbolList",
      I: GetRunningSymbolListRequest,
      O: GetRunningSymbolListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.AddSymbols
     */
    addSymbols: {
      name: "AddSymbols",
      I: AddSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.AddFutureSymbols
     */
    addFutureSymbols: {
      name: "AddFutureSymbols",
      I: AddSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.RemoveSymbols
     */
    removeSymbols: {
      name: "RemoveSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.RemoveFutureSymbols
     */
    removeFutureSymbols: {
      name: "RemoveFutureSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.RemoveFromIgnoreSymbols
     */
    removeFromIgnoreSymbols: {
      name: "RemoveFromIgnoreSymbols",
      I: RemoveFromIgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.IgnoreSymbols
     */
    ignoreSymbols: {
      name: "IgnoreSymbols",
      I: IgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.RemoveFromFutureIgnoreSymbols
     */
    removeFromFutureIgnoreSymbols: {
      name: "RemoveFromFutureIgnoreSymbols",
      I: RemoveFromIgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.IgnoreFutureSymbols
     */
    ignoreFutureSymbols: {
      name: "IgnoreFutureSymbols",
      I: IgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.GetRedFlags
     */
    getRedFlags: {
      name: "GetRedFlags",
      I: GetRedFlagsRequest,
      O: GetRedFlagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc brain.BrainService.GetRedFlagsStream
     */
    getRedFlagsStream: {
      name: "GetRedFlagsStream",
      I: GetRedFlagsRequest,
      O: GetRedFlagsResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

