export const pnlNames: any = {
  previoustotalusdtvalue: 'Previous Total USDT',
  currenttotalusdtvalue: 'Current Total USDT',
  pnltotalusdtpercentage: 'Pnl USDT %',
  pnltotalusdt: 'Pnl USDT',
  previoustotalbusdvalue: 'Previous Total BUSD',
  currenttotalbusdvalue: 'Current Total BUSD',
  pnltotalbusdpercentage: 'Pnl BUSD %',
  pnltotalbusd: 'Pnl BUSD',
  previousbtcvalue: 'Previous Total BTC',
  currentbtcvalue: 'Current Total BTC',
  pnlbtcpercentage: 'Pnl BTC %',
  pnlbtc: 'Pnl BTC',
  currentspotusdt: 'Current Spot USDT',
  pnlspotusdtpercentage: 'Pnl Spot USDT %',
  currentfutureusdt: 'Current Futures USDT',
  pnlfutureusdtpercentage: 'Pnl Futures USDT %',
  currentspotbusd: 'Current Spot BUSD',
  pnlspotbusdpercentage: 'Pnl Spot BUSD %',
  currentfuturebusd: 'Current Futures BUSD',
  pnlfuturebusdpercentage: 'Pnl Futures BUSD %',
  currentspotbtc: 'Current Spot BTC',
  pnlspotbtcpercentage: 'Pnl Spot BTC %',
  currentfuturebtc: 'Current Futures BTC',
  currentspotinvestedpercentage: 'Current Spot Invested %',
};
