// @generated by protoc-gen-connect-web v0.3.0 with parameter "target=js+ts+dts"
// @generated from file auth/auth_service.proto (package auth, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetAllRolesRequest, GetAllRolesResponse, GetDefaultRolesRequest, GetDefaultRolesResponse, ListAccountsDetailsRequest, ListAccountsDetailsResponse, ListAllAccountsRequest, ListAllAccountsResponse, LoginRequest, LoginResponse, ProfileRequest, ProfileResponse, TenantCreateApiKeyRequest, TenantCreateApiKeyResponse, UpdateAccountRoleRequest, UpdateAccountRoleResponse} from "./auth_types_pb.js";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service auth.AuthService
 */
export const AuthService = {
  typeName: "auth.AuthService",
  methods: {
    /**
     * Get exchange marketplace health status for a specific market
     *
     * @generated from rpc auth.AuthService.PublicLoginAndRegister
     */
    publicLoginAndRegister: {
      name: "PublicLoginAndRegister",
      I: LoginRequest,
      O: LoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get default roles that a user can select in their first register/login
     *
     * @generated from rpc auth.AuthService.PublicGetDefaultRoles
     */
    publicGetDefaultRoles: {
      name: "PublicGetDefaultRoles",
      I: GetDefaultRolesRequest,
      O: GetDefaultRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all the roles from a tenant
     *
     * @generated from rpc auth.AuthService.GetAllRoles
     */
    getAllRoles: {
      name: "GetAllRoles",
      I: GetAllRolesRequest,
      O: GetAllRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Update profile data
     *
     * @generated from rpc auth.AuthService.UpdateProfile
     */
    updateProfile: {
      name: "UpdateProfile",
      I: ProfileRequest,
      O: ProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.AuthService.ListAccountsDetails
     */
    listAccountsDetails: {
      name: "ListAccountsDetails",
      I: ListAccountsDetailsRequest,
      O: ListAccountsDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.AuthService.ListAllAccounts
     */
    listAllAccounts: {
      name: "ListAllAccounts",
      I: ListAllAccountsRequest,
      O: ListAllAccountsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create api key for an account. Needs tenant super admin role
     *
     * @generated from rpc auth.AuthService.TenantCreateApiKey
     */
    tenantCreateApiKey: {
      name: "TenantCreateApiKey",
      I: TenantCreateApiKeyRequest,
      O: TenantCreateApiKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Update Account Role
     *
     * @generated from rpc auth.AuthService.AddAccountRole
     */
    addAccountRole: {
      name: "AddAccountRole",
      I: UpdateAccountRoleRequest,
      O: UpdateAccountRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Remove Account Role
     *
     * @generated from rpc auth.AuthService.RemoveAccountRole
     */
    removeAccountRole: {
      name: "RemoveAccountRole",
      I: UpdateAccountRoleRequest,
      O: UpdateAccountRoleResponse,
      kind: MethodKind.Unary,
    },
  }
};

