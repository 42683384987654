import styles from './HarvesterModal.module.scss';

const HarvesterModal = (props: any) => {
  return (
    <div className={styles.harvesterModal}>
      <h3>List of down Harvesters</h3>
      <ul>
        {props.harvesters.map((harvester: string, key: number) => {
          return <li key={key}>{harvester}</li>;
        })}
      </ul>
    </div>
  );
};
export default HarvesterModal;
