import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';

import { accountInt, addAccount, selectPNLDays, setPNLDays } from '../../app/redux/applicationAccount.slice.reducer';
import { selectAccessToken } from '../../app/redux/authorization.slice.reducer';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import styles from './Refresh.module.scss';

const RefreshModal = (props: any) => {
  const days = useSelector(selectPNLDays);
  const [daysS, setDaysS] = useState<number | string>(days || 0);
  const [isNow, setIsNow] = useState(true);
  const userToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  const setDaysState = (e: ChangeEvent<HTMLInputElement>) => {
    const x = e.target.value;
    if (isNaN(Number(x)) && x.trim() !== '') return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isNaN(Number(x)) && x % 1 != 0 && Number(x) !== 0) return;
    else setDaysS(x.trim());
  };

  const onRefresh = async () => {
    dispatch(setPNLDays(Number(daysS)));
    const pnlResp = await ExecutorHelper.getPNL(props.account.executor, Number(daysS), isNow, userToken);
    const newAccount: accountInt = JSON.parse(JSON.stringify(props.account));
    newAccount.pnl = pnlResp;
    dispatch(addAccount(newAccount));
    props.setEdit(false);
  };

  return (
    <div className={styles.mainWrapper}>
      <p>Refresh Results</p>
      <h5>Pick up from to when you want to see results. By default is 0 days before till now</h5>
      <div className={'floatingInputContainer'}>
        <input
          className="field-input"
          id="btcValueInput"
          name="btcValueInput"
          type="text"
          placeholder=" "
          value={daysS}
          autoComplete={'off'}
          onChange={setDaysState}
        />
        <label className="floatingInputPlaceholder" htmlFor="btcValueInput">
          Days before
        </label>
      </div>
      <div className={styles.nowCheck}>
        <h5>Until Now: </h5>
        <Toggle checked={isNow} icons={false} onChange={() => setIsNow(!isNow)} />
      </div>
      <div
        className={styles.refreshButton}
        role={'button'}
        tabIndex={0}
        onMouseDown={(e) => {
          if (e.nativeEvent.which === 1) onRefresh();
        }}
      >
        <img src="/img/refresh.svg" alt="refresh" />
        <h5>Refresh</h5>
      </div>
    </div>
  );
};
export default RefreshModal;
