import React, { useState } from 'react';
import Toggle from 'react-toggle';

import { AuthHelper } from '../../pkg/apiHelpers/authHelper';
import {
  AccountAndRoles,
  GetAllRolesResponse,
  RoleDto,
  UpdateAccountRoleRequest,
} from '../../pkg/protobuf/v2/auth/auth_types_pb';
import styles from './Users.module.scss';

const UserRow = (props: { value: AccountAndRoles; roles: GetAllRolesResponse | undefined; userToken: string }) => {
  const [isSuperUser, setIsSuperUser] = useState(props.value.roles.findIndex((v) => v.name === 'superuser') > -1);
  const [isViewOnly, setIsViewOnly] = useState(props.value.roles.findIndex((v) => v.name === 'viewOnlyRole') > -1);
  const [isNotInvited, setIsNotInvited] = useState(
    props.value.roles.findIndex((v) => v.name === 'defaultVisitorRoleAll') > -1,
  );

  const onRoleChange = async (value: AccountAndRoles, roleName: string, toggleBool: boolean) => {
    const roleIndex = props.roles?.roles.findIndex((r) => r.name === roleName);
    if (roleIndex !== undefined && roleIndex > -1 && props.roles?.roles[roleIndex].id !== undefined) {
      const request = new UpdateAccountRoleRequest();
      request.id = props.value.id;
      const newRole = new RoleDto();
      newRole.id = props.roles.roles[roleIndex].id;
      request.role = newRole;
      return await (toggleBool
        ? AuthHelper.removeAccountRolePromise(request, props.userToken)
        : AuthHelper.addAccountRolePromise(request, props.userToken));
    }
  };
  return (
    <div className={styles.head}>
      <h5>{props.value.email}</h5>
      <Toggle
        className={styles.reactToggle}
        checked={isSuperUser}
        icons={false}
        onChange={async () => {
          if (await onRoleChange(props.value, 'superuser', isSuperUser)) setIsSuperUser(!isSuperUser);
        }}
      />
      <Toggle
        className={styles.reactToggle}
        checked={isViewOnly}
        icons={false}
        onChange={async () => {
          if (await onRoleChange(props.value, 'viewOnlyRole', isViewOnly)) setIsViewOnly(!isViewOnly);
        }}
      />
      <Toggle
        className={styles.reactToggle}
        checked={isNotInvited}
        icons={false}
        onChange={async () => {
          if (await onRoleChange(props.value, 'defaultVisitorRoleAll', isNotInvited)) setIsNotInvited(!isNotInvited);
        }}
      />
    </div>
  );
};

export default UserRow;
