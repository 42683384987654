import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectAccounts } from '../../app/redux/applicationAccount.slice.reducer';
import {
  selectAccessToken,
  setAccessToken,
  setAccessTokenAfterOTP,
  setLoggedIn,
} from '../../app/redux/authorization.slice.reducer';
import { pricesInterface, selectPrices, setPrices } from '../../app/redux/prices.slice.reducer';
import { accounts, accountsLocal } from '../../config/accounts';
import { logger } from '../../config/Logger';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import { ResponseStream } from '../../pkg/protobuf/harvester/tr_harvester_service_pb_service';
import { PriceTickerResponse } from '../../pkg/protobuf/v2/harvester/tr_harvester_types_pb';
import ErrorModal from '../modal/ErrorModal';
import GenericModal from '../modal/GenericModal';
import HarvesterModal from '../modal/HarvesterModal';
import Navbar from '../navbar/Navbar';
import styles from './Prices.module.scss';

interface priceSort {
  symbol: 0 | 1 | 2;
  price: 0 | 1 | 2;
  date: 0 | 1 | 2;
}

const Prices = withRouter(({ history }) => {
  const [accountList, setAccountList] = useState(process.env.NODE_ENV === 'production' ? accounts : accountsLocal);
  const [harvesterHealth, setHarvesterHealth] = useState(1);
  const [harvesterDownList, setHarvesterDownList] = useState<string[]>([]);
  const [showHarvesterModal, setShowHarvesterModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [priceTickerMap, setPriceTickerMap] = useState<PriceTickerResponse | undefined>(undefined);
  const [spotSearch, setSpotSearch] = useState<string>('');
  const [futureSearch, setFutureSearch] = useState<string>('');
  const [asset, setAsset] = useState('');
  const [sortSpot, setSortSpot] = useState<priceSort>({
    symbol: 0,
    price: 0,
    date: 0,
  });
  const [sortFuture, setSortFuture] = useState<priceSort>({
    symbol: 0,
    price: 0,
    date: 0,
  });
  const allAccounts = useSelector(selectAccounts);
  const userToken = useSelector(selectAccessToken);
  const allPriceTickerLists = useSelector(selectPrices);
  const dispatch = useDispatch();

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', dateOptions);
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 });

  useEffect(() => {
    const abortController = new AbortController();
    let stream: (() => void | null) | undefined;
    async function fetchHarvesterHealth() {
      try {
        const health = await HarvesterHelper.getHarvesterHealth(2, userToken);
        let healthCombination = true;
        const healthMap: string[] = [];
        if (health?.alive)
          Object.entries(health?.alive)?.forEach((entry, key) => {
            if (!entry) healthMap.push('Spot ' + entry[0]);
            healthCombination = healthCombination && entry[1];
          });
        if (health?.aliveFuture)
          Object.entries(health?.aliveFuture)?.forEach((entry, key) => {
            if (!entry) healthMap.push('Future ' + entry[0]);
            healthCombination = healthCombination && entry[1];
          });
        setHarvesterDownList(healthMap);
        if (healthCombination) setHarvesterHealth(3);
        else setHarvesterHealth(1);
        const priceTicker = await HarvesterHelper.getPriceTickerPromise(2, userToken);
        const pricesTickerInterface: pricesInterface = {
          response: priceTicker || new PriceTickerResponse(),
        };
        dispatch(setPrices(pricesTickerInterface));
        stream = await HarvesterHelper.getUpdatedPriceTickerStream(2, dispatch, setPrices, undefined, userToken);
      } catch (e: any) {
        if (e.message.includes('permission denied')) setShowErrorModal(true);
        if (e.message.includes('access token is invalid')) logout();
        logger.error({ message: e });
      }
    }
    fetchHarvesterHealth();
    setAccountAsset(allAccounts);
    return () => {
      try {
        if (stream) stream();
      } catch (e: any) {
        logger.debug({ message: e.message });
      }
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const tempSpotMap = JSON.parse(
      JSON.stringify(allPriceTickerLists?.response?.spotPriceMap || [], (_, v) =>
        typeof v === 'bigint' ? v.toString() : v,
      ),
    );
    const tempFutureMap = JSON.parse(
      JSON.stringify(allPriceTickerLists?.response?.futurePriceMap || [], (_, v) =>
        typeof v === 'bigint' ? v.toString() : v,
      ),
    );
    // console.log(
    //   Object.entries(tempSpotMap)
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     .sort((a: any, b: any) => {
    //       if (sortSpot.symbol === 0 && sortSpot.price === 0 && sortSpot.date === 0) return;
    //       else if (sortSpot.symbol === 1) return a[0] > b[0] ? -1 : 1;
    //       // else if (sortSpot.price === 1) return a[1].Price - b[1].Price;
    //       // else if (sortSpot.price === 2) return b[1].Price - a[1].Price;
    //       // else if (sortSpot.date === 1) return a[1].receivedTime - b[1].receivedTime;
    //       // else if (sortSpot.date === 2) return b[1].receivedTime - a[1].receivedTime;
    //       return;
    //     })
    //     .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
    // );
    const sortSpotMap = Object.entries(tempSpotMap)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .sort((a: any, b: any) => {
        // console.log(a[1].receivedTime);
        if (sortSpot.symbol === 0 && sortSpot.price === 0 && sortSpot.date === 0) return a[0] > b[0] ? 1 : -1;
        else if (sortSpot.symbol === 1) return a[0] > b[0] ? -1 : 1;
        else if (sortSpot.price === 1) return b[1].price - a[1].price;
        else if (sortSpot.price === 2) return a[1].price - b[1].price;
        else if (sortSpot.date === 1) return Number(a[1].receivedTime) - Number(b[1].receivedTime);
        else if (sortSpot.date === 2) return b[1].receivedTime - a[1].receivedTime;
        return;
      })
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    // console.log(sortSpotMap);
    const sortFutureMap = Object.entries(tempFutureMap)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .sort((a: any, b: any) => {
        if (sortFuture.symbol === 0 && sortFuture.price === 0 && sortFuture.date === 0) return a[0] > b[0] ? 1 : -1;
        else if (sortFuture.symbol === 1) return a[0] > b[0] ? -1 : 1;
        else if (sortFuture.price === 1) return b[1].price - a[1].price;
        else if (sortFuture.price === 2) return a[1].price - b[1].price;
        else if (sortFuture.date === 1) return a[1].receivedTime - b[1].receivedTime;
        else if (sortFuture.date === 2) return b[1].receivedTime - a[1].receivedTime;
        return;
      })
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const priceTickerMapTemp = JSON.parse(
      JSON.stringify(allPriceTickerLists?.response || [], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    priceTickerMapTemp.spotPriceMap = sortSpotMap;
    priceTickerMapTemp.futurePriceMap = sortFutureMap;
    setPriceTickerMap(priceTickerMapTemp || allPriceTickerLists.response);
    return () => {
      abortController.abort();
    };
  }, [allPriceTickerLists]);

  const setAccountAsset = async (allAccounts: any) => {
    try {
      let account: any;
      for (account in allAccounts) {
        const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(
          allAccounts[account]?.executor,
          userToken,
        );
        let asset = 'USDT';
        if (executorSymbolsList && executorSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
        else if (executorSymbolsList && executorSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
        else if (executorSymbolsList && executorSymbolsList.symbolsBusd.length > 0) asset = 'BUSD';
        setAsset(asset);
      }
    } catch (e) {
      logger.error({ message: e });
    }
  };

  const logout = () => {
    dispatch(setLoggedIn(false));
    dispatch(setAccessToken(''));
    dispatch(setAccessTokenAfterOTP(''));
    window.localStorage.clear();
    window.location.pathname = '/';
  };

  const sortMap = (sortType: string, tradeType: string) => {
    const priceSortOptions: priceSort = { symbol: 0, price: 0, date: 0 };

    if (tradeType == 'spot') {
      if (sortType === 'symbol') {
        priceSortOptions.symbol = sortSpot.symbol === 1 ? 0 : 1;
      } else if (sortType === 'price') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        priceSortOptions.price = sortSpot.price >= 2 ? 0 : sortSpot.price + 1;
      } else if (sortType === 'date') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        priceSortOptions.date = sortSpot.date >= 2 ? 0 : sortSpot.date + 1;
      }
      setSortSpot(priceSortOptions);
    } else if (tradeType === 'future') {
      if (sortType === 'symbol') {
        priceSortOptions.symbol = sortFuture.symbol === 1 ? 0 : 1;
      } else if (sortType === 'price') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        priceSortOptions.price = sortFuture.price >= 2 ? 0 : sortFuture.price + 1;
      } else if (sortType === 'date') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        priceSortOptions.date = sortFuture.date >= 2 ? 0 : sortFuture.date + 1;
      }
      setSortFuture(priceSortOptions);
    }
  };

  return (
    <div className={styles.pricesWrapper}>
      <Navbar />
      <div className={styles.pricesContent}>
        <div className={styles.header}>
          <h3>
            Harvester
            {harvesterHealth === 1 && (
              <span
                role={'button'}
                style={{ cursor: 'pointer' }}
                tabIndex={0}
                onMouseDown={() => {
                  setShowHarvesterModal(true);
                }}
              >
                <img src="/img/warning.svg" alt="warning" />
              </span>
            )}
            <div className={styles.harvesterHealthStatus}>
              <h4
                className={
                  harvesterHealth > 1 ? styles.harvesterHealthStatusActive : styles.harvesterHealthStatusFailure
                }
              >
                {harvesterHealth > 1 ? 'Alive' : 'Failure'}
              </h4>
              {Array(harvesterHealth)
                .fill(0)
                .map((_, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        styles.harvesterHealthLevel +
                        ' ' +
                        (harvesterHealth > 1 ? styles.harvesterHealthLevelActive : styles.harvesterHealthLevelFailure)
                      }
                    />
                  );
                })}
            </div>
          </h3>
          <div
            role={'button'}
            tabIndex={0}
            className={styles.logout}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) logout();
            }}
          >
            <a>Logout</a>
          </div>
        </div>
        <div className={styles.totalsSection}>
          <div className={styles.totalsSectionTop}>
            <h3>Spot Price List</h3>
            <div className={styles.inputContainer + ' floatingInputContainer'}>
              <img src="/img/search.svg" alt="search" />
              <input
                type="text"
                placeholder={'Search'}
                className={styles.fieldInput + ' field-input'}
                onChange={(e) => {
                  setSpotSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.totalsSectionMain}>
            <div className={styles.accountContent}>
              <div className={styles.pricesInfo}>
                <h4>#</h4>
                <h4>
                  Symbol{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('symbol', 'spot')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>First daily Price</h4>
                <h4>
                  Price{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('price', 'spot')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>Price Change %</h4>
                <h4>
                  Received Time{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('date', 'spot')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>
                  Trade Time{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('date', 'spot')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>From</h4>
              </div>
              <hr />
              {priceTickerMap?.spotPriceMap &&
                Object.entries(priceTickerMap?.spotPriceMap)
                  .filter((s: any) => {
                    return s[0].includes(spotSearch.toUpperCase());
                  })
                  .map((v, k) => {
                    const changePercent = (v[1].price - v[1].openPrice) / v[1].openPrice;
                    return (
                      <div key={k} className={styles.pricesInfo}>
                        <h5>{k + 1}</h5>
                        <h5>{v[0]}</h5>
                        <h5 style={{ display: 'flex', gap: '12px' }}>
                          <span>{v[1].openPrice}</span> <span>{asset}</span>
                        </h5>
                        <h5 style={{ display: 'flex', gap: '12px' }}>
                          <span>{v[1].price}</span> <span>{asset}</span>
                        </h5>
                        <h5
                          className={
                            changePercent >= 0
                              ? styles.harvesterHealthStatusActive
                              : styles.harvesterHealthStatusFailure
                          }
                        >
                          {NumFormatter.format(changePercent)}
                        </h5>
                        <h5>{dateFormatter.format(Number(v[1].receivedTime))}</h5>
                        <h5>{dateFormatter.format(Number(v[1].tradeTime))}</h5>
                        <h5>{v[1].way}</h5>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className={styles.totalsSectionTop}>
            <h3>Future Price List</h3>
            <div className={styles.inputContainer + ' floatingInputContainer'}>
              <img src="/img/search.svg" alt="search" />
              <input
                type="text"
                placeholder={'Search'}
                className={styles.fieldInput + ' field-input'}
                onChange={(e) => {
                  setFutureSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.totalsSectionMain}>
            <div className={styles.accountContent}>
              <div className={styles.pricesInfo}>
                <h4>#</h4>
                <h4>
                  Symbol{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('symbol', 'future')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>First daily Price</h4>
                <h4>
                  Price{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('price', 'future')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>Price Change %</h4>
                <h4>
                  Received Time{' '}
                  <div role={'button'} tabIndex={0} onMouseDown={() => sortMap('date', 'future')}>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>
                  Trade Time{' '}
                  <div>
                    <img src="/img/sort.png" style={{ cursor: 'pointer' }} alt="sort" />{' '}
                  </div>
                </h4>
                <h4>From</h4>
              </div>
              <hr />
              {priceTickerMap?.futurePriceMap &&
                Object.entries(priceTickerMap?.futurePriceMap)
                  .filter((s: any) => {
                    return s[0].includes(futureSearch.toUpperCase());
                  })
                  .map((v, k) => {
                    const changePercent = (v[1].price - v[1].openPrice) / v[1].openPrice;
                    return (
                      <div key={k} className={styles.pricesInfo}>
                        <h5>{k + 1}</h5>
                        <h5>{v[0]}</h5>
                        <h5 style={{ display: 'flex', gap: '12px' }}>
                          {v[1].openPrice} <span>{asset}</span>
                        </h5>
                        <h5 style={{ display: 'flex', gap: '12px' }}>
                          <span>{v[1].price}</span> <span>{asset}</span>
                        </h5>
                        <h5
                          className={
                            changePercent >= 0
                              ? styles.harvesterHealthStatusActive
                              : styles.harvesterHealthStatusFailure
                          }
                        >
                          {NumFormatter.format(changePercent)}
                        </h5>
                        <h5>{dateFormatter.format(Number(v[1].receivedTime))}</h5>
                        <h5>{dateFormatter.format(Number(v[1].tradeTime))}</h5>
                        <h5>{v[1].way}</h5>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
      {showHarvesterModal && (
        <GenericModal
          component={HarvesterModal}
          show={showHarvesterModal}
          setEdit={setShowHarvesterModal}
          width={'15vw'}
          harvesters={harvesterDownList}
        />
      )}
      {showErrorModal && <GenericModal component={ErrorModal} show={showErrorModal} width={'65vw'} />}
    </div>
  );
});

export default Prices;
