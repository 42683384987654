import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import toast from 'react-simple-toasts';

import { accountInt, selectAccounts } from '../../app/redux/applicationAccount.slice.reducer';
import {
  selectAccessToken,
  setAccessToken,
  setAccessTokenAfterOTP,
  setLoggedIn,
} from '../../app/redux/authorization.slice.reducer';
import {
  addOrder,
  orderInterface,
  removeOrder,
  selectNotionalBuy,
  selectNotionalBuyMinute,
  selectNotionalSell,
  selectNotionalSellMinute,
  selectOpenOrders,
  setNotionalBuy,
  setNotionalBuyMinute,
  setNotionalSell,
  setNotionalSellMinute,
  setOrders,
} from '../../app/redux/orders.slice.reducer';
import {
  pnlTradesInterface,
  selectPnlCalcTrades,
  setPnlTrades,
  updatePnlTradesPrices,
} from '../../app/redux/pnlCalcTrades.slice.reducer';
import { pricesInterface, setPrices } from '../../app/redux/prices.slice.reducer';
import { logger } from '../../config/Logger';
import { Status } from '../../config/utils';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import { GetSumsResponse } from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { PriceTickerResponse } from '../../pkg/protobuf/v2/harvester/tr_harvester_types_pb';
import PnlCalculatorFragment from '../fragments/PnlCalculatorFragment/PnlCalculatorFragment';
import Navbar from '../navbar/Navbar';
import styles from './Orders.module.scss';

export type SumsResponseType = {
  accountName: string;
  sums: GetSumsResponse | undefined;
};

const Orders = withRouter(({ history }) => {
  const allAccounts = useSelector(selectAccounts);
  const [renderOpenOrders, setRenderOpenOrders] = useState<Map<string, orderInterface[]>>(() => {
    const ordersMapTemp = new Map();
    for (const account of allAccounts) {
      ordersMapTemp.set(account.name, []);
    }
    return ordersMapTemp;
  });
  const [isRightSectionOpen, setIsRightSectionOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [spotInvestmentState, setSpotInvestmentState] = useState<number>(0);
  const [spotPnlState, setSpotPnlState] = useState<number>(0);
  const [futureInvestmentState, setFutureInvestmentState] = useState<number>(0);
  const [futurePnlState, setFuturePnlState] = useState<number>(0);
  const [sums, setSums] = useState<Array<SumsResponseType>>([]);
  const openOrders = useSelector(selectOpenOrders);
  const notionalBuy = useSelector(selectNotionalBuy);
  const notionalSell = useSelector(selectNotionalSell);
  const notionalBuyMinute = useSelector(selectNotionalBuyMinute);
  const notionalSellMinute = useSelector(selectNotionalSellMinute);
  const userToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  let openOrdersStream: (() => void | null) | undefined;
  const sumsStreams: Array<(() => void | null) | undefined> = [];
  let updatedPriceStream: (() => void | null) | undefined;

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', dateOptions);
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  const [sourceOptions, setSourceOptions] = useState<any[]>([]);

  const onSelectOptionChange = (e: any, setValue: any) => {
    const x = e !== null ? e.value : null;
    setValue(x);
  };

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(setOrders([]));
    const m: pnlTradesInterface[] = [];
    dispatch(setPnlTrades(m));
    setAccounts();
    return () => {
      try {
        if (sumsStreams.length > 0) {
          for (const sumsStream of sumsStreams) {
            if (sumsStream) {
              sumsStream();
            }
          }
        }
      } catch (e: any) {
        logger.debug({ message: e });
      }
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // This function transforms allAccounts into the desired options format
    const options: any[] = allAccounts.map((account: { name: any }, index: any) => ({
      value: index, // Use index as value
      label: account.name,
    }));

    setSourceOptions(options);
  }, [allAccounts]);

  useEffect(() => {
    const abortController = new AbortController();
    renderOrders(openOrders);
    return () => {
      abortController.abort();
    };
  }, [openOrders]);

  useEffect(() => {
    setAccountAsset();
    return () => {
      dispatch(setOrders([]));
      try {
        if (openOrdersStream) openOrdersStream();
      } catch (e: any) {
        logger.debug({ message: e });
      }
      try {
        if (updatedPriceStream) updatedPriceStream();
      } catch (e: any) {
        logger.debug({ message: e });
      }
    };
  }, [selectedAccount]);

  useEffect(() => {
    const calculatedSpotInvestmentSum = sums.reduce((accumulator, sum) => {
      if (sum.sums) {
        return accumulator + sum.sums.investedSpotSum;
      }
      return accumulator;
    }, 0);
    const calculatedFuturesInvestmentSum = sums.reduce((accumulator, sum) => {
      if (sum.sums) {
        return accumulator + sum.sums.investedFuturesSum;
      }
      return accumulator;
    }, 0);
    const calculatedSpotPnlSum = sums.reduce((accumulator, sum) => {
      if (sum.sums) {
        return accumulator + sum.sums.pnlSpotSum;
      }
      return accumulator;
    }, 0);
    const calculatedFuturesPnlSum = sums.reduce((accumulator, sum) => {
      if (sum.sums) {
        return accumulator + sum.sums.pnlFuturesSum;
      }
      return accumulator;
    }, 0);

    setSpotInvestmentState(calculatedSpotInvestmentSum);
    setFutureInvestmentState(calculatedFuturesInvestmentSum);
    setSpotPnlState(calculatedSpotPnlSum);
    setFuturePnlState(calculatedFuturesPnlSum);
  }, [sums]);

  const setAccounts = async () => {
    for (const acc of allAccounts) {
      const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(acc?.executor, userToken);
      let asset = '';
      if (executorSymbolsList && executorSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
      else if (executorSymbolsList && executorSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
      else if (executorSymbolsList && executorSymbolsList.symbolsBusd.length > 0) asset = 'BUSD';
      const sumsResponse = await ExecutorHelper.getSums(acc?.executor, userToken);

      setSums((prevState) => {
        const temp = JSON.parse(JSON.stringify(prevState, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
        const index = temp.findIndex((p: any) => p.accountName === acc.name);
        if (index !== -1) {
          temp.splice(index, 1);
        }
        temp.push({ accountName: acc.name, sums: sumsResponse });
        return temp;
      });
      sumsStreams.push(await ExecutorHelper.getSumsStream(sums, setSums, acc.executor, acc.name, asset, userToken));
    }
  };
  const setAccountAsset = async () => {
    try {
      const account = [...allAccounts][selectedAccount];
      if (account !== undefined) {
        const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(account?.executor, userToken);

        const priceTicker = await HarvesterHelper.getPriceTickerPromise(account.server, userToken);
        if (priceTicker) {
          const pricesTickerInterface: pricesInterface = {
            response: priceTicker || new PriceTickerResponse(),
          };
          dispatch(updatePnlTradesPrices(pricesTickerInterface));
        } else toast("Can't get harvester prices");

        let asset = '';
        if (executorSymbolsList && executorSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
        else if (executorSymbolsList && executorSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
        else if (executorSymbolsList && executorSymbolsList.symbolsBusd.length > 0) asset = 'BUSD';
        openOrdersStream = await ExecutorHelper.getOpenOrdersStream(
          dispatch,
          addOrder,
          removeOrder,
          setNotionalBuy,
          setNotionalSell,
          setNotionalBuyMinute,
          setNotionalSellMinute,
          account.executor,
          account.name,
          asset,
          userToken,
        );
        updatedPriceStream = await HarvesterHelper.getUpdatedPriceTickerPeriodicallyStream(
          account.server,
          dispatch,
          setPrices,
          updatePnlTradesPrices,
          userToken,
        );
      }
    } catch (e) {
      logger.error({ message: 'CLOSING STREAM' + e });
    }
  };

  function renderOrders(openOrders: any) {
    const ordersMapTemp = new Map();
    for (const account of allAccounts) {
      ordersMapTemp.set(account.name, []);
    }
    if (openOrders?.length > 0) {
      let order: number;
      for (order = 0; order < openOrders.length; order++) {
        if (openOrders[order].order.Status === 0) continue;
        const index = ordersMapTemp
          .get(openOrders[order].accountKey)
          ?.findIndex((o: any) => o.order?.OrderID === openOrders[order].order.OrderID);
        if (!ordersMapTemp.has(openOrders[order].accountKey)) {
          ordersMapTemp.set(openOrders[order].accountKey, [openOrders[order]]);
        } else if (ordersMapTemp.has(openOrders[order].accountKey) && index !== undefined && index > -1) {
          const orders = JSON.parse(
            JSON.stringify(ordersMapTemp.get(openOrders[order].accountKey), (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            ),
          );
          orders[index] = JSON.parse(
            JSON.stringify(openOrders[order], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
          );
          ordersMapTemp.set(
            JSON.parse(JSON.stringify(openOrders[order], (_, v) => (typeof v === 'bigint' ? v.toString() : v)))
              .accountKey,
            orders,
          );
        } else {
          const orders = JSON.parse(
            JSON.stringify(ordersMapTemp.get(openOrders[order].accountKey), (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            ),
          );
          ordersMapTemp.set(openOrders[order].accountKey, [...orders, openOrders[order]]);
        }
      }
    }
    setRenderOpenOrders(ordersMapTemp);
  }

  const logout = () => {
    dispatch(setLoggedIn(false));
    dispatch(setAccessToken(''));
    dispatch(setAccessTokenAfterOTP(''));
    window.localStorage.clear();
    window.location.pathname = '/';
  };

  const renderOrdersDiv = (value: orderInterface[] | undefined, name: string) => {
    if (value) {
      let sorted: orderInterface[] = [];
      let spotList: orderInterface[] = [];
      let futureList: orderInterface[] = [];
      if (value?.length > 1) {
        sorted = value?.sort((a: orderInterface, b: orderInterface) => {
          if (a.order.UpdateTime !== b.order.UpdateTime) {
            return a.order.UpdateTime > b.order.UpdateTime ? -1 : 1;
          }
          // Secondary sorting based on OrderID or another unique attribute
          return a.order.OrderID < b.order.OrderID ? -1 : 1;
        });
      } else if (value?.length === 1) {
        sorted = value;
      }
      spotList = sorted.filter((v) => {
        return v.order.orderType === 1;
      });
      futureList = sorted.filter((v) => {
        return v.order.orderType === 2;
      });
      return (
        <div className={styles.accountContent}>
          <div className={styles.accountInfo}>
            <div className={styles.accountProfile}>
              <div className={styles.name}>
                <img src="/img/profile.svg" alt="profile" />
                <h4>{name}</h4>
              </div>
            </div>
          </div>
          <hr />
          <h4>Spot Orders</h4>
          <hr />
          <div className={styles.ordersInfo}>
            <h4>#</h4>
            <h4>Order ID</h4>
            <h4>Symbol</h4>
            <h4>Side</h4>
            <h4>Price</h4>
            <h4>Quantity</h4>
            <h4>Status</h4>
            <h4>Time Executed</h4>
            <h4>Actions</h4>
          </div>
          {spotList.length > 0 ? (
            spotList?.map((order: orderInterface, k: number) => {
              return (
                <div key={'spot' + k} className={styles.ordersInfo}>
                  <h5>{k + 1}</h5>
                  <h5>{order.order.OrderID}</h5>
                  <h5>{order.order.symbol?.symbolName}</h5>
                  <h5>{order.order.Side}</h5>
                  <h5>{order.order.Price}</h5>
                  <h5>{order.order.OriginalQuantity}</h5>
                  <h5>{Status[order.order.Status]}</h5>
                  <h5>
                    {Number(order.order.UpdateTime)
                      ? dateFormatter.format(new Date(Number(order.order.UpdateTime)))
                      : ''}
                  </h5>
                  {order.order.Status === 1 || order.order.Status === 2 ? (
                    <h5>
                      <button
                        className={styles.button}
                        disabled={order.order.Status !== 1 && order.order.Status !== 2}
                        onClick={async () => {
                          if (order?.order?.OrderID && order?.order?.symbol?.symbolName) {
                            const response = await ExecutorHelper.CancelOrder(
                              allAccounts[selectedAccount].executor,
                              userToken,
                              order?.order?.OrderID,
                              order?.order?.symbol?.symbolName,
                            );
                            if (response) {
                              dispatch(
                                removeOrder({
                                  accountKey: allAccounts[selectedAccount].name,
                                  order: order.order,
                                }),
                              );
                              toast(`Order Canceled`);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </h5>
                  ) : (
                    <h5>{''}</h5>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.accountContent}>
              <h4>No Open Orders yet</h4>
            </div>
          )}
          <hr />
          <h4>Future Orders</h4>
          <hr />
          <div className={styles.ordersInfo}>
            <h4>#</h4>
            <h4>Order ID</h4>
            <h4>Symbol</h4>
            <h4>Side</h4>
            <h4>Price</h4>
            <h4>Quantity</h4>
            <h4>Status</h4>
            <h4>Time Executed</h4>
          </div>
          {futureList.length > 0 ? (
            futureList?.map((order: orderInterface, k: number) => {
              return (
                <div key={'future' + k} className={styles.ordersInfo}>
                  <h5>{k + 1}</h5>
                  <h5>{order.order.OrderID}</h5>
                  <h5>{order.order.symbol?.symbolName}</h5>
                  <h5>{order.order.Side}</h5>
                  <h5>{order.order.Price}</h5>
                  <h5>{order.order.OriginalQuantity}</h5>
                  <h5>{Status[order.order.Status]}</h5>
                  <h5>
                    {Number(order.order.UpdateTime)
                      ? dateFormatter.format(new Date(Number(order.order.UpdateTime)))
                      : ''}
                  </h5>
                </div>
              );
            })
          ) : (
            <div className={styles.accountContent}>
              <h4>No Open Orders yet</h4>
            </div>
          )}
        </div>
      );
    } else
      return (
        <div className={styles.accountContent}>
          <h4>No Open Orders yet</h4>
        </div>
      );
  };

  return (
    <div className={styles.ordersWrapper}>
      <Navbar />
      <div className={styles.ordersContent}>
        <div className={styles.header} tabIndex={0} role={'button'}>
          <h3>Open Orders</h3>
          <div
            role={'button'}
            tabIndex={0}
            className={styles.logout}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) logout();
            }}
          >
            <a>Logout</a>
          </div>
        </div>
        <div className={styles.totalsSection}>
          <div className={styles.totalsSectionTop}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <h3>Accounts</h3>
              <h4>Spot Investment: {NumFormatter.format(spotInvestmentState)}</h4>
              <h4>Spot Pnl: {NumFormatter.format(spotPnlState)}</h4>
              <h4>Futures Investment: {NumFormatter.format(futureInvestmentState)}</h4>
              <h4>Futures Pnl: {NumFormatter.format(futurePnlState)}</h4>
              <h4>Total Investment: {NumFormatter.format(spotInvestmentState + futureInvestmentState)}</h4>
              <h4>Total Pnl: {NumFormatter.format(spotPnlState + futurePnlState)}</h4>
            </div>
            <button className={styles.button} onClick={() => setIsRightSectionOpen(!isRightSectionOpen)}>
              Open PNL Calculator
            </button>
          </div>
          <Select
            className={'react-select react-selectPadding'}
            options={sourceOptions}
            classNamePrefix={'react-select'}
            value={sourceOptions[selectedAccount]}
            isClearable={false}
            isSearchable={true}
            onChange={(e) => onSelectOptionChange(e, setSelectedAccount)}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: '0 32px' }}>
            <h4>Notional Buy: {NumFormatter.format(notionalBuy || 0)}</h4>
            <h4>Notional Sell: {NumFormatter.format(notionalSell || 0)}</h4>
            <h4>Notional Buy Minute: {NumFormatter.format(notionalBuyMinute || 0)}</h4>
            <h4>Notional Sell Minute: {NumFormatter.format(notionalSellMinute || 0)}</h4>
          </div>
          <div className={styles.totalsSectionMain}>
            {selectedAccount !== null && sourceOptions.length > 0
              ? renderOrdersDiv(
                  renderOpenOrders.get(sourceOptions[selectedAccount].label),
                  sourceOptions[selectedAccount].label,
                )
              : ''}
          </div>
          {isRightSectionOpen && (
            <PnlCalculatorFragment
              isRightSectionOpen={isRightSectionOpen}
              setIsRightSectionOpen={setIsRightSectionOpen}
              account={allAccounts[selectedAccount]}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Orders;
