import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { OpenOrder } from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { RootState } from '../store';

export interface orderInterface {
  accountKey: string;
  order: OpenOrder;
}

export interface ordersInterface {
  accountKey: string;
  orders: OpenOrder[];
  notionalBuy: number;
  notionalSell: number;
  notionalBuyMinute: number;
  notionalSellMinute: number;
}

export interface initialStateInterface {
  orders: orderInterface[];
  notionalBuy: number;
  notionalSell: number;
  notionalBuyMinute: number;
  notionalSellMinute: number;
}

export const initialState: initialStateInterface = {
  orders: [],
  notionalBuy: 0,
  notionalSell: 0,
  notionalBuyMinute: 0,
  notionalSellMinute: 0,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<orderInterface[] | never[]>) => {
      state.orders = action.payload;
    },
    setNotionalBuy: (state, action: PayloadAction<number>) => {
      state.notionalBuy = action.payload;
    },
    setNotionalSell: (state, action: PayloadAction<number>) => {
      state.notionalSell = action.payload;
    },
    setNotionalBuyMinute: (state, action: PayloadAction<number>) => {
      state.notionalBuyMinute = action.payload;
    },
    setNotionalSellMinute: (state, action: PayloadAction<number>) => {
      state.notionalSellMinute = action.payload;
    },
    addOrder: (state, action: PayloadAction<ordersInterface | undefined>) => {
      const currentState = JSON.parse(
        JSON.stringify(state.orders, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );

      if (!action.payload?.orders || action.payload.orders.length < 1) {
        console.log('no update skip', action.payload);
        return;
      }
      const orders = action.payload.orders;
      for (let i = 0; i < orders.length; i++) {
        const newElement = orders[i];
        const index = currentState.findIndex((p: orderInterface) => p?.order?.OrderID === newElement?.OrderID);
        // if exists then replace previous value with new one
        if (index >= 0) {
          currentState[index].accountKey = action.payload.accountKey;
          currentState[index].order = newElement;
        } else {
          // append
          currentState.push({ accountKey: action.payload.accountKey, order: newElement });
        }
      }
      state.orders = currentState;
      state.notionalBuy = action.payload.notionalBuy;
      state.notionalSell = action.payload.notionalSell;
      state.notionalBuyMinute = action.payload.notionalBuyMinute;
      state.notionalSellMinute = action.payload.notionalSellMinute;
    },
    removeOrder: (state, action: PayloadAction<orderInterface | undefined>) => {
      let currentState = JSON.parse(JSON.stringify(state.orders, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      if (currentState === undefined) currentState = [];
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      state.orders = currentState.filter((o: any) => {
        return o.order.OrderID !== newElement.order.OrderID;
      });
    },
  },
});

export const selectOpenOrders = (state: RootState) => state.orders.orders;
export const selectNotionalBuy = (state: RootState) => state.orders.notionalBuy;
export const selectNotionalSell = (state: RootState) => state.orders.notionalSell;
export const selectNotionalBuyMinute = (state: RootState) => state.orders.notionalBuyMinute;
export const selectNotionalSellMinute = (state: RootState) => state.orders.notionalSellMinute;

export const {
  setOrders,
  addOrder,
  removeOrder,
  setNotionalBuy,
  setNotionalSell,
  setNotionalBuyMinute,
  setNotionalSellMinute,
} = ordersSlice.actions;

export default ordersSlice.reducer;
