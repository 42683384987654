import jwt_decode from 'jwt-decode';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toast from 'react-simple-toasts';
import ReactTooltip from 'react-tooltip';

import {
  accountInt,
  addAccount,
  addBrainHealth,
  addConfigs,
  addExHealth,
  addFutureAlgoParams,
  addPnls,
  selectAccounts,
  selectPNLDays,
  setAccounts,
} from '../../app/redux/applicationAccount.slice.reducer';
import {
  selectAccessToken,
  setAccessToken,
  setAccessTokenAfterOTP,
  setLoggedIn,
  user,
} from '../../app/redux/authorization.slice.reducer';
import { accounts, accountsLocal } from '../../config/accounts';
import { logger } from '../../config/Logger';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import { BrainConfigsResponse } from '../../pkg/protobuf/v2/brain/tr_brain_types_pb';
import {
  ExecutorConfigsRequest,
  ExecutorConfigsResponse,
  ExecutorPNLResponse,
} from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { BrainSyncResponse, GetDiskUsageResponse } from '../../pkg/protobuf/v2/harvester/tr_harvester_types_pb';
import AccountComponent from '../account/AccountComponent';
import ErrorModal from '../modal/ErrorModal';
import GenericModal from '../modal/GenericModal';
import GlobalSymbolModal from '../modal/GlobalSymbolsModal';
import HarvesterModal from '../modal/HarvesterModal';
import Navbar from '../navbar/Navbar';
import styles from './Dashboard.module.scss';

const Dashboard = withRouter(({ history }) => {
  const [harvesterHealth, setHarvesterHealth] = useState(1);
  const [harvesterHealthDO, setHarvesterHealthDO] = useState(1);
  const [diskUsage, setDiskUsage] = useState<GetDiskUsageResponse | undefined>();
  const [brainSync, setBrainSync] = useState<BrainSyncResponse | undefined>();
  const [accountList, setAccountList] = useState(process.env.NODE_ENV === 'production' ? accounts : accountsLocal);
  const allAccounts = useSelector(selectAccounts);
  const [multiSelectedAccounts, setMultiSelectedAccounts] = useState<accountInt[]>([]);
  const [allAccountsState, setAllAccountsState] = useState(allAccounts);
  const [harvesterDownList, setHarvesterDownList] = useState<string[]>([]);
  const [harvesterDownListDO, setHarvesterDownListDO] = useState<string[]>([]);
  const [isExpansionError, setIsExpansionError] = useState(false);
  const [isHoldError, setIsHoldError] = useState(false);
  const [isZigZagError, setIsZigZagError] = useState(false);
  const [isToBeSaved, setIsToBeSaved] = useState(false);
  const [isToBeSavedUsdt, setIsToBeSavedUsdt] = useState(false);
  const [isToBeSavedTelegramOtp, setIsToBeSavedTelegramOtp] = useState(false);
  const [isToBeSavedBusd, setIsToBeSavedBusd] = useState(false);
  const [btcValue, setBtcValue] = useState('10%');
  const [usdtValue, setUsdtValue] = useState('10%');
  const [telegramOtp, setTelegramOtp] = useState('');
  const [deCoin, setDeCoin] = useState('TESTUSDT');
  const [ignoredSpotCoin, setIgnoredSpotCoin] = useState('TESTUSDT');
  const [ignoredFutureCoin, setIgnoredFutureCoin] = useState('TESTUSDT');
  const [busdValue, setBusdValue] = useState('10%');
  const [pnlTotals, setPnlTotals] = useState<any>(new ExecutorPNLResponse());
  const [totalVpls, setTotalVpls] = useState(0);
  const [isAnyVplZero, setIsAnyVplZero] = useState(false);
  const [isListOrdered, setIsListOrdered] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showHarvesterModal, setShowHarvesterModal] = useState(false);
  const [showHarvesterModalDO, setShowHarvesterModalDO] = useState(false);
  const [binanceSymbolsModal, setBinanceSymbolsModal] = useState(false);
  const [dOSymbolsModal, setDOSymbolsModal] = useState(false);
  const [isExpansionBtnDisabled, setIsExpansionBtnDisabled] = useState(true);
  const [trueCounter, setTrueCounter] = useState(0);
  const [falseCounter, setFalseCounter] = useState(0);
  const [assetsList, setAssetsList] = useState<any>({});
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const userToken = useSelector(selectAccessToken);
  const decoded: user = jwt_decode(userToken);
  const days = useSelector(selectPNLDays);
  const dispatch = useDispatch();

  const [getRunningSymbolListTimeLimiter, setGetRunningSymbolListTimeLimiter] = useState(new Date().getTime());

  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

  useEffect(() => {
    const superUserIndex = decoded.roles?.findIndex((value) => value.name === 'superuser');
    const viewOnlyIndex = decoded.roles?.findIndex((value) => value.name === 'viewOnlyRole');
    setIsViewOnly(viewOnlyIndex !== undefined && viewOnlyIndex > -1);
    if (superUserIndex === undefined || viewOnlyIndex == undefined || (superUserIndex < 0 && viewOnlyIndex < 0))
      setShowErrorModal(true);
    const abortController = new AbortController();
    dispatch(setAccounts([]));
    async function fetchHarvesterHealth(server: number, setHarvesterDownList: any, setHarvesterHealth: any) {
      try {
        const health = await HarvesterHelper.getHarvesterHealth(server, userToken);
        let healthCombination = true;
        const healthMap: string[] = [];
        if (health?.alive)
          Object.entries(health?.alive)?.forEach((entry, key) => {
            if (!entry[1]) healthMap.push('Spot ' + entry[0]);
            healthCombination = healthCombination && entry[1];
          });
        if (health?.aliveFuture)
          Object.entries(health?.aliveFuture)?.forEach((entry, key) => {
            if (!entry[1]) healthMap.push('Future ' + entry[0]);
            healthCombination = healthCombination && entry[1];
          });
        setHarvesterDownList(healthMap);
        if (healthCombination) setHarvesterHealth(3);
        else setHarvesterHealth(1);
        const diskUsage = await HarvesterHelper.getDiskUsage(2, userToken);
        setDiskUsage(diskUsage);
        const brainSync = await HarvesterHelper.getBrainSync(2, userToken);
        setBrainSync(brainSync);
      } catch (e: any) {
        if (e.message.includes('permission denied')) setShowErrorModal(true);
        if (e.message.includes('access token is invalid')) logout();
        logger.error({ message: e });
      }
    }
    // fetchHarvesterHealth(1, setHarvesterDownList, setHarvesterHealth);
    fetchHarvesterHealth(2, setHarvesterDownListDO, setHarvesterHealthDO);
    const x: accountInt[] = [];
    for (let i = 0; i < accountList.length; i++) {
      //initializing account
      const acc: accountInt = {
        key: accountList[i].key,
        name: accountList[i].name,
        brain: accountList[i].brain,
        executor: accountList[i].executor,
        executorRest: accountList[i].executorRest,
        server: accountList[i].server,
        brainHealth: false,
        executorHealth: false,
        pnlDays: 0,
        pnl: new ExecutorPNLResponse(),
        configs: new ExecutorConfigsResponse(),
        futurealgoparams: new BrainConfigsResponse(),
      };
      dispatch(addAccount(acc));
      x.push(acc);
    }
    setAllAccountsState(x);
    async function fetchAccountsWithDelay() {
      setIsExpansionBtnDisabled(true);
      fetchAccounts(x);
      await delay(2000);
      setIsExpansionBtnDisabled(false);
    }
    fetchAccountsWithDelay();
    const accountsInterval = setInterval(() => {
      fetchHarvesterHealth(2, setHarvesterDownListDO, setHarvesterHealthDO);
      fetchAccounts(x);
    }, 90000);
    return () => {
      clearInterval(accountsInterval);
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    //Warning if there is an account without remote configs
    if (trueCounter > 0 && falseCounter > 0) {
      setIsExpansionError(true);
    } else if ((trueCounter > 0 && falseCounter === 0) || (trueCounter === 0 && falseCounter > 0)) {
      setIsExpansionError(false);
    }
    return () => {
      abortController.abort();
    };
  }, [trueCounter, falseCounter]);

  useEffect(() => {
    const abortController = new AbortController();
    if (allAccounts) {
      setAllAccountsState(allAccounts);
      let totalUsdt = 0;
      let totalBusd = 0;
      let totalBtc = 0;
      let spotPercentage = 0;
      let totalPreviousUsdt = 0;
      let totalPreviousBusd = 0;
      let totalPreviousBtc = 0;
      let totalVpls = 0;
      setIsAnyVplZero(false);
      for (const acc of allAccounts) {
        totalUsdt += acc.pnl.currentTotalUsdtValue;
        totalBusd += acc.pnl.currentTotalBusdValue;
        totalBtc += acc.pnl.currentBtcValue;
        totalPreviousUsdt += acc.pnl.previousTotalUsdtValue;
        totalPreviousBusd += acc.pnl.previousTotalBusdValue;
        totalPreviousBtc += acc.pnl.previousBtcValue;
        totalVpls += acc.configs.usdtValuePerLevel;
        if (!acc.configs.usdtValuePerLevel) setIsAnyVplZero(true);
        setAccountAsset(acc);
      }

      for (const acc of allAccounts) {
        if (acc.pnl.currentTotalUsdtValue > 0)
          spotPercentage += (acc.pnl.currentSpotInvestedPercentage * acc.pnl.currentTotalUsdtValue) / (totalUsdt || 1);
        else if (acc.pnl.currentTotalBusdValue > 0)
          spotPercentage += (acc.pnl.currentSpotInvestedPercentage * acc.pnl.currentTotalBusdValue) / (totalBusd || 1);
      }
      setPnlTotals({
        ...pnlTotals,
        currentTotalUsdtValue: totalUsdt,
        currentTotalBusdValue: totalBusd,
        currentBtcValue: totalBtc,
        currentSpotInvestedPercentage: spotPercentage,
        previousTotalUsdtValue: totalPreviousUsdt,
        previousTotalBusdValue: totalPreviousBusd,
        previousBtcValue: totalPreviousBtc,
      });
      setTotalVpls(totalVpls);
    }
    setAllAccountsState(allAccounts);
    return () => {
      abortController.abort();
    };
  }, [allAccounts]);

  const logout = () => {
    dispatch(setLoggedIn(false));
    dispatch(setAccessToken(''));
    dispatch(setAccessTokenAfterOTP(''));
    window.localStorage.clear();
    window.location.pathname = '/';
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function fetchAccounts(allAccountsState: any) {
    const accountInts: accountInt[] = [];
    if (allAccountsState) {
      for (const acc of allAccountsState) {
        //deep copied the account object to let me manipulate it
        const x: accountInt = JSON.parse(JSON.stringify(acc));
        //Get executor's health status
        ExecutorHelper.getExecutorHealth(x.executor, userToken)
          .then(async (value) => {
            if (value) x.executorHealth = value;
            dispatch(addExHealth(x));
            //Get pnl data
            const pnlResp = await ExecutorHelper.getPNL(acc.executor, days || 0, true, userToken);
            if (pnlResp) {
              x.pnl = pnlResp;
            }
            dispatch(addPnls(x));
            //Get remote configs
            const configsResp = await ExecutorHelper.getRemoteConfigs(acc.executor, userToken);
            if (configsResp) {
              x.configs = configsResp;
              setAccountAsset(acc);
              if (x.configs.disableExpansion) setTrueCounter((prev) => prev++);
              else setFalseCounter((prev) => prev++);
            } else setFalseCounter((prev) => prev++);
            dispatch(addConfigs(x));
          })
          .catch((e: any) => {
            if (e.message.includes('permission denied')) setShowErrorModal(true);
            if (e.message.includes('access token is invalid')) logout();
            logger.error({ message: e });
          });
        //Get brain's health status
        BrainHelper.getBrainHealth(acc.brain, userToken)
          .then(async (value) => {
            if (value) x.brainHealth = value;
            dispatch(addBrainHealth(x));

            const futureAlgoParamsResp = await BrainHelper.getRemoteConfigs(acc.brain, userToken);
            if (futureAlgoParamsResp) x.futurealgoparams = futureAlgoParamsResp;
            dispatch(addFutureAlgoParams(x));
          })
          .catch((e: any) => {
            if (e.message.includes('permission denied')) setShowErrorModal(true);
            if (e.message.includes('access token is invalid')) logout();
            logger.error({ message: e });
          });
        accountInts.push(x);
      }
      setAllAccountsState(accountInts);
    }
  }

  //find which basket each user uses and assign some pnls
  const setAccountAsset = async (account: any) => {
    try {
      const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(account?.executor, userToken);
      let asset = 'USDT';
      const key = account.key;
      const list = assetsList;
      list[key] = {};
      if (executorSymbolsList && executorSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
      else if (executorSymbolsList && executorSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
      else if (executorSymbolsList && executorSymbolsList.symbolsBusd.length > 0) asset = 'BUSD';
      list[key].asset = asset;
      setAssetsList(list);
    } catch (e) {
      logger.error({ message: e });
    }
  };

  const onNewLevelChange = (e: ChangeEvent<HTMLInputElement>, setIsToBeSaved: any, setBtcValue: any) => {
    setIsToBeSaved(true);
    let x = e.target.value;
    if (e.target.value[e.target.value.length - 1] === '%') x = e.target.value.substring(0, e.target.value.length - 1);
    //Check if Number, 0 spaces, only 1 % symbol
    if ((!Number(x) && x !== '' && Number(x) !== 0) || e.target.value.includes('%%')) return;
    setBtcValue(e.target.value);
  };

  const onSaveBtcValuePerLevel = () => {
    setIsToBeSaved(false);
    if (!btcValue.includes('%')) setBtcValue(btcValue + '%');
    let v = btcValue;
    if (v.includes('%')) {
      v = v.substring(0, v.length - 1);
    }
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    tempAccounts.map((account: accountInt) => {
      if (account.configs) {
        const x: number = parseFloat(
          (
            parseFloat((account.configs.btcValuePerLevel * parseFloat(v)).toFixed(8)) / parseFloat('100') +
            parseFloat(String(account.configs.btcValuePerLevel))
          ).toFixed(8),
        );
        try {
          onUpdateRemoteConfigs(account, 'btcValuePerLevel', x);
        } catch (e) {
          logger.error({ message: e });
        }
      }
    });
    fetchAccounts(allAccountsState);
  };

  const onSaveUsdtValuePerLevel = () => {
    setIsToBeSavedUsdt(false);
    if (!usdtValue.includes('%')) setUsdtValue(usdtValue + '%');
    let v = usdtValue;
    if (v.includes('%')) {
      v = v.substring(0, v.length - 1);
    }
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    tempAccounts.map((account: accountInt) => {
      if (account.configs) {
        const x: number = parseFloat(
          (
            parseFloat((account.configs.usdtValuePerLevel * parseFloat(v)).toFixed(8)) / parseFloat('100') +
            parseFloat(String(account.configs.usdtValuePerLevel))
          ).toFixed(8),
        );
        try {
          onUpdateRemoteConfigs(account, 'usdtValuePerLevel', x);
        } catch (e) {
          logger.error({ message: e });
        }
      }
    });
    fetchAccounts(allAccountsState);
  };

  const onSaveBusdValuePerLevel = () => {
    setIsToBeSavedBusd(false);
    if (!busdValue.includes('%')) setBusdValue(busdValue + '%');
    let v = busdValue;
    if (v.includes('%')) {
      v = v.substring(0, v.length - 1);
    }
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    tempAccounts.map((account: accountInt) => {
      if (account.configs) {
        const x: number = parseFloat(
          (
            parseFloat((account.configs.busdValuePerLevel * parseFloat(v)).toFixed(8)) / parseFloat('100') +
            parseFloat(String(account.configs.busdValuePerLevel))
          ).toFixed(8),
        );
        try {
          onUpdateRemoteConfigs(account, 'busdValuePerLevel', x);
        } catch (e) {
          logger.error({ message: e });
        }
      }
    });
    fetchAccounts(allAccountsState);
  };

  const onDisabledExpansion = async (disabledExp: boolean) => {
    if (!isExpansionBtnDisabled) {
      const disabledMap: boolean[] = [];
      const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
      for (const account in tempAccounts) {
        if (tempAccounts[account] && tempAccounts[account].configs) {
          try {
            const disabled = await onUpdateRemoteConfigs(tempAccounts[account], 'disableExpansion', disabledExp);
            disabledMap.push(disabled);
          } catch (e) {
            logger.error({ message: 'onDisabledExpansion Dashboard.tsx: ' + e });
          }
        }
      }
      setIsExpansionError(disabledMap.includes(false) && disabledMap.includes(true));
    }
  };

  const onHOLD = async (hold: boolean) => {
    const disabledMap: boolean[] = [];
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    for (const account in tempAccounts) {
      if (tempAccounts[account] && tempAccounts[account].configs) {
        try {
          const disabled = await onUpdateRemoteConfigs(tempAccounts[account], 'hold', hold);
          disabledMap.push(disabled);
        } catch (e) {
          logger.error({ message: 'onHOLD Dashboard.tsx: ' + e });
        }
      }
    }
    setIsHoldError(disabledMap.includes(false) && disabledMap.includes(true));
  };

  const onAllowZigZag = async (allowZigZag: boolean) => {
    const disabledMap: boolean[] = [];
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    for (const account in tempAccounts) {
      if (tempAccounts[account] && tempAccounts[account].configs) {
        try {
          const disabled = await onUpdateRemoteConfigs(tempAccounts[account], 'allowZigZag', allowZigZag);
          disabledMap.push(disabled);
        } catch (e) {
          logger.error({ message: 'onAllowZigZag Dashboard.tsx: ' + e });
        }
      }
    }
    setIsZigZagError(disabledMap.includes(false) && disabledMap.includes(true));
  };

  const onUpdateRemoteConfigs = async (account: accountInt, key: string, value: any) => {
    if (account && account.configs) {
      const executorConfigs: ExecutorConfigsRequest = JSON.parse(JSON.stringify(account.configs));
      switch (key) {
        case 'hold':
          executorConfigs.hold = value;
          break;
        case 'allowZigZag':
          executorConfigs.allowZigZag = value;
          break;
        case 'allowFutureExecution':
          executorConfigs.allowFutureExecution = value;
          break;
        case 'disableExpansion':
          executorConfigs.disableExpansion = value;
          break;
        case 'btcValuePerLevel':
          executorConfigs.btcValuePerLevel = Number(value);
          break;
        case 'usdtValuePerLevel':
          executorConfigs.usdtValuePerLevel = Number(value);
          break;
        case 'busdValuePerLevel':
          executorConfigs.busdValuePerLevel = Number(value);
          break;
      }
      await ExecutorHelper.setOrUpdateRemoteConfigs(account.executor, executorConfigs, userToken);
      const configs = await ExecutorHelper.getRemoteConfigs(account.executor, userToken);
      const newAccount: any = JSON.parse(JSON.stringify(account));
      newAccount.configs = configs ? configs : new ExecutorConfigsResponse();
      dispatch(addAccount(newAccount));
      if (key === 'disableExpansion' && (configs === null || configs?.disableExpansion !== value)) {
        toast('For account: ' + account.name + ' disable expansion not updated!');
        return configs?.disableExpansion || false;
      } else if (key === 'hold' && (configs === null || configs?.hold !== value)) {
        toast('For account: ' + account.name + ' hold not updated!');
        return configs?.hold || false;
      } else if (key === 'usdtValuePerLevel' && (configs === null || configs?.usdtValuePerLevel !== value)) {
        toast('For account: ' + account.name + ' vpl not updated!');
      }
    }
    return false;
  };

  const onDEListChange = async (action: string, coin: string) => {
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    for (const account in tempAccounts) {
      if (tempAccounts[account] && tempAccounts[account].configs) {
        try {
          if (action === 'add') {
            const response = await ExecutorHelper.removeDESymbols(tempAccounts[account].executor, [coin], userToken);
            if (!response)
              toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' not added from DE!', 8000);
            else toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' added from DE!', 5000);
          } else if (action === 'remove') {
            const response = await ExecutorHelper.addDESymbols(tempAccounts[account].executor, [coin], userToken);
            if (!response)
              toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' not removed from DE!', 8000);
            else toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' removed from DE!', 5000);
          } else {
            toast('Action: ' + action + ' not recognized!');
          }
        } catch (e) {
          logger.error({ message: 'onDEListChange Dashboard.tsx: ' + e });
          toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' not added!', 8000);
        }
      }
    }
  };

  const onIgnoreListChange = async (action: string, list: string, coin: string) => {
    const tempAccounts = isMultiSelect ? multiSelectedAccounts : allAccounts;
    for (const account in tempAccounts) {
      if (tempAccounts[account] && tempAccounts[account].configs) {
        try {
          //add symbol to spot ignored lists
          if (action === 'add' && list === 'spot') {
            const brainResponse = await BrainHelper.ignoreSymbols(tempAccounts[account].brain, [coin], userToken);
            if (!brainResponse) {
              toast('For account: ' + tempAccounts[account].brain + ' coin ' + coin + ' not ignored from Spot Brain!');
              continue;
            }
            const executorResponse = await ExecutorHelper.ignoreSymbols(
              tempAccounts[account].executor,
              [coin],
              userToken,
            );
            if (!executorResponse)
              toast(
                'For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' not ignored from Spot Executor!',
              );
          } //add symbol to futures ignored lists
          else if (action === 'add' && list === 'futures') {
            const brainResponse = await BrainHelper.ignoreFutureSymbols(tempAccounts[account].brain, [coin], userToken);
            if (!brainResponse) {
              toast(
                'For account: ' + tempAccounts[account].brain + ' coin ' + coin + ' not ignored from Futures Brain!',
              );
              continue;
            }
            const executorResponse = await ExecutorHelper.ignoreFutureSymbols(
              tempAccounts[account].executor,
              [coin],
              userToken,
            );
            if (!executorResponse)
              toast(
                'For account: ' +
                  tempAccounts[account].executor +
                  ' coin ' +
                  coin +
                  ' not ignored from Futures Executor!',
              );
          } //remove symbol from spot ignored lists
          else if (action === 'remove' && list === 'spot') {
            return; //remove this when we need to remove from all accounts
            const brainResponse = await BrainHelper.removeFromIgnoreSymbols(
              tempAccounts[account].brain,
              [coin],
              userToken,
            );
            if (!brainResponse) {
              toast(
                'For account: ' +
                  tempAccounts[account].executor +
                  ' coin ' +
                  coin +
                  ' not removed ignored from Spot Brain!',
              );
              continue;
            }
            const executorResponse = await ExecutorHelper.removeFromIgnoreSymbols(
              tempAccounts[account].executor,
              [coin],
              userToken,
            );
            if (!executorResponse)
              toast(
                'For account: ' +
                  tempAccounts[account].executor +
                  ' coin ' +
                  coin +
                  ' not removed ignored from Spot Executor!',
              );
          } //remove symbol from futures ignored lists
          else if (action === 'remove' && list === 'futures') {
            return; //remove this when we need to remove from all accounts
            const brainResponse = await BrainHelper.removeFromFutureIgnoreSymbols(
              tempAccounts[account].brain,
              [coin],
              userToken,
            );
            if (!brainResponse) {
              toast(
                'For account: ' +
                  tempAccounts[account].executor +
                  ' coin ' +
                  coin +
                  ' not removed ignored from Futures Brain!',
              );
              continue;
            }
            const executorResponse = await ExecutorHelper.removeFromFutureIgnoreSymbols(
              tempAccounts[account].executor,
              [coin],
              userToken,
            );
            if (!executorResponse)
              toast(
                'For account: ' +
                  tempAccounts[account].executor +
                  ' coin ' +
                  coin +
                  ' not removed ignored from Futures Executor!',
              );
          } else {
            toast('Action: ' + action + ' not recognized!');
          }
        } catch (e) {
          logger.error({ message: 'onDEListChange Dashboard.tsx: ' + e });
          toast('For account: ' + tempAccounts[account].executor + ' coin ' + coin + ' not added!');
        }
      }
    }
  };

  return (
    <div className={styles.dashboardWrapper}>
      {/*NAVBAR*/}
      <Navbar />

      {/*  MAIN CONTENT */}
      <div className={styles.dashboardContent}>
        {!isViewOnly ? (
          <Fragment>
            <div
              className={styles.header}
              tabIndex={0}
              role={'button'}
              onMouseDown={(e) => {
                if (e.nativeEvent.which === 1) setShowInfo(!showInfo);
              }}
            >
              <h3>
                Global Configurations{' '}
                <img className={showInfo ? styles.arrowUp : ''} src="/img/arrow_down.svg" alt="arrow" />
              </h3>
              <div
                role={'button'}
                tabIndex={0}
                className={styles.logout}
                onMouseDown={(e) => {
                  if (e.nativeEvent.which === 1) logout();
                }}
              >
                <a>Logout</a>
              </div>
            </div>
            <div className={styles.dashboardMainInfo + ' ' + (showInfo ? styles.dashboardMainInfoShow : '')}>
              {/*<div className={styles.harvesterHealth}>*/}
              {/*  <p>*/}
              {/*    Contabo Harvester Health{' '}*/}
              {/*    {harvesterHealth === 1 && (*/}
              {/*      <span*/}
              {/*        role={'button'}*/}
              {/*        style={{ cursor: 'pointer' }}*/}
              {/*        tabIndex={0}*/}
              {/*        onMouseDown={() => {*/}
              {/*          setShowHarvesterModal(true);*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <img src="/img/warning.svg" alt="warning" />*/}
              {/*      </span>*/}
              {/*    )}*/}
              {/*  </p>*/}
              {/*  <div className={styles.harvesterHealthStatus}>*/}
              {/*    <h4*/}
              {/*      className={*/}
              {/*        harvesterHealth > 1 ? styles.harvesterHealthStatusActive : styles.harvesterHealthStatusFailure*/}
              {/*      }*/}
              {/*    >*/}
              {/*      {harvesterHealth > 1 ? 'Alive' : 'Failure'}*/}
              {/*    </h4>*/}
              {/*    {Array(harvesterHealth)*/}
              {/*      .fill(0)*/}
              {/*      .map((_, i) => {*/}
              {/*        return (*/}
              {/*          <div*/}
              {/*            key={i}*/}
              {/*            className={*/}
              {/*              styles.harvesterHealthLevel +*/}
              {/*              ' ' +*/}
              {/*              (harvesterHealth > 1*/}
              {/*                ? styles.harvesterHealthLevelActive*/}
              {/*                : styles.harvesterHealthLevelFailure)*/}
              {/*            }*/}
              {/*          />*/}
              {/*        );*/}
              {/*      })}*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className={styles.harvesterHealth}>
                <p>
                  Harvester Health{' '}
                  {harvesterHealthDO === 1 && (
                    <span
                      role={'button'}
                      style={{ cursor: 'pointer' }}
                      tabIndex={0}
                      onMouseDown={(event) => {
                        if (event.button === 2) return;
                        setShowHarvesterModalDO(true);
                      }}
                    >
                      <img src="/img/warning.svg" alt="warning" />
                    </span>
                  )}
                </p>
                <p>{`(${NumFormatter.format(
                  Number(diskUsage?.used || 0) / 1024 / 1024 / 1024,
                )} GB / ${NumFormatter.format(Number(diskUsage?.total || 0) / 1024 / 1024 / 1024)} GB)`}</p>
                <div className={styles.harvesterHealthStatus}>
                  <h4
                    className={
                      harvesterHealthDO > 1 ? styles.harvesterHealthStatusActive : styles.harvesterHealthStatusFailure
                    }
                  >
                    {harvesterHealthDO > 1 ? 'Alive' : 'Failure'}
                  </h4>
                  {Array(harvesterHealthDO)
                    .fill(0)
                    .map((_, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            styles.harvesterHealthLevel +
                            ' ' +
                            (harvesterHealthDO > 1
                              ? styles.harvesterHealthLevelActive
                              : styles.harvesterHealthLevelFailure)
                          }
                        />
                      );
                    })}
                </div>
              </div>
              <div className={styles.expansionSection}>
                <div>Brain in sync: {brainSync?.brainSync ? brainSync.brainName : 'None'}</div>
                <div>
                  <button
                    disabled={!brainSync?.brainSync}
                    onClick={async () => {
                      const brainSync = await HarvesterHelper.unlockBrainSync(2, userToken);
                      if (brainSync) {
                        brainSync.brainSync = false;
                        setBrainSync(brainSync);
                      } else toast("Couldn't unlock harvester");
                    }}
                  >
                    <h4>Unlock sync</h4>
                  </button>
                </div>
              </div>
              <div className={styles.valueInfo}>
                <div>
                  <div className={'floatingInputContainer'}>
                    <input
                      className="field-input"
                      id="btcValueInput"
                      name="btcValueInput"
                      type="text"
                      placeholder=" "
                      value={btcValue}
                      autoComplete={'off'}
                      onChange={(e) => onNewLevelChange(e, setIsToBeSaved, setBtcValue)}
                    />
                    <label className="floatingInputPlaceholder" htmlFor="btcValueInput">
                      BTC value per level
                    </label>
                  </div>
                </div>
                <div className={styles.btnWrapper}>
                  <button
                    className={styles.saveBtn + ' ' + (isToBeSaved ? styles.saveBtnActive : '')}
                    onClick={() => onSaveBtcValuePerLevel()}
                  >
                    <img src="/img/save_btn.svg" alt="save" />
                  </button>
                </div>
              </div>
              <div className={styles.valueInfo}>
                <div>
                  <div className={'floatingInputContainer'}>
                    <input
                      className="field-input"
                      id="usdtValueInput"
                      name="usdtValueInput"
                      type="text"
                      placeholder=" "
                      value={usdtValue}
                      autoComplete={'off'}
                      onChange={(e) => onNewLevelChange(e, setIsToBeSavedUsdt, setUsdtValue)}
                    />
                    <label className="floatingInputPlaceholder" htmlFor="usdtValueInput">
                      USDT value per level
                    </label>
                  </div>
                </div>
                <div className={styles.btnWrapper}>
                  <button
                    className={styles.saveBtn + ' ' + (isToBeSavedUsdt ? styles.saveBtnActive : '')}
                    onClick={() => onSaveUsdtValuePerLevel()}
                  >
                    <img src="/img/save_btn.svg" alt="save" />
                  </button>
                </div>
              </div>
              <div className={styles.valueInfo}>
                <div>
                  <div className={'floatingInputContainer'}>
                    <input
                      className="field-input"
                      id="busdValueInput"
                      name="busdValueInput"
                      type="text"
                      placeholder=" "
                      value={busdValue}
                      autoComplete={'off'}
                      onChange={(e) => onNewLevelChange(e, setIsToBeSavedBusd, setBusdValue)}
                    />
                    <label className="floatingInputPlaceholder" htmlFor="busdValueInput">
                      BUSD value per level
                    </label>
                  </div>
                </div>
                <div className={styles.btnWrapper}>
                  <button
                    className={styles.saveBtn + ' ' + (isToBeSavedBusd ? styles.saveBtnActive : '')}
                    onClick={() => onSaveBusdValuePerLevel()}
                  >
                    <img src="/img/save_btn.svg" alt="save" />
                  </button>
                </div>
              </div>
              {/*<div className={styles.globalSymbolsSection}>*/}
              {/*  <button onClick={() => setBinanceSymbolsModal(true)}>*/}
              {/*    <img src="/img/bitcoin.svg" alt="bitcoin" /> <h4>Global Contabo List of symbols</h4>*/}
              {/*  </button>*/}
              {/*</div>*/}
              <div className={styles.globalSymbolsSection}>
                <button onClick={() => setDOSymbolsModal(true)}>
                  <img src="/img/bitcoin.svg" alt="bitcoin" /> <h4>Global DO List of symbols</h4>
                </button>
              </div>
              <div className={styles.expansionSection}>
                <div>
                  Expansion
                  <p data-tip="Not synchronized" data-text-color="#F8810A">
                    {isExpansionError && <img src="/img/warning.svg" alt="warning" />}
                  </p>
                </div>
                {/*<Toggle*/}
                {/*  checked={isExpansion}*/}
                {/*  icons={false}*/}
                {/*  disabled={isExpansionBtnDisabled}*/}
                {/*  onChange={onDisabledExpansion}*/}
                {/*/>*/}
                <div>
                  <button onClick={() => onDisabledExpansion(false)}>
                    <h4>Enable {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                  <button onClick={() => onDisabledExpansion(true)}>
                    <h4>Disable {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                </div>
              </div>
              <div className={styles.expansionSection}>
                <div>
                  HOLD
                  <p data-tip="Not synchronized" data-text-color="#F8810A">
                    {isHoldError && <img src="/img/warning.svg" alt="warning" />}
                  </p>
                </div>
                <div>
                  <button onClick={() => onHOLD(true)}>
                    <h4>Put {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                  <button onClick={() => onHOLD(false)}>
                    <h4>Remove {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                </div>
              </div>
              <div className={styles.valueInfo + ' ' + styles.valueInfoColumn}>
                <div className={'floatingInputContainer'}>
                  <input
                    className="field-input"
                    id="addRemoveDE"
                    name="addRemoveDE"
                    type="text"
                    placeholder=" "
                    value={deCoin}
                    autoComplete={'off'}
                    onChange={(e) => setDeCoin(e.target.value.toUpperCase())}
                  />
                  <label className="floatingInputPlaceholder" htmlFor="usdtValueInput">
                    Don{"'"}t Expand List Coin
                  </label>
                </div>
                <div>
                  <button onClick={() => onDEListChange('add', deCoin)}>
                    <h4>Add {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                  <button onClick={() => onDEListChange('remove', deCoin)}>
                    <h4>Remove {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                </div>
              </div>
              <div className={styles.valueInfo + ' ' + styles.valueInfoColumn}>
                <div className={'floatingInputContainer'}>
                  <input
                    className="field-input"
                    id="addRemoveIgnoredSpot"
                    name="addRemoveIgnoredSpot"
                    type="text"
                    placeholder=" "
                    value={ignoredSpotCoin}
                    autoComplete={'off'}
                    onChange={(e) => setIgnoredSpotCoin(e.target.value.toUpperCase())}
                  />
                  <label className="floatingInputPlaceholder" htmlFor="usdtValueInput">
                    Ignore Spot List Coin
                  </label>
                </div>
                <div>
                  <button onClick={() => onIgnoreListChange('add', 'spot', ignoredSpotCoin)}>
                    <h4>Add {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                  {/*<button onClick={() => onIgnoreListChange('remove', 'spot', ignoredSpotCoin)}>*/}
                  {/*  <h4>Remove {isMultiSelect ? 'Selected' : 'All'}</h4>*/}
                  {/*</button>*/}
                </div>
              </div>
              <div className={styles.valueInfo + ' ' + styles.valueInfoColumn}>
                <div className={'floatingInputContainer'}>
                  <input
                    className="field-input"
                    id="addRemoveIgnoredFuture"
                    name="addRemoveIgnoredFuture"
                    type="text"
                    placeholder=" "
                    value={ignoredFutureCoin}
                    autoComplete={'off'}
                    onChange={(e) => setIgnoredFutureCoin(e.target.value.toUpperCase())}
                  />
                  <label className="floatingInputPlaceholder" htmlFor="usdtValueInput">
                    Ignore Futures List Coin
                  </label>
                </div>
                <div>
                  <button onClick={() => onIgnoreListChange('add', 'futures', ignoredFutureCoin)}>
                    <h4>Add {isMultiSelect ? 'Selected' : 'All'}</h4>
                  </button>
                  {/*<button onClick={() => onIgnoreListChange('remove', 'futures', ignoredFutureCoin)}>*/}
                  {/*  <h4>Remove {isMultiSelect ? 'Selected' : 'All'}</h4>*/}
                  {/*</button>*/}
                </div>
              </div>
              <div className={styles.valueInfo}>
                <div>
                  <div className={'floatingInputContainer'}>
                    <input
                      className="field-input"
                      id="telegramOtpInput"
                      name="telegramOtpInput"
                      type="text"
                      placeholder=" "
                      value={telegramOtp}
                      autoComplete={'off'}
                      onChange={(e) => {
                        setIsToBeSavedTelegramOtp(true);
                        setTelegramOtp(e.target.value);
                      }}
                    />
                    <label className="floatingInputPlaceholder" htmlFor="usdtValueInput">
                      Telegram OTP
                    </label>
                  </div>
                </div>
                <div className={styles.btnWrapper}>
                  <button
                    className={styles.saveBtn + ' ' + (isToBeSavedTelegramOtp ? styles.saveBtnActive : '')}
                    onClick={async () => {
                      setIsToBeSavedTelegramOtp(false);
                      const alex = allAccounts.find((a: any) => a.executor.toLowerCase().includes('alextest'));
                      console.log(allAccounts);
                      console.log(alex);
                      if (alex) {
                        try {
                          const response = await ExecutorHelper.SetTelegramOTPCode(
                            alex.executor,
                            telegramOtp,
                            userToken,
                          );
                          // if (!response?.success) toast('Something went wrong with Telegram OTP');
                        } catch (e: any) {
                          toast(e?.message);
                        }
                      }
                    }}
                  >
                    <img src="/img/save_btn.svg" alt="save" />
                  </button>
                </div>
              </div>
              {/*<div className={styles.expansionSection}>*/}
              {/*  <div>*/}
              {/*    Zig Zag*/}
              {/*    <p data-tip="Not synchronized" data-text-color="#F8810A">*/}
              {/*      {isZigZagError && <img src="/img/warning.svg" alt="warning" />}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <button onClick={() => onAllowZigZag(true)}>*/}
              {/*      <h4>Allow All</h4>*/}
              {/*    </button>*/}
              {/*    <button onClick={() => onAllowZigZag(false)}>*/}
              {/*      <h4>Remove All</h4>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </Fragment>
        ) : (
          <div
            className={styles.header}
            tabIndex={0}
            role={'button'}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) setShowInfo(!showInfo);
            }}
          >
            <h3> </h3>
            <div
              role={'button'}
              tabIndex={0}
              className={styles.logout}
              onMouseDown={(e) => {
                if (e.nativeEvent.which === 1) logout();
              }}
            >
              <a>Logout</a>
            </div>
          </div>
        )}

        <div className={styles.totalsSection}>
          <div className={styles.totalsSectionTop}>
            <h3>Totals</h3>
          </div>
          <div className={styles.totalsSectionMain}>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalSum"
                name="totalSum"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentTotalUsdtValue)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalSum">
                Total USDT Accounts Sum
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalBusdSum"
                name="totalBusdSum"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentTotalBusdValue)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalBusdSum">
                Total BUSD Accounts Sum
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalBtc"
                name="totalBtc"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentBtcValue)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalBtc">
                BTC Accounts Sum
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalSpotPercent"
                name="totalSpotPercent"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentSpotInvestedPercentage || 0)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalSpotPercent">
                Total Spot %
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalUsdtPercentage"
                name="totalUsdtPercentage"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentTotalUsdtValue - pnlTotals?.previousTotalUsdtValue || 0)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalUsdtPercentage">
                Total Pnl USDT
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalBusdPercentage"
                name="totalBusdPercentage"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentTotalBusdValue - pnlTotals?.previousTotalBusdValue || 0)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalBusdPercentage">
                Total Pnl BUSD
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className="field-input"
                id="totalBtcPercentage"
                name="totalBtcPercentage"
                type="text"
                placeholder=" "
                value={NumFormatter.format(pnlTotals?.currentBtcValue - pnlTotals?.previousBtcValue || 0)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalBtcPercentage">
                Total Pnl BTC
              </label>
            </div>
            <div className={'floatingInputContainer'}>
              <input
                className={'field-input' + (isAnyVplZero ? ' ' + styles.failure : ' ' + styles.active)}
                id="totalBtcPercentage"
                name="totalBtcPercentage"
                type="text"
                placeholder=" "
                value={NumFormatter.format(totalVpls || 0)}
                autoComplete={'off'}
                disabled={true}
              />
              <label className="floatingInputPlaceholder" htmlFor="totalBtcPercentage">
                Total VPLs
              </label>
            </div>
          </div>
        </div>

        <div className={styles.accountsSection + ' ' + (isListOrdered ? styles.accountsSectionList : '')}>
          <div className={styles.accountsSectionTop}>
            <h3>Accounts</h3>
            <div className={styles.accountsSectionActions}>
              {/*<h4>Refresh Results</h4>*/}
              {/*<button className={styles.refreshBtn}>*/}
              {/*  <img src="/img/refresh.svg" alt="refresh" />*/}
              {/*</button>*/}
              {!isViewOnly ? (
                <button
                  className={styles.orderBtn}
                  onClick={() => {
                    setIsMultiSelect(!isMultiSelect);
                  }}
                >
                  <img src={'/img/multiselect.svg'} alt="order" />
                </button>
              ) : (
                ''
              )}
              <button
                className={styles.orderBtn}
                onClick={() => {
                  setIsListOrdered(!isListOrdered);
                }}
              >
                <img src={'/img/' + (isListOrdered ? 'list' : 'square') + '_order.svg'} alt="order" />
              </button>
            </div>
          </div>
          <div className={styles.accountsSectionMain}>
            {allAccountsState
              ? allAccountsState.map((account: any, i: number) => {
                  return (
                    <AccountComponent
                      key={i}
                      history={history}
                      account={account}
                      isMultiSelect={isMultiSelect}
                      isExpansionBtnDisabled={isExpansionBtnDisabled || isViewOnly}
                      assetsList={assetsList}
                      multiSelectedAccounts={multiSelectedAccounts}
                      setMultiSelectedAccounts={setMultiSelectedAccounts}
                    />
                  );
                })
              : ''}
          </div>
        </div>
      </div>
      <ReactTooltip backgroundColor={'#242430'} />
      <ReactTooltip backgroundColor={'#242430'} />
      {showHarvesterModal && (
        <GenericModal
          component={HarvesterModal}
          show={showHarvesterModal}
          setEdit={setShowHarvesterModal}
          width={'15vw'}
          harvesters={harvesterDownList}
        />
      )}
      {showHarvesterModalDO && (
        <GenericModal
          component={HarvesterModal}
          show={showHarvesterModalDO}
          setEdit={setShowHarvesterModalDO}
          width={'15vw'}
          harvesters={harvesterDownListDO}
        />
      )}
      {binanceSymbolsModal && (
        <GenericModal
          component={GlobalSymbolModal}
          show={binanceSymbolsModal}
          setEdit={setBinanceSymbolsModal}
          width={'65vw'}
          server={1}
        />
      )}
      {dOSymbolsModal && (
        <GenericModal
          component={GlobalSymbolModal}
          show={dOSymbolsModal}
          setEdit={setDOSymbolsModal}
          width={'65vw'}
          market={2}
        />
      )}
      {showErrorModal && <GenericModal component={ErrorModal} show={showErrorModal} width={'65vw'} />}
    </div>
  );
});

export default Dashboard;
