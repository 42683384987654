import { grpc } from '@improbable-eng/grpc-web';
import { ResponseStream } from '../executor/tr_executor_service_pb_service';
import { HarvesterServiceClient } from './tr_harvester_service_pb_service';
import { promisify } from 'es6-promisify';
import {
  BrainSyncRequest,
  BrainSyncResponse,
  GetAllIgnoredSymbolsRequest,
  GetAllIgnoredSymbolsResponse,
  GetAllSupportedSymbolsRequest,
  GetAllSupportedSymbolsResponse,
  GetDiskUsageRequest,
  GetDiskUsageResponse,
  HarvHealthStatusRequest,
  HarvHealthStatusResponse,
  PriceTickerRequest,
  PriceTickerResponse,
  PriceTickerStreamResponse,
  ReEnableSymbolsRequest,
  RemoveSymbolRequest,
} from './tr_harvester_types_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';

export class HarvesterServiceClientPromisified extends HarvesterServiceClient {
  getMarketHealthStatusPromise: (
    param1: HarvHealthStatusRequest,
    metadata?: grpc.Metadata,
  ) => Promise<HarvHealthStatusResponse | null> = promisify(this.getMarketHealthStatus);
  getDiskUsagePromise: (param1: GetDiskUsageRequest, metadata?: grpc.Metadata) => Promise<GetDiskUsageResponse | null> =
    promisify(this.getDiskUsage);
  getAllSupportedSymbolsPromise: (
    param1: GetAllSupportedSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetAllSupportedSymbolsResponse | null> = promisify(this.getAllSupportedSymbols);
  getAllIgnoredSymbolsPromise: (
    param1: GetAllIgnoredSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetAllIgnoredSymbolsResponse | null> = promisify(this.getAllIgnoredSymbols);
  reEnableSymbolsPromise: (
    param1: ReEnableSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.reEnableSymbols);
  reEnableFutureSymbolsPromise: (
    param1: ReEnableSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.reEnableFutureSymbols);
  removeSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeSymbols);
  removeFutureSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFutureSymbols);
  getPriceTickerPromise: (param1: PriceTickerRequest, metadata?: grpc.Metadata) => Promise<PriceTickerResponse | null> =
    promisify(this.getPriceTicker);
  getBrainSyncPromise: (param1: BrainSyncRequest, metadata?: grpc.Metadata) => Promise<BrainSyncResponse | null> =
    promisify(this.getBrainSync);
  unlockBrainSyncPromise: (param1: BrainSyncRequest, metadata?: grpc.Metadata) => Promise<BrainSyncResponse | null> =
    promisify(this.unlockBrainSync);
}
