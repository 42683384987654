import {
  CallbackClient,
  createCallbackClient,
  createGrpcWebTransport,
  createPromiseClient,
  PromiseClient,
} from '@bufbuild/connect-web';
import { ServiceType } from '@bufbuild/protobuf';

import { AuthServiceClient } from '../pkg/protobuf/auth/auth_service_pb_service';
import { AuthServiceClientPromisified } from '../pkg/protobuf/auth/auth-service-client-promisified';
import { BrainServiceClientPromisified } from '../pkg/protobuf/brain/brain-service-client-promisified';
import { BrainServiceClient } from '../pkg/protobuf/brain/tr_brain_service_pb_service';
import { ExecutorServiceClientPromisified } from '../pkg/protobuf/executor/executor-service-client-promisified';
import { ExecutorServiceClient } from '../pkg/protobuf/executor/tr_executor_service_pb_service';
import { HarvesterServiceClientPromisified } from '../pkg/protobuf/harvester/harvester-service-client-promisified';
import { HarvesterServiceClient } from '../pkg/protobuf/harvester/tr_harvester_service_pb_service';
import { AuthService } from '../pkg/protobuf/v2/auth/auth_service_connectweb';
import { BrainService } from '../pkg/protobuf/v2/brain/tr_brain_service_connectweb';
import { ExecutorService } from '../pkg/protobuf/v2/executor/tr_executor_service_connectweb';
import { HarvesterService } from '../pkg/protobuf/v2/harvester/tr_harvester_service_connectweb';
import { accounts, accountsLocal } from './accounts';
import { logger } from './Logger';

export class Backend {
  public static executorClientMap: Map<string, PromiseClient<typeof ExecutorService>> = new Map();
  public static executorCallbackClientMap: Map<string, CallbackClient<typeof ExecutorService>> = new Map();

  public static brainClientMap: Map<string, PromiseClient<typeof BrainService>> = new Map();
  public static brainCallbackClientMap: Map<string, CallbackClient<typeof BrainService>> = new Map();

  public static getExecutorClient = (url: string) => {
    const accountsTemp = process.env.NODE_ENV === 'production' ? accounts : accountsLocal;
    if (Backend.executorClientMap.size <= 0) {
      accountsTemp.map((account) => {
        const client = createPromiseClient(
          ExecutorService,
          createGrpcWebTransport({
            baseUrl: account.executor,
          }),
        );
        Backend.executorClientMap.set(account.executor, client);
      });
    } else if (!Backend.executorClientMap.get(url)) {
      logger.error({ message: 'executorClientMap error for client: ' + url });
    }
    return Backend.executorClientMap.get(url) || null;
  };

  public static getExecutorCallbackClient = (url: string) => {
    const accountsTemp = process.env.NODE_ENV === 'production' ? accounts : accountsLocal;
    if (Backend.executorCallbackClientMap.size <= 0) {
      accountsTemp.map((account) => {
        const client = createCallbackClient(
          ExecutorService,
          createGrpcWebTransport({
            baseUrl: account.executor,
          }),
        );
        Backend.executorCallbackClientMap.set(account.executor, client);
      });
    } else if (!Backend.executorCallbackClientMap.get(url)) {
      logger.error({ message: 'executorCallbackClientMap error for client: ' + url });
    }
    return Backend.executorCallbackClientMap.get(url) || null;
  };

  public static getBrainClient = (url: string) => {
    const accountsTemp = process.env.NODE_ENV === 'production' ? accounts : accountsLocal;
    if (Backend.brainClientMap.size <= 0) {
      accountsTemp.map((account) => {
        const client = createPromiseClient(
          BrainService,
          createGrpcWebTransport({
            baseUrl: account.brain,
          }),
        );
        Backend.brainClientMap.set(account.brain, client);
      });
    } else if (!Backend.brainClientMap.get(url)) {
      logger.error({ message: 'brainClientMap error for client: ' + url });
    }
    return Backend.brainClientMap.get(url) || null;
  };

  public static getBrainCallbackClient = (url: string) => {
    const accountsTemp = process.env.NODE_ENV === 'production' ? accounts : accountsLocal;
    if (Backend.brainCallbackClientMap.size <= 0) {
      accountsTemp.map((account) => {
        const client = createCallbackClient(
          BrainService,
          createGrpcWebTransport({
            baseUrl: account.brain,
          }),
        );
        Backend.brainCallbackClientMap.set(account.brain, client);
      });
    } else if (!Backend.brainCallbackClientMap.get(url)) {
      logger.error({ message: 'brainCallbackClientMap error for client: ' + url });
    }
    return Backend.brainCallbackClientMap.get(url) || null;
  };

  public static authClient = new AuthServiceClient(process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost', {
    debug: process.env.NODE_ENV == 'development',
  });
  public static authClientPromisified = new AuthServiceClientPromisified(
    process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost',
    { debug: process.env.NODE_ENV == 'development' },
  );

  public static authClientV2 = createPromiseClient(
    AuthService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost',
    }),
  );

  public static harvesterClient = new HarvesterServiceClient(
    process.env.REACT_APP_BACKEND_HOST_HARVESTER || 'https://grpc-web.orange-quest.com/harvester',
    {
      debug: process.env.NODE_ENV == 'development',
    },
  );

  public static harvesterClientV2 = createPromiseClient(
    HarvesterService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST_HARVESTER_DO || 'http://grpc-web.localhost',
    }),
  );

  public static harvesterClientCallbackV2 = createCallbackClient(
    HarvesterService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST_HARVESTER_DO || 'http://grpc-web.localhost',
    }),
  );

  public static harvesterClientV2Old = createPromiseClient(
    HarvesterService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST_HARVESTER || 'http://grpc-web.localhost',
    }),
  );

  public static harvesterClientCallbackV2Old = createCallbackClient(
    HarvesterService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST_HARVESTER || 'http://grpc-web.localhost',
    }),
  );

  public static harvesterClientPromisified = new HarvesterServiceClientPromisified(
    process.env.REACT_APP_BACKEND_HOST_HARVESTER || 'https://grpc-web.orange-quest.com/harvester',
    { debug: process.env.NODE_ENV == 'development' },
  );

  public static harvesterDOClientPromisified = new HarvesterServiceClientPromisified(
    process.env.REACT_APP_BACKEND_HOST_HARVESTER_DO ||
      'https://grpc-web.digitalocean-binance.orange-quest.com/harvester',
    { debug: process.env.NODE_ENV == 'development' },
  );

  public static executorClient(url: string) {
    return new ExecutorServiceClient(url, {
      debug: process.env.NODE_ENV == 'development',
    });
  }

  public static executorClientPromisified(url: string) {
    return new ExecutorServiceClientPromisified(url, {
      debug: process.env.NODE_ENV == 'development',
    });
  }

  public static brainClient(url: string) {
    return new BrainServiceClient(url, {
      debug: process.env.NODE_ENV == 'development',
    });
  }

  public static brainClientPromisified(url: string) {
    return new BrainServiceClientPromisified(url, {
      debug: process.env.NODE_ENV == 'development',
    });
  }
}
