// source: harvester/tr_harvester_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.harvester.BrainSyncRequest', null, global);
goog.exportSymbol('proto.harvester.BrainSyncResponse', null, global);
goog.exportSymbol('proto.harvester.GetAllIgnoredSymbolsRequest', null, global);
goog.exportSymbol('proto.harvester.GetAllIgnoredSymbolsResponse', null, global);
goog.exportSymbol('proto.harvester.GetAllSupportedSymbolsRequest', null, global);
goog.exportSymbol('proto.harvester.GetAllSupportedSymbolsResponse', null, global);
goog.exportSymbol('proto.harvester.GetDiskUsageRequest', null, global);
goog.exportSymbol('proto.harvester.GetDiskUsageResponse', null, global);
goog.exportSymbol('proto.harvester.HarvHealthStatusRequest', null, global);
goog.exportSymbol('proto.harvester.HarvHealthStatusResponse', null, global);
goog.exportSymbol('proto.harvester.HarvOHLCVRequest', null, global);
goog.exportSymbol('proto.harvester.HarvOHLCVResponse', null, global);
goog.exportSymbol('proto.harvester.LastTradeRequest', null, global);
goog.exportSymbol('proto.harvester.Market', null, global);
goog.exportSymbol('proto.harvester.NOOP', null, global);
goog.exportSymbol('proto.harvester.OHLCV', null, global);
goog.exportSymbol('proto.harvester.PriceTicker', null, global);
goog.exportSymbol('proto.harvester.PriceTickerRequest', null, global);
goog.exportSymbol('proto.harvester.PriceTickerResponse', null, global);
goog.exportSymbol('proto.harvester.PriceTickerStreamResponse', null, global);
goog.exportSymbol('proto.harvester.ReEnableSymbolsRequest', null, global);
goog.exportSymbol('proto.harvester.RemoveSymbolRequest', null, global);
goog.exportSymbol('proto.harvester.Symbol', null, global);
goog.exportSymbol('proto.harvester.TradeType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.HarvHealthStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.HarvHealthStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.HarvHealthStatusRequest.displayName = 'proto.harvester.HarvHealthStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.HarvHealthStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.HarvHealthStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.HarvHealthStatusResponse.displayName = 'proto.harvester.HarvHealthStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.HarvOHLCVRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.HarvOHLCVRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.HarvOHLCVRequest.displayName = 'proto.harvester.HarvOHLCVRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetAllSupportedSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.GetAllSupportedSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetAllSupportedSymbolsRequest.displayName = 'proto.harvester.GetAllSupportedSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetAllSupportedSymbolsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.harvester.GetAllSupportedSymbolsResponse.repeatedFields_, null);
};
goog.inherits(proto.harvester.GetAllSupportedSymbolsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetAllSupportedSymbolsResponse.displayName = 'proto.harvester.GetAllSupportedSymbolsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetAllIgnoredSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.GetAllIgnoredSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetAllIgnoredSymbolsRequest.displayName = 'proto.harvester.GetAllIgnoredSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetAllIgnoredSymbolsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.harvester.GetAllIgnoredSymbolsResponse.repeatedFields_, null);
};
goog.inherits(proto.harvester.GetAllIgnoredSymbolsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetAllIgnoredSymbolsResponse.displayName = 'proto.harvester.GetAllIgnoredSymbolsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.LastTradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.LastTradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.LastTradeRequest.displayName = 'proto.harvester.LastTradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.HarvOHLCVResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.harvester.HarvOHLCVResponse.repeatedFields_, null);
};
goog.inherits(proto.harvester.HarvOHLCVResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.HarvOHLCVResponse.displayName = 'proto.harvester.HarvOHLCVResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.OHLCV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.OHLCV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.OHLCV.displayName = 'proto.harvester.OHLCV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.ReEnableSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.harvester.ReEnableSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.harvester.ReEnableSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.ReEnableSymbolsRequest.displayName = 'proto.harvester.ReEnableSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.RemoveSymbolRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.harvester.RemoveSymbolRequest.repeatedFields_, null);
};
goog.inherits(proto.harvester.RemoveSymbolRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.RemoveSymbolRequest.displayName = 'proto.harvester.RemoveSymbolRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.NOOP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.NOOP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.NOOP.displayName = 'proto.harvester.NOOP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.PriceTickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.PriceTickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.PriceTickerRequest.displayName = 'proto.harvester.PriceTickerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.PriceTicker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.PriceTicker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.PriceTicker.displayName = 'proto.harvester.PriceTicker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.PriceTickerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.PriceTickerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.PriceTickerResponse.displayName = 'proto.harvester.PriceTickerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.PriceTickerStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.PriceTickerStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.PriceTickerStreamResponse.displayName = 'proto.harvester.PriceTickerStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetDiskUsageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.GetDiskUsageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetDiskUsageRequest.displayName = 'proto.harvester.GetDiskUsageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.GetDiskUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.GetDiskUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.GetDiskUsageResponse.displayName = 'proto.harvester.GetDiskUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.BrainSyncRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.BrainSyncRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.BrainSyncRequest.displayName = 'proto.harvester.BrainSyncRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.harvester.BrainSyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.harvester.BrainSyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.harvester.BrainSyncResponse.displayName = 'proto.harvester.BrainSyncResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.HarvHealthStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.HarvHealthStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.HarvHealthStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvHealthStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.HarvHealthStatusRequest}
 */
proto.harvester.HarvHealthStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.HarvHealthStatusRequest;
  return proto.harvester.HarvHealthStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.HarvHealthStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.HarvHealthStatusRequest}
 */
proto.harvester.HarvHealthStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.HarvHealthStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.HarvHealthStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.HarvHealthStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvHealthStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.harvester.HarvHealthStatusRequest.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.HarvHealthStatusRequest} returns this
 */
proto.harvester.HarvHealthStatusRequest.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.HarvHealthStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.HarvHealthStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.HarvHealthStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvHealthStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0),
    aliveMap: (f = msg.getAliveMap()) ? f.toObject(includeInstance, undefined) : [],
    cacheMetrics: jspb.Message.getFieldWithDefault(msg, 3, ""),
    aliveFutureMap: (f = msg.getAliveFutureMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.HarvHealthStatusResponse}
 */
proto.harvester.HarvHealthStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.HarvHealthStatusResponse;
  return proto.harvester.HarvHealthStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.HarvHealthStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.HarvHealthStatusResponse}
 */
proto.harvester.HarvHealthStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    case 2:
      var value = msg.getAliveMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCacheMetrics(value);
      break;
    case 4:
      var value = msg.getAliveFutureMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.HarvHealthStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.HarvHealthStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.HarvHealthStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvHealthStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAliveMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getCacheMetrics();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAliveFutureMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.harvester.HarvHealthStatusResponse.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.HarvHealthStatusResponse} returns this
 */
proto.harvester.HarvHealthStatusResponse.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * map<string, bool> alive = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.harvester.HarvHealthStatusResponse.prototype.getAliveMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.harvester.HarvHealthStatusResponse} returns this
 */
proto.harvester.HarvHealthStatusResponse.prototype.clearAliveMap = function() {
  this.getAliveMap().clear();
  return this;};


/**
 * optional string cache_metrics = 3;
 * @return {string}
 */
proto.harvester.HarvHealthStatusResponse.prototype.getCacheMetrics = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.HarvHealthStatusResponse} returns this
 */
proto.harvester.HarvHealthStatusResponse.prototype.setCacheMetrics = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, bool> alive_future = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.harvester.HarvHealthStatusResponse.prototype.getAliveFutureMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.harvester.HarvHealthStatusResponse} returns this
 */
proto.harvester.HarvHealthStatusResponse.prototype.clearAliveFutureMap = function() {
  this.getAliveFutureMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.HarvOHLCVRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.HarvOHLCVRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.HarvOHLCVRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvOHLCVRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0),
    retrievaltimebeforemillis: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationmillis: jspb.Message.getFieldWithDefault(msg, 3, 0),
    now: jspb.Message.getFieldWithDefault(msg, 4, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.HarvOHLCVRequest}
 */
proto.harvester.HarvOHLCVRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.HarvOHLCVRequest;
  return proto.harvester.HarvOHLCVRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.HarvOHLCVRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.HarvOHLCVRequest}
 */
proto.harvester.HarvOHLCVRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRetrievaltimebeforemillis(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationmillis(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNow(value);
      break;
    case 5:
      var value = /** @type {!proto.harvester.Symbol} */ (reader.readEnum());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.HarvOHLCVRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.HarvOHLCVRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.HarvOHLCVRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvOHLCVRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRetrievaltimebeforemillis();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDurationmillis();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNow();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSymbol();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.harvester.HarvOHLCVRequest.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.HarvOHLCVRequest} returns this
 */
proto.harvester.HarvOHLCVRequest.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 retrievalTimeBeforeMillis = 2;
 * @return {number}
 */
proto.harvester.HarvOHLCVRequest.prototype.getRetrievaltimebeforemillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.HarvOHLCVRequest} returns this
 */
proto.harvester.HarvOHLCVRequest.prototype.setRetrievaltimebeforemillis = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 durationMillis = 3;
 * @return {number}
 */
proto.harvester.HarvOHLCVRequest.prototype.getDurationmillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.HarvOHLCVRequest} returns this
 */
proto.harvester.HarvOHLCVRequest.prototype.setDurationmillis = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 now = 4;
 * @return {number}
 */
proto.harvester.HarvOHLCVRequest.prototype.getNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.HarvOHLCVRequest} returns this
 */
proto.harvester.HarvOHLCVRequest.prototype.setNow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Symbol symbol = 5;
 * @return {!proto.harvester.Symbol}
 */
proto.harvester.HarvOHLCVRequest.prototype.getSymbol = function() {
  return /** @type {!proto.harvester.Symbol} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.harvester.Symbol} value
 * @return {!proto.harvester.HarvOHLCVRequest} returns this
 */
proto.harvester.HarvOHLCVRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetAllSupportedSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetAllSupportedSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetAllSupportedSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllSupportedSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteAsset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetAllSupportedSymbolsRequest}
 */
proto.harvester.GetAllSupportedSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetAllSupportedSymbolsRequest;
  return proto.harvester.GetAllSupportedSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetAllSupportedSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetAllSupportedSymbolsRequest}
 */
proto.harvester.GetAllSupportedSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetAllSupportedSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetAllSupportedSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetAllSupportedSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllSupportedSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteAsset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string quote_asset = 1;
 * @return {string}
 */
proto.harvester.GetAllSupportedSymbolsRequest.prototype.getQuoteAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.GetAllSupportedSymbolsRequest} returns this
 */
proto.harvester.GetAllSupportedSymbolsRequest.prototype.setQuoteAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.harvester.GetAllSupportedSymbolsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetAllSupportedSymbolsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetAllSupportedSymbolsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllSupportedSymbolsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    futuresymbolsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse}
 */
proto.harvester.GetAllSupportedSymbolsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetAllSupportedSymbolsResponse;
  return proto.harvester.GetAllSupportedSymbolsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetAllSupportedSymbolsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse}
 */
proto.harvester.GetAllSupportedSymbolsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFuturesymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetAllSupportedSymbolsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetAllSupportedSymbolsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllSupportedSymbolsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFuturesymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};


/**
 * repeated string futureSymbols = 2;
 * @return {!Array<string>}
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.getFuturesymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.setFuturesymbolsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.addFuturesymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.GetAllSupportedSymbolsResponse} returns this
 */
proto.harvester.GetAllSupportedSymbolsResponse.prototype.clearFuturesymbolsList = function() {
  return this.setFuturesymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetAllIgnoredSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetAllIgnoredSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetAllIgnoredSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllIgnoredSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteAsset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetAllIgnoredSymbolsRequest}
 */
proto.harvester.GetAllIgnoredSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetAllIgnoredSymbolsRequest;
  return proto.harvester.GetAllIgnoredSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetAllIgnoredSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetAllIgnoredSymbolsRequest}
 */
proto.harvester.GetAllIgnoredSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetAllIgnoredSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetAllIgnoredSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetAllIgnoredSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllIgnoredSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteAsset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string quote_asset = 1;
 * @return {string}
 */
proto.harvester.GetAllIgnoredSymbolsRequest.prototype.getQuoteAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.GetAllIgnoredSymbolsRequest} returns this
 */
proto.harvester.GetAllIgnoredSymbolsRequest.prototype.setQuoteAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.harvester.GetAllIgnoredSymbolsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetAllIgnoredSymbolsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetAllIgnoredSymbolsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllIgnoredSymbolsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    futuresymbolsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetAllIgnoredSymbolsResponse;
  return proto.harvester.GetAllIgnoredSymbolsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetAllIgnoredSymbolsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFuturesymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetAllIgnoredSymbolsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetAllIgnoredSymbolsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetAllIgnoredSymbolsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFuturesymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};


/**
 * repeated string futureSymbols = 2;
 * @return {!Array<string>}
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.getFuturesymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.setFuturesymbolsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.addFuturesymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.GetAllIgnoredSymbolsResponse} returns this
 */
proto.harvester.GetAllIgnoredSymbolsResponse.prototype.clearFuturesymbolsList = function() {
  return this.setFuturesymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.LastTradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.LastTradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.LastTradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.LastTradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.LastTradeRequest}
 */
proto.harvester.LastTradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.LastTradeRequest;
  return proto.harvester.LastTradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.LastTradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.LastTradeRequest}
 */
proto.harvester.LastTradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    case 5:
      var value = /** @type {!proto.harvester.Symbol} */ (reader.readEnum());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.LastTradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.LastTradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.LastTradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.LastTradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.harvester.LastTradeRequest.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.LastTradeRequest} returns this
 */
proto.harvester.LastTradeRequest.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Symbol symbol = 5;
 * @return {!proto.harvester.Symbol}
 */
proto.harvester.LastTradeRequest.prototype.getSymbol = function() {
  return /** @type {!proto.harvester.Symbol} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.harvester.Symbol} value
 * @return {!proto.harvester.LastTradeRequest} returns this
 */
proto.harvester.LastTradeRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.harvester.HarvOHLCVResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.HarvOHLCVResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.HarvOHLCVResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.HarvOHLCVResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvOHLCVResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.harvester.OHLCV.toObject, includeInstance),
    alive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.HarvOHLCVResponse}
 */
proto.harvester.HarvOHLCVResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.HarvOHLCVResponse;
  return proto.harvester.HarvOHLCVResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.HarvOHLCVResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.HarvOHLCVResponse}
 */
proto.harvester.HarvOHLCVResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.harvester.OHLCV;
      reader.readMessage(value,proto.harvester.OHLCV.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.HarvOHLCVResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.HarvOHLCVResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.HarvOHLCVResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.HarvOHLCVResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.harvester.OHLCV.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated OHLCV data = 2;
 * @return {!Array<!proto.harvester.OHLCV>}
 */
proto.harvester.HarvOHLCVResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.harvester.OHLCV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.harvester.OHLCV, 2));
};


/**
 * @param {!Array<!proto.harvester.OHLCV>} value
 * @return {!proto.harvester.HarvOHLCVResponse} returns this
*/
proto.harvester.HarvOHLCVResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.harvester.OHLCV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.harvester.OHLCV}
 */
proto.harvester.HarvOHLCVResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.harvester.OHLCV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.HarvOHLCVResponse} returns this
 */
proto.harvester.HarvOHLCVResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional bool alive = 3;
 * @return {boolean}
 */
proto.harvester.HarvOHLCVResponse.prototype.getAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.harvester.HarvOHLCVResponse} returns this
 */
proto.harvester.HarvOHLCVResponse.prototype.setAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.OHLCV.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.OHLCV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.OHLCV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.OHLCV.toObject = function(includeInstance, msg) {
  var f, obj = {
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    highprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lowprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    marketname: jspb.Message.getFieldWithDefault(msg, 6, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 7, 0),
    from: jspb.Message.getFieldWithDefault(msg, 8, 0),
    to: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ignoredsymbol: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.OHLCV}
 */
proto.harvester.OHLCV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.OHLCV;
  return proto.harvester.OHLCV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.OHLCV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.OHLCV}
 */
proto.harvester.OHLCV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 6:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    case 7:
      var value = /** @type {!proto.harvester.Symbol} */ (reader.readEnum());
      msg.setSymbol(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoredsymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.OHLCV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.OHLCV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.OHLCV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.OHLCV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSymbol();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIgnoredsymbol();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional double openPrice = 1;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double highPrice = 2;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getHighprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setHighprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lowPrice = 3;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getLowprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setLowprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double volume = 5;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Market marketName = 6;
 * @return {!proto.harvester.Market}
 */
proto.harvester.OHLCV.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Symbol symbol = 7;
 * @return {!proto.harvester.Symbol}
 */
proto.harvester.OHLCV.prototype.getSymbol = function() {
  return /** @type {!proto.harvester.Symbol} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.harvester.Symbol} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 from = 8;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 to = 9;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 duration = 10;
 * @return {number}
 */
proto.harvester.OHLCV.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool ignoredSymbol = 11;
 * @return {boolean}
 */
proto.harvester.OHLCV.prototype.getIgnoredsymbol = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.harvester.OHLCV} returns this
 */
proto.harvester.OHLCV.prototype.setIgnoredsymbol = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.harvester.ReEnableSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.ReEnableSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.ReEnableSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.ReEnableSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.ReEnableSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.ReEnableSymbolsRequest}
 */
proto.harvester.ReEnableSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.ReEnableSymbolsRequest;
  return proto.harvester.ReEnableSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.ReEnableSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.ReEnableSymbolsRequest}
 */
proto.harvester.ReEnableSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.ReEnableSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.ReEnableSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.ReEnableSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.ReEnableSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.harvester.ReEnableSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.ReEnableSymbolsRequest} returns this
 */
proto.harvester.ReEnableSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.ReEnableSymbolsRequest} returns this
 */
proto.harvester.ReEnableSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.ReEnableSymbolsRequest} returns this
 */
proto.harvester.ReEnableSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.harvester.RemoveSymbolRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.RemoveSymbolRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.RemoveSymbolRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.RemoveSymbolRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.RemoveSymbolRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.RemoveSymbolRequest}
 */
proto.harvester.RemoveSymbolRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.RemoveSymbolRequest;
  return proto.harvester.RemoveSymbolRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.RemoveSymbolRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.RemoveSymbolRequest}
 */
proto.harvester.RemoveSymbolRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.RemoveSymbolRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.RemoveSymbolRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.RemoveSymbolRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.RemoveSymbolRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.harvester.RemoveSymbolRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.harvester.RemoveSymbolRequest} returns this
 */
proto.harvester.RemoveSymbolRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.harvester.RemoveSymbolRequest} returns this
 */
proto.harvester.RemoveSymbolRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.harvester.RemoveSymbolRequest} returns this
 */
proto.harvester.RemoveSymbolRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.NOOP.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.NOOP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.NOOP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.NOOP.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.NOOP}
 */
proto.harvester.NOOP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.NOOP;
  return proto.harvester.NOOP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.NOOP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.NOOP}
 */
proto.harvester.NOOP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.NOOP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.NOOP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.NOOP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.NOOP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.harvester.NOOP.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.NOOP} returns this
 */
proto.harvester.NOOP.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.PriceTickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.PriceTickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.PriceTickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.PriceTickerRequest}
 */
proto.harvester.PriceTickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.PriceTickerRequest;
  return proto.harvester.PriceTickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.PriceTickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.PriceTickerRequest}
 */
proto.harvester.PriceTickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.PriceTickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.PriceTickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.PriceTickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.harvester.PriceTickerRequest.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.harvester.PriceTickerRequest} returns this
 */
proto.harvester.PriceTickerRequest.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.PriceTicker.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.PriceTicker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.PriceTicker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTicker.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    receivedTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tradetype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    opentonowpricepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    tradeTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    way: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.PriceTicker}
 */
proto.harvester.PriceTicker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.PriceTicker;
  return proto.harvester.PriceTicker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.PriceTicker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.PriceTicker}
 */
proto.harvester.PriceTicker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceivedTime(value);
      break;
    case 3:
      var value = /** @type {!proto.harvester.TradeType} */ (reader.readEnum());
      msg.setTradetype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpentonowpricepercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTradeTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.PriceTicker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.PriceTicker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.PriceTicker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTicker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getReceivedTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTradetype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOpentonowpricepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTradeTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getWay();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.harvester.PriceTicker.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 received_time = 2;
 * @return {number}
 */
proto.harvester.PriceTicker.prototype.getReceivedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setReceivedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TradeType tradeType = 3;
 * @return {!proto.harvester.TradeType}
 */
proto.harvester.PriceTicker.prototype.getTradetype = function() {
  return /** @type {!proto.harvester.TradeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.harvester.TradeType} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setTradetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double openPrice = 4;
 * @return {number}
 */
proto.harvester.PriceTicker.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double openToNowPricePercentage = 5;
 * @return {number}
 */
proto.harvester.PriceTicker.prototype.getOpentonowpricepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setOpentonowpricepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 trade_time = 6;
 * @return {number}
 */
proto.harvester.PriceTicker.prototype.getTradeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setTradeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string way = 7;
 * @return {string}
 */
proto.harvester.PriceTicker.prototype.getWay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.PriceTicker} returns this
 */
proto.harvester.PriceTicker.prototype.setWay = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.PriceTickerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.PriceTickerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.PriceTickerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotpricemapMap: (f = msg.getSpotpricemapMap()) ? f.toObject(includeInstance, proto.harvester.PriceTicker.toObject) : [],
    futurepricemapMap: (f = msg.getFuturepricemapMap()) ? f.toObject(includeInstance, proto.harvester.PriceTicker.toObject) : [],
    noop: (f = msg.getNoop()) && proto.harvester.NOOP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.PriceTickerResponse}
 */
proto.harvester.PriceTickerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.PriceTickerResponse;
  return proto.harvester.PriceTickerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.PriceTickerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.PriceTickerResponse}
 */
proto.harvester.PriceTickerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSpotpricemapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.harvester.PriceTicker.deserializeBinaryFromReader, "", new proto.harvester.PriceTicker());
         });
      break;
    case 2:
      var value = msg.getFuturepricemapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.harvester.PriceTicker.deserializeBinaryFromReader, "", new proto.harvester.PriceTicker());
         });
      break;
    case 3:
      var value = new proto.harvester.NOOP;
      reader.readMessage(value,proto.harvester.NOOP.deserializeBinaryFromReader);
      msg.setNoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.PriceTickerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.PriceTickerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.PriceTickerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotpricemapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.harvester.PriceTicker.serializeBinaryToWriter);
  }
  f = message.getFuturepricemapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.harvester.PriceTicker.serializeBinaryToWriter);
  }
  f = message.getNoop();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.harvester.NOOP.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, PriceTicker> spotPriceMap = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.harvester.PriceTicker>}
 */
proto.harvester.PriceTickerResponse.prototype.getSpotpricemapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.harvester.PriceTicker>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.harvester.PriceTicker));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.harvester.PriceTickerResponse} returns this
 */
proto.harvester.PriceTickerResponse.prototype.clearSpotpricemapMap = function() {
  this.getSpotpricemapMap().clear();
  return this;};


/**
 * map<string, PriceTicker> futurePriceMap = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.harvester.PriceTicker>}
 */
proto.harvester.PriceTickerResponse.prototype.getFuturepricemapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.harvester.PriceTicker>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.harvester.PriceTicker));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.harvester.PriceTickerResponse} returns this
 */
proto.harvester.PriceTickerResponse.prototype.clearFuturepricemapMap = function() {
  this.getFuturepricemapMap().clear();
  return this;};


/**
 * optional NOOP noop = 3;
 * @return {?proto.harvester.NOOP}
 */
proto.harvester.PriceTickerResponse.prototype.getNoop = function() {
  return /** @type{?proto.harvester.NOOP} */ (
    jspb.Message.getWrapperField(this, proto.harvester.NOOP, 3));
};


/**
 * @param {?proto.harvester.NOOP|undefined} value
 * @return {!proto.harvester.PriceTickerResponse} returns this
*/
proto.harvester.PriceTickerResponse.prototype.setNoop = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.harvester.PriceTickerResponse} returns this
 */
proto.harvester.PriceTickerResponse.prototype.clearNoop = function() {
  return this.setNoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.harvester.PriceTickerResponse.prototype.hasNoop = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.PriceTickerStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.PriceTickerStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.PriceTickerStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priceticker: (f = msg.getPriceticker()) && proto.harvester.PriceTicker.toObject(includeInstance, f),
    noop: (f = msg.getNoop()) && proto.harvester.NOOP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.PriceTickerStreamResponse}
 */
proto.harvester.PriceTickerStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.PriceTickerStreamResponse;
  return proto.harvester.PriceTickerStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.PriceTickerStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.PriceTickerStreamResponse}
 */
proto.harvester.PriceTickerStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new proto.harvester.PriceTicker;
      reader.readMessage(value,proto.harvester.PriceTicker.deserializeBinaryFromReader);
      msg.setPriceticker(value);
      break;
    case 3:
      var value = new proto.harvester.NOOP;
      reader.readMessage(value,proto.harvester.NOOP.deserializeBinaryFromReader);
      msg.setNoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.PriceTickerStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.PriceTickerStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.PriceTickerStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.PriceTickerStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriceticker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.harvester.PriceTicker.serializeBinaryToWriter
    );
  }
  f = message.getNoop();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.harvester.NOOP.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.harvester.PriceTickerStreamResponse.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.PriceTickerStreamResponse} returns this
 */
proto.harvester.PriceTickerStreamResponse.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PriceTicker priceTicker = 2;
 * @return {?proto.harvester.PriceTicker}
 */
proto.harvester.PriceTickerStreamResponse.prototype.getPriceticker = function() {
  return /** @type{?proto.harvester.PriceTicker} */ (
    jspb.Message.getWrapperField(this, proto.harvester.PriceTicker, 2));
};


/**
 * @param {?proto.harvester.PriceTicker|undefined} value
 * @return {!proto.harvester.PriceTickerStreamResponse} returns this
*/
proto.harvester.PriceTickerStreamResponse.prototype.setPriceticker = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.harvester.PriceTickerStreamResponse} returns this
 */
proto.harvester.PriceTickerStreamResponse.prototype.clearPriceticker = function() {
  return this.setPriceticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.harvester.PriceTickerStreamResponse.prototype.hasPriceticker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NOOP noop = 3;
 * @return {?proto.harvester.NOOP}
 */
proto.harvester.PriceTickerStreamResponse.prototype.getNoop = function() {
  return /** @type{?proto.harvester.NOOP} */ (
    jspb.Message.getWrapperField(this, proto.harvester.NOOP, 3));
};


/**
 * @param {?proto.harvester.NOOP|undefined} value
 * @return {!proto.harvester.PriceTickerStreamResponse} returns this
*/
proto.harvester.PriceTickerStreamResponse.prototype.setNoop = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.harvester.PriceTickerStreamResponse} returns this
 */
proto.harvester.PriceTickerStreamResponse.prototype.clearNoop = function() {
  return this.setNoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.harvester.PriceTickerStreamResponse.prototype.hasNoop = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetDiskUsageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetDiskUsageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetDiskUsageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetDiskUsageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetDiskUsageRequest}
 */
proto.harvester.GetDiskUsageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetDiskUsageRequest;
  return proto.harvester.GetDiskUsageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetDiskUsageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetDiskUsageRequest}
 */
proto.harvester.GetDiskUsageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetDiskUsageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetDiskUsageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetDiskUsageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetDiskUsageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.GetDiskUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.GetDiskUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.GetDiskUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetDiskUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    free: jspb.Message.getFieldWithDefault(msg, 2, 0),
    used: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.GetDiskUsageResponse}
 */
proto.harvester.GetDiskUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.GetDiskUsageResponse;
  return proto.harvester.GetDiskUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.GetDiskUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.GetDiskUsageResponse}
 */
proto.harvester.GetDiskUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFree(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.GetDiskUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.GetDiskUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.GetDiskUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.GetDiskUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFree();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsed();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.harvester.GetDiskUsageResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.GetDiskUsageResponse} returns this
 */
proto.harvester.GetDiskUsageResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 free = 2;
 * @return {number}
 */
proto.harvester.GetDiskUsageResponse.prototype.getFree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.GetDiskUsageResponse} returns this
 */
proto.harvester.GetDiskUsageResponse.prototype.setFree = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 used = 3;
 * @return {number}
 */
proto.harvester.GetDiskUsageResponse.prototype.getUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.GetDiskUsageResponse} returns this
 */
proto.harvester.GetDiskUsageResponse.prototype.setUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.BrainSyncRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.BrainSyncRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.BrainSyncRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.BrainSyncRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    brainName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    force: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.BrainSyncRequest}
 */
proto.harvester.BrainSyncRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.BrainSyncRequest;
  return proto.harvester.BrainSyncRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.BrainSyncRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.BrainSyncRequest}
 */
proto.harvester.BrainSyncRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrainName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.BrainSyncRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.BrainSyncRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.BrainSyncRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.BrainSyncRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrainName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string brain_name = 1;
 * @return {string}
 */
proto.harvester.BrainSyncRequest.prototype.getBrainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.BrainSyncRequest} returns this
 */
proto.harvester.BrainSyncRequest.prototype.setBrainName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 time = 2;
 * @return {number}
 */
proto.harvester.BrainSyncRequest.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.harvester.BrainSyncRequest} returns this
 */
proto.harvester.BrainSyncRequest.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool force = 3;
 * @return {boolean}
 */
proto.harvester.BrainSyncRequest.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.harvester.BrainSyncRequest} returns this
 */
proto.harvester.BrainSyncRequest.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.harvester.BrainSyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.harvester.BrainSyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.harvester.BrainSyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.BrainSyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    brainSync: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    brainName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.harvester.BrainSyncResponse}
 */
proto.harvester.BrainSyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.harvester.BrainSyncResponse;
  return proto.harvester.BrainSyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.harvester.BrainSyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.harvester.BrainSyncResponse}
 */
proto.harvester.BrainSyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBrainSync(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrainName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.harvester.BrainSyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.harvester.BrainSyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.harvester.BrainSyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.harvester.BrainSyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrainSync();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBrainName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool brain_sync = 1;
 * @return {boolean}
 */
proto.harvester.BrainSyncResponse.prototype.getBrainSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.harvester.BrainSyncResponse} returns this
 */
proto.harvester.BrainSyncResponse.prototype.setBrainSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string brain_name = 2;
 * @return {string}
 */
proto.harvester.BrainSyncResponse.prototype.getBrainName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.harvester.BrainSyncResponse} returns this
 */
proto.harvester.BrainSyncResponse.prototype.setBrainName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.harvester.Symbol = {
  UNKNOWN_PAIR: 0,
  ETHBTC: 1,
  LTCBTC: 2,
  BNBBTC: 3,
  NEOBTC: 4,
  GASBTC: 5,
  WTCBTC: 6,
  LRCBTC: 7,
  QTUMBTC: 8,
  YOYOBTC: 9,
  OMGBTC: 10,
  ZRXBTC: 11,
  STRAXBTC: 12,
  KNCBTC: 15,
  FUNBTC: 16,
  IOTABTC: 18,
  LINKBTC: 19,
  XVGBTC: 20,
  MTLBTC: 22,
  EOSBTC: 23,
  SNTBTC: 24,
  ETCBTC: 25,
  MTHBTC: 26,
  DNTBTC: 27,
  ZECBTC: 28,
  BNTBTC: 29,
  ASTBTC: 30,
  DASHBTC: 31,
  OAXBTC: 32,
  BTGBTC: 33,
  REQBTC: 35,
  TRXBTC: 37,
  POWRBTC: 38,
  ARKBTC: 39,
  XRPBTC: 40,
  ENJBTC: 41,
  STORJBTC: 42,
  KMDBTC: 43,
  NULSBTC: 45,
  RDNBTC: 46,
  XMRBTC: 47,
  DLTBTC: 48,
  AMBBTC: 49,
  BATBTC: 50,
  GVTBTC: 52,
  CDTBTC: 53,
  QSPBTC: 56,
  BTSBTC: 57,
  FIROBTC: 58,
  LSKBTC: 59,
  MANABTC: 60,
  BCDBTC: 61,
  ADXBTC: 62,
  ADABTC: 63,
  PPTBTC: 64,
  XLMBTC: 66,
  CNDBTC: 67,
  WABIBTC: 68,
  WAVESBTC: 70,
  GTOBTC: 71,
  ICXBTC: 72,
  ELFBTC: 74,
  AIONBTC: 75,
  NEBLBTC: 76,
  BRDBTC: 77,
  NAVBTC: 78,
  APPCBTC: 79,
  RLCBTC: 81,
  PIVXBTC: 82,
  IOSTBTC: 83,
  STEEMBTC: 84,
  NANOBTC: 85,
  BLZBTC: 87,
  POABTC: 89,
  ZILBTC: 90,
  ONTBTC: 91,
  XEMBTC: 92,
  WANBTC: 93,
  QLCBTC: 95,
  SYSBTC: 96,
  GRSBTC: 97,
  LOOMBTC: 99,
  REPBTC: 100,
  ZENBTC: 101,
  CVCBTC: 103,
  THETABTC: 104,
  IOTXBTC: 105,
  QKCBTC: 106,
  NXSBTC: 108,
  DATABTC: 109,
  SCBTC: 110,
  NASBTC: 111,
  ARDRBTC: 112,
  VETBTC: 114,
  DOCKBTC: 115,
  POLYBTC: 116,
  GOBTC: 118,
  RVNBTC: 119,
  DCRBTC: 120,
  MITHBTC: 121,
  RENBTC: 122,
  ONGBTC: 123,
  FETBTC: 124,
  CELRBTC: 125,
  MATICBTC: 126,
  ATOMBTC: 127,
  PHBBTC: 128,
  TFUELBTC: 129,
  ONEBTC: 130,
  FTMBTC: 131,
  ALGOBTC: 132,
  DUSKBTC: 134,
  ANKRBTC: 135,
  COSBTC: 136,
  PERLBTC: 138,
  CHZBTC: 139,
  BANDBTC: 140,
  BEAMBTC: 141,
  XTZBTC: 142,
  HBARBTC: 143,
  NKNBTC: 144,
  STXBTC: 145,
  KAVABTC: 146,
  ARPABTC: 147,
  CTXCBTC: 148,
  BCHBTC: 149,
  VITEBTC: 151,
  FTTBTC: 152,
  OGNBTC: 153,
  DREPBTC: 154,
  TCTBTC: 155,
  WRXBTC: 156,
  LTOBTC: 157,
  COTIBTC: 159,
  STPTBTC: 160,
  SOLBTC: 161,
  CTSIBTC: 162,
  HIVEBTC: 163,
  CHRBTC: 164,
  MDTBTC: 165,
  STMXBTC: 166,
  PNTBTC: 167,
  DGBBTC: 168,
  COMPBTC: 169,
  SXPBTC: 170,
  SNXBTC: 171,
  IRISBTC: 172,
  MKRBTC: 173,
  FIOBTC: 175,
  AVABTC: 176,
  BALBTC: 177,
  YFIBTC: 178,
  JSTBTC: 179,
  SRMBTC: 180,
  ANTBTC: 181,
  CRVBTC: 182,
  SANDBTC: 183,
  OCEANBTC: 184,
  NMRBTC: 185,
  DOTBTC: 186,
  LUNABTC: 187,
  IDEXBTC: 188,
  RSRBTC: 189,
  PAXGBTC: 190,
  WNXMBTC: 191,
  TRBBTC: 192,
  BZRXBTC: 193,
  WBTCBTC: 194,
  SUSHIBTC: 195,
  YFIIBTC: 196,
  KSMBTC: 197,
  EGLDBTC: 198,
  DIABTC: 199,
  UMABTC: 200,
  BELBTC: 201,
  WINGBTC: 202,
  UNIBTC: 203,
  OXTBTC: 205,
  HNTBTC: 208,
  FLMBTC: 209,
  SCRTBTC: 210,
  ORNBTC: 211,
  UTKBTC: 212,
  XVSBTC: 213,
  VIDTBTC: 215,
  AAVEBTC: 216,
  NEARBTC: 217,
  FILBTC: 218,
  AERGOBTC: 220,
  EVXBTC: 221,
  GLMBTC: 222,
  AXSBTC: 223,
  TOMOBTC: 224,
  HARDBTC: 225,
  AVAXBTC: 226,
  CTKBTC: 227,
  AUDIOBTC: 228,
  CELOBTC: 229,
  SKLBTC: 230,
  UNFIBTC: 231,
  ATMBTC: 232,
  ROSEBTC: 233,
  MDABTC: 234,
  VIBBTC: 235,
  SKYBTC: 236,
  ALPHABTC: 237,
  GRTBTC: 238,
  RIFBTC: 239,
  TRUBTC: 240,
  TWTBTC: 241,
  LITBTC: 243,
  SFPBTC: 244,
  FXSBTC: 245,
  DODOBTC: 246,
  FRONTBTC: 247,
  CAKEBTC: 248,
  AUCTIONBTC: 249,
  PHABTC: 250,
  TVKBTC: 251,
  BADGERBTC: 252,
  FISBTC: 253,
  OMBTC: 254,
  PONDBTC: 255,
  DEGOBTC: 256,
  ALICEBTC: 257,
  FORTHBTC: 258,
  EZBTC: 259,
  BTCUSDT: 500,
  AAVEUSDT: 502,
  ACMUSDT: 503,
  ADAUSDT: 504,
  AIONUSDT: 505,
  AKROUSDT: 506,
  ALGOUSDT: 507,
  ALICEUSDT: 508,
  ALPHAUSDT: 509,
  ANKRUSDT: 510,
  ANTUSDT: 511,
  ARDRUSDT: 512,
  ARPAUSDT: 513,
  ARUSDT: 514,
  ASRUSDT: 515,
  ATAUSDT: 516,
  ATMUSDT: 517,
  ATOMUSDT: 518,
  AUDIOUSDT: 519,
  AUTOUSDT: 520,
  AVAUSDT: 521,
  AVAXUSDT: 522,
  AXSUSDT: 523,
  BADGERUSDT: 524,
  BAKEUSDT: 525,
  BALUSDT: 526,
  BANDUSDT: 527,
  BARUSDT: 528,
  BATUSDT: 529,
  BCHUSDT: 530,
  BEAMUSDT: 531,
  BELUSDT: 532,
  BLZUSDT: 533,
  BNBUSDT: 534,
  BNTUSDT: 535,
  BTSUSDT: 537,
  BURGERUSDT: 539,
  CAKEUSDT: 541,
  CELOUSDT: 542,
  CELRUSDT: 543,
  CFXUSDT: 544,
  CHRUSDT: 545,
  CHZUSDT: 546,
  CKBUSDT: 547,
  COCOSUSDT: 548,
  COMPUSDT: 549,
  COSUSDT: 550,
  COTIUSDT: 551,
  CRVUSDT: 552,
  CTKUSDT: 553,
  CTSIUSDT: 554,
  CTXCUSDT: 555,
  CVCUSDT: 556,
  DASHUSDT: 557,
  DATAUSDT: 558,
  DCRUSDT: 559,
  DEGOUSDT: 560,
  DENTUSDT: 561,
  DGBUSDT: 562,
  DIAUSDT: 563,
  DOCKUSDT: 565,
  DODOUSDT: 566,
  DOGEUSDT: 567,
  DOTUSDT: 568,
  DREPUSDT: 569,
  DUSKUSDT: 570,
  EGLDUSDT: 571,
  ENJUSDT: 572,
  EOSUSDT: 573,
  EPSUSDT: 574,
  ETCUSDT: 575,
  ETHUSDT: 576,
  FETUSDT: 577,
  FILUSDT: 578,
  FIOUSDT: 579,
  FIROUSDT: 580,
  FISUSDT: 581,
  FLMUSDT: 582,
  FORTHUSDT: 583,
  FTMUSDT: 584,
  FUNUSDT: 586,
  GRTUSDT: 587,
  GTCUSDT: 589,
  HARDUSDT: 592,
  HBARUSDT: 593,
  HIVEUSDT: 594,
  HOTUSDT: 596,
  ICPUSDT: 597,
  ICXUSDT: 598,
  IOSTUSDT: 600,
  IOTAUSDT: 601,
  IOTXUSDT: 602,
  IRISUSDT: 603,
  JSTUSDT: 604,
  JUVUSDT: 605,
  KAVAUSDT: 606,
  KEYUSDT: 607,
  KMDUSDT: 608,
  KNCUSDT: 609,
  KSMUSDT: 610,
  LINAUSDT: 611,
  LINKUSDT: 612,
  LITUSDT: 613,
  LPTUSDT: 614,
  LRCUSDT: 615,
  LSKUSDT: 616,
  LTCUSDT: 617,
  LTOUSDT: 618,
  LUNAUSDT: 619,
  MANAUSDT: 620,
  MASKUSDT: 621,
  MATICUSDT: 622,
  MBLUSDT: 623,
  MDTUSDT: 624,
  MDXUSDT: 625,
  MFTUSDT: 626,
  MIRUSDT: 627,
  MITHUSDT: 628,
  MKRUSDT: 629,
  MTLUSDT: 630,
  NANOUSDT: 631,
  NEARUSDT: 633,
  NEOUSDT: 634,
  NKNUSDT: 635,
  NMRUSDT: 636,
  NULSUSDT: 637,
  OCEANUSDT: 639,
  OGNUSDT: 640,
  OGUSDT: 641,
  OMGUSDT: 642,
  OMUSDT: 643,
  ONEUSDT: 644,
  ONGUSDT: 645,
  ONTUSDT: 646,
  ORNUSDT: 647,
  OXTUSDT: 648,
  PERLUSDT: 649,
  PERPUSDT: 650,
  PNTUSDT: 651,
  POLSUSDT: 652,
  PONDUSDT: 653,
  PSGUSDT: 654,
  PUNDIXUSDT: 655,
  QTUMUSDT: 656,
  REEFUSDT: 658,
  RENUSDT: 659,
  REPUSDT: 660,
  RIFUSDT: 661,
  RLCUSDT: 662,
  ROSEUSDT: 663,
  RSRUSDT: 664,
  RVNUSDT: 666,
  SANDUSDT: 667,
  SCUSDT: 668,
  SFPUSDT: 669,
  SHIBUSDT: 670,
  SKLUSDT: 671,
  SLPUSDT: 672,
  SNXUSDT: 673,
  SOLUSDT: 674,
  STMXUSDT: 676,
  STORJUSDT: 677,
  STPTUSDT: 678,
  STRAXUSDT: 679,
  STXUSDT: 680,
  SUSHIUSDT: 681,
  SXPUSDT: 682,
  TFUELUSDT: 684,
  THETAUSDT: 685,
  TKOUSDT: 686,
  TLMUSDT: 687,
  TOMOUSDT: 688,
  TORNUSDT: 689,
  TRBUSDT: 690,
  TROYUSDT: 691,
  TRUUSDT: 692,
  TRXUSDT: 693,
  TWTUSDT: 694,
  UMAUSDT: 695,
  UNFIUSDT: 696,
  UNIUSDT: 697,
  UTKUSDT: 698,
  VETUSDT: 699,
  VITEUSDT: 700,
  VTHOUSDT: 701,
  WANUSDT: 702,
  WAVESUSDT: 703,
  WINGUSDT: 704,
  WINUSDT: 705,
  WRXUSDT: 706,
  WTCUSDT: 707,
  XEMUSDT: 708,
  XLMUSDT: 709,
  XMRUSDT: 710,
  XRPUSDT: 711,
  XTZUSDT: 712,
  XVGUSDT: 713,
  XVSUSDT: 714,
  YFIIUSDT: 715,
  YFIUSDT: 716,
  ZECUSDT: 717,
  ZENUSDT: 718,
  ZILUSDT: 719,
  ZRXUSDT: 720,
  RUNEUSDT: 724,
  SUPERUSDT: 725,
  ERNUSDT: 727,
  KLAYUSDT: 728,
  PHAUSDT: 729,
  BONDUSDT: 730,
  MLNUSDT: 731,
  DEXEUSDT: 732,
  C98USDT: 733,
  CLVUSDT: 734,
  QNTUSDT: 735,
  FLOWUSDT: 738,
  TVKUSDT: 739,
  MINAUSDT: 740,
  RAYUSDT: 741,
  FARMUSDT: 742,
  ALPACAUSDT: 743,
  QUICKUSDT: 744,
  MBOXUSDT: 745,
  FORUSDT: 746,
  REQUSDT: 747,
  GHSTUSDT: 748,
  WAXPUSDT: 749,
  TRIBEUSDT: 750,
  GNOUSDT: 751,
  XECUSDT: 752,
  ELFUSDT: 753,
  DYDXUSDT: 754,
  IDEXUSDT: 756,
  VIDTUSDT: 757,
  GALAUSDT: 758,
  ILVUSDT: 759,
  YGGUSDT: 760,
  SYSUSDT: 761,
  DFUSDT: 762,
  FRONTUSDT: 763,
  CVPUSDT: 764,
  AGLDUSDT: 765,
  RADUSDT: 766,
  BETAUSDT: 767,
  RAREUSDT: 768,
  AUCTIONUSDT: 981,
  FIDAUSDT: 982,
  LAZIOUSDT: 983,
  CHESSUSDT: 984,
  ADXUSDT: 985,
  BNBBUSD: 769,
  BTCBUSD: 770,
  XRPBUSD: 771,
  ETHBUSD: 772,
  LTCBUSD: 773,
  LINKBUSD: 774,
  ETCBUSD: 775,
  TRXBUSD: 776,
  EOSBUSD: 777,
  XLMBUSD: 778,
  ADABUSD: 779,
  BCHBUSD: 780,
  QTUMBUSD: 781,
  VETBUSD: 782,
  ICXBUSD: 783,
  BNTBUSD: 784,
  ATOMBUSD: 785,
  DASHBUSD: 786,
  NEOBUSD: 787,
  WAVESBUSD: 788,
  XTZBUSD: 789,
  BATBUSD: 790,
  ENJBUSD: 791,
  NANOBUSD: 792,
  ONTBUSD: 793,
  RVNBUSD: 794,
  ALGOBUSD: 795,
  BTTBUSD: 796,
  TOMOBUSD: 797,
  XMRBUSD: 798,
  ZECBUSD: 799,
  DATABUSD: 800,
  SOLBUSD: 801,
  CTSIBUSD: 802,
  HBARBUSD: 803,
  MATICBUSD: 804,
  WRXBUSD: 805,
  ZILBUSD: 806,
  KNCBUSD: 807,
  LRCBUSD: 808,
  DGBBUSD: 809,
  COMPBUSD: 810,
  SXPBUSD: 811,
  SNXBUSD: 812,
  MKRBUSD: 813,
  RUNEBUSD: 814,
  MANABUSD: 815,
  DOGEBUSD: 816,
  ZRXBUSD: 817,
  FIOBUSD: 818,
  AVABUSD: 819,
  IOTABUSD: 820,
  BALBUSD: 821,
  YFIBUSD: 822,
  JSTBUSD: 823,
  SRMBUSD: 824,
  ANTBUSD: 825,
  CRVBUSD: 826,
  SANDBUSD: 827,
  OCEANBUSD: 828,
  NMRBUSD: 829,
  DOTBUSD: 830,
  LUNABUSD: 831,
  IDEXBUSD: 832,
  RSRBUSD: 833,
  TRBBUSD: 834,
  SUSHIBUSD: 836,
  YFIIBUSD: 837,
  KSMBUSD: 838,
  EGLDBUSD: 839,
  DIABUSD: 840,
  BELBUSD: 841,
  WINGBUSD: 842,
  UNIBUSD: 843,
  AVAXBUSD: 844,
  FLMBUSD: 845,
  CAKEBUSD: 846,
  XVSBUSD: 847,
  ALPHABUSD: 848,
  VIDTBUSD: 849,
  AAVEBUSD: 850,
  NEARBUSD: 851,
  FILBUSD: 852,
  ONEBUSD: 854,
  AUDIOBUSD: 855,
  CTKBUSD: 856,
  AXSBUSD: 857,
  HARDBUSD: 858,
  DNTBUSD: 859,
  CVPBUSD: 860,
  STRAXBUSD: 861,
  FORBUSD: 862,
  UNFIBUSD: 863,
  FRONTBUSD: 864,
  ROSEBUSD: 865,
  SYSBUSD: 866,
  SKLBUSD: 867,
  GHSTBUSD: 868,
  DFBUSD: 869,
  JUVBUSD: 870,
  PSGBUSD: 871,
  DEXEBUSD: 872,
  CKBBUSD: 873,
  TWTBUSD: 874,
  LITBUSD: 875,
  SFPBUSD: 876,
  DODOBUSD: 877,
  BAKEBUSD: 878,
  BANDBUSD: 879,
  GRTBUSD: 880,
  IOSTBUSD: 881,
  REEFBUSD: 882,
  ACMBUSD: 883,
  PHABUSD: 884,
  TVKBUSD: 885,
  BADGERBUSD: 886,
  FISBUSD: 887,
  OMBUSD: 888,
  PONDBUSD: 889,
  DEGOBUSD: 890,
  ALICEBUSD: 891,
  CHZBUSD: 892,
  LINABUSD: 893,
  PERPBUSD: 894,
  RAMPBUSD: 895,
  SUPERBUSD: 896,
  CFXBUSD: 897,
  XVGBUSD: 898,
  EPSBUSD: 899,
  AUTOBUSD: 900,
  TKOBUSD: 901,
  TLMBUSD: 902,
  BTGBUSD: 903,
  HOTBUSD: 904,
  MIRBUSD: 905,
  BARBUSD: 906,
  FORTHBUSD: 907,
  BURGERBUSD: 908,
  SLPBUSD: 909,
  SHIBBUSD: 910,
  ICPBUSD: 911,
  ARBUSD: 912,
  POLSBUSD: 913,
  MDXBUSD: 914,
  MASKBUSD: 915,
  LPTBUSD: 916,
  NUBUSD: 917,
  RLCBUSD: 918,
  CELRBUSD: 919,
  ATMBUSD: 920,
  ZENBUSD: 921,
  FTMBUSD: 922,
  THETABUSD: 923,
  WINBUSD: 924,
  KAVABUSD: 925,
  XEMBUSD: 926,
  ATABUSD: 927,
  GTCBUSD: 928,
  TORNBUSD: 929,
  COTIBUSD: 930,
  KEEPBUSD: 931,
  SCBUSD: 932,
  CHRBUSD: 933,
  STMXBUSD: 934,
  HNTBUSD: 935,
  FTTBUSD: 936,
  DOCKBUSD: 937,
  ERNBUSD: 938,
  KLAYBUSD: 939,
  UTKBUSD: 940,
  IOTXBUSD: 941,
  BONDBUSD: 942,
  MLNBUSD: 943,
  LTOBUSD: 944,
  QUICKBUSD: 945,
  C98BUSD: 946,
  CLVBUSD: 947,
  QNTBUSD: 948,
  FLOWBUSD: 949,
  XECBUSD: 950,
  MINABUSD: 951,
  RAYBUSD: 952,
  FARMBUSD: 953,
  ALPACABUSD: 954,
  ORNBUSD: 955,
  MBOXBUSD: 956,
  WAXPBUSD: 957,
  TRIBEBUSD: 958,
  GNOBUSD: 959,
  MTLBUSD: 960,
  OGNBUSD: 961,
  POLYBUSD: 962,
  DYDXBUSD: 963,
  ELFBUSD: 964,
  GALABUSD: 965,
  ILVBUSD: 966,
  RENBUSD: 967,
  YGGBUSD: 968,
  STXBUSD: 969,
  FETBUSD: 970,
  ARPABUSD: 971,
  LSKBUSD: 972,
  DENTBUSD: 973,
  AGLDBUSD: 974,
  RADBUSD: 975,
  HIVEBUSD: 976,
  STPTBUSD: 977,
  BETABUSD: 978,
  RAREBUSD: 979,
  TROYBUSD: 980,
  DEFIUSDT: 1200,
  ENSUSDT: 1201,
  DARUSDT: 1202,
  BNXUSDT: 1203,
  RGTUSDT: 1204,
  MOVRUSDT: 1205,
  CITYUSDT: 1206,
  KP3RUSDT: 1207,
  QIUSDT: 1208,
  PORTOUSDT: 1209,
  POWRUSDT: 1210,
  VGXUSDT: 1211,
  JASMYUSDT: 1212,
  AMPUSDT: 1213,
  PLAUSDT: 1214,
  PYRUSDT: 1215,
  RNDRUSDT: 1216,
  ALCXUSDT: 1217,
  SANTOSUSDT: 1218,
  MCUSDT: 1219,
  BICOUSDT: 1221,
  FLUXUSDT: 1222,
  FXSUSDT: 1223,
  VOXELUSDT: 1224,
  HIGHUSDT: 1225,
  CVXUSDT: 1226,
  PEOPLEUSDT: 1227,
  OOKIUSDT: 1228,
  SPELLUSDT: 1229,
  JOEUSDT: 1230,
  ACHUSDT: 1231,
  IMXUSDT: 1232,
  GLMRUSDT: 1233,
  LOKAUSDT: 1234,
  SCRTUSDT: 1235,
  API3USDT: 1236,
  BTTCUSDT: 1237,
  ACAUSDT: 1238,
  ANCUSDT: 1239,
  XNOUSDT: 1240,
  WOOUSDT: 1241,
  ALPINEUSDT: 1242,
  TUSDT: 1243,
  ASTRUSDT: 1244,
  GMTUSDT: 1246,
  KDAUSDT: 1247,
  APEUSDT: 1248,
  BSWUSDT: 1249,
  BIFIUSDT: 1250,
  MULTIUSDT: 1251,
  EPXUSDT: 1252,
  GALUSDT: 1253,
  LDOUSDT: 1254,
  MOBUSDT: 1255,
  OPUSDT: 1256,
  REIUSDT: 1257,
  STEEMUSDT: 1258,
  APTUSDT: 1259,
  GMXUSDT: 1260,
  LEVERUSDT: 1261,
  LUNCUSDT: 1262,
  NEBLUSDT: 1263,
  NEXOUSDT: 1264,
  OSMOUSDT: 1265,
  POLYXUSDT: 1266,
  STGUSDT: 1267,
  INJUSDT: 1268
};

/**
 * @enum {number}
 */
proto.harvester.Market = {
  UNKNOWN_MARKET: 0,
  BINANCE: 1
};

/**
 * @enum {number}
 */
proto.harvester.TradeType = {
  UNKNOWN_TRADE_TYPE: 0,
  SPOT: 1,
  FUTURE: 2
};

goog.object.extend(exports, proto.harvester);
