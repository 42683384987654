import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { BrainConfigsResponse } from '../../pkg/protobuf/v2/brain/tr_brain_types_pb';
import { ExecutorConfigsResponse, ExecutorPNLResponse } from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { RootState } from '../store';

export interface accountInt {
  key: string;
  name: string;
  brain: string;
  executor: string;
  executorRest: string;
  server: number;
  brainHealth: boolean;
  executorHealth: boolean;
  pnlDays: number;
  pnl: ExecutorPNLResponse | undefined;
  configs: ExecutorConfigsResponse | undefined;
  futurealgoparams: BrainConfigsResponse | undefined;
}

export interface pnl {
  pnlDays: number;
}

export interface accountsInt {
  accounts: accountInt[];
}

export const initialState: any = {
  key: '',
  name: '',
  brain: '',
  executor: '',
  executorRest: '',
  server: 0,
  brainHealth: false,
  executorHealth: false,
  pnlDays: 0,
  pnl: new ExecutorPNLResponse(),
  configs: { ...new ExecutorConfigsResponse(), disableExpansion: true },
  futurealgoparams: new BrainConfigsResponse(),
};

export const applicationAccountSlice = createSlice({
  name: 'applicationAccount',
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<accountInt[]>) => {
      state.accountsInt = action.payload;
    },
    setAccount: (state, action: PayloadAction<accountInt>) => {
      state.accountInt = action.payload;
    },
    addAccount: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index] = newElement;
      } else {
        // append
        currentState.push(newElement);
      }
      state.accountsInt = currentState;
    },
    addExHealth: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index].executorHealth = newElement.executorHealth;
      } else {
        // append
        currentState.concat(newElement);
      }
      state.accountsInt = currentState;
    },
    addBrainHealth: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index].brainHealth = newElement.brainHealth;
      } else {
        // append
        currentState.concat(newElement);
      }

      state.accountsInt = currentState;
    },
    addPnls: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index].pnl = newElement.pnl;
      } else {
        // append
        currentState.concat(newElement);
      }

      state.accountsInt = currentState;
    },
    addConfigs: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index].configs = newElement.configs;
      } else {
        // append
        currentState.concat(newElement);
      }

      state.accountsInt = currentState;
    },
    addFutureAlgoParams: (state, action: PayloadAction<accountInt | undefined>) => {
      const currentState = state.accountsInt;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const index = currentState.findIndex((p: accountInt) => p?.key === newElement?.key);
      // if exists then replace previous value with new one
      if (index >= 0) {
        currentState[index].futurealgoparams = newElement.futurealgoparams;
      } else {
        // append
        currentState.concat(newElement);
      }

      state.accountsInt = currentState;
    },
    setPNLDays: (state, action: PayloadAction<number>) => {
      state.pnlDays = action.payload;
    },
  },
});

export const {
  setAccounts,
  setAccount,
  addAccount,
  addExHealth,
  addBrainHealth,
  addPnls,
  addConfigs,
  addFutureAlgoParams,
  setPNLDays,
} = applicationAccountSlice.actions;

export const selectAccount = (state: RootState, action: PayloadAction<string | undefined>) => {
  return state.applicationAccount.accountsInt.find((elem: accountInt) => elem.key === action.payload);
};
export const selectAccounts = (state: RootState) => state.applicationAccount.accountsInt;
export const selectPNLDays = (state: RootState) => state.applicationAccount.pnlDays;

export default applicationAccountSlice.reducer;
