import { grpc } from '@improbable-eng/grpc-web';
import { BrainServiceClient } from './tr_brain_service_pb_service';
import { promisify } from 'es6-promisify';
import {
  BrainConfigsRequest,
  BrainConfigsResponse,
  BrainHealthStatusRequest,
  BrainHealthStatusResponse,
  GetRedFlagsRequest,
  GetRedFlagsResponse,
  GetRunningSymbolListRequest,
  GetRunningSymbolListResponse,
  IgnoreSymbolsRequest,
  RemoveFromIgnoreSymbolsRequest,
  RemoveSymbolRequest,
} from './tr_brain_types_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { AddSymbolsRequest } from '../executor/tr_executor_types_pb';

export class BrainServiceClientPromisified extends BrainServiceClient {
  getBrainHealthStatusPromise: (
    param1: BrainHealthStatusRequest,
    metadata?: grpc.Metadata,
  ) => Promise<BrainHealthStatusResponse | null> = promisify(this.getBrainHealthStatus);
  getRunningSymbolListPromise: (
    param1: GetRunningSymbolListRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetRunningSymbolListResponse | null> = promisify(this.getRunningSymbolList);
  removeSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeSymbols);
  addSymbolsPromise: (
    param1: AddSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.addSymbols);
  removeFutureSymbolsPromise: (
    param1: RemoveSymbolRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFutureSymbols);
  addFutureSymbolsPromise: (
    param1: AddSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.addFutureSymbols);
  getRemoteConfigsPromise: (
    param1: BrainConfigsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<BrainConfigsResponse | null> = promisify(this.getRemoteConfigs);
  setOrUpdateRemoteConfigsPromise: (
    param1: BrainConfigsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.setOrUpdateRemoteConfigs);
  removeFromIgnoreSymbolsPromise: (
    param1: RemoveFromIgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFromIgnoreSymbols);
  ignoreSymbolsPromise: (
    param1: IgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.ignoreSymbols);
  removeFromFutureIgnoreSymbolsPromise: (
    param1: RemoveFromIgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.removeFromFutureIgnoreSymbols);
  ignoreFutureSymbolsPromise: (
    param1: IgnoreSymbolsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<google_protobuf_empty_pb.Empty | null> = promisify(this.ignoreFutureSymbols);
  getRedFlagsPromise: (param1: GetRedFlagsRequest, metadata?: grpc.Metadata) => Promise<GetRedFlagsResponse | null> =
    promisify(this.getRedFlags);
}
