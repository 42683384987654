import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAccessToken,
  setAccessToken,
  setAccessTokenAfterOTP,
  setLoggedIn,
} from '../../app/redux/authorization.slice.reducer';
import { AuthHelper } from '../../pkg/apiHelpers/authHelper';
import {
  GetAllRolesRequest,
  GetAllRolesResponse,
  ListAllAccountsRequest,
  ListAllAccountsResponse,
} from '../../pkg/protobuf/v2/auth/auth_types_pb';
import Navbar from '../navbar/Navbar';
import UserRow from './UserRow';
import styles from './Users.module.scss';

const Users = () => {
  const userToken = useSelector(selectAccessToken);
  const [accounts, setAccounts] = useState<ListAllAccountsResponse | undefined>(undefined);
  const [roles, setRoles] = useState<GetAllRolesResponse | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  const fetchUsersAsync = async () => {
    const requestMessage = new ListAllAccountsRequest();
    const resp = await AuthHelper.listAllAccountsPromise(requestMessage, userToken);
    const rolesResp = await AuthHelper.getAllRolesPromise(new GetAllRolesRequest(), userToken);
    setAccounts(resp);
    setRoles(rolesResp);
  };

  const logout = () => {
    dispatch(setLoggedIn(false));
    dispatch(setAccessToken(''));
    dispatch(setAccessTokenAfterOTP(''));
    window.localStorage.clear();
    window.location.pathname = '/';
  };

  return (
    <div className={styles.usersWrapper}>
      <Navbar />
      <div className={styles.usersDash}>
        <div className={styles.header}>
          <h3>User Management</h3>
          <div
            role={'button'}
            tabIndex={0}
            className={styles.logout}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) logout();
            }}
          >
            <a>Logout</a>
          </div>
        </div>
        <div className={styles.usersContent}>
          <div className={styles.section}>
            <div>
              <h2>Users List</h2>
            </div>
            <div className={styles.content}>
              <div className={styles.head}>
                <h4>Email</h4>
                <h4>Admin</h4>
                <h4>View Only</h4>
                <h4>Visitor</h4>
              </div>
              <hr />
              {accounts?.accounts.map((value, key) => {
                return <UserRow value={value} roles={roles} userToken={userToken} key={key} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
