// @generated by protoc-gen-connect-web v0.3.0 with parameter "target=js+ts+dts"
// @generated from file harvester/tr_harvester_service.proto (package harvester, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {BrainSyncRequest, BrainSyncResponse, GetAllIgnoredSymbolsRequest, GetAllIgnoredSymbolsResponse, GetAllSupportedSymbolsRequest, GetAllSupportedSymbolsResponse, GetDiskUsageRequest, GetDiskUsageResponse, HarvHealthStatusRequest, HarvHealthStatusResponse, HarvOHLCVRequest, HarvOHLCVResponse, LastTradeRequest, PriceTickerRequest, PriceTickerResponse, ReEnableSymbolsRequest, RemoveSymbolRequest} from "./tr_harvester_types_pb.js";
import {Empty, MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service harvester.HarvesterService
 */
export const HarvesterService = {
  typeName: "harvester.HarvesterService",
  methods: {
    /**
     * Get exchange marketplace health status for a specific market
     *
     * @generated from rpc harvester.HarvesterService.GetMarketHealthStatus
     */
    getMarketHealthStatus: {
      name: "GetMarketHealthStatus",
      I: HarvHealthStatusRequest,
      O: HarvHealthStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get Spot last X (seconds) OHLCV data from harvester for a specific market and symbol
     *
     * @generated from rpc harvester.HarvesterService.GetSpotLastXohlcvData
     */
    getSpotLastXohlcvData: {
      name: "GetSpotLastXohlcvData",
      I: HarvOHLCVRequest,
      O: HarvOHLCVResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get Spot last trade as OHLCV data from harvester for a specific market and symbol
     *
     * @generated from rpc harvester.HarvesterService.GetSpotLastTradeAsOHLCVData
     */
    getSpotLastTradeAsOHLCVData: {
      name: "GetSpotLastTradeAsOHLCVData",
      I: LastTradeRequest,
      O: HarvOHLCVResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get Future last X (seconds) OHLCV data from harvester for a specific market
     *
     * @generated from rpc harvester.HarvesterService.GetFutureLastXohlcvData
     */
    getFutureLastXohlcvData: {
      name: "GetFutureLastXohlcvData",
      I: HarvOHLCVRequest,
      O: HarvOHLCVResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get Future last trade as OHLCV data from harvester for a specific market and symbol
     *
     * @generated from rpc harvester.HarvesterService.GetFutureLastTradeAsOHLCVData
     */
    getFutureLastTradeAsOHLCVData: {
      name: "GetFutureLastTradeAsOHLCVData",
      I: LastTradeRequest,
      O: HarvOHLCVResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetAllSupportedSymbols
     */
    getAllSupportedSymbols: {
      name: "GetAllSupportedSymbols",
      I: GetAllSupportedSymbolsRequest,
      O: GetAllSupportedSymbolsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetAllIgnoredSymbols
     */
    getAllIgnoredSymbols: {
      name: "GetAllIgnoredSymbols",
      I: GetAllIgnoredSymbolsRequest,
      O: GetAllIgnoredSymbolsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.ReEnableSymbols
     */
    reEnableSymbols: {
      name: "ReEnableSymbols",
      I: ReEnableSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.ReEnableFutureSymbols
     */
    reEnableFutureSymbols: {
      name: "ReEnableFutureSymbols",
      I: ReEnableSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.RemoveSymbols
     */
    removeSymbols: {
      name: "RemoveSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.RemoveFutureSymbols
     */
    removeFutureSymbols: {
      name: "RemoveFutureSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetUpdatedPriceTickerStream
     */
    getUpdatedPriceTickerStream: {
      name: "GetUpdatedPriceTickerStream",
      I: PriceTickerRequest,
      O: PriceTickerResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetUpdatedPriceTickerPeriodicallyStream
     */
    getUpdatedPriceTickerPeriodicallyStream: {
      name: "GetUpdatedPriceTickerPeriodicallyStream",
      I: PriceTickerRequest,
      O: PriceTickerResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetPriceTicker
     */
    getPriceTicker: {
      name: "GetPriceTicker",
      I: PriceTickerRequest,
      O: PriceTickerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetDiskUsage
     */
    getDiskUsage: {
      name: "GetDiskUsage",
      I: GetDiskUsageRequest,
      O: GetDiskUsageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.LockBrainSync
     */
    lockBrainSync: {
      name: "LockBrainSync",
      I: BrainSyncRequest,
      O: BrainSyncResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.UnlockBrainSync
     */
    unlockBrainSync: {
      name: "UnlockBrainSync",
      I: BrainSyncRequest,
      O: BrainSyncResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc harvester.HarvesterService.GetBrainSync
     */
    getBrainSync: {
      name: "GetBrainSync",
      I: BrainSyncRequest,
      O: BrainSyncResponse,
      kind: MethodKind.Unary,
    },
  }
};

