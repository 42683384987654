import { ConnectError } from '@bufbuild/connect-web';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';

import { SumsResponseType } from '../../components/orders/Orders';
import { Backend } from '../../config/backend';
import { logger } from '../../config/Logger';
import {
  AddSymbolsRequest,
  GetRunningSymbolListRequest,
  GetRunningSymbolListResponse,
  RemoveSymbolRequest,
} from '../protobuf/v2/brain/tr_brain_types_pb';
// import {ResponseStream} from '../protobuf/v2/executor/tr_executor_service_pb_service';
import {
  CancelOrderRequest,
  DownloadRequest,
  DownloadResponse,
  ExecuteOrderRequest,
  ExecuteOrderResponse,
  ExecutorBase,
  ExecutorConfigsRequest,
  ExecutorConfigsResponse,
  ExecutorHealthStatusRequest,
  ExecutorPNLRequest,
  ExecutorPNLResponse,
  GetActualAPIPositionsRequest,
  GetActualAPIPositionsResponse,
  GetDisabledSymbolsRequest,
  GetDisabledSymbolsResponse,
  GetOpenOrdersRequest,
  GetOpenOrdersResponse,
  GetPnlCalculatorMapsResponse,
  GetPnlCalculatorRequest,
  GetPnlCalculatorResponse,
  GetSumsRequest,
  GetSumsResponse,
  IgnoreSymbolsRequest,
  OrderType,
  RemoveFromIgnoreSymbolsRequest,
  ResetConfigSignalRequest,
  ResetConfigSignalResponse,
  SetTelegramOTPCodeRequest,
  SetTelegramOTPCodeResponse,
  Symbol,
} from '../protobuf/v2/executor/tr_executor_types_pb';
import { Market } from '../protobuf/v2/harvester/tr_harvester_types_pb';

export class ExecutorHelper {
  private static clients = Backend.getExecutorClient;
  private static callbackClients = Backend.getExecutorCallbackClient;

  public static async getExecutorHealth(url: string, userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new ExecutorHealthStatusRequest();
      const base = new ExecutorBase();
      base.marketName = Market.BINANCE;
      request.base = base;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await this.clients(url)?.getExecutorHealthStatus(request, { headers });
      return response?.alive;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async getPNL(
    url: string,
    daysBefore: number,
    now: boolean,
    userToken = '',
  ): Promise<ExecutorPNLResponse | undefined> {
    try {
      const request = new ExecutorPNLRequest();
      request.daysBefore = daysBefore;
      request.againstNow = now;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getPNL(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async getRemoteConfigs(url: string, userToken = ''): Promise<ExecutorConfigsResponse | undefined> {
    try {
      const requestMessage = new google_protobuf_empty_pb.Empty();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getRemoteConfigs(requestMessage, { headers });
    } catch (e) {
      logger.error({ message: 'getRemoteConfigs executorHelper.ts: ' + e });
      return undefined;
    }
  }

  public static async setOrUpdateRemoteConfigs(
    url: string,
    executorConfigs: ExecutorConfigsRequest,
    userToken = '',
  ): Promise<boolean> {
    try {
      const requestMessage = new ExecutorConfigsRequest();
      requestMessage.btcValuePerLevel = executorConfigs.btcValuePerLevel;
      requestMessage.allowFutureExecution = executorConfigs.allowFutureExecution;
      requestMessage.disableExpansion = executorConfigs.disableExpansion;
      requestMessage.usdtValuePerLevel = executorConfigs.usdtValuePerLevel;
      requestMessage.busdValuePerLevel = executorConfigs.busdValuePerLevel;
      requestMessage.hold = executorConfigs.hold;
      requestMessage.allowZigZag = executorConfigs.allowZigZag;
      requestMessage.boostVpl = executorConfigs.boostVpl;
      requestMessage.maximizeInvestment = executorConfigs.maximizeInvestment;
      requestMessage.levelsPerCoinMin = executorConfigs.levelsPerCoinMin;
      requestMessage.levelsPerCoinMax = executorConfigs.levelsPerCoinMax;
      requestMessage.levelsPerCoinSafetyFactor = executorConfigs.levelsPerCoinSafetyFactor;
      requestMessage.percentInvestedMax = executorConfigs.percentInvestedMax;
      requestMessage.percentInvestedMin = executorConfigs.percentInvestedMin;
      requestMessage.allowNegativeSignal = executorConfigs.allowNegativeSignal;
      requestMessage.allowAboveNotional = executorConfigs.allowAboveNotional;
      requestMessage.notionalMultiplier = executorConfigs.notionalMultiplier;
      requestMessage.targetSpotInvestedPct = executorConfigs.targetSpotInvestedPct;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.setOrUpdateRemoteConfigs(requestMessage, { headers });
      return true;
    } catch (e) {
      logger.error({ message: 'setOrUpdateRemoteConfigs executorHelper.ts: ' + e });
      return false;
    }
  }

  public static async getRunningSymbolList(
    url: string,
    userToken = '',
  ): Promise<GetRunningSymbolListResponse | undefined> {
    try {
      const request = new GetRunningSymbolListRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getRunningSymbolList(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return new GetRunningSymbolListResponse();
    }
  }

  public static async getRunningWithoutDESymbolList(
    url: string,
    userToken = '',
  ): Promise<GetRunningSymbolListResponse | undefined> {
    try {
      const request = new GetRunningSymbolListRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getRunningWithoutDESymbolList(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return new GetRunningSymbolListResponse();
    }
  }

  public static async getDESymbolList(url: string, userToken = ''): Promise<GetRunningSymbolListResponse | undefined> {
    try {
      const request = new GetRunningSymbolListRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getDESymbolList(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return new GetRunningSymbolListResponse();
    }
  }

  public static async removeSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.removeSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeDESymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.removeDESymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async addSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new AddSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.addSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async addDESymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new AddSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.addDESymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFutureSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.removeFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async addFutureSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new AddSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.addFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFromIgnoreSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveFromIgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.removeFromIgnoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async ignoreSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new IgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.ignoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFromFutureIgnoreSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveFromIgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.removeFromFutureIgnoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async ignoreFutureSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new IgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.ignoreFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async getOpenOrdersStream(
    dispatch: any,
    addOrder: any,
    removeOrder: any,
    setNotionalBuy: any,
    setNotionalSell: any,
    setNotionalBuyMinute: any,
    setNotionalSellMinute: any,
    url: string,
    accountName: string,
    accountAsset: string,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new GetOpenOrdersRequest();
      request.quoteAsset = accountAsset;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await this.clients(url)?.getOpenOrders(request, { headers });
      dispatch(setNotionalBuy(response?.notionalBuy || 0));
      dispatch(setNotionalSell(response?.notionalSell || 0));
      dispatch(setNotionalBuyMinute(response?.notionalBuyMinute || 0));
      dispatch(setNotionalSellMinute(response?.notionalSellMinute || 0));
      if (response && response?.openOrders?.length > 0) {
        dispatch(
          addOrder({
            accountKey: accountName,
            orders: response.openOrders,
            notionalBuy: response.notionalBuy,
            notionalSell: response.notionalSell,
            notionalBuyMinute: response.notionalBuyMinute,
            notionalSellMinute: response.notionalSellMinute,
          }),
        );
        response.openOrders.forEach((order) => {
          if (order.Status === 3 || order.Status === 4 || order.Status === 6 || order.Status === 7) {
            setTimeout(function () {
              dispatch(removeOrder({ accountKey: accountName, order: order }));
            }, 60000);
          }
        });
      }
      const cancelStream = this.callbackClients(url)?.getOpenOrdersStream(
        request,
        async (message: GetOpenOrdersResponse) => {
          logger.debug({
            message: `DATA inside Stream getOpenOrdersStream received! Message: ${JSON.stringify(message, (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            )}`,
          });
          if (!message.noop) {
            dispatch(
              addOrder({
                accountKey: accountName,
                orders: message.openOrders,
                notionalBuy: message.notionalBuy,
                notionalSell: message.notionalSell,
                notionalBuyMinute: message.notionalBuyMinute,
                notionalSellMinute: message.notionalSellMinute,
              }),
            );
            message.openOrders.forEach((order) => {
              if (order.Status === 3 || order.Status === 4 || order.Status === 6 || order.Status === 7) {
                setTimeout(function () {
                  dispatch(removeOrder({ accountKey: accountName, order: order }));
                }, 60000);
              }
            });
          }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: 'CLOSING Stream' + error });
          if (error && cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e) {
      logger.error({ message: 'CLOSING Stream' + e });
      return () => {
        'no stream avaialble';
      };
    }
  }

  public static async getPnlCalculatorStream(
    dispatch: any,
    addPnlTrade: any,
    url: string,
    accountName: string,
    accountAsset: string,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new GetOpenOrdersRequest();
      request.quoteAsset = accountAsset;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const cancelStream = this.callbackClients(url)?.getPnlCalculatorStream(
        request,
        async (rsp: GetPnlCalculatorResponse) => {
          logger.debug({
            message: `DATA inside Stream getPnlCalculatorStream received! Message: ${JSON.stringify(rsp, (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            )}`,
          });
          if (userToken === '') {
            logger.debug({ message: 'Stream is cancelled! userToke is empty' });
            if (cancelStream) cancelStream();
          }
          if (!rsp.noop) {
            dispatch(addPnlTrade({ accountKey: accountName, trade: rsp }));
          }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: error?.message });
          if (cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e) {
      logger.error({ message: e });
      return () => {
        'no stream avaialble';
      };
    }
  }

  public static async getPnlCalculator(
    url: string,
    userToken: string,
  ): Promise<GetPnlCalculatorMapsResponse | undefined> {
    try {
      const request = new GetPnlCalculatorRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getPnlCalculator(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async getSums(url: string, userToken: string): Promise<GetSumsResponse | undefined> {
    try {
      const request = new GetSumsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getSums(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async getSumsStream(
    sums: Array<SumsResponseType>,
    setSums: any,
    url: string,
    accountName: string,
    accountAsset: string,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new GetSumsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const cancelStream = this.callbackClients(url)?.getSumsStream(
        request,
        async (rsp: GetSumsResponse) => {
          // logger.debug({
          //   message: `DATA inside Stream getSumsStream received! Message: ${JSON.stringify(rsp, (_, v) =>
          //     typeof v === 'bigint' ? v.toString() : v,
          //   )}`,
          // });
          if (userToken === '') {
            logger.debug({ message: 'Stream is cancelled! userToken is empty' });
            if (cancelStream) cancelStream();
          }
          if (!rsp.noop) {
            setSums((prevState: any) => {
              const temp = JSON.parse(JSON.stringify(prevState, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
              const index = temp.findIndex((p: any) => p.accountName === accountName);
              if (index !== -1) {
                temp.splice(index, 1);
              }
              temp.push({ accountName: accountName, sums: rsp });
              return temp;
            });
          }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: error?.message });
          if (cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e) {
      logger.error({ message: e });
      return () => {
        'no stream avaialble';
      };
    }
  }

  public static async ExecuteOrder(
    url: string,
    userToken: string,
    symbolName: string,
    price: number,
    type: 0 | 1 | 2,
    quantity: number,
    orderType?: string,
  ): Promise<ExecuteOrderResponse | undefined> {
    try {
      const request = new ExecuteOrderRequest();
      const symbol = new Symbol();
      symbol.symbolName = symbolName;
      request.symbol = symbol;
      request.price = price;
      request.type = type;
      request.quantity = quantity;
      if (orderType) request.orderType = orderType;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.executeOrder(request, { headers });
    } catch (e) {
      logger.error({ message: e });
    }
  }

  public static async CancelOrder(url: string, userToken: string, id: string, symbolName: string): Promise<boolean> {
    try {
      const request = new CancelOrderRequest();
      request.id = id;
      const symbol = new Symbol();
      symbol.symbolName = symbolName;
      request.symbol = symbol;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.clients(url)?.cancelOrder(request, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e });
      return !!e.message.toLowerCase()?.includes('unknown order sent');
    }
  }

  public static async Download(
    url: string,
    id: string,
    daysBefore: number,
    time: string | undefined,
    userToken = '',
  ): Promise<DownloadResponse | undefined> {
    try {
      const request = new DownloadRequest();
      request.id = id;
      request.daysBefore = BigInt(daysBefore);
      if (time) request.time = time;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.download(request, { headers });
    } catch (e: any) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async GetDisabledCoins(url: string, userToken = ''): Promise<GetDisabledSymbolsResponse | undefined> {
    try {
      const request = new GetDisabledSymbolsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getDisabledCoins(request, { headers });
    } catch (e: any) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async GetActualAPIPositions(
    url: string,
    userToken = '',
  ): Promise<GetActualAPIPositionsResponse | undefined> {
    try {
      const request = new GetActualAPIPositionsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.getActualAPIPositions(request, { headers });
    } catch (e: any) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async SetTelegramOTPCode(
    url: string,
    code: string,
    userToken = '',
  ): Promise<SetTelegramOTPCodeResponse | undefined> {
    try {
      const request = new SetTelegramOTPCodeRequest();
      request.code = code;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.setTelegramOTPCode(request, { headers });
    } catch (e: any) {
      logger.error({ message: e });
      throw e;
      // return undefined;
    }
  }

  public static async ResetConfigSignal(url: string, userToken = ''): Promise<ResetConfigSignalResponse | undefined> {
    try {
      const request = new ResetConfigSignalRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.clients(url)?.resetConfigSignal(request, { headers });
    } catch (e: any) {
      logger.error({ message: e });
      throw e;
    }
  }
}
