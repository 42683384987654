import { ConnectError } from '@bufbuild/connect-web';

import { Backend } from '../../config/backend';
import { logger } from '../../config/Logger';
import {
  AddSymbolsRequest,
  BrainBase,
  BrainConfigsRequest,
  BrainConfigsResponse,
  BrainFutureAlgoParams,
  BrainFutureAlgoSource,
  BrainHealthStatusRequest,
  GetRedFlagsRequest,
  GetRedFlagsResponse,
  GetRunningSymbolListRequest,
  GetRunningSymbolListResponse,
  HedgeType,
  IgnoreSymbolsRequest,
  RemoveFromIgnoreSymbolsRequest,
  RemoveSymbolRequest,
} from '../protobuf/v2/brain/tr_brain_types_pb';

export class BrainHelper {
  private static client = Backend.getBrainClient;
  private static clientCallback = Backend.getBrainCallbackClient;

  public static async getBrainHealth(url: string, userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new BrainHealthStatusRequest();
      const base = new BrainBase();
      base.brainUUID = 's';
      request.base = base;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await this.client(url)?.getBrainHealthStatus(request, { headers });
      return response?.alive;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async getRunningSymbolList(
    url: string,
    userToken = '',
  ): Promise<GetRunningSymbolListResponse | undefined> {
    try {
      const request = new GetRunningSymbolListRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.client(url)?.getRunningSymbolList(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return new GetRunningSymbolListResponse();
    }
  }

  public static async removeSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.removeSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async addSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new AddSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.addSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFutureSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.removeFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async addFutureSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new AddSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.addFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFromIgnoreSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveFromIgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.removeFromIgnoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async ignoreSymbols(url: string, symbols: string[], userToken = ''): Promise<boolean | undefined> {
    try {
      const request = new IgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.ignoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFromFutureIgnoreSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new RemoveFromIgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.removeFromFutureIgnoreSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async ignoreFutureSymbols(
    url: string,
    symbols: string[],
    userToken = '',
  ): Promise<boolean | undefined> {
    try {
      const request = new IgnoreSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await this.client(url)?.ignoreFutureSymbols(request, { headers });
      return true;
    } catch (e) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async getRemoteConfigs(url: string, userToken = ''): Promise<BrainConfigsResponse | undefined> {
    try {
      const request = new BrainConfigsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await this.client(url)?.getRemoteConfigs(request, { headers });
      return response;
    } catch (e) {
      logger.error({ message: e });
      return new BrainConfigsResponse();
    }
  }

  public static async setOrUpdateRemoteConfigs(
    url: string,
    length: number,
    lookback: number,
    source: BrainFutureAlgoSource,
    hedgeType: HedgeType,
    allowFutureExecution: boolean,
    allowNegativeSignal: boolean,
    disableQuarterly: boolean,
    disableRedFlags: boolean,
    discountFactor: number,
    tradeOffset: number,
    numberOfLevels: number,
    redFlagsNumberOfLevels: number,
    quarterlyTradeOffset: number,
    userToken = '',
  ) {
    try {
      const request = new BrainConfigsRequest();
      const params = new BrainFutureAlgoParams();
      params.length = length;
      params.lookBack = lookback;
      params.source = source;
      params.hedgeType = hedgeType;
      params.allowFutureExecution = allowFutureExecution;
      request.futureAlgoParams = params;
      request.allowNegativeSignal = allowNegativeSignal;
      request.disabledQuarterlyLevels = disableQuarterly;
      request.disabledRedFlags = disableRedFlags;
      request.discountFactor = discountFactor;
      request.tradeOffset = tradeOffset;
      request.numberOfLevels = numberOfLevels;
      request.redFlagsNumberOfLevels = redFlagsNumberOfLevels;
      request.quarterlyTradeOffset = quarterlyTradeOffset;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await this.client(url)?.setOrUpdateRemoteConfigs(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return new BrainConfigsResponse();
    }
  }

  public static async getRedFlags(url: string, userToken = ''): Promise<GetRedFlagsResponse | undefined> {
    try {
      const request = new GetRedFlagsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await this.client(url)?.getRedFlags(request, { headers });
      return response;
    } catch (e) {
      logger.error({ message: e });
      return new GetRedFlagsResponse();
    }
  }

  public static async getRedFlagsStream(
    dispatch: any,
    setRedFlags: any,
    url: string,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new GetRedFlagsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const cancelStream = await this.clientCallback(url)?.getRedFlagsStream(
        request,
        async (message: GetRedFlagsResponse) => {
          if (message.noop?.data === 'still alive stream connection')
            if (cancelStream) cancelStream();
            else {
              dispatch(setRedFlags({ spotRedFlags: message.spotSymbols, futureRedFlags: message.futureSymbols }));
            }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: error?.message });
          if (cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }
}
