// @generated by protoc-gen-connect-web v0.3.0 with parameter "target=js+ts+dts"
// @generated from file executor/tr_executor_service.proto (package executor, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {AddSymbolsRequest, CancelOrderRequest, CancelOrderResponse, DownloadRequest, DownloadResponse, ExecuteOrderRequest, ExecuteOrderResponse, ExecutorConfigsRequest, ExecutorConfigsResponse, ExecutorHealthStatusRequest, ExecutorHealthStatusResponse, ExecutorPNLRequest, ExecutorPNLResponse, GetActualAPIPositionsRequest, GetActualAPIPositionsResponse, GetDisabledSymbolsRequest, GetDisabledSymbolsResponse, GetOpenOrdersRequest, GetOpenOrdersResponse, GetPnlCalculatorMapsResponse, GetPnlCalculatorRequest, GetPnlCalculatorResponse, GetRunningSymbolListRequest, GetRunningSymbolListResponse, GetSumsRequest, GetSumsResponse, IgnoreSymbolsRequest, RemoveFromIgnoreSymbolsRequest, RemoveSymbolRequest, ResetConfigSignalRequest, ResetConfigSignalResponse, SetTelegramOTPCodeRequest, SetTelegramOTPCodeResponse} from "./tr_executor_types_pb.js";
import {Empty, MethodKind} from "@bufbuild/protobuf";
import {SignalBase} from "../brain/tr_brain_types_pb.js";

/**
 * @generated from service executor.ExecutorService
 */
export const ExecutorService = {
  typeName: "executor.ExecutorService",
  methods: {
    /**
     * Get executors health status for a specific market
     *
     * @generated from rpc executor.ExecutorService.GetExecutorHealthStatus
     */
    getExecutorHealthStatus: {
      name: "GetExecutorHealthStatus",
      I: ExecutorHealthStatusRequest,
      O: ExecutorHealthStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * send signal to executor
     *
     * @generated from rpc executor.ExecutorService.SendSignal
     */
    sendSignal: {
      name: "SendSignal",
      I: SignalBase,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * set or update remote configs
     *
     * @generated from rpc executor.ExecutorService.SetOrUpdateRemoteConfigs
     */
    setOrUpdateRemoteConfigs: {
      name: "SetOrUpdateRemoteConfigs",
      I: ExecutorConfigsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * get remote configs
     *
     * @generated from rpc executor.ExecutorService.GetRemoteConfigs
     */
    getRemoteConfigs: {
      name: "GetRemoteConfigs",
      I: Empty,
      O: ExecutorConfigsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * get the account PNL
     *
     * @generated from rpc executor.ExecutorService.GetPNL
     */
    getPNL: {
      name: "GetPNL",
      I: ExecutorPNLRequest,
      O: ExecutorPNLResponse,
      kind: MethodKind.Unary,
    },
    /**
     * get running list of symbols per quote coin
     *
     * @generated from rpc executor.ExecutorService.GetRunningSymbolList
     */
    getRunningSymbolList: {
      name: "GetRunningSymbolList",
      I: GetRunningSymbolListRequest,
      O: GetRunningSymbolListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetRunningWithoutDESymbolList
     */
    getRunningWithoutDESymbolList: {
      name: "GetRunningWithoutDESymbolList",
      I: GetRunningSymbolListRequest,
      O: GetRunningSymbolListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetDESymbolList
     */
    getDESymbolList: {
      name: "GetDESymbolList",
      I: GetRunningSymbolListRequest,
      O: GetRunningSymbolListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.AddSymbols
     */
    addSymbols: {
      name: "AddSymbols",
      I: AddSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.AddDESymbols
     */
    addDESymbols: {
      name: "AddDESymbols",
      I: AddSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.AddFutureSymbols
     */
    addFutureSymbols: {
      name: "AddFutureSymbols",
      I: AddSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.RemoveSymbols
     */
    removeSymbols: {
      name: "RemoveSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.RemoveFromIgnoreSymbols
     */
    removeFromIgnoreSymbols: {
      name: "RemoveFromIgnoreSymbols",
      I: RemoveFromIgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.IgnoreSymbols
     */
    ignoreSymbols: {
      name: "IgnoreSymbols",
      I: IgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.RemoveFromFutureIgnoreSymbols
     */
    removeFromFutureIgnoreSymbols: {
      name: "RemoveFromFutureIgnoreSymbols",
      I: RemoveFromIgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.IgnoreFutureSymbols
     */
    ignoreFutureSymbols: {
      name: "IgnoreFutureSymbols",
      I: IgnoreSymbolsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.RemoveDESymbols
     */
    removeDESymbols: {
      name: "RemoveDESymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.RemoveFutureSymbols
     */
    removeFutureSymbols: {
      name: "RemoveFutureSymbols",
      I: RemoveSymbolRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetOpenOrders
     */
    getOpenOrders: {
      name: "GetOpenOrders",
      I: GetOpenOrdersRequest,
      O: GetOpenOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetOpenOrdersStream
     */
    getOpenOrdersStream: {
      name: "GetOpenOrdersStream",
      I: GetOpenOrdersRequest,
      O: GetOpenOrdersResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetPnlCalculatorStream
     */
    getPnlCalculatorStream: {
      name: "GetPnlCalculatorStream",
      I: GetPnlCalculatorRequest,
      O: GetPnlCalculatorResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetPnlCalculator
     */
    getPnlCalculator: {
      name: "GetPnlCalculator",
      I: GetPnlCalculatorRequest,
      O: GetPnlCalculatorMapsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetSums
     */
    getSums: {
      name: "GetSums",
      I: GetSumsRequest,
      O: GetSumsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetSumsStream
     */
    getSumsStream: {
      name: "GetSumsStream",
      I: GetSumsRequest,
      O: GetSumsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc executor.ExecutorService.ExecuteOrder
     */
    executeOrder: {
      name: "ExecuteOrder",
      I: ExecuteOrderRequest,
      O: ExecuteOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.CancelOrder
     */
    cancelOrder: {
      name: "CancelOrder",
      I: CancelOrderRequest,
      O: CancelOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.Download
     */
    download: {
      name: "Download",
      I: DownloadRequest,
      O: DownloadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetDisabledCoins
     */
    getDisabledCoins: {
      name: "GetDisabledCoins",
      I: GetDisabledSymbolsRequest,
      O: GetDisabledSymbolsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.GetActualAPIPositions
     */
    getActualAPIPositions: {
      name: "GetActualAPIPositions",
      I: GetActualAPIPositionsRequest,
      O: GetActualAPIPositionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.SetTelegramOTPCode
     */
    setTelegramOTPCode: {
      name: "SetTelegramOTPCode",
      I: SetTelegramOTPCodeRequest,
      O: SetTelegramOTPCodeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc executor.ExecutorService.ResetConfigSignal
     */
    resetConfigSignal: {
      name: "ResetConfigSignal",
      I: ResetConfigSignalRequest,
      O: ResetConfigSignalResponse,
      kind: MethodKind.Unary,
    },
  }
};

