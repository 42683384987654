import { ConnectError } from '@bufbuild/connect-web';

import { pricesInterface } from '../../app/redux/prices.slice.reducer';
import { Backend } from '../../config/backend';
import { logger } from '../../config/Logger';
import { RemoveSymbolRequest } from '../protobuf/v2/brain/tr_brain_types_pb';
import { GetPnlCalculatorResponse } from '../protobuf/v2/executor/tr_executor_types_pb';
import {
  BrainSyncRequest,
  BrainSyncResponse,
  GetAllIgnoredSymbolsRequest,
  GetAllSupportedSymbolsRequest,
  GetDiskUsageRequest,
  GetDiskUsageResponse,
  HarvHealthStatusRequest,
  HarvHealthStatusResponse,
  Market,
  PriceTickerRequest,
  PriceTickerResponse,
  ReEnableSymbolsRequest,
} from '../protobuf/v2/harvester/tr_harvester_types_pb';

export class HarvesterHelper {
  private static client = Backend.harvesterClientV2Old;
  private static clientCallback = Backend.harvesterClientCallbackV2Old;
  private static clientDO = Backend.harvesterClientV2;
  private static clientCallbackDO = Backend.harvesterClientCallbackV2;

  public static async getHarvesterHealth(
    server: number,
    userToken = '',
  ): Promise<HarvHealthStatusResponse | undefined> {
    const request = new HarvHealthStatusRequest();
    request.marketName = Market.BINANCE;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
    return await client.getMarketHealthStatus(request, { headers });
  }

  public static async getDiskUsage(server: number, userToken = ''): Promise<GetDiskUsageResponse | undefined> {
    const request = new GetDiskUsageRequest();
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
    return await client.getDiskUsage(request, { headers });
  }

  public static async getAllSupportedSymbols(server: number, asset: string, userToken = ''): Promise<any> {
    const request = new GetAllSupportedSymbolsRequest();
    request.quoteAsset = asset;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
    return await client.getAllSupportedSymbols(request, { headers });
  }

  public static async getAllIgnoredSymbols(server: number, asset: string, userToken = ''): Promise<any> {
    const request = new GetAllIgnoredSymbolsRequest();
    request.quoteAsset = asset;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
    return await client.getAllIgnoredSymbols(request, { headers });
  }

  public static async reEnableSymbols(server: number, symbols: string[], userToken = ''): Promise<boolean> {
    try {
      const request = new ReEnableSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      await client.reEnableSymbols(request, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async reEnableFutureSymbols(server: number, symbols: string[], userToken = ''): Promise<boolean> {
    try {
      const request = new ReEnableSymbolsRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      await client.reEnableFutureSymbols(request, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeSymbols(server: number, symbols: string[], userToken = ''): Promise<boolean> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      await client.removeSymbols(request, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async removeFutureSymbols(server: number, symbols: string[], userToken = ''): Promise<boolean> {
    try {
      const request = new RemoveSymbolRequest();
      request.symbols = symbols;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      await client.removeFutureSymbols(request, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e });
      return false;
    }
  }

  public static async getPriceTickerPromise(server: number, userToken = ''): Promise<PriceTickerResponse | undefined> {
    try {
      const request = new PriceTickerRequest();
      request.marketName = Market.BINANCE;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      return await client.getPriceTicker(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async getUpdatedPriceTickerStream(
    server: number,
    dispatch: any,
    setPrices: any,
    updatePnlTradesPrices: any,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new PriceTickerRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.clientCallback : HarvesterHelper.clientCallbackDO;
      const cancelStream = await client.getUpdatedPriceTickerStream(
        request,
        async (message: PriceTickerResponse) => {
          logger.debug({
            message: `DATA inside Stream getUpdatedPriceTickerStream received! Message: ${JSON.stringify(
              message,
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            )}`,
          });
          if (!message.noop) {
            const pricesTickerInterface: pricesInterface = {
              response: message,
            };
            dispatch(setPrices(pricesTickerInterface));
            if (updatePnlTradesPrices) {
              dispatch(updatePnlTradesPrices(pricesTickerInterface));
            }
          }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: error?.message });
          if (cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async getUpdatedPriceTickerPeriodicallyStream(
    server: number,
    dispatch: any,
    setPrices: any,
    updatePnlTradesPrices: any,
    userToken = '',
  ): Promise<(() => void | null) | undefined> {
    try {
      const request = new PriceTickerRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.clientCallback : HarvesterHelper.clientCallbackDO;
      const cancelStream = await client.getUpdatedPriceTickerPeriodicallyStream(
        request,
        async (message: PriceTickerResponse) => {
          if (!message.noop) {
            const pricesTickerInterface: pricesInterface = {
              response: message,
            };
            dispatch(setPrices(pricesTickerInterface));
            if (updatePnlTradesPrices) {
              dispatch(updatePnlTradesPrices(pricesTickerInterface));
            }
          }
        },
        (error: ConnectError | undefined) => {
          logger.debug({ message: error });
          if (cancelStream) cancelStream();
        },
        { headers },
      );
      return cancelStream;
    } catch (e: any) {
      logger.error({ message: e.message });
      return undefined;
    }
  }

  public static async getBrainSync(server: number, userToken = ''): Promise<BrainSyncResponse | undefined> {
    try {
      const request = new BrainSyncRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      return await client.getBrainSync(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }

  public static async unlockBrainSync(server: number, userToken = ''): Promise<BrainSyncResponse | undefined> {
    try {
      const request = new BrainSyncRequest();
      request.force = true;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const client = server === 1 ? HarvesterHelper.client : HarvesterHelper.clientDO;
      return await client.unlockBrainSync(request, { headers });
    } catch (e) {
      logger.error({ message: e });
      return undefined;
    }
  }
}
