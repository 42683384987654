import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetPnlCalculatorResponse } from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { RootState } from '../store';
import { pricesInterface } from './prices.slice.reducer';

export interface pnlTradeInterface {
  accountKey: string;
  trade: GetPnlCalculatorResponse;
}

export interface pnlTradesInterface {
  accountKey: string;
  spot: GetPnlCalculatorResponse[];
  future: GetPnlCalculatorResponse[];
}

export const initialState: any = {
  pnlTrades: [],
  accountKey: '',
  spot: [],
  future: [],
};

export const pnlCalcTradesSlice = createSlice({
  name: 'pnlCalcTrades',
  initialState,
  reducers: {
    setPnlTrades: (state, action: PayloadAction<pnlTradesInterface[] | never[]>) => {
      state.pnlTrades = action?.payload;
    },
    addPnlMaps: (state, action: PayloadAction<pnlTradesInterface | undefined>) => {
      const currentState = state.pnlTrades;
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }

      const newElement = action.payload;
      const index = currentState.findIndex((p: pnlTradesInterface) => p?.accountKey === newElement?.accountKey);

      if (index >= 0) {
        currentState[index] = newElement;
      } else {
        // append
        currentState.push(newElement);
      }
      state.pnlTrades = currentState;
    },
    addPnlTrade: (state, action: PayloadAction<pnlTradeInterface | undefined>) => {
      const currentState = JSON.parse(
        JSON.stringify(state.pnlTrades, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      const accountKeyIndex = currentState.findIndex(
        (p: pnlTradesInterface) => p?.accountKey === newElement?.accountKey,
      );
      let tempState = [];
      if (accountKeyIndex >= 0) tempState = currentState[accountKeyIndex];
      let map: any = {};
      if (newElement.trade.orderType == 1) {
        map = tempState.spot;
      } else if (newElement.trade.orderType == 2) {
        map = tempState.future;
      }

      // const index = map.findIndex((value: any) => value[0] === newElement?.trade?.symbol?.baseAsset);
      if (!newElement.trade.symbol?.baseAsset) return;
      const element = map[newElement.trade.symbol?.baseAsset];
      // if exists then replace previous value with new one
      map[newElement.trade.symbol?.baseAsset] = newElement.trade;
      if (newElement.trade.orderType == 1) {
        tempState.spot = map;
      } else if (newElement.trade.orderType == 2) {
        tempState.future = map;
      }
      currentState[accountKeyIndex] = tempState;
      state.pnlTrades = currentState;
    },
    updatePnlTradesPrices: (state, action: PayloadAction<pricesInterface | undefined>) => {
      const currentState = JSON.parse(
        JSON.stringify(state.pnlTrades, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const prices = action.payload;
      const spotMap = Object.entries(prices.response.spotPriceMap);
      const futureMap = Object.entries(prices.response.futurePriceMap);

      currentState.map((st: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.entries(st.spot).map((spot: [string, GetPnlCalculatorResponse]) => {
          const index = spotMap.findIndex((sp) => {
            return sp[0] === spot[1].symbol?.symbolName;
          });
          if (index > -1) {
            spot[1].Price = spotMap[index][1].price;
            spot[1].investment = spot[1].CumulativeQuantity * spotMap[index][1].price;
            spot[1].pnl = spot[1].CumulativeMoneyPaid + spot[1].CumulativeQuantity * spotMap[index][1].price;
          }
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.entries(st.future).map((future: [string, GetPnlCalculatorResponse]) => {
          const index = futureMap.findIndex((sp) => {
            return sp[0] === future[1].symbol?.symbolName;
          });
          if (index > -1) {
            future[1].Price = futureMap[index][1].price;
            future[1].investment = future[1].CumulativeQuantity * futureMap[index][1].price;
            future[1].pnl = future[1].CumulativeMoneyPaid + future[1].CumulativeQuantity * futureMap[index][1].price;
          }
        });
      });
      state.pnlTrades = currentState;
    },
    removePnlTrade: (state, action: PayloadAction<pnlTradeInterface | undefined>) => {
      let currentState = JSON.parse(JSON.stringify(state.pnlTrades));
      if (currentState === undefined) currentState = [];
      if (!action.payload) {
        console.log('no update skip', action.payload);
        return;
      }
      const newElement = action.payload;
      let map = [];
      if (newElement.trade.orderType == 1) {
        map = currentState.spot;
      } else if (newElement.trade.orderType == 2) {
        map = currentState.future;
      }
      state.pnlTrades = map.filter((o: any) => {
        return o.trade.OrderID !== newElement.trade.OrderID;
      });
    },
  },
});

export const selectPnlCalcTrades = (state: RootState) => state.pnlCalcTrades.pnlTrades;

export const { setPnlTrades, addPnlMaps, addPnlTrade, updatePnlTradesPrices, removePnlTrade } =
  pnlCalcTradesSlice.actions;

export default pnlCalcTradesSlice.reducer;
