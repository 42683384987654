import React, { useEffect, useRef, useState } from 'react';

import styles from './Modal.module.scss';

const Modal = (props: any) => {
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  function useOutsideMessage(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (props.setEdit) props.setEdit(false);
        }
      }

      // Bind the event listener
      setTimeout(() => document.addEventListener('mousedown', handleClickOutside), 100);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef1 = useRef(null);
  useOutsideMessage(wrapperRef1);
  return (
    <div className={styles.modalBackground + ' ' + (props.show ? styles.modalBackgroundShow : '')}>
      <div
        ref={wrapperRef1}
        className={styles.mainModal}
        style={{ minWidth: props.width, maxHeight: props.height || '70vh', top: top, left: left }}
      >
        {props.component ? React.createElement(props.component, props) : ''}
      </div>
    </div>
  );
};
export default Modal;
