import React, { ChangeEvent, useState } from 'react';

import styles from './Inputs.module.scss';

const InputWithSaveBtn = (props: {
  value: string | number;
  setValue: any;
  onSaveFunction: any;
  saveType: string;
  labelText: string;
  inputId: string;
  disabled?: boolean;
}) => {
  const [toBeSaved, setToBeSaved] = useState(false);

  const onParameterChange = (e: ChangeEvent<HTMLInputElement>, setToBeSaved: any, setValue: any) => {
    if (setToBeSaved !== undefined) setToBeSaved(true);
    const x = e.target.value;
    if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
    setValue(e.target.value.trim());
  };

  return (
    <div className={styles.inputRow}>
      <div className={'floatingInputContainer floatingInputContainerLighter'}>
        <input
          disabled={props.disabled}
          className="field-input"
          id={props.inputId}
          name={props.inputId}
          type="text"
          placeholder=" "
          value={props.value}
          onChange={(e) => onParameterChange(e, setToBeSaved, props.setValue)}
        />
        <label className="floatingInputPlaceholder" htmlFor={props.inputId}>
          {props.labelText}
        </label>
      </div>
      <div className={styles.btnWrapper}>
        <button
          className={styles.saveBtn + ' ' + (toBeSaved ? styles.saveBtnActive : '')}
          onClick={() => {
            if (toBeSaved) props.onSaveFunction(props.saveType);
            setToBeSaved(false);
          }}
        >
          <img src="/img/save_btn.svg" alt="save" />
        </button>
      </div>
    </div>
  );
};

export default InputWithSaveBtn;
