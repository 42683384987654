// source: brain/tr_brain_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.brain.Action', null, global);
goog.exportSymbol('proto.brain.AddSymbolsRequest', null, global);
goog.exportSymbol('proto.brain.Algorithm', null, global);
goog.exportSymbol('proto.brain.BrainBase', null, global);
goog.exportSymbol('proto.brain.BrainConfigsRequest', null, global);
goog.exportSymbol('proto.brain.BrainConfigsResponse', null, global);
goog.exportSymbol('proto.brain.BrainFutureAlgoParams', null, global);
goog.exportSymbol('proto.brain.BrainFutureAlgoSource', null, global);
goog.exportSymbol('proto.brain.BrainHealthStatusRequest', null, global);
goog.exportSymbol('proto.brain.BrainHealthStatusResponse', null, global);
goog.exportSymbol('proto.brain.BrainSignalRequest', null, global);
goog.exportSymbol('proto.brain.BrainSignalResponse', null, global);
goog.exportSymbol('proto.brain.FutureSignal', null, global);
goog.exportSymbol('proto.brain.GetRedFlagsRequest', null, global);
goog.exportSymbol('proto.brain.GetRedFlagsResponse', null, global);
goog.exportSymbol('proto.brain.GetRunningSymbolListRequest', null, global);
goog.exportSymbol('proto.brain.GetRunningSymbolListResponse', null, global);
goog.exportSymbol('proto.brain.HedgeType', null, global);
goog.exportSymbol('proto.brain.IgnoreSymbolsRequest', null, global);
goog.exportSymbol('proto.brain.NOOP', null, global);
goog.exportSymbol('proto.brain.RemoveFromIgnoreSymbolsRequest', null, global);
goog.exportSymbol('proto.brain.RemoveSymbolRequest', null, global);
goog.exportSymbol('proto.brain.SignalBase', null, global);
goog.exportSymbol('proto.brain.SignalBase.SignalelementCase', null, global);
goog.exportSymbol('proto.brain.SpotSignal', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainHealthStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainHealthStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainHealthStatusRequest.displayName = 'proto.brain.BrainHealthStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainHealthStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainHealthStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainHealthStatusResponse.displayName = 'proto.brain.BrainHealthStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainSignalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainSignalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainSignalRequest.displayName = 'proto.brain.BrainSignalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainSignalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.BrainSignalResponse.repeatedFields_, null);
};
goog.inherits(proto.brain.BrainSignalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainSignalResponse.displayName = 'proto.brain.BrainSignalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.SignalBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.brain.SignalBase.oneofGroups_);
};
goog.inherits(proto.brain.SignalBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.SignalBase.displayName = 'proto.brain.SignalBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.SpotSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.SpotSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.SpotSignal.displayName = 'proto.brain.SpotSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.FutureSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.FutureSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.FutureSignal.displayName = 'proto.brain.FutureSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainBase.displayName = 'proto.brain.BrainBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainConfigsRequest.displayName = 'proto.brain.BrainConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainConfigsResponse.displayName = 'proto.brain.BrainConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.BrainFutureAlgoParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.BrainFutureAlgoParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.BrainFutureAlgoParams.displayName = 'proto.brain.BrainFutureAlgoParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.GetRunningSymbolListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.GetRunningSymbolListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.GetRunningSymbolListRequest.displayName = 'proto.brain.GetRunningSymbolListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.GetRunningSymbolListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.GetRunningSymbolListResponse.repeatedFields_, null);
};
goog.inherits(proto.brain.GetRunningSymbolListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.GetRunningSymbolListResponse.displayName = 'proto.brain.GetRunningSymbolListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.AddSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.AddSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.brain.AddSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.AddSymbolsRequest.displayName = 'proto.brain.AddSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.RemoveSymbolRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.RemoveSymbolRequest.repeatedFields_, null);
};
goog.inherits(proto.brain.RemoveSymbolRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.RemoveSymbolRequest.displayName = 'proto.brain.RemoveSymbolRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.IgnoreSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.IgnoreSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.brain.IgnoreSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.IgnoreSymbolsRequest.displayName = 'proto.brain.IgnoreSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.RemoveFromIgnoreSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.RemoveFromIgnoreSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.brain.RemoveFromIgnoreSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.RemoveFromIgnoreSymbolsRequest.displayName = 'proto.brain.RemoveFromIgnoreSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.GetRedFlagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.GetRedFlagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.GetRedFlagsRequest.displayName = 'proto.brain.GetRedFlagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.GetRedFlagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brain.GetRedFlagsResponse.repeatedFields_, null);
};
goog.inherits(proto.brain.GetRedFlagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.GetRedFlagsResponse.displayName = 'proto.brain.GetRedFlagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brain.NOOP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brain.NOOP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brain.NOOP.displayName = 'proto.brain.NOOP';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainHealthStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainHealthStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainHealthStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainHealthStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.brain.BrainBase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainHealthStatusRequest}
 */
proto.brain.BrainHealthStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainHealthStatusRequest;
  return proto.brain.BrainHealthStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainHealthStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainHealthStatusRequest}
 */
proto.brain.BrainHealthStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainBase;
      reader.readMessage(value,proto.brain.BrainBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainHealthStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainHealthStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainHealthStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainHealthStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainBase.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrainBase base = 1;
 * @return {?proto.brain.BrainBase}
 */
proto.brain.BrainHealthStatusRequest.prototype.getBase = function() {
  return /** @type{?proto.brain.BrainBase} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainBase, 1));
};


/**
 * @param {?proto.brain.BrainBase|undefined} value
 * @return {!proto.brain.BrainHealthStatusRequest} returns this
*/
proto.brain.BrainHealthStatusRequest.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainHealthStatusRequest} returns this
 */
proto.brain.BrainHealthStatusRequest.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainHealthStatusRequest.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainHealthStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainHealthStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainHealthStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainHealthStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.brain.BrainBase.toObject(includeInstance, f),
    alive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainHealthStatusResponse}
 */
proto.brain.BrainHealthStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainHealthStatusResponse;
  return proto.brain.BrainHealthStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainHealthStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainHealthStatusResponse}
 */
proto.brain.BrainHealthStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainBase;
      reader.readMessage(value,proto.brain.BrainBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainHealthStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainHealthStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainHealthStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainHealthStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainBase.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional BrainBase base = 1;
 * @return {?proto.brain.BrainBase}
 */
proto.brain.BrainHealthStatusResponse.prototype.getBase = function() {
  return /** @type{?proto.brain.BrainBase} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainBase, 1));
};


/**
 * @param {?proto.brain.BrainBase|undefined} value
 * @return {!proto.brain.BrainHealthStatusResponse} returns this
*/
proto.brain.BrainHealthStatusResponse.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainHealthStatusResponse} returns this
 */
proto.brain.BrainHealthStatusResponse.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainHealthStatusResponse.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool alive = 2;
 * @return {boolean}
 */
proto.brain.BrainHealthStatusResponse.prototype.getAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.brain.BrainHealthStatusResponse} returns this
 */
proto.brain.BrainHealthStatusResponse.prototype.setAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainSignalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainSignalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainSignalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainSignalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.brain.BrainBase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainSignalRequest}
 */
proto.brain.BrainSignalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainSignalRequest;
  return proto.brain.BrainSignalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainSignalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainSignalRequest}
 */
proto.brain.BrainSignalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainBase;
      reader.readMessage(value,proto.brain.BrainBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainSignalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainSignalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainSignalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainSignalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainBase.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrainBase base = 1;
 * @return {?proto.brain.BrainBase}
 */
proto.brain.BrainSignalRequest.prototype.getBase = function() {
  return /** @type{?proto.brain.BrainBase} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainBase, 1));
};


/**
 * @param {?proto.brain.BrainBase|undefined} value
 * @return {!proto.brain.BrainSignalRequest} returns this
*/
proto.brain.BrainSignalRequest.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainSignalRequest} returns this
 */
proto.brain.BrainSignalRequest.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainSignalRequest.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.BrainSignalResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainSignalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainSignalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainSignalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainSignalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    health: (f = msg.getHealth()) && proto.brain.BrainHealthStatusResponse.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.brain.SignalBase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainSignalResponse}
 */
proto.brain.BrainSignalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainSignalResponse;
  return proto.brain.BrainSignalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainSignalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainSignalResponse}
 */
proto.brain.BrainSignalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainHealthStatusResponse;
      reader.readMessage(value,proto.brain.BrainHealthStatusResponse.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    case 2:
      var value = new proto.brain.SignalBase;
      reader.readMessage(value,proto.brain.SignalBase.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainSignalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainSignalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainSignalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainSignalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainHealthStatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.brain.SignalBase.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrainHealthStatusResponse health = 1;
 * @return {?proto.brain.BrainHealthStatusResponse}
 */
proto.brain.BrainSignalResponse.prototype.getHealth = function() {
  return /** @type{?proto.brain.BrainHealthStatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainHealthStatusResponse, 1));
};


/**
 * @param {?proto.brain.BrainHealthStatusResponse|undefined} value
 * @return {!proto.brain.BrainSignalResponse} returns this
*/
proto.brain.BrainSignalResponse.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainSignalResponse} returns this
 */
proto.brain.BrainSignalResponse.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainSignalResponse.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SignalBase data = 2;
 * @return {!Array<!proto.brain.SignalBase>}
 */
proto.brain.BrainSignalResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.brain.SignalBase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.brain.SignalBase, 2));
};


/**
 * @param {!Array<!proto.brain.SignalBase>} value
 * @return {!proto.brain.BrainSignalResponse} returns this
*/
proto.brain.BrainSignalResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.brain.SignalBase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.brain.SignalBase}
 */
proto.brain.BrainSignalResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.brain.SignalBase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.BrainSignalResponse} returns this
 */
proto.brain.BrainSignalResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.brain.SignalBase.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.brain.SignalBase.SignalelementCase = {
  SIGNALELEMENT_NOT_SET: 0,
  SPOTSIGNAL: 1,
  FUTURESIGNAL: 2
};

/**
 * @return {proto.brain.SignalBase.SignalelementCase}
 */
proto.brain.SignalBase.prototype.getSignalelementCase = function() {
  return /** @type {proto.brain.SignalBase.SignalelementCase} */(jspb.Message.computeOneofCase(this, proto.brain.SignalBase.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.SignalBase.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.SignalBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.SignalBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.SignalBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotsignal: (f = msg.getSpotsignal()) && proto.brain.SpotSignal.toObject(includeInstance, f),
    futuresignal: (f = msg.getFuturesignal()) && proto.brain.FutureSignal.toObject(includeInstance, f),
    levelssum: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    calculationtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    algorithm: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ignored: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.SignalBase}
 */
proto.brain.SignalBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.SignalBase;
  return proto.brain.SignalBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.SignalBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.SignalBase}
 */
proto.brain.SignalBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.SpotSignal;
      reader.readMessage(value,proto.brain.SpotSignal.deserializeBinaryFromReader);
      msg.setSpotsignal(value);
      break;
    case 2:
      var value = new proto.brain.FutureSignal;
      reader.readMessage(value,proto.brain.FutureSignal.deserializeBinaryFromReader);
      msg.setFuturesignal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelssum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCalculationtime(value);
      break;
    case 5:
      var value = /** @type {!proto.brain.Algorithm} */ (reader.readEnum());
      msg.setAlgorithm(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnored(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.SignalBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.SignalBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.SignalBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.SignalBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotsignal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.SpotSignal.serializeBinaryToWriter
    );
  }
  f = message.getFuturesignal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.brain.FutureSignal.serializeBinaryToWriter
    );
  }
  f = message.getLevelssum();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCalculationtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAlgorithm();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIgnored();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional SpotSignal spotSignal = 1;
 * @return {?proto.brain.SpotSignal}
 */
proto.brain.SignalBase.prototype.getSpotsignal = function() {
  return /** @type{?proto.brain.SpotSignal} */ (
    jspb.Message.getWrapperField(this, proto.brain.SpotSignal, 1));
};


/**
 * @param {?proto.brain.SpotSignal|undefined} value
 * @return {!proto.brain.SignalBase} returns this
*/
proto.brain.SignalBase.prototype.setSpotsignal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.brain.SignalBase.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.clearSpotsignal = function() {
  return this.setSpotsignal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.SignalBase.prototype.hasSpotsignal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FutureSignal futureSignal = 2;
 * @return {?proto.brain.FutureSignal}
 */
proto.brain.SignalBase.prototype.getFuturesignal = function() {
  return /** @type{?proto.brain.FutureSignal} */ (
    jspb.Message.getWrapperField(this, proto.brain.FutureSignal, 2));
};


/**
 * @param {?proto.brain.FutureSignal|undefined} value
 * @return {!proto.brain.SignalBase} returns this
*/
proto.brain.SignalBase.prototype.setFuturesignal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.brain.SignalBase.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.clearFuturesignal = function() {
  return this.setFuturesignal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.SignalBase.prototype.hasFuturesignal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double levelsSum = 3;
 * @return {number}
 */
proto.brain.SignalBase.prototype.getLevelssum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.setLevelssum = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 calculationTime = 4;
 * @return {number}
 */
proto.brain.SignalBase.prototype.getCalculationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.setCalculationtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Algorithm algorithm = 5;
 * @return {!proto.brain.Algorithm}
 */
proto.brain.SignalBase.prototype.getAlgorithm = function() {
  return /** @type {!proto.brain.Algorithm} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.brain.Algorithm} value
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.setAlgorithm = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool ignored = 6;
 * @return {boolean}
 */
proto.brain.SignalBase.prototype.getIgnored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.brain.SignalBase} returns this
 */
proto.brain.SignalBase.prototype.setIgnored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.SpotSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.SpotSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.SpotSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.SpotSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pricesymbol: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    signal: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.SpotSignal}
 */
proto.brain.SpotSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.SpotSignal;
  return proto.brain.SpotSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.SpotSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.SpotSignal}
 */
proto.brain.SpotSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricesymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSignal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.SpotSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.SpotSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.SpotSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.SpotSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPricesymbol();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSignal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.brain.SpotSignal.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.SpotSignal} returns this
 */
proto.brain.SpotSignal.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double priceSymbol = 2;
 * @return {number}
 */
proto.brain.SpotSignal.prototype.getPricesymbol = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.SpotSignal} returns this
 */
proto.brain.SpotSignal.prototype.setPricesymbol = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double signal = 3;
 * @return {number}
 */
proto.brain.SpotSignal.prototype.getSignal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.SpotSignal} returns this
 */
proto.brain.SpotSignal.prototype.setSignal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.FutureSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.FutureSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.FutureSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.FutureSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    basesymbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spotpricesymbol: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    futurepricesymbol: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    spotpricepersymbolMap: (f = msg.getSpotpricepersymbolMap()) ? f.toObject(includeInstance, undefined) : [],
    signal: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    action: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hedgetype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pb_delete: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.FutureSignal}
 */
proto.brain.FutureSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.FutureSignal;
  return proto.brain.FutureSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.FutureSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.FutureSignal}
 */
proto.brain.FutureSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasesymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpotpricesymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFuturepricesymbol(value);
      break;
    case 4:
      var value = msg.getSpotpricepersymbolMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSignal(value);
      break;
    case 6:
      var value = /** @type {!proto.brain.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 7:
      var value = /** @type {!proto.brain.HedgeType} */ (reader.readEnum());
      msg.setHedgetype(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.FutureSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.FutureSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.FutureSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.FutureSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasesymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpotpricesymbol();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFuturepricesymbol();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSpotpricepersymbolMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getSignal();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getHedgetype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDelete();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string baseSymbol = 1;
 * @return {string}
 */
proto.brain.FutureSignal.prototype.getBasesymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setBasesymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double spotPriceSymbol = 2;
 * @return {number}
 */
proto.brain.FutureSignal.prototype.getSpotpricesymbol = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setSpotpricesymbol = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double futurePriceSymbol = 3;
 * @return {number}
 */
proto.brain.FutureSignal.prototype.getFuturepricesymbol = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setFuturepricesymbol = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * map<string, double> spotPricePerSymbol = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.brain.FutureSignal.prototype.getSpotpricepersymbolMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.clearSpotpricepersymbolMap = function() {
  this.getSpotpricepersymbolMap().clear();
  return this;};


/**
 * optional double signal = 5;
 * @return {number}
 */
proto.brain.FutureSignal.prototype.getSignal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setSignal = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Action action = 6;
 * @return {!proto.brain.Action}
 */
proto.brain.FutureSignal.prototype.getAction = function() {
  return /** @type {!proto.brain.Action} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.brain.Action} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional HedgeType hedgeType = 7;
 * @return {!proto.brain.HedgeType}
 */
proto.brain.FutureSignal.prototype.getHedgetype = function() {
  return /** @type {!proto.brain.HedgeType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.brain.HedgeType} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setHedgetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool delete = 8;
 * @return {boolean}
 */
proto.brain.FutureSignal.prototype.getDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.brain.FutureSignal} returns this
 */
proto.brain.FutureSignal.prototype.setDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainBase.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    brainuuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    braindescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    algorithm: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainBase}
 */
proto.brain.BrainBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainBase;
  return proto.brain.BrainBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainBase}
 */
proto.brain.BrainBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrainuuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBraindescription(value);
      break;
    case 3:
      var value = /** @type {!proto.brain.Algorithm} */ (reader.readEnum());
      msg.setAlgorithm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrainuuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBraindescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlgorithm();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string brainUUID = 1;
 * @return {string}
 */
proto.brain.BrainBase.prototype.getBrainuuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.BrainBase} returns this
 */
proto.brain.BrainBase.prototype.setBrainuuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string brainDescription = 2;
 * @return {string}
 */
proto.brain.BrainBase.prototype.getBraindescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.BrainBase} returns this
 */
proto.brain.BrainBase.prototype.setBraindescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Algorithm algorithm = 3;
 * @return {!proto.brain.Algorithm}
 */
proto.brain.BrainBase.prototype.getAlgorithm = function() {
  return /** @type {!proto.brain.Algorithm} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.brain.Algorithm} value
 * @return {!proto.brain.BrainBase} returns this
 */
proto.brain.BrainBase.prototype.setAlgorithm = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    futurealgoparams: (f = msg.getFuturealgoparams()) && proto.brain.BrainFutureAlgoParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainConfigsRequest}
 */
proto.brain.BrainConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainConfigsRequest;
  return proto.brain.BrainConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainConfigsRequest}
 */
proto.brain.BrainConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainFutureAlgoParams;
      reader.readMessage(value,proto.brain.BrainFutureAlgoParams.deserializeBinaryFromReader);
      msg.setFuturealgoparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFuturealgoparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainFutureAlgoParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrainFutureAlgoParams futureAlgoParams = 1;
 * @return {?proto.brain.BrainFutureAlgoParams}
 */
proto.brain.BrainConfigsRequest.prototype.getFuturealgoparams = function() {
  return /** @type{?proto.brain.BrainFutureAlgoParams} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainFutureAlgoParams, 1));
};


/**
 * @param {?proto.brain.BrainFutureAlgoParams|undefined} value
 * @return {!proto.brain.BrainConfigsRequest} returns this
*/
proto.brain.BrainConfigsRequest.prototype.setFuturealgoparams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainConfigsRequest} returns this
 */
proto.brain.BrainConfigsRequest.prototype.clearFuturealgoparams = function() {
  return this.setFuturealgoparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainConfigsRequest.prototype.hasFuturealgoparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    futurealgoparams: (f = msg.getFuturealgoparams()) && proto.brain.BrainFutureAlgoParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainConfigsResponse}
 */
proto.brain.BrainConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainConfigsResponse;
  return proto.brain.BrainConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainConfigsResponse}
 */
proto.brain.BrainConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brain.BrainFutureAlgoParams;
      reader.readMessage(value,proto.brain.BrainFutureAlgoParams.deserializeBinaryFromReader);
      msg.setFuturealgoparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFuturealgoparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.brain.BrainFutureAlgoParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrainFutureAlgoParams futureAlgoParams = 1;
 * @return {?proto.brain.BrainFutureAlgoParams}
 */
proto.brain.BrainConfigsResponse.prototype.getFuturealgoparams = function() {
  return /** @type{?proto.brain.BrainFutureAlgoParams} */ (
    jspb.Message.getWrapperField(this, proto.brain.BrainFutureAlgoParams, 1));
};


/**
 * @param {?proto.brain.BrainFutureAlgoParams|undefined} value
 * @return {!proto.brain.BrainConfigsResponse} returns this
*/
proto.brain.BrainConfigsResponse.prototype.setFuturealgoparams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.BrainConfigsResponse} returns this
 */
proto.brain.BrainConfigsResponse.prototype.clearFuturealgoparams = function() {
  return this.setFuturealgoparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.BrainConfigsResponse.prototype.hasFuturealgoparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.BrainFutureAlgoParams.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.BrainFutureAlgoParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.BrainFutureAlgoParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainFutureAlgoParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lookback: jspb.Message.getFieldWithDefault(msg, 2, 0),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allowfutureexecution: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hedgetype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.BrainFutureAlgoParams}
 */
proto.brain.BrainFutureAlgoParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.BrainFutureAlgoParams;
  return proto.brain.BrainFutureAlgoParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.BrainFutureAlgoParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.BrainFutureAlgoParams}
 */
proto.brain.BrainFutureAlgoParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLookback(value);
      break;
    case 3:
      var value = /** @type {!proto.brain.BrainFutureAlgoSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowfutureexecution(value);
      break;
    case 5:
      var value = /** @type {!proto.brain.HedgeType} */ (reader.readEnum());
      msg.setHedgetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.BrainFutureAlgoParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.BrainFutureAlgoParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.BrainFutureAlgoParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.BrainFutureAlgoParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLookback();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAllowfutureexecution();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHedgetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 length = 1;
 * @return {number}
 */
proto.brain.BrainFutureAlgoParams.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.brain.BrainFutureAlgoParams} returns this
 */
proto.brain.BrainFutureAlgoParams.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 lookBack = 2;
 * @return {number}
 */
proto.brain.BrainFutureAlgoParams.prototype.getLookback = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.brain.BrainFutureAlgoParams} returns this
 */
proto.brain.BrainFutureAlgoParams.prototype.setLookback = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BrainFutureAlgoSource source = 3;
 * @return {!proto.brain.BrainFutureAlgoSource}
 */
proto.brain.BrainFutureAlgoParams.prototype.getSource = function() {
  return /** @type {!proto.brain.BrainFutureAlgoSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.brain.BrainFutureAlgoSource} value
 * @return {!proto.brain.BrainFutureAlgoParams} returns this
 */
proto.brain.BrainFutureAlgoParams.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool allowFutureExecution = 4;
 * @return {boolean}
 */
proto.brain.BrainFutureAlgoParams.prototype.getAllowfutureexecution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.brain.BrainFutureAlgoParams} returns this
 */
proto.brain.BrainFutureAlgoParams.prototype.setAllowfutureexecution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional HedgeType hedgeType = 5;
 * @return {!proto.brain.HedgeType}
 */
proto.brain.BrainFutureAlgoParams.prototype.getHedgetype = function() {
  return /** @type {!proto.brain.HedgeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.brain.HedgeType} value
 * @return {!proto.brain.BrainFutureAlgoParams} returns this
 */
proto.brain.BrainFutureAlgoParams.prototype.setHedgetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.GetRunningSymbolListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.GetRunningSymbolListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.GetRunningSymbolListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRunningSymbolListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.GetRunningSymbolListRequest}
 */
proto.brain.GetRunningSymbolListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.GetRunningSymbolListRequest;
  return proto.brain.GetRunningSymbolListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.GetRunningSymbolListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.GetRunningSymbolListRequest}
 */
proto.brain.GetRunningSymbolListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.GetRunningSymbolListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.GetRunningSymbolListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.GetRunningSymbolListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRunningSymbolListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.GetRunningSymbolListResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.GetRunningSymbolListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.GetRunningSymbolListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.GetRunningSymbolListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRunningSymbolListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsUsdtList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    symbolsBtcList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    symbolsBusdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    futureSymbolsUsdtList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.GetRunningSymbolListResponse}
 */
proto.brain.GetRunningSymbolListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.GetRunningSymbolListResponse;
  return proto.brain.GetRunningSymbolListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.GetRunningSymbolListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.GetRunningSymbolListResponse}
 */
proto.brain.GetRunningSymbolListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsUsdt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsBtc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsBusd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFutureSymbolsUsdt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.GetRunningSymbolListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.GetRunningSymbolListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.GetRunningSymbolListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRunningSymbolListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsUsdtList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSymbolsBtcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSymbolsBusdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFutureSymbolsUsdtList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string symbols_usdt = 1;
 * @return {!Array<string>}
 */
proto.brain.GetRunningSymbolListResponse.prototype.getSymbolsUsdtList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.setSymbolsUsdtList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.addSymbolsUsdt = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.clearSymbolsUsdtList = function() {
  return this.setSymbolsUsdtList([]);
};


/**
 * repeated string symbols_btc = 2;
 * @return {!Array<string>}
 */
proto.brain.GetRunningSymbolListResponse.prototype.getSymbolsBtcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.setSymbolsBtcList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.addSymbolsBtc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.clearSymbolsBtcList = function() {
  return this.setSymbolsBtcList([]);
};


/**
 * repeated string symbols_busd = 3;
 * @return {!Array<string>}
 */
proto.brain.GetRunningSymbolListResponse.prototype.getSymbolsBusdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.setSymbolsBusdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.addSymbolsBusd = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.clearSymbolsBusdList = function() {
  return this.setSymbolsBusdList([]);
};


/**
 * repeated string future_symbols_usdt = 4;
 * @return {!Array<string>}
 */
proto.brain.GetRunningSymbolListResponse.prototype.getFutureSymbolsUsdtList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.setFutureSymbolsUsdtList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.addFutureSymbolsUsdt = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRunningSymbolListResponse} returns this
 */
proto.brain.GetRunningSymbolListResponse.prototype.clearFutureSymbolsUsdtList = function() {
  return this.setFutureSymbolsUsdtList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.AddSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.AddSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.AddSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.AddSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.AddSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.AddSymbolsRequest}
 */
proto.brain.AddSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.AddSymbolsRequest;
  return proto.brain.AddSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.AddSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.AddSymbolsRequest}
 */
proto.brain.AddSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.AddSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.AddSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.AddSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.AddSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.brain.AddSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.AddSymbolsRequest} returns this
 */
proto.brain.AddSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.AddSymbolsRequest} returns this
 */
proto.brain.AddSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.AddSymbolsRequest} returns this
 */
proto.brain.AddSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.RemoveSymbolRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.RemoveSymbolRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.RemoveSymbolRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.RemoveSymbolRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.RemoveSymbolRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.RemoveSymbolRequest}
 */
proto.brain.RemoveSymbolRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.RemoveSymbolRequest;
  return proto.brain.RemoveSymbolRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.RemoveSymbolRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.RemoveSymbolRequest}
 */
proto.brain.RemoveSymbolRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.RemoveSymbolRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.RemoveSymbolRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.RemoveSymbolRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.RemoveSymbolRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.brain.RemoveSymbolRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.RemoveSymbolRequest} returns this
 */
proto.brain.RemoveSymbolRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.RemoveSymbolRequest} returns this
 */
proto.brain.RemoveSymbolRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.RemoveSymbolRequest} returns this
 */
proto.brain.RemoveSymbolRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.IgnoreSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.IgnoreSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.IgnoreSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.IgnoreSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.IgnoreSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.IgnoreSymbolsRequest}
 */
proto.brain.IgnoreSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.IgnoreSymbolsRequest;
  return proto.brain.IgnoreSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.IgnoreSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.IgnoreSymbolsRequest}
 */
proto.brain.IgnoreSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.IgnoreSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.IgnoreSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.IgnoreSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.IgnoreSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.brain.IgnoreSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.IgnoreSymbolsRequest} returns this
 */
proto.brain.IgnoreSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.IgnoreSymbolsRequest} returns this
 */
proto.brain.IgnoreSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.IgnoreSymbolsRequest} returns this
 */
proto.brain.IgnoreSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.RemoveFromIgnoreSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.RemoveFromIgnoreSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.RemoveFromIgnoreSymbolsRequest}
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.RemoveFromIgnoreSymbolsRequest;
  return proto.brain.RemoveFromIgnoreSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.RemoveFromIgnoreSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.RemoveFromIgnoreSymbolsRequest}
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.RemoveFromIgnoreSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.RemoveFromIgnoreSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.brain.RemoveFromIgnoreSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.GetRedFlagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.GetRedFlagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.GetRedFlagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRedFlagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteAsset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.GetRedFlagsRequest}
 */
proto.brain.GetRedFlagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.GetRedFlagsRequest;
  return proto.brain.GetRedFlagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.GetRedFlagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.GetRedFlagsRequest}
 */
proto.brain.GetRedFlagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.GetRedFlagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.GetRedFlagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.GetRedFlagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRedFlagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteAsset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string quote_asset = 1;
 * @return {string}
 */
proto.brain.GetRedFlagsRequest.prototype.getQuoteAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.GetRedFlagsRequest} returns this
 */
proto.brain.GetRedFlagsRequest.prototype.setQuoteAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brain.GetRedFlagsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.GetRedFlagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.GetRedFlagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.GetRedFlagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRedFlagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotSymbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    futureSymbolsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    noop: (f = msg.getNoop()) && proto.brain.NOOP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.GetRedFlagsResponse}
 */
proto.brain.GetRedFlagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.GetRedFlagsResponse;
  return proto.brain.GetRedFlagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.GetRedFlagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.GetRedFlagsResponse}
 */
proto.brain.GetRedFlagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpotSymbols(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFutureSymbols(value);
      break;
    case 3:
      var value = new proto.brain.NOOP;
      reader.readMessage(value,proto.brain.NOOP.deserializeBinaryFromReader);
      msg.setNoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.GetRedFlagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.GetRedFlagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.GetRedFlagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.GetRedFlagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFutureSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getNoop();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.brain.NOOP.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string spot_symbols = 1;
 * @return {!Array<string>}
 */
proto.brain.GetRedFlagsResponse.prototype.getSpotSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.setSpotSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.addSpotSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.clearSpotSymbolsList = function() {
  return this.setSpotSymbolsList([]);
};


/**
 * repeated string future_symbols = 2;
 * @return {!Array<string>}
 */
proto.brain.GetRedFlagsResponse.prototype.getFutureSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.setFutureSymbolsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.addFutureSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.clearFutureSymbolsList = function() {
  return this.setFutureSymbolsList([]);
};


/**
 * optional NOOP noop = 3;
 * @return {?proto.brain.NOOP}
 */
proto.brain.GetRedFlagsResponse.prototype.getNoop = function() {
  return /** @type{?proto.brain.NOOP} */ (
    jspb.Message.getWrapperField(this, proto.brain.NOOP, 3));
};


/**
 * @param {?proto.brain.NOOP|undefined} value
 * @return {!proto.brain.GetRedFlagsResponse} returns this
*/
proto.brain.GetRedFlagsResponse.prototype.setNoop = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brain.GetRedFlagsResponse} returns this
 */
proto.brain.GetRedFlagsResponse.prototype.clearNoop = function() {
  return this.setNoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brain.GetRedFlagsResponse.prototype.hasNoop = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brain.NOOP.prototype.toObject = function(opt_includeInstance) {
  return proto.brain.NOOP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brain.NOOP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.NOOP.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brain.NOOP}
 */
proto.brain.NOOP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brain.NOOP;
  return proto.brain.NOOP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brain.NOOP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brain.NOOP}
 */
proto.brain.NOOP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brain.NOOP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brain.NOOP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brain.NOOP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brain.NOOP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.brain.NOOP.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brain.NOOP} returns this
 */
proto.brain.NOOP.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.brain.Action = {
  UNKNOWN_ACTION: 0,
  SHORT_ACTION: 1,
  LONG_ACTION: 2,
  NOTHING_ACTION: 3
};

/**
 * @enum {number}
 */
proto.brain.HedgeType = {
  HEDGETYPEUNKNOWN: 0,
  HEDGETYPESMA: 1,
  HEDGETYPEALWAYSON: 2
};

/**
 * @enum {number}
 */
proto.brain.Algorithm = {
  UNKNOWN_ALGO: 0,
  ALGO_C: 1,
  ALGO_A: 2,
  ALGO_A_FUTURE: 3
};

/**
 * @enum {number}
 */
proto.brain.BrainFutureAlgoSource = {
  UNKNOWN_SOURCE: 0,
  OPEN: 1,
  CLOSE: 2,
  HIGH: 3,
  LOW: 4
};

goog.object.extend(exports, proto.brain);
