// @generated by protoc-gen-es v0.2.0 with parameter "target=js+ts+dts"
// @generated from file executor/tr_executor_types.proto (package executor, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {proto3} from "@bufbuild/protobuf";
import {Market} from "../harvester/tr_harvester_types_pb.js";

/**
 * @generated from enum executor.Status
 */
export const Status = proto3.makeEnum(
  "executor.Status",
  [
    {no: 0, name: "UNKNOWN_ACTION"},
    {no: 1, name: "STATUS_NEW"},
    {no: 2, name: "PARTIALLY_FILLED"},
    {no: 3, name: "FILLED"},
    {no: 4, name: "CANCELED"},
    {no: 5, name: "PENDING_CANCEL"},
    {no: 6, name: "REJECTED"},
    {no: 7, name: "EXPIRED"},
    {no: 8, name: "STARTED"},
    {no: 9, name: "NEW_INSURANCE"},
    {no: 10, name: "NEW_ADL"},
  ],
);

/**
 * @generated from enum executor.OrderType
 */
export const OrderType = proto3.makeEnum(
  "executor.OrderType",
  [
    {no: 0, name: "UNKNOWN_ORDER_TYPE"},
    {no: 1, name: "SPOT"},
    {no: 2, name: "FUTURE"},
  ],
);

/**
 * @generated from message executor.ExecutorHealthStatusRequest
 */
export const ExecutorHealthStatusRequest = proto3.makeMessageType(
  "executor.ExecutorHealthStatusRequest",
  () => [
    { no: 1, name: "base", kind: "message", T: ExecutorBase },
  ],
);

/**
 * @generated from message executor.ExecutorHealthStatusResponse
 */
export const ExecutorHealthStatusResponse = proto3.makeMessageType(
  "executor.ExecutorHealthStatusResponse",
  () => [
    { no: 1, name: "base", kind: "message", T: ExecutorBase },
    { no: 2, name: "alive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message executor.ExecutorBase
 */
export const ExecutorBase = proto3.makeMessageType(
  "executor.ExecutorBase",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
  ],
);

/**
 * @generated from message executor.ExecutorConfigsRequest
 */
export const ExecutorConfigsRequest = proto3.makeMessageType(
  "executor.ExecutorConfigsRequest",
  () => [
    { no: 1, name: "disableExpansion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "allowFutureExecution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "btcValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "usdtValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "busdValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "hold", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "allow_zig_zag", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "levels_per_coin_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "levels_per_coin_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "levels_per_coin_safety_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "percent_invested_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "percent_invested_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "allow_negative_signal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "allow_above_notional", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "notional_multiplier", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "boost_vpl", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "maximize_investment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "set_value_per_signal", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "target_spot_invested_pct", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.ExecutorConfigsResponse
 */
export const ExecutorConfigsResponse = proto3.makeMessageType(
  "executor.ExecutorConfigsResponse",
  () => [
    { no: 1, name: "disableExpansion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "allowFutureExecution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "btcValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "usdtValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "busdValuePerLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "hold", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "allow_zig_zag", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "levels_per_coin_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "levels_per_coin_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "levels_per_coin_safety_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "percent_invested_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "percent_invested_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "allow_negative_signal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "allow_above_notional", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "notional_multiplier", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "boost_vpl", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "maximize_investment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "set_value_per_signal", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "target_spot_invested_pct", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.ExecutorPNLRequest
 */
export const ExecutorPNLRequest = proto3.makeMessageType(
  "executor.ExecutorPNLRequest",
  () => [
    { no: 1, name: "daysBefore", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "againstNow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message executor.ExecutorPNLResponse
 */
export const ExecutorPNLResponse = proto3.makeMessageType(
  "executor.ExecutorPNLResponse",
  () => [
    { no: 1, name: "previousTotalUsdtValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "currentTotalUsdtValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "pnlTotalUsdtPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "pnlTotalUsdt", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "previousBtcValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "currentBtcValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "pnlBtcPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "pnlBtc", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "previousTotalBusdValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "currentTotalBusdValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "pnlTotalBusdPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "pnlTotalBusd", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "currentSpotUSDT", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "pnlSpotUsdtPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "currentFutureUSDT", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "pnlFutureUsdtPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "currentSpotBTC", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "pnlSpotBtcPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "currentFutureBTC", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 20, name: "currentSpotBUSD", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "pnlSpotBusdPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 22, name: "currentFutureBUSD", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 23, name: "pnlFutureBusdPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "currentSpotInvestedPercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 25, name: "moreThan5PercentOfTheInvestedAmount", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
    { no: 26, name: "fromTime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "toTime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "futureUnrealizedPnl", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 29, name: "spotInvestedAgainstTotalBalancePercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 30, name: "futureInvestedAgainstTotalBalancePercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 31, name: "exposurePercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 32, name: "future_invested_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.GetRunningSymbolListRequest
 */
export const GetRunningSymbolListRequest = proto3.makeMessageType(
  "executor.GetRunningSymbolListRequest",
  [],
);

/**
 * @generated from message executor.GetRunningSymbolListResponse
 */
export const GetRunningSymbolListResponse = proto3.makeMessageType(
  "executor.GetRunningSymbolListResponse",
  () => [
    { no: 1, name: "symbols_usdt", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "symbols_btc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "symbols_busd", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "future_symbols_usdt", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.AddSymbolsRequest
 */
export const AddSymbolsRequest = proto3.makeMessageType(
  "executor.AddSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.RemoveSymbolRequest
 */
export const RemoveSymbolRequest = proto3.makeMessageType(
  "executor.RemoveSymbolRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.IgnoreSymbolsRequest
 */
export const IgnoreSymbolsRequest = proto3.makeMessageType(
  "executor.IgnoreSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.RemoveFromIgnoreSymbolsRequest
 */
export const RemoveFromIgnoreSymbolsRequest = proto3.makeMessageType(
  "executor.RemoveFromIgnoreSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.Symbol
 */
export const Symbol = proto3.makeMessageType(
  "executor.Symbol",
  () => [
    { no: 1, name: "symbolName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "baseAsset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quoteAsset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.GetOpenOrdersRequest
 */
export const GetOpenOrdersRequest = proto3.makeMessageType(
  "executor.GetOpenOrdersRequest",
  () => [
    { no: 1, name: "quoteAsset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.GetPnlCalculatorRequest
 */
export const GetPnlCalculatorRequest = proto3.makeMessageType(
  "executor.GetPnlCalculatorRequest",
  () => [
    { no: 1, name: "quoteAsset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.NOOP
 */
export const NOOP = proto3.makeMessageType(
  "executor.NOOP",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.OpenOrder
 */
export const OpenOrder = proto3.makeMessageType(
  "executor.OpenOrder",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "marketName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "symbol", kind: "message", T: Symbol },
    { no: 4, name: "OrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ClientOrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "OriginalQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "ExecutedQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "IcebergQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "CumulativeQuoteQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "OriginalQuoteOrderQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "Status", kind: "enum", T: proto3.getEnumType(Status) },
    { no: 13, name: "TimeInForce", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "Type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "Side", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "StopPrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "IsWorking", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "CurrentTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "ExecutedTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "UpdateTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 21, name: "orderType", kind: "enum", T: proto3.getEnumType(OrderType) },
  ],
);

/**
 * @generated from message executor.GetOpenOrdersResponse
 */
export const GetOpenOrdersResponse = proto3.makeMessageType(
  "executor.GetOpenOrdersResponse",
  () => [
    { no: 1, name: "openOrders", kind: "message", T: OpenOrder, repeated: true },
    { no: 2, name: "noop", kind: "message", T: NOOP },
    { no: 3, name: "notional_buy", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "notional_sell", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "notional_buy_minute", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "notional_sell_minute", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.GetPnlCalculatorResponse
 */
export const GetPnlCalculatorResponse = proto3.makeMessageType(
  "executor.GetPnlCalculatorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "marketName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "symbol", kind: "message", T: Symbol },
    { no: 4, name: "OrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ClientOrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "ExecutedQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "CumulativeQuantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "CumulativeMoneyPaid", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "Status", kind: "enum", T: proto3.getEnumType(Status) },
    { no: 11, name: "CurrentTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "ExecutedTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "UpdateTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "orderType", kind: "enum", T: proto3.getEnumType(OrderType) },
    { no: 15, name: "investment", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "pnl", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "noop", kind: "message", T: NOOP },
    { no: 18, name: "maxInvestment", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "investment30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 20, name: "pnl30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "maxInvestment30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 22, name: "price30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 23, name: "desired_investment", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "cumulative_money_paid30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 25, name: "turnover_daily", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 26, name: "turnover_daily30", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 27, name: "red_flagged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "weight", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.GetPnlCalculatorMapsResponse
 */
export const GetPnlCalculatorMapsResponse = proto3.makeMessageType(
  "executor.GetPnlCalculatorMapsResponse",
  () => [
    { no: 1, name: "spot", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GetPnlCalculatorResponse} },
    { no: 2, name: "future", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GetPnlCalculatorResponse} },
  ],
);

/**
 * @generated from message executor.ExecuteOrderRequest
 */
export const ExecuteOrderRequest = proto3.makeMessageType(
  "executor.ExecuteOrderRequest",
  () => [
    { no: 1, name: "order_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "symbol", kind: "message", T: Symbol },
    { no: 3, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(OrderType) },
    { no: 5, name: "quantity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.ExecuteOrderResponse
 */
export const ExecuteOrderResponse = proto3.makeMessageType(
  "executor.ExecuteOrderResponse",
  () => [
    { no: 1, name: "order", kind: "message", T: OpenOrder },
  ],
);

/**
 * @generated from message executor.CancelOrderRequest
 */
export const CancelOrderRequest = proto3.makeMessageType(
  "executor.CancelOrderRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "symbol", kind: "message", T: Symbol },
  ],
);

/**
 * @generated from message executor.CancelOrderResponse
 */
export const CancelOrderResponse = proto3.makeMessageType(
  "executor.CancelOrderResponse",
  [],
);

/**
 * @generated from message executor.DownloadRequest
 */
export const DownloadRequest = proto3.makeMessageType(
  "executor.DownloadRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "days_before", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.DownloadResponse
 */
export const DownloadResponse = proto3.makeMessageType(
  "executor.DownloadResponse",
  () => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message executor.GetDisabledSymbolsRequest
 */
export const GetDisabledSymbolsRequest = proto3.makeMessageType(
  "executor.GetDisabledSymbolsRequest",
  [],
);

/**
 * @generated from message executor.GetDisabledSymbolsResponse
 */
export const GetDisabledSymbolsResponse = proto3.makeMessageType(
  "executor.GetDisabledSymbolsResponse",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message executor.GetActualAPIPositionsRequest
 */
export const GetActualAPIPositionsRequest = proto3.makeMessageType(
  "executor.GetActualAPIPositionsRequest",
  [],
);

/**
 * @generated from message executor.GetActualAPIPositionsResponse
 */
export const GetActualAPIPositionsResponse = proto3.makeMessageType(
  "executor.GetActualAPIPositionsResponse",
  () => [
    { no: 1, name: "spot", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Position} },
    { no: 2, name: "futures", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Position} },
  ],
);

/**
 * @generated from message executor.Position
 */
export const Position = proto3.makeMessageType(
  "executor.Position",
  () => [
    { no: 1, name: "free", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "locked", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message executor.GetSumsRequest
 */
export const GetSumsRequest = proto3.makeMessageType(
  "executor.GetSumsRequest",
  [],
);

/**
 * @generated from message executor.GetSumsResponse
 */
export const GetSumsResponse = proto3.makeMessageType(
  "executor.GetSumsResponse",
  () => [
    { no: 1, name: "invested_spot_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "invested_futures_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "pnl_spot_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "pnl_futures_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "cumulative_money_paid_spot_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "cumulative_money_paid_futures_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "turnover_daily_spot_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "turnover_daily_futures_sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "noop", kind: "message", T: NOOP },
  ],
);

/**
 * @generated from message executor.SetTelegramOTPCodeRequest
 */
export const SetTelegramOTPCodeRequest = proto3.makeMessageType(
  "executor.SetTelegramOTPCodeRequest",
  () => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message executor.SetTelegramOTPCodeResponse
 */
export const SetTelegramOTPCodeResponse = proto3.makeMessageType(
  "executor.SetTelegramOTPCodeResponse",
  () => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message executor.ResetConfigSignalRequest
 */
export const ResetConfigSignalRequest = proto3.makeMessageType(
  "executor.ResetConfigSignalRequest",
  [],
);

/**
 * @generated from message executor.ResetConfigSignalResponse
 */
export const ResetConfigSignalResponse = proto3.makeMessageType(
  "executor.ResetConfigSignalResponse",
  () => [
    { no: 1, name: "ok", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

