import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { PriceTickerResponse, PriceTickerStreamResponse } from '../../pkg/protobuf/harvester/tr_harvester_types_pb';
import { RootState } from '../store';

export interface redFlagsInterface {
  spotRedFlags: string[];
  futureRedFlags: string[];
}

export const initialState: any = {
  response: new PriceTickerResponse().toObject(),
};

export const redFlagsSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setRedFlags: (state, action: PayloadAction<redFlagsInterface>) => {
      state.redFlagsInterface = action.payload;
    },
  },
});

export const selectRedFlags = (state: RootState) => state.prices.redFlagsInterface;

export const { setRedFlags } = redFlagsSlice.actions;

export default redFlagsSlice.reducer;
