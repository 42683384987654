import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { selectAccessToken } from '../../app/redux/authorization.slice.reducer';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import styles from './Symbol.module.scss';

const DisableExpansionSymbolsModal = (props: any) => {
  const userToken = useSelector(selectAccessToken);

  const onRemoveSymbol = (symbol: string) => {
    props.setRemovedSymbolsList([...props.removedSymbolsList, symbol]);
    const removedIndex = props.runningRenderedSymbolsList.indexOf(symbol);
    const removedList = props.runningRenderedSymbolsList.slice();
    removedList.splice(removedIndex, 1);
    const removedIndex2 = props.addedSymbolsList.indexOf(symbol);
    const removedList2 = props.addedSymbolsList.slice();
    removedList2.splice(removedIndex2, 1);
    props.setRenderedRunningSymbolsList(removedList);
    props.setAddedSymbolsList(removedList2);
  };

  const onAddSymbol = (symbol: string) => {
    const x = props.removedSymbolsList.slice();
    const index = x.indexOf(symbol);
    props.setRenderedRunningSymbolsList([...props.runningRenderedSymbolsList, symbol].sort());
    props.setAddedSymbolsList([...props.addedSymbolsList, symbol]);
    x.splice(index, 1);
    props.setRemovedSymbolsList(x);
  };

  const onSave = async () => {
    await ExecutorHelper.removeDESymbols(props.account.executor, props.removedSymbolsList, userToken);
    await ExecutorHelper.addDESymbols(props.account.executor, props.addedSymbolsList, userToken);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <img src="/img/bitcoin.svg" alt="bitcoin" />
        <p>Lists of Symbols</p>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>Running</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.runningSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') props.setRenderedRunningSymbolsList(search);
                else props.setRenderedRunningSymbolsList(props.runningSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.runningRenderedSymbolsList.length > 0
              ? props.runningRenderedSymbolsList.map((symbol: string, i: number) => {
                  if (!props.removedSymbolsList.includes(symbol) || props.addedSymbolsList.includes(symbol))
                    return (
                      <div key={i} className={styles.coinsElement}>
                        <div>
                          <h5>{symbol}</h5>
                        </div>
                        <div className={styles.coinsOrigin}>
                          {props.executorSymbolsList.includes(symbol) ? (
                            <img src="/img/executor.svg" alt="executor" />
                          ) : (
                            ''
                          )}
                        </div>
                        <button
                          onClick={(e) => {
                            if (e.nativeEvent.which === 1) onRemoveSymbol(symbol);
                          }}
                        >
                          <img src="/img/minus.svg" alt="minus" />
                        </button>
                      </div>
                    );
                })
              : ''}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Available from Disabled</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = props.symbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') props.setSearchedSymbolsList(search);
                else props.setSearchedSymbolsList(props.symbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {props.searchedSymbolsList.length > 0 &&
              props.searchedSymbolsList.map((symbol: string, i: number) => {
                if (
                  (props.dESymbolsList.includes(symbol) || props.removedSymbolsList.includes(symbol)) &&
                  !props.addedSymbolsList.includes(symbol)
                )
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1) onAddSymbol(symbol);
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <a
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) props.setEdit(false);
          }}
        >
          Cancel
        </a>
        <div>
          <a
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) onSave();
              props.setEdit(false);
            }}
          >
            Save
          </a>
        </div>
      </div>
      <ReactTooltip backgroundColor={'#242430'} />
    </div>
  );
};
export default DisableExpansionSymbolsModal;
