import React, { ErrorInfo, ReactNode } from 'react';

import { logger } from '../../config/Logger';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  info: ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: ReactNode; // This defines children prop explicitly
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
    info: null,
  };

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: null, info: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logger.error(error);
    this.setState({
      error: error,
      info: errorInfo,
    });

    if (error.message.includes('token')) {
      // Uncomment the following lines if you want to clear localStorage and redirect upon errors containing 'token'
      // window.localStorage.clear();
      // window.location.pathname = '/';
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // Error fallback UI
      return (
        <div className={'page'}>
          <div className="ion-no-border header">
            <div className={'title'}>
              <img loading="lazy" className="headerLogo" src="whiteclover_logo_black.png" alt="logo" />
            </div>
          </div>
          <div className={'content'}>
            <h1>Something went wrong with our services.</h1>
            <p>
              Please click <a href="/">here</a> to redirect to our page.
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}
