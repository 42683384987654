// @generated by protoc-gen-es v0.2.0 with parameter "target=js+ts+dts"
// @generated from file harvester/tr_harvester_types.proto (package harvester, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {proto3} from "@bufbuild/protobuf";

/**
 * @generated from enum harvester.Symbol
 */
export const Symbol = proto3.makeEnum(
  "harvester.Symbol",
  [
    {no: 0, name: "UNKNOWN_PAIR"},
    {no: 1, name: "ETHBTC"},
    {no: 2, name: "LTCBTC"},
    {no: 3, name: "BNBBTC"},
    {no: 4, name: "NEOBTC"},
    {no: 5, name: "GASBTC"},
    {no: 6, name: "WTCBTC"},
    {no: 7, name: "LRCBTC"},
    {no: 8, name: "QTUMBTC"},
    {no: 9, name: "YOYOBTC"},
    {no: 10, name: "OMGBTC"},
    {no: 11, name: "ZRXBTC"},
    {no: 12, name: "STRAXBTC"},
    {no: 15, name: "KNCBTC"},
    {no: 16, name: "FUNBTC"},
    {no: 18, name: "IOTABTC"},
    {no: 19, name: "LINKBTC"},
    {no: 20, name: "XVGBTC"},
    {no: 22, name: "MTLBTC"},
    {no: 23, name: "EOSBTC"},
    {no: 24, name: "SNTBTC"},
    {no: 25, name: "ETCBTC"},
    {no: 26, name: "MTHBTC"},
    {no: 27, name: "DNTBTC"},
    {no: 28, name: "ZECBTC"},
    {no: 29, name: "BNTBTC"},
    {no: 30, name: "ASTBTC"},
    {no: 31, name: "DASHBTC"},
    {no: 32, name: "OAXBTC"},
    {no: 33, name: "BTGBTC"},
    {no: 35, name: "REQBTC"},
    {no: 37, name: "TRXBTC"},
    {no: 38, name: "POWRBTC"},
    {no: 39, name: "ARKBTC"},
    {no: 40, name: "XRPBTC"},
    {no: 41, name: "ENJBTC"},
    {no: 42, name: "STORJBTC"},
    {no: 43, name: "KMDBTC"},
    {no: 45, name: "NULSBTC"},
    {no: 46, name: "RDNBTC"},
    {no: 47, name: "XMRBTC"},
    {no: 48, name: "DLTBTC"},
    {no: 49, name: "AMBBTC"},
    {no: 50, name: "BATBTC"},
    {no: 52, name: "GVTBTC"},
    {no: 53, name: "CDTBTC"},
    {no: 56, name: "QSPBTC"},
    {no: 57, name: "BTSBTC"},
    {no: 58, name: "FIROBTC"},
    {no: 59, name: "LSKBTC"},
    {no: 60, name: "MANABTC"},
    {no: 61, name: "BCDBTC"},
    {no: 62, name: "ADXBTC"},
    {no: 63, name: "ADABTC"},
    {no: 64, name: "PPTBTC"},
    {no: 66, name: "XLMBTC"},
    {no: 67, name: "CNDBTC"},
    {no: 68, name: "WABIBTC"},
    {no: 70, name: "WAVESBTC"},
    {no: 71, name: "GTOBTC"},
    {no: 72, name: "ICXBTC"},
    {no: 74, name: "ELFBTC"},
    {no: 75, name: "AIONBTC"},
    {no: 76, name: "NEBLBTC"},
    {no: 77, name: "BRDBTC"},
    {no: 78, name: "NAVBTC"},
    {no: 79, name: "APPCBTC"},
    {no: 81, name: "RLCBTC"},
    {no: 82, name: "PIVXBTC"},
    {no: 83, name: "IOSTBTC"},
    {no: 84, name: "STEEMBTC"},
    {no: 85, name: "NANOBTC"},
    {no: 87, name: "BLZBTC"},
    {no: 89, name: "POABTC"},
    {no: 90, name: "ZILBTC"},
    {no: 91, name: "ONTBTC"},
    {no: 92, name: "XEMBTC"},
    {no: 93, name: "WANBTC"},
    {no: 95, name: "QLCBTC"},
    {no: 96, name: "SYSBTC"},
    {no: 97, name: "GRSBTC"},
    {no: 99, name: "LOOMBTC"},
    {no: 100, name: "REPBTC"},
    {no: 101, name: "ZENBTC"},
    {no: 103, name: "CVCBTC"},
    {no: 104, name: "THETABTC"},
    {no: 105, name: "IOTXBTC"},
    {no: 106, name: "QKCBTC"},
    {no: 108, name: "NXSBTC"},
    {no: 109, name: "DATABTC"},
    {no: 110, name: "SCBTC"},
    {no: 111, name: "NASBTC"},
    {no: 112, name: "ARDRBTC"},
    {no: 114, name: "VETBTC"},
    {no: 115, name: "DOCKBTC"},
    {no: 116, name: "POLYBTC"},
    {no: 118, name: "GOBTC"},
    {no: 119, name: "RVNBTC"},
    {no: 120, name: "DCRBTC"},
    {no: 121, name: "MITHBTC"},
    {no: 122, name: "RENBTC"},
    {no: 123, name: "ONGBTC"},
    {no: 124, name: "FETBTC"},
    {no: 125, name: "CELRBTC"},
    {no: 126, name: "MATICBTC"},
    {no: 127, name: "ATOMBTC"},
    {no: 128, name: "PHBBTC"},
    {no: 129, name: "TFUELBTC"},
    {no: 130, name: "ONEBTC"},
    {no: 131, name: "FTMBTC"},
    {no: 132, name: "ALGOBTC"},
    {no: 134, name: "DUSKBTC"},
    {no: 135, name: "ANKRBTC"},
    {no: 136, name: "COSBTC"},
    {no: 138, name: "PERLBTC"},
    {no: 139, name: "CHZBTC"},
    {no: 140, name: "BANDBTC"},
    {no: 141, name: "BEAMBTC"},
    {no: 142, name: "XTZBTC"},
    {no: 143, name: "HBARBTC"},
    {no: 144, name: "NKNBTC"},
    {no: 145, name: "STXBTC"},
    {no: 146, name: "KAVABTC"},
    {no: 147, name: "ARPABTC"},
    {no: 148, name: "CTXCBTC"},
    {no: 149, name: "BCHBTC"},
    {no: 151, name: "VITEBTC"},
    {no: 152, name: "FTTBTC"},
    {no: 153, name: "OGNBTC"},
    {no: 154, name: "DREPBTC"},
    {no: 155, name: "TCTBTC"},
    {no: 156, name: "WRXBTC"},
    {no: 157, name: "LTOBTC"},
    {no: 159, name: "COTIBTC"},
    {no: 160, name: "STPTBTC"},
    {no: 161, name: "SOLBTC"},
    {no: 162, name: "CTSIBTC"},
    {no: 163, name: "HIVEBTC"},
    {no: 164, name: "CHRBTC"},
    {no: 165, name: "MDTBTC"},
    {no: 166, name: "STMXBTC"},
    {no: 167, name: "PNTBTC"},
    {no: 168, name: "DGBBTC"},
    {no: 169, name: "COMPBTC"},
    {no: 170, name: "SXPBTC"},
    {no: 171, name: "SNXBTC"},
    {no: 172, name: "IRISBTC"},
    {no: 173, name: "MKRBTC"},
    {no: 175, name: "FIOBTC"},
    {no: 176, name: "AVABTC"},
    {no: 177, name: "BALBTC"},
    {no: 178, name: "YFIBTC"},
    {no: 179, name: "JSTBTC"},
    {no: 180, name: "SRMBTC"},
    {no: 181, name: "ANTBTC"},
    {no: 182, name: "CRVBTC"},
    {no: 183, name: "SANDBTC"},
    {no: 184, name: "OCEANBTC"},
    {no: 185, name: "NMRBTC"},
    {no: 186, name: "DOTBTC"},
    {no: 187, name: "LUNABTC"},
    {no: 188, name: "IDEXBTC"},
    {no: 189, name: "RSRBTC"},
    {no: 190, name: "PAXGBTC"},
    {no: 191, name: "WNXMBTC"},
    {no: 192, name: "TRBBTC"},
    {no: 193, name: "BZRXBTC"},
    {no: 194, name: "WBTCBTC"},
    {no: 195, name: "SUSHIBTC"},
    {no: 196, name: "YFIIBTC"},
    {no: 197, name: "KSMBTC"},
    {no: 198, name: "EGLDBTC"},
    {no: 199, name: "DIABTC"},
    {no: 200, name: "UMABTC"},
    {no: 201, name: "BELBTC"},
    {no: 202, name: "WINGBTC"},
    {no: 203, name: "UNIBTC"},
    {no: 205, name: "OXTBTC"},
    {no: 208, name: "HNTBTC"},
    {no: 209, name: "FLMBTC"},
    {no: 210, name: "SCRTBTC"},
    {no: 211, name: "ORNBTC"},
    {no: 212, name: "UTKBTC"},
    {no: 213, name: "XVSBTC"},
    {no: 215, name: "VIDTBTC"},
    {no: 216, name: "AAVEBTC"},
    {no: 217, name: "NEARBTC"},
    {no: 218, name: "FILBTC"},
    {no: 220, name: "AERGOBTC"},
    {no: 221, name: "EVXBTC"},
    {no: 222, name: "GLMBTC"},
    {no: 223, name: "AXSBTC"},
    {no: 224, name: "TOMOBTC"},
    {no: 225, name: "HARDBTC"},
    {no: 226, name: "AVAXBTC"},
    {no: 227, name: "CTKBTC"},
    {no: 228, name: "AUDIOBTC"},
    {no: 229, name: "CELOBTC"},
    {no: 230, name: "SKLBTC"},
    {no: 231, name: "UNFIBTC"},
    {no: 232, name: "ATMBTC"},
    {no: 233, name: "ROSEBTC"},
    {no: 234, name: "MDABTC"},
    {no: 235, name: "VIBBTC"},
    {no: 236, name: "SKYBTC"},
    {no: 237, name: "ALPHABTC"},
    {no: 238, name: "GRTBTC"},
    {no: 239, name: "RIFBTC"},
    {no: 240, name: "TRUBTC"},
    {no: 241, name: "TWTBTC"},
    {no: 243, name: "LITBTC"},
    {no: 244, name: "SFPBTC"},
    {no: 245, name: "FXSBTC"},
    {no: 246, name: "DODOBTC"},
    {no: 247, name: "FRONTBTC"},
    {no: 248, name: "CAKEBTC"},
    {no: 249, name: "AUCTIONBTC"},
    {no: 250, name: "PHABTC"},
    {no: 251, name: "TVKBTC"},
    {no: 252, name: "BADGERBTC"},
    {no: 253, name: "FISBTC"},
    {no: 254, name: "OMBTC"},
    {no: 255, name: "PONDBTC"},
    {no: 256, name: "DEGOBTC"},
    {no: 257, name: "ALICEBTC"},
    {no: 258, name: "FORTHBTC"},
    {no: 259, name: "EZBTC"},
    {no: 500, name: "BTCUSDT"},
    {no: 502, name: "AAVEUSDT"},
    {no: 503, name: "ACMUSDT"},
    {no: 504, name: "ADAUSDT"},
    {no: 505, name: "AIONUSDT"},
    {no: 506, name: "AKROUSDT"},
    {no: 507, name: "ALGOUSDT"},
    {no: 508, name: "ALICEUSDT"},
    {no: 509, name: "ALPHAUSDT"},
    {no: 510, name: "ANKRUSDT"},
    {no: 511, name: "ANTUSDT"},
    {no: 512, name: "ARDRUSDT"},
    {no: 513, name: "ARPAUSDT"},
    {no: 514, name: "ARUSDT"},
    {no: 515, name: "ASRUSDT"},
    {no: 516, name: "ATAUSDT"},
    {no: 517, name: "ATMUSDT"},
    {no: 518, name: "ATOMUSDT"},
    {no: 519, name: "AUDIOUSDT"},
    {no: 520, name: "AUTOUSDT"},
    {no: 521, name: "AVAUSDT"},
    {no: 522, name: "AVAXUSDT"},
    {no: 523, name: "AXSUSDT"},
    {no: 524, name: "BADGERUSDT"},
    {no: 525, name: "BAKEUSDT"},
    {no: 526, name: "BALUSDT"},
    {no: 527, name: "BANDUSDT"},
    {no: 528, name: "BARUSDT"},
    {no: 529, name: "BATUSDT"},
    {no: 530, name: "BCHUSDT"},
    {no: 532, name: "BELUSDT"},
    {no: 533, name: "BLZUSDT"},
    {no: 534, name: "BNBUSDT"},
    {no: 535, name: "BNTUSDT"},
    {no: 537, name: "BTSUSDT"},
    {no: 539, name: "BURGERUSDT"},
    {no: 541, name: "CAKEUSDT"},
    {no: 542, name: "CELOUSDT"},
    {no: 543, name: "CELRUSDT"},
    {no: 544, name: "CFXUSDT"},
    {no: 545, name: "CHRUSDT"},
    {no: 546, name: "CHZUSDT"},
    {no: 547, name: "CKBUSDT"},
    {no: 548, name: "COCOSUSDT"},
    {no: 549, name: "COMPUSDT"},
    {no: 550, name: "COSUSDT"},
    {no: 551, name: "COTIUSDT"},
    {no: 552, name: "CRVUSDT"},
    {no: 553, name: "CTKUSDT"},
    {no: 554, name: "CTSIUSDT"},
    {no: 555, name: "CTXCUSDT"},
    {no: 557, name: "DASHUSDT"},
    {no: 558, name: "DATAUSDT"},
    {no: 559, name: "DCRUSDT"},
    {no: 560, name: "DEGOUSDT"},
    {no: 561, name: "DENTUSDT"},
    {no: 562, name: "DGBUSDT"},
    {no: 563, name: "DIAUSDT"},
    {no: 565, name: "DOCKUSDT"},
    {no: 566, name: "DODOUSDT"},
    {no: 567, name: "DOGEUSDT"},
    {no: 568, name: "DOTUSDT"},
    {no: 569, name: "DREPUSDT"},
    {no: 570, name: "DUSKUSDT"},
    {no: 571, name: "EGLDUSDT"},
    {no: 572, name: "ENJUSDT"},
    {no: 573, name: "EOSUSDT"},
    {no: 574, name: "EPSUSDT"},
    {no: 575, name: "ETCUSDT"},
    {no: 576, name: "ETHUSDT"},
    {no: 577, name: "FETUSDT"},
    {no: 578, name: "FILUSDT"},
    {no: 579, name: "FIOUSDT"},
    {no: 580, name: "FIROUSDT"},
    {no: 581, name: "FISUSDT"},
    {no: 582, name: "FLMUSDT"},
    {no: 583, name: "FORTHUSDT"},
    {no: 584, name: "FTMUSDT"},
    {no: 586, name: "FUNUSDT"},
    {no: 587, name: "GRTUSDT"},
    {no: 589, name: "GTCUSDT"},
    {no: 592, name: "HARDUSDT"},
    {no: 593, name: "HBARUSDT"},
    {no: 594, name: "HIVEUSDT"},
    {no: 596, name: "HOTUSDT"},
    {no: 597, name: "ICPUSDT"},
    {no: 598, name: "ICXUSDT"},
    {no: 600, name: "IOSTUSDT"},
    {no: 601, name: "IOTAUSDT"},
    {no: 602, name: "IOTXUSDT"},
    {no: 603, name: "IRISUSDT"},
    {no: 604, name: "JSTUSDT"},
    {no: 605, name: "JUVUSDT"},
    {no: 606, name: "KAVAUSDT"},
    {no: 607, name: "KEYUSDT"},
    {no: 608, name: "KMDUSDT"},
    {no: 609, name: "KNCUSDT"},
    {no: 610, name: "KSMUSDT"},
    {no: 611, name: "LINAUSDT"},
    {no: 612, name: "LINKUSDT"},
    {no: 613, name: "LITUSDT"},
    {no: 614, name: "LPTUSDT"},
    {no: 615, name: "LRCUSDT"},
    {no: 616, name: "LSKUSDT"},
    {no: 617, name: "LTCUSDT"},
    {no: 618, name: "LTOUSDT"},
    {no: 619, name: "LUNAUSDT"},
    {no: 620, name: "MANAUSDT"},
    {no: 621, name: "MASKUSDT"},
    {no: 622, name: "MATICUSDT"},
    {no: 623, name: "MBLUSDT"},
    {no: 624, name: "MDTUSDT"},
    {no: 625, name: "MDXUSDT"},
    {no: 627, name: "MIRUSDT"},
    {no: 629, name: "MKRUSDT"},
    {no: 630, name: "MTLUSDT"},
    {no: 633, name: "NEARUSDT"},
    {no: 634, name: "NEOUSDT"},
    {no: 635, name: "NKNUSDT"},
    {no: 636, name: "NMRUSDT"},
    {no: 637, name: "NULSUSDT"},
    {no: 639, name: "OCEANUSDT"},
    {no: 640, name: "OGNUSDT"},
    {no: 641, name: "OGUSDT"},
    {no: 642, name: "OMGUSDT"},
    {no: 643, name: "OMUSDT"},
    {no: 644, name: "ONEUSDT"},
    {no: 645, name: "ONGUSDT"},
    {no: 646, name: "ONTUSDT"},
    {no: 647, name: "ORNUSDT"},
    {no: 648, name: "OXTUSDT"},
    {no: 649, name: "PERLUSDT"},
    {no: 650, name: "PERPUSDT"},
    {no: 651, name: "PNTUSDT"},
    {no: 652, name: "POLSUSDT"},
    {no: 653, name: "PONDUSDT"},
    {no: 654, name: "PSGUSDT"},
    {no: 655, name: "PUNDIXUSDT"},
    {no: 656, name: "QTUMUSDT"},
    {no: 658, name: "REEFUSDT"},
    {no: 659, name: "RENUSDT"},
    {no: 661, name: "RIFUSDT"},
    {no: 662, name: "RLCUSDT"},
    {no: 663, name: "ROSEUSDT"},
    {no: 664, name: "RSRUSDT"},
    {no: 666, name: "RVNUSDT"},
    {no: 667, name: "SANDUSDT"},
    {no: 668, name: "SCUSDT"},
    {no: 669, name: "SFPUSDT"},
    {no: 670, name: "SHIBUSDT"},
    {no: 671, name: "SKLUSDT"},
    {no: 672, name: "SLPUSDT"},
    {no: 673, name: "SNXUSDT"},
    {no: 674, name: "SOLUSDT"},
    {no: 676, name: "STMXUSDT"},
    {no: 677, name: "STORJUSDT"},
    {no: 678, name: "STPTUSDT"},
    {no: 679, name: "STRAXUSDT"},
    {no: 680, name: "STXUSDT"},
    {no: 681, name: "SUSHIUSDT"},
    {no: 682, name: "SXPUSDT"},
    {no: 684, name: "TFUELUSDT"},
    {no: 685, name: "THETAUSDT"},
    {no: 686, name: "TKOUSDT"},
    {no: 687, name: "TLMUSDT"},
    {no: 688, name: "TOMOUSDT"},
    {no: 689, name: "TORNUSDT"},
    {no: 690, name: "TRBUSDT"},
    {no: 691, name: "TROYUSDT"},
    {no: 692, name: "TRUUSDT"},
    {no: 693, name: "TRXUSDT"},
    {no: 694, name: "TWTUSDT"},
    {no: 695, name: "UMAUSDT"},
    {no: 696, name: "UNFIUSDT"},
    {no: 697, name: "UNIUSDT"},
    {no: 698, name: "UTKUSDT"},
    {no: 699, name: "VETUSDT"},
    {no: 700, name: "VITEUSDT"},
    {no: 701, name: "VTHOUSDT"},
    {no: 702, name: "WANUSDT"},
    {no: 703, name: "WAVESUSDT"},
    {no: 704, name: "WINGUSDT"},
    {no: 705, name: "WINUSDT"},
    {no: 706, name: "WRXUSDT"},
    {no: 707, name: "WTCUSDT"},
    {no: 708, name: "XEMUSDT"},
    {no: 709, name: "XLMUSDT"},
    {no: 710, name: "XMRUSDT"},
    {no: 711, name: "XRPUSDT"},
    {no: 712, name: "XTZUSDT"},
    {no: 713, name: "XVGUSDT"},
    {no: 714, name: "XVSUSDT"},
    {no: 715, name: "YFIIUSDT"},
    {no: 716, name: "YFIUSDT"},
    {no: 717, name: "ZECUSDT"},
    {no: 718, name: "ZENUSDT"},
    {no: 719, name: "ZILUSDT"},
    {no: 720, name: "ZRXUSDT"},
    {no: 724, name: "RUNEUSDT"},
    {no: 725, name: "SUPERUSDT"},
    {no: 727, name: "ERNUSDT"},
    {no: 728, name: "KLAYUSDT"},
    {no: 729, name: "PHAUSDT"},
    {no: 730, name: "BONDUSDT"},
    {no: 731, name: "MLNUSDT"},
    {no: 732, name: "DEXEUSDT"},
    {no: 733, name: "C98USDT"},
    {no: 734, name: "CLVUSDT"},
    {no: 735, name: "QNTUSDT"},
    {no: 738, name: "FLOWUSDT"},
    {no: 739, name: "TVKUSDT"},
    {no: 740, name: "MINAUSDT"},
    {no: 741, name: "RAYUSDT"},
    {no: 742, name: "FARMUSDT"},
    {no: 743, name: "ALPACAUSDT"},
    {no: 744, name: "QUICKUSDT"},
    {no: 745, name: "MBOXUSDT"},
    {no: 746, name: "FORUSDT"},
    {no: 747, name: "REQUSDT"},
    {no: 748, name: "GHSTUSDT"},
    {no: 749, name: "WAXPUSDT"},
    {no: 751, name: "GNOUSDT"},
    {no: 752, name: "XECUSDT"},
    {no: 753, name: "ELFUSDT"},
    {no: 754, name: "DYDXUSDT"},
    {no: 756, name: "IDEXUSDT"},
    {no: 757, name: "VIDTUSDT"},
    {no: 758, name: "GALAUSDT"},
    {no: 759, name: "ILVUSDT"},
    {no: 760, name: "YGGUSDT"},
    {no: 761, name: "SYSUSDT"},
    {no: 762, name: "DFUSDT"},
    {no: 763, name: "FRONTUSDT"},
    {no: 764, name: "CVPUSDT"},
    {no: 765, name: "AGLDUSDT"},
    {no: 766, name: "RADUSDT"},
    {no: 767, name: "BETAUSDT"},
    {no: 768, name: "RAREUSDT"},
    {no: 981, name: "AUCTIONUSDT"},
    {no: 982, name: "FIDAUSDT"},
    {no: 983, name: "LAZIOUSDT"},
    {no: 984, name: "CHESSUSDT"},
    {no: 985, name: "ADXUSDT"},
    {no: 769, name: "BNBBUSD"},
    {no: 770, name: "BTCBUSD"},
    {no: 771, name: "XRPBUSD"},
    {no: 772, name: "ETHBUSD"},
    {no: 773, name: "LTCBUSD"},
    {no: 774, name: "LINKBUSD"},
    {no: 775, name: "ETCBUSD"},
    {no: 776, name: "TRXBUSD"},
    {no: 777, name: "EOSBUSD"},
    {no: 778, name: "XLMBUSD"},
    {no: 779, name: "ADABUSD"},
    {no: 780, name: "BCHBUSD"},
    {no: 781, name: "QTUMBUSD"},
    {no: 782, name: "VETBUSD"},
    {no: 783, name: "ICXBUSD"},
    {no: 784, name: "BNTBUSD"},
    {no: 785, name: "ATOMBUSD"},
    {no: 786, name: "DASHBUSD"},
    {no: 787, name: "NEOBUSD"},
    {no: 788, name: "WAVESBUSD"},
    {no: 789, name: "XTZBUSD"},
    {no: 790, name: "BATBUSD"},
    {no: 791, name: "ENJBUSD"},
    {no: 792, name: "NANOBUSD"},
    {no: 793, name: "ONTBUSD"},
    {no: 794, name: "RVNBUSD"},
    {no: 795, name: "ALGOBUSD"},
    {no: 796, name: "BTTBUSD"},
    {no: 797, name: "TOMOBUSD"},
    {no: 798, name: "XMRBUSD"},
    {no: 799, name: "ZECBUSD"},
    {no: 800, name: "DATABUSD"},
    {no: 801, name: "SOLBUSD"},
    {no: 802, name: "CTSIBUSD"},
    {no: 803, name: "HBARBUSD"},
    {no: 804, name: "MATICBUSD"},
    {no: 805, name: "WRXBUSD"},
    {no: 806, name: "ZILBUSD"},
    {no: 807, name: "KNCBUSD"},
    {no: 808, name: "LRCBUSD"},
    {no: 809, name: "DGBBUSD"},
    {no: 810, name: "COMPBUSD"},
    {no: 811, name: "SXPBUSD"},
    {no: 812, name: "SNXBUSD"},
    {no: 813, name: "MKRBUSD"},
    {no: 814, name: "RUNEBUSD"},
    {no: 815, name: "MANABUSD"},
    {no: 816, name: "DOGEBUSD"},
    {no: 817, name: "ZRXBUSD"},
    {no: 818, name: "FIOBUSD"},
    {no: 819, name: "AVABUSD"},
    {no: 820, name: "IOTABUSD"},
    {no: 821, name: "BALBUSD"},
    {no: 822, name: "YFIBUSD"},
    {no: 823, name: "JSTBUSD"},
    {no: 824, name: "SRMBUSD"},
    {no: 825, name: "ANTBUSD"},
    {no: 826, name: "CRVBUSD"},
    {no: 827, name: "SANDBUSD"},
    {no: 828, name: "OCEANBUSD"},
    {no: 829, name: "NMRBUSD"},
    {no: 830, name: "DOTBUSD"},
    {no: 831, name: "LUNABUSD"},
    {no: 832, name: "IDEXBUSD"},
    {no: 833, name: "RSRBUSD"},
    {no: 834, name: "TRBBUSD"},
    {no: 836, name: "SUSHIBUSD"},
    {no: 837, name: "YFIIBUSD"},
    {no: 838, name: "KSMBUSD"},
    {no: 839, name: "EGLDBUSD"},
    {no: 840, name: "DIABUSD"},
    {no: 841, name: "BELBUSD"},
    {no: 842, name: "WINGBUSD"},
    {no: 843, name: "UNIBUSD"},
    {no: 844, name: "AVAXBUSD"},
    {no: 845, name: "FLMBUSD"},
    {no: 846, name: "CAKEBUSD"},
    {no: 847, name: "XVSBUSD"},
    {no: 848, name: "ALPHABUSD"},
    {no: 849, name: "VIDTBUSD"},
    {no: 850, name: "AAVEBUSD"},
    {no: 851, name: "NEARBUSD"},
    {no: 852, name: "FILBUSD"},
    {no: 854, name: "ONEBUSD"},
    {no: 855, name: "AUDIOBUSD"},
    {no: 856, name: "CTKBUSD"},
    {no: 857, name: "AXSBUSD"},
    {no: 858, name: "HARDBUSD"},
    {no: 859, name: "DNTBUSD"},
    {no: 860, name: "CVPBUSD"},
    {no: 861, name: "STRAXBUSD"},
    {no: 862, name: "FORBUSD"},
    {no: 863, name: "UNFIBUSD"},
    {no: 864, name: "FRONTBUSD"},
    {no: 865, name: "ROSEBUSD"},
    {no: 866, name: "SYSBUSD"},
    {no: 867, name: "SKLBUSD"},
    {no: 868, name: "GHSTBUSD"},
    {no: 869, name: "DFBUSD"},
    {no: 870, name: "JUVBUSD"},
    {no: 871, name: "PSGBUSD"},
    {no: 872, name: "DEXEBUSD"},
    {no: 873, name: "CKBBUSD"},
    {no: 874, name: "TWTBUSD"},
    {no: 875, name: "LITBUSD"},
    {no: 876, name: "SFPBUSD"},
    {no: 877, name: "DODOBUSD"},
    {no: 878, name: "BAKEBUSD"},
    {no: 879, name: "BANDBUSD"},
    {no: 880, name: "GRTBUSD"},
    {no: 881, name: "IOSTBUSD"},
    {no: 882, name: "REEFBUSD"},
    {no: 883, name: "ACMBUSD"},
    {no: 884, name: "PHABUSD"},
    {no: 885, name: "TVKBUSD"},
    {no: 886, name: "BADGERBUSD"},
    {no: 887, name: "FISBUSD"},
    {no: 888, name: "OMBUSD"},
    {no: 889, name: "PONDBUSD"},
    {no: 890, name: "DEGOBUSD"},
    {no: 891, name: "ALICEBUSD"},
    {no: 892, name: "CHZBUSD"},
    {no: 893, name: "LINABUSD"},
    {no: 894, name: "PERPBUSD"},
    {no: 895, name: "RAMPBUSD"},
    {no: 896, name: "SUPERBUSD"},
    {no: 897, name: "CFXBUSD"},
    {no: 898, name: "XVGBUSD"},
    {no: 899, name: "EPSBUSD"},
    {no: 900, name: "AUTOBUSD"},
    {no: 901, name: "TKOBUSD"},
    {no: 902, name: "TLMBUSD"},
    {no: 903, name: "BTGBUSD"},
    {no: 904, name: "HOTBUSD"},
    {no: 905, name: "MIRBUSD"},
    {no: 906, name: "BARBUSD"},
    {no: 907, name: "FORTHBUSD"},
    {no: 908, name: "BURGERBUSD"},
    {no: 909, name: "SLPBUSD"},
    {no: 910, name: "SHIBBUSD"},
    {no: 911, name: "ICPBUSD"},
    {no: 912, name: "ARBUSD"},
    {no: 913, name: "POLSBUSD"},
    {no: 914, name: "MDXBUSD"},
    {no: 915, name: "MASKBUSD"},
    {no: 916, name: "LPTBUSD"},
    {no: 917, name: "NUBUSD"},
    {no: 918, name: "RLCBUSD"},
    {no: 919, name: "CELRBUSD"},
    {no: 920, name: "ATMBUSD"},
    {no: 921, name: "ZENBUSD"},
    {no: 922, name: "FTMBUSD"},
    {no: 923, name: "THETABUSD"},
    {no: 924, name: "WINBUSD"},
    {no: 925, name: "KAVABUSD"},
    {no: 926, name: "XEMBUSD"},
    {no: 927, name: "ATABUSD"},
    {no: 928, name: "GTCBUSD"},
    {no: 929, name: "TORNBUSD"},
    {no: 930, name: "COTIBUSD"},
    {no: 931, name: "KEEPBUSD"},
    {no: 932, name: "SCBUSD"},
    {no: 933, name: "CHRBUSD"},
    {no: 934, name: "STMXBUSD"},
    {no: 935, name: "HNTBUSD"},
    {no: 936, name: "FTTBUSD"},
    {no: 937, name: "DOCKBUSD"},
    {no: 938, name: "ERNBUSD"},
    {no: 939, name: "KLAYBUSD"},
    {no: 940, name: "UTKBUSD"},
    {no: 941, name: "IOTXBUSD"},
    {no: 942, name: "BONDBUSD"},
    {no: 943, name: "MLNBUSD"},
    {no: 944, name: "LTOBUSD"},
    {no: 945, name: "QUICKBUSD"},
    {no: 946, name: "C98BUSD"},
    {no: 947, name: "CLVBUSD"},
    {no: 948, name: "QNTBUSD"},
    {no: 949, name: "FLOWBUSD"},
    {no: 950, name: "XECBUSD"},
    {no: 951, name: "MINABUSD"},
    {no: 952, name: "RAYBUSD"},
    {no: 953, name: "FARMBUSD"},
    {no: 954, name: "ALPACABUSD"},
    {no: 955, name: "ORNBUSD"},
    {no: 956, name: "MBOXBUSD"},
    {no: 957, name: "WAXPBUSD"},
    {no: 958, name: "TRIBEBUSD"},
    {no: 959, name: "GNOBUSD"},
    {no: 960, name: "MTLBUSD"},
    {no: 961, name: "OGNBUSD"},
    {no: 962, name: "POLYBUSD"},
    {no: 963, name: "DYDXBUSD"},
    {no: 964, name: "ELFBUSD"},
    {no: 965, name: "GALABUSD"},
    {no: 966, name: "ILVBUSD"},
    {no: 967, name: "RENBUSD"},
    {no: 968, name: "YGGBUSD"},
    {no: 969, name: "STXBUSD"},
    {no: 970, name: "FETBUSD"},
    {no: 971, name: "ARPABUSD"},
    {no: 972, name: "LSKBUSD"},
    {no: 973, name: "DENTBUSD"},
    {no: 974, name: "AGLDBUSD"},
    {no: 975, name: "RADBUSD"},
    {no: 976, name: "HIVEBUSD"},
    {no: 977, name: "STPTBUSD"},
    {no: 978, name: "BETABUSD"},
    {no: 979, name: "RAREBUSD"},
    {no: 980, name: "TROYBUSD"},
    {no: 1200, name: "DEFIUSDT"},
    {no: 1201, name: "ENSUSDT"},
    {no: 1202, name: "DARUSDT"},
    {no: 1203, name: "BNXUSDT"},
    {no: 1204, name: "RGTUSDT"},
    {no: 1205, name: "MOVRUSDT"},
    {no: 1206, name: "CITYUSDT"},
    {no: 1207, name: "KP3RUSDT"},
    {no: 1208, name: "QIUSDT"},
    {no: 1209, name: "PORTOUSDT"},
    {no: 1210, name: "POWRUSDT"},
    {no: 1211, name: "VGXUSDT"},
    {no: 1212, name: "JASMYUSDT"},
    {no: 1213, name: "AMPUSDT"},
    {no: 1214, name: "PLAUSDT"},
    {no: 1215, name: "PYRUSDT"},
    {no: 1216, name: "RNDRUSDT"},
    {no: 1217, name: "ALCXUSDT"},
    {no: 1218, name: "SANTOSUSDT"},
    {no: 1219, name: "MCUSDT"},
    {no: 1221, name: "BICOUSDT"},
    {no: 1222, name: "FLUXUSDT"},
    {no: 1223, name: "FXSUSDT"},
    {no: 1224, name: "VOXELUSDT"},
    {no: 1225, name: "HIGHUSDT"},
    {no: 1226, name: "CVXUSDT"},
    {no: 1227, name: "PEOPLEUSDT"},
    {no: 1228, name: "OOKIUSDT"},
    {no: 1229, name: "SPELLUSDT"},
    {no: 1230, name: "JOEUSDT"},
    {no: 1231, name: "ACHUSDT"},
    {no: 1232, name: "IMXUSDT"},
    {no: 1233, name: "GLMRUSDT"},
    {no: 1234, name: "LOKAUSDT"},
    {no: 1235, name: "SCRTUSDT"},
    {no: 1236, name: "API3USDT"},
    {no: 1237, name: "BTTCUSDT"},
    {no: 1238, name: "ACAUSDT"},
    {no: 1239, name: "ANCUSDT"},
    {no: 1240, name: "XNOUSDT"},
    {no: 1241, name: "WOOUSDT"},
    {no: 1242, name: "ALPINEUSDT"},
    {no: 1243, name: "TUSDT"},
    {no: 1244, name: "ASTRUSDT"},
    {no: 1246, name: "GMTUSDT"},
    {no: 1247, name: "KDAUSDT"},
    {no: 1248, name: "APEUSDT"},
    {no: 1249, name: "BSWUSDT"},
    {no: 1250, name: "BIFIUSDT"},
    {no: 1251, name: "MULTIUSDT"},
    {no: 1252, name: "EPXUSDT"},
    {no: 1253, name: "GALUSDT"},
    {no: 1254, name: "LDOUSDT"},
    {no: 1255, name: "MOBUSDT"},
    {no: 1256, name: "OPUSDT"},
    {no: 1257, name: "REIUSDT"},
    {no: 1258, name: "STEEMUSDT"},
    {no: 1259, name: "APTUSDT"},
    {no: 1260, name: "GMXUSDT"},
    {no: 1261, name: "LEVERUSDT"},
    {no: 1262, name: "LUNCUSDT"},
    {no: 1263, name: "NEBLUSDT"},
    {no: 1264, name: "NEXOUSDT"},
    {no: 1265, name: "OSMOUSDT"},
    {no: 1266, name: "POLYXUSDT"},
    {no: 1267, name: "STGUSDT"},
    {no: 1268, name: "INJUSDT"},
    {no: 1269, name: "HFTUSDT"},
    {no: 1270, name: "PHBUSDT"},
    {no: 1271, name: "REPUSDT"},
    {no: 1272, name: "HOOKUSDT"},
    {no: 1273, name: "MAGICUSDT"},
    {no: 1274, name: "AGIXUSDT"},
    {no: 1275, name: "CVCUSDT"},
    {no: 1276, name: "FTTUSDT"},
    {no: 1277, name: "HNTUSDT"},
    {no: 1278, name: "LQTYUSDT"},
    {no: 1279, name: "LUNA2USDT"},
    {no: 1280, name: "SRMUSDT"},
    {no: 1281, name: "SSVUSDT"},
    {no: 1282, name: "AMBUSDT"},
    {no: 1283, name: "BETHUSDT"},
    {no: 1284, name: "GASUSDT"},
    {no: 1285, name: "GLMUSDT"},
    {no: 1286, name: "GNSUSDT"},
    {no: 1287, name: "HIFIUSDT"},
    {no: 1288, name: "PROMUSDT"},
    {no: 1289, name: "PROSUSDT"},
    {no: 1290, name: "QKCUSDT"},
    {no: 1291, name: "RPLUSDT"},
    {no: 1292, name: "SYNUSDT"},
    {no: 1293, name: "UFTUSDT"},
    {no: 1294, name: "VIBUSDT"},
  ],
);

/**
 * @generated from enum harvester.Market
 */
export const Market = proto3.makeEnum(
  "harvester.Market",
  [
    {no: 0, name: "UNKNOWN_MARKET"},
    {no: 1, name: "BINANCE"},
  ],
);

/**
 * @generated from enum harvester.TradeType
 */
export const TradeType = proto3.makeEnum(
  "harvester.TradeType",
  [
    {no: 0, name: "UNKNOWN_TRADE_TYPE"},
    {no: 1, name: "SPOT"},
    {no: 2, name: "FUTURE"},
  ],
);

/**
 * @generated from message harvester.HarvHealthStatusRequest
 */
export const HarvHealthStatusRequest = proto3.makeMessageType(
  "harvester.HarvHealthStatusRequest",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
  ],
);

/**
 * @generated from message harvester.HarvHealthStatusResponse
 */
export const HarvHealthStatusResponse = proto3.makeMessageType(
  "harvester.HarvHealthStatusResponse",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
    { no: 2, name: "alive", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 3, name: "cache_metrics", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "alive_future", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
  ],
);

/**
 * @generated from message harvester.HarvOHLCVRequest
 */
export const HarvOHLCVRequest = proto3.makeMessageType(
  "harvester.HarvOHLCVRequest",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
    { no: 2, name: "retrievalTimeBeforeMillis", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "durationMillis", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "now", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "symbol", kind: "enum", T: proto3.getEnumType(Symbol) },
  ],
);

/**
 * @generated from message harvester.GetAllSupportedSymbolsRequest
 */
export const GetAllSupportedSymbolsRequest = proto3.makeMessageType(
  "harvester.GetAllSupportedSymbolsRequest",
  () => [
    { no: 1, name: "quote_asset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message harvester.GetAllSupportedSymbolsResponse
 */
export const GetAllSupportedSymbolsResponse = proto3.makeMessageType(
  "harvester.GetAllSupportedSymbolsResponse",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "futureSymbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message harvester.GetAllIgnoredSymbolsRequest
 */
export const GetAllIgnoredSymbolsRequest = proto3.makeMessageType(
  "harvester.GetAllIgnoredSymbolsRequest",
  () => [
    { no: 1, name: "quote_asset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message harvester.GetAllIgnoredSymbolsResponse
 */
export const GetAllIgnoredSymbolsResponse = proto3.makeMessageType(
  "harvester.GetAllIgnoredSymbolsResponse",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "futureSymbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message harvester.LastTradeRequest
 */
export const LastTradeRequest = proto3.makeMessageType(
  "harvester.LastTradeRequest",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
    { no: 5, name: "symbol", kind: "enum", T: proto3.getEnumType(Symbol) },
  ],
);

/**
 * @generated from message harvester.HarvOHLCVResponse
 */
export const HarvOHLCVResponse = proto3.makeMessageType(
  "harvester.HarvOHLCVResponse",
  () => [
    { no: 2, name: "data", kind: "message", T: OHLCV, repeated: true },
    { no: 3, name: "alive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message harvester.OHLCV
 */
export const OHLCV = proto3.makeMessageType(
  "harvester.OHLCV",
  () => [
    { no: 1, name: "openPrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "highPrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "lowPrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "closePrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "volume", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
    { no: 7, name: "symbol", kind: "enum", T: proto3.getEnumType(Symbol) },
    { no: 8, name: "from", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "to", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "duration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "ignoredSymbol", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message harvester.ReEnableSymbolsRequest
 */
export const ReEnableSymbolsRequest = proto3.makeMessageType(
  "harvester.ReEnableSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message harvester.RemoveSymbolRequest
 */
export const RemoveSymbolRequest = proto3.makeMessageType(
  "harvester.RemoveSymbolRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message harvester.NOOP
 */
export const NOOP = proto3.makeMessageType(
  "harvester.NOOP",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message harvester.PriceTickerRequest
 */
export const PriceTickerRequest = proto3.makeMessageType(
  "harvester.PriceTickerRequest",
  () => [
    { no: 1, name: "marketName", kind: "enum", T: proto3.getEnumType(Market) },
  ],
);

/**
 * @generated from message harvester.PriceTicker
 */
export const PriceTicker = proto3.makeMessageType(
  "harvester.PriceTicker",
  () => [
    { no: 1, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "received_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "tradeType", kind: "enum", T: proto3.getEnumType(TradeType) },
    { no: 4, name: "openPrice", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "openToNowPricePercentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "trade_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "way", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message harvester.PriceTickerResponse
 */
export const PriceTickerResponse = proto3.makeMessageType(
  "harvester.PriceTickerResponse",
  () => [
    { no: 1, name: "spotPriceMap", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PriceTicker} },
    { no: 2, name: "futurePriceMap", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: PriceTicker} },
    { no: 3, name: "noop", kind: "message", T: NOOP },
  ],
);

/**
 * @generated from message harvester.PriceTickerStreamResponse
 */
export const PriceTickerStreamResponse = proto3.makeMessageType(
  "harvester.PriceTickerStreamResponse",
  () => [
    { no: 1, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "priceTicker", kind: "message", T: PriceTicker },
    { no: 3, name: "noop", kind: "message", T: NOOP },
  ],
);

/**
 * @generated from message harvester.GetDiskUsageRequest
 */
export const GetDiskUsageRequest = proto3.makeMessageType(
  "harvester.GetDiskUsageRequest",
  [],
);

/**
 * @generated from message harvester.GetDiskUsageResponse
 */
export const GetDiskUsageResponse = proto3.makeMessageType(
  "harvester.GetDiskUsageResponse",
  () => [
    { no: 1, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "free", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "used", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message harvester.BrainSyncRequest
 */
export const BrainSyncRequest = proto3.makeMessageType(
  "harvester.BrainSyncRequest",
  () => [
    { no: 1, name: "brain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message harvester.BrainSyncResponse
 */
export const BrainSyncResponse = proto3.makeMessageType(
  "harvester.BrainSyncResponse",
  () => [
    { no: 1, name: "brain_sync", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "brain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

