import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { selectAccessToken } from '../../app/redux/authorization.slice.reducer';
import { logger } from '../../config/Logger';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import styles from './Symbol.module.scss';

const GlobalSymbolModal = (props: any) => {
  const [btcSymbolsList, setBtcSymbolsList] = useState<string[]>([]);
  const [usdtSymbolsList, setUsdtSymbolsList] = useState<string[]>([]);
  const [usdtFutureSymbolsList, setUsdtFutureSymbolsList] = useState<string[]>([]);
  const [busdSymbolsList, setBusdSymbolsList] = useState<string[]>([]);
  const [btcIgnoredSymbolsList, setIgnoredBtcSymbolsList] = useState<string[]>([]);
  const [usdtIgnoredSymbolsList, setIgnoredUsdtSymbolsList] = useState<string[]>([]);
  const [usdtFutureIgnoredSymbolsList, setIgnoredFutureUsdtSymbolsList] = useState<string[]>([]);
  const [busdIgnoredSymbolsList, setIgnoredBusdSymbolsList] = useState<string[]>([]);
  const [renderedBtcSymbolsList, setRenderedBtcSymbolsList] = useState<string[]>([]);
  const [renderedUsdtSymbolsList, setRenderedUsdtSymbolsList] = useState<string[]>([]);
  const [renderedUsdtFutureSymbolsList, setRenderedUsdtFutureSymbolsList] = useState<string[]>([]);
  const [renderedBusdSymbolsList, setRenderedBusdSymbolsList] = useState<string[]>([]);
  const [renderedBtcIgnoredSymbolsList, setRenderedBtcIgnoredSymbolsList] = useState<string[]>([]);
  const [renderedUsdtIgnoredSymbolsList, setRenderedUsdtIgnoredSymbolsList] = useState<string[]>([]);
  const [renderedUsdtFutureIgnoredSymbolsList, setRenderedUsdtFutureIgnoredSymbolsList] = useState<string[]>([]);
  const [renderedBusdIgnoredSymbolsList, setRenderedBusdIgnoredSymbolsList] = useState<string[]>([]);
  const [removedBtcSymbolsList, setRemovedBtcSymbolsList] = useState<string[]>([]);
  const [addedBtcSymbolsList, setAddedBtcSymbolsList] = useState<string[]>([]);
  const [removedUsdtSymbolsList, setRemovedUsdtSymbolsList] = useState<string[]>([]);
  const [removedUsdtFutureSymbolsList, setRemovedUsdtFutureSymbolsList] = useState<string[]>([]);
  const [addedUsdtSymbolsList, setAddedUsdtSymbolsList] = useState<string[]>([]);
  const [addedUsdtFutureSymbolsList, setAddedUsdtFutureSymbolsList] = useState<string[]>([]);
  const [removedBusdSymbolsList, setRemovedBusdSymbolsList] = useState<string[]>([]);
  const [addedBusdSymbolsList, setAddedBusdSymbolsList] = useState<string[]>([]);
  const userToken = useSelector(selectAccessToken);

  useEffect(() => {
    fetchSymbolLists(props.server);
  }, []);

  const fetchSymbolLists = async (server: number) => {
    try {
      const supportedBTCListSymbols = await HarvesterHelper.getAllSupportedSymbols(server, 'BTC', userToken);
      const supportedUSDTListSymbols = await HarvesterHelper.getAllSupportedSymbols(server, 'USDT', userToken);
      const supportedBUSDListSymbols = await HarvesterHelper.getAllSupportedSymbols(server, 'BUSD', userToken);
      setBtcSymbolsList(supportedBTCListSymbols.symbols.sort());
      setRenderedBtcSymbolsList(supportedBTCListSymbols.symbols.sort());
      setUsdtSymbolsList(supportedUSDTListSymbols.symbols.sort());
      setRenderedUsdtSymbolsList(supportedUSDTListSymbols.symbols.sort());
      setUsdtFutureSymbolsList(supportedUSDTListSymbols.futureSymbols.sort());
      setRenderedUsdtFutureSymbolsList(supportedUSDTListSymbols.futureSymbols.sort());
      setBusdSymbolsList(supportedBUSDListSymbols.futureSymbols.sort());
      setRenderedBusdSymbolsList(supportedBUSDListSymbols.symbols.sort());
      const ignoredBTCListSymbols = await HarvesterHelper.getAllIgnoredSymbols(server, 'BTC', userToken);
      const ignoredUSDTListSymbols = await HarvesterHelper.getAllIgnoredSymbols(server, 'USDT', userToken);
      const ignoredBUSDListSymbols = await HarvesterHelper.getAllIgnoredSymbols(server, 'BUSD', userToken);
      setIgnoredBtcSymbolsList(ignoredBTCListSymbols.symbols.sort());
      setRenderedBtcIgnoredSymbolsList(ignoredBTCListSymbols.symbols.sort());
      setIgnoredUsdtSymbolsList(ignoredUSDTListSymbols.symbols.sort());
      setRenderedUsdtIgnoredSymbolsList(ignoredUSDTListSymbols.symbols.sort());
      setIgnoredFutureUsdtSymbolsList(ignoredUSDTListSymbols.futureSymbols.sort());
      setRenderedUsdtFutureIgnoredSymbolsList(ignoredUSDTListSymbols.futureSymbols.sort());
      setIgnoredBusdSymbolsList(ignoredBUSDListSymbols.symbols.sort());
      setRenderedBusdIgnoredSymbolsList(ignoredBUSDListSymbols.symbols.sort());
    } catch (e) {
      logger.error({ message: e });
    }
  };

  const onRemoveSymbol = (
    setRemovedSymbolsList: any,
    removedSymbolsList: any,
    renderedSymbolsList: any,
    renderedIgnoredSymbolsList: any,
    setRenderedSymbolsList: any,
    setRenderedIgnoredSymbolsList: any,
    symbol: string,
  ) => {
    setRemovedSymbolsList([...removedSymbolsList, symbol]);
    const removedIndex = renderedSymbolsList.indexOf(symbol);
    const removedList = renderedSymbolsList.slice();
    removedList.splice(removedIndex, 1);
    setRenderedSymbolsList(removedList);
    setRenderedIgnoredSymbolsList([...renderedIgnoredSymbolsList, symbol].sort());
  };

  const onAddSymbol = (
    removedSymbolsList: any,
    setRenderedSymbolsList: any,
    renderedSymbolsList: any,
    renderedIgnoredSymbolsList: any,
    setRenderedIgnoredSymbolsList: any,
    setAddedSymbolsList: any,
    addedSymbolsList: any,
    setRemovedSymbolsList: any,
    symbol: string,
  ) => {
    const x = removedSymbolsList.slice();
    const index = x.indexOf(symbol);
    setRenderedSymbolsList([...renderedSymbolsList, symbol].sort());
    const removedIndex = renderedIgnoredSymbolsList.indexOf(symbol);
    const removedList = renderedIgnoredSymbolsList.slice();
    removedList.splice(removedIndex, 1);
    setRenderedIgnoredSymbolsList(removedList);
    setAddedSymbolsList([...addedSymbolsList, symbol]);
    x.splice(index, 1);
    setRemovedSymbolsList(x);
  };

  const onSave = async (server: number) => {
    await HarvesterHelper.removeSymbols(server, removedBtcSymbolsList, userToken);
    await HarvesterHelper.removeSymbols(server, removedUsdtSymbolsList, userToken);
    await HarvesterHelper.removeFutureSymbols(server, removedUsdtFutureSymbolsList, userToken);
    await HarvesterHelper.removeSymbols(server, removedBusdSymbolsList, userToken);
    await HarvesterHelper.reEnableSymbols(server, addedBtcSymbolsList, userToken);
    await HarvesterHelper.reEnableSymbols(server, addedUsdtSymbolsList, userToken);
    await HarvesterHelper.reEnableFutureSymbols(server, addedUsdtFutureSymbolsList, userToken);
    await HarvesterHelper.reEnableSymbols(server, addedBusdSymbolsList, userToken);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <img src="/img/bitcoin.svg" alt="bitcoin" />
        <p>Lists of Symbols</p>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>BTC HARVESTER LIST</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = btcSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                const search1 = addedBtcSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedBtcSymbolsList([...search, ...search1].sort());
                else setRenderedBtcSymbolsList(btcSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedBtcSymbolsList.map((symbol, i) => {
              if (!removedBtcSymbolsList.includes(symbol))
                return (
                  <div key={i} className={styles.coinsElement}>
                    <div>
                      <h5>{symbol}</h5>
                    </div>
                    <button
                      onClick={(e) => {
                        if (e.nativeEvent.which === 1)
                          onRemoveSymbol(
                            setRemovedBtcSymbolsList,
                            removedBtcSymbolsList,
                            renderedBtcSymbolsList,
                            renderedBtcIgnoredSymbolsList,
                            setRenderedBtcSymbolsList,
                            setRenderedBtcIgnoredSymbolsList,
                            symbol,
                          );
                      }}
                    >
                      <img src="/img/minus.svg" alt="minus" />
                    </button>
                  </div>
                );
            })}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Ignored BTC List</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = btcIgnoredSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedBtcIgnoredSymbolsList(search);
                else setRenderedBtcIgnoredSymbolsList(btcIgnoredSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedBtcIgnoredSymbolsList.length > 0 &&
              renderedBtcIgnoredSymbolsList.map((symbol, i) => {
                if (removedBtcSymbolsList.includes(symbol) || !addedBtcSymbolsList.includes(symbol))
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1)
                            onAddSymbol(
                              removedBtcSymbolsList,
                              setRenderedBtcSymbolsList,
                              renderedBtcSymbolsList,
                              renderedBtcIgnoredSymbolsList,
                              setRenderedBtcIgnoredSymbolsList,
                              setAddedBtcSymbolsList,
                              addedBtcSymbolsList,
                              setRemovedBtcSymbolsList,
                              symbol,
                            );
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>USDT HARVESTER LIST</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = usdtSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedUsdtSymbolsList(search);
                else setRenderedUsdtSymbolsList(usdtSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedUsdtSymbolsList.map((symbol, i) => {
              if (!removedUsdtSymbolsList.includes(symbol) || addedUsdtSymbolsList.includes(symbol))
                return (
                  <div key={i} className={styles.coinsElement}>
                    <div>
                      <h5>{symbol}</h5>
                    </div>
                    <button
                      onClick={(e) => {
                        if (e.nativeEvent.which === 1)
                          onRemoveSymbol(
                            setRemovedUsdtSymbolsList,
                            removedUsdtSymbolsList,
                            renderedUsdtSymbolsList,
                            renderedUsdtIgnoredSymbolsList,
                            setRenderedUsdtSymbolsList,
                            setRenderedUsdtIgnoredSymbolsList,
                            symbol,
                          );
                      }}
                    >
                      <img src="/img/minus.svg" alt="minus" />
                    </button>
                  </div>
                );
            })}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Ignored USDT List</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = usdtIgnoredSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedUsdtIgnoredSymbolsList(search);
                else setRenderedUsdtIgnoredSymbolsList(usdtIgnoredSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedUsdtIgnoredSymbolsList.length > 0 &&
              renderedUsdtIgnoredSymbolsList.map((symbol, i) => {
                if (removedUsdtSymbolsList.includes(symbol) || !addedUsdtSymbolsList.includes(symbol))
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1)
                            onAddSymbol(
                              removedUsdtSymbolsList,
                              setRenderedUsdtSymbolsList,
                              renderedUsdtSymbolsList,
                              renderedUsdtIgnoredSymbolsList,
                              setRenderedUsdtIgnoredSymbolsList,
                              setAddedUsdtSymbolsList,
                              addedUsdtSymbolsList,
                              setRemovedUsdtSymbolsList,
                              symbol,
                            );
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>BUSD HARVESTER LIST</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = busdSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedBusdSymbolsList(search);
                else setRenderedBusdSymbolsList(busdSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedBusdSymbolsList.map((symbol, i) => {
              if (!removedBusdSymbolsList.includes(symbol) || addedBusdSymbolsList.includes(symbol))
                return (
                  <div key={i} className={styles.coinsElement}>
                    <div>
                      <h5>{symbol}</h5>
                    </div>
                    <button
                      onClick={(e) => {
                        if (e.nativeEvent.which === 1)
                          onRemoveSymbol(
                            setRemovedBusdSymbolsList,
                            removedBusdSymbolsList,
                            renderedBusdSymbolsList,
                            renderedBusdIgnoredSymbolsList,
                            setRenderedBusdSymbolsList,
                            setRenderedBusdIgnoredSymbolsList,
                            symbol,
                          );
                      }}
                    >
                      <img src="/img/minus.svg" alt="minus" />
                    </button>
                  </div>
                );
            })}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Ignored BUSD List</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = busdIgnoredSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedBusdIgnoredSymbolsList(search);
                else setRenderedBusdIgnoredSymbolsList(busdIgnoredSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedBusdIgnoredSymbolsList.length > 0 &&
              renderedBusdIgnoredSymbolsList.map((symbol, i) => {
                if (removedBusdSymbolsList.includes(symbol) || !addedBusdSymbolsList.includes(symbol))
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1)
                            onAddSymbol(
                              removedBusdSymbolsList,
                              setRenderedBusdSymbolsList,
                              renderedBusdSymbolsList,
                              renderedBusdIgnoredSymbolsList,
                              setRenderedBusdIgnoredSymbolsList,
                              setAddedBusdSymbolsList,
                              addedBusdSymbolsList,
                              setRemovedBusdSymbolsList,
                              symbol,
                            );
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.harvesterList}>
        <div>
          <div className={styles.subHeader}>
            <p>FUTURES USDT HARVESTER LIST</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = usdtFutureSymbolsList.filter((s: any) => s.includes(e.target.value.toUpperCase()));
                if (e.target.value.trim() !== '') setRenderedUsdtFutureSymbolsList(search);
                else setRenderedUsdtFutureSymbolsList(usdtFutureSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedUsdtFutureSymbolsList.map((symbol, i) => {
              if (!removedUsdtFutureSymbolsList.includes(symbol) || addedUsdtFutureSymbolsList.includes(symbol))
                return (
                  <div key={i} className={styles.coinsElement}>
                    <div>
                      <h5>{symbol}</h5>
                    </div>
                    <button
                      onClick={(e) => {
                        if (e.nativeEvent.which === 1)
                          onRemoveSymbol(
                            setRemovedUsdtFutureSymbolsList,
                            removedUsdtFutureSymbolsList,
                            renderedUsdtFutureSymbolsList,
                            renderedUsdtFutureIgnoredSymbolsList,
                            setRenderedUsdtFutureSymbolsList,
                            setRenderedUsdtFutureIgnoredSymbolsList,
                            symbol,
                          );
                      }}
                    >
                      <img src="/img/minus.svg" alt="minus" />
                    </button>
                  </div>
                );
            })}
          </div>
        </div>
        <div>
          <div className={styles.subHeader}>
            <p>Ignored FUTURES USDT List</p>
          </div>
          <div className={styles.inputContainer + ' floatingInputContainer'}>
            <img src="/img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={'Search'}
              className={styles.fieldInput + ' field-input'}
              onChange={(e) => {
                const search = usdtFutureIgnoredSymbolsList.filter((s: any) =>
                  s.includes(e.target.value.toUpperCase()),
                );
                if (e.target.value.trim() !== '') setRenderedUsdtFutureIgnoredSymbolsList(search);
                else setRenderedUsdtFutureIgnoredSymbolsList(usdtFutureIgnoredSymbolsList);
              }}
            />
          </div>
          <div className={styles.coinsWrapper}>
            {renderedUsdtFutureIgnoredSymbolsList.length > 0 &&
              renderedUsdtFutureIgnoredSymbolsList.map((symbol, i) => {
                if (removedUsdtFutureSymbolsList.includes(symbol) || !addedUsdtFutureSymbolsList.includes(symbol))
                  return (
                    <div key={i} className={styles.coinsElement}>
                      <div>
                        <h5>{symbol}</h5>
                      </div>
                      <button
                        onClick={(e) => {
                          if (e.nativeEvent.which === 1)
                            onAddSymbol(
                              removedUsdtFutureSymbolsList,
                              setRenderedUsdtFutureSymbolsList,
                              renderedUsdtFutureSymbolsList,
                              renderedUsdtIgnoredSymbolsList,
                              setRenderedUsdtFutureIgnoredSymbolsList,
                              setAddedUsdtFutureSymbolsList,
                              addedUsdtFutureSymbolsList,
                              setRemovedUsdtFutureSymbolsList,
                              symbol,
                            );
                        }}
                      >
                        <img src="/img/plus.svg" alt="plus" />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <a
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) props.setEdit(false);
          }}
        >
          Cancel
        </a>
        <div>
          <a
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) onSave(props.server);
              props.setEdit(false);
            }}
          >
            Save
          </a>
        </div>
      </div>
      <ReactTooltip backgroundColor={'#242430'} />
    </div>
  );
};
export default GlobalSymbolModal;
