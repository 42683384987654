import { combineReducers } from 'redux';

import applicationAccountReducer from './applicationAccount.slice.reducer';
import authorizationReducer from './authorization.slice.reducer';
import fileReducer from './file.slice.reducer';
import ordersReducer from './orders.slice.reducer';
import pnlCalcTradesReducer from './pnlCalcTrades.slice.reducer';
import pricesReducer from './prices.slice.reducer';
import redFlagsReducer from './redFlags.slice.reducer';

// The key of this object will be the name of the store
const rootReducers = combineReducers({
  authorization: authorizationReducer,
  applicationAccount: applicationAccountReducer,
  orders: ordersReducer,
  prices: pricesReducer,
  pnlCalcTrades: pnlCalcTradesReducer,
  redFlags: redFlagsReducer,
  file: fileReducer,
});

export default rootReducers;
