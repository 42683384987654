// source: executor/tr_executor_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var harvester_tr_harvester_types_pb = require('../harvester/tr_harvester_types_pb.js');
goog.object.extend(proto, harvester_tr_harvester_types_pb);
goog.exportSymbol('proto.executor.AddSymbolsRequest', null, global);
goog.exportSymbol('proto.executor.CancelOrderRequest', null, global);
goog.exportSymbol('proto.executor.CancelOrderResponse', null, global);
goog.exportSymbol('proto.executor.ExecuteOrderRequest', null, global);
goog.exportSymbol('proto.executor.ExecuteOrderResponse', null, global);
goog.exportSymbol('proto.executor.ExecutorBase', null, global);
goog.exportSymbol('proto.executor.ExecutorConfigsRequest', null, global);
goog.exportSymbol('proto.executor.ExecutorConfigsResponse', null, global);
goog.exportSymbol('proto.executor.ExecutorHealthStatusRequest', null, global);
goog.exportSymbol('proto.executor.ExecutorHealthStatusResponse', null, global);
goog.exportSymbol('proto.executor.ExecutorPNLRequest', null, global);
goog.exportSymbol('proto.executor.ExecutorPNLResponse', null, global);
goog.exportSymbol('proto.executor.GetOpenOrdersRequest', null, global);
goog.exportSymbol('proto.executor.GetOpenOrdersResponse', null, global);
goog.exportSymbol('proto.executor.GetPnlCalculatorMapsResponse', null, global);
goog.exportSymbol('proto.executor.GetPnlCalculatorRequest', null, global);
goog.exportSymbol('proto.executor.GetPnlCalculatorResponse', null, global);
goog.exportSymbol('proto.executor.GetRunningSymbolListRequest', null, global);
goog.exportSymbol('proto.executor.GetRunningSymbolListResponse', null, global);
goog.exportSymbol('proto.executor.IgnoreSymbolsRequest', null, global);
goog.exportSymbol('proto.executor.NOOP', null, global);
goog.exportSymbol('proto.executor.OpenOrder', null, global);
goog.exportSymbol('proto.executor.OrderType', null, global);
goog.exportSymbol('proto.executor.RemoveFromIgnoreSymbolsRequest', null, global);
goog.exportSymbol('proto.executor.RemoveSymbolRequest', null, global);
goog.exportSymbol('proto.executor.Status', null, global);
goog.exportSymbol('proto.executor.Symbol', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorHealthStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorHealthStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorHealthStatusRequest.displayName = 'proto.executor.ExecutorHealthStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorHealthStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorHealthStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorHealthStatusResponse.displayName = 'proto.executor.ExecutorHealthStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorBase.displayName = 'proto.executor.ExecutorBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorConfigsRequest.displayName = 'proto.executor.ExecutorConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorConfigsResponse.displayName = 'proto.executor.ExecutorConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorPNLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorPNLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorPNLRequest.displayName = 'proto.executor.ExecutorPNLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecutorPNLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecutorPNLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecutorPNLResponse.displayName = 'proto.executor.ExecutorPNLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetRunningSymbolListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.GetRunningSymbolListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetRunningSymbolListRequest.displayName = 'proto.executor.GetRunningSymbolListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetRunningSymbolListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.GetRunningSymbolListResponse.repeatedFields_, null);
};
goog.inherits(proto.executor.GetRunningSymbolListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetRunningSymbolListResponse.displayName = 'proto.executor.GetRunningSymbolListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.AddSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.AddSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.executor.AddSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.AddSymbolsRequest.displayName = 'proto.executor.AddSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.RemoveSymbolRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.RemoveSymbolRequest.repeatedFields_, null);
};
goog.inherits(proto.executor.RemoveSymbolRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.RemoveSymbolRequest.displayName = 'proto.executor.RemoveSymbolRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.IgnoreSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.IgnoreSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.executor.IgnoreSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.IgnoreSymbolsRequest.displayName = 'proto.executor.IgnoreSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.RemoveFromIgnoreSymbolsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.RemoveFromIgnoreSymbolsRequest.repeatedFields_, null);
};
goog.inherits(proto.executor.RemoveFromIgnoreSymbolsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.RemoveFromIgnoreSymbolsRequest.displayName = 'proto.executor.RemoveFromIgnoreSymbolsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.Symbol = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.Symbol, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.Symbol.displayName = 'proto.executor.Symbol';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetOpenOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.GetOpenOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetOpenOrdersRequest.displayName = 'proto.executor.GetOpenOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetPnlCalculatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.GetPnlCalculatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetPnlCalculatorRequest.displayName = 'proto.executor.GetPnlCalculatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.NOOP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.NOOP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.NOOP.displayName = 'proto.executor.NOOP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.OpenOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.OpenOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.OpenOrder.displayName = 'proto.executor.OpenOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetOpenOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.executor.GetOpenOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.executor.GetOpenOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetOpenOrdersResponse.displayName = 'proto.executor.GetOpenOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetPnlCalculatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.GetPnlCalculatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetPnlCalculatorResponse.displayName = 'proto.executor.GetPnlCalculatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.GetPnlCalculatorMapsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.GetPnlCalculatorMapsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.GetPnlCalculatorMapsResponse.displayName = 'proto.executor.GetPnlCalculatorMapsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecuteOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecuteOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecuteOrderRequest.displayName = 'proto.executor.ExecuteOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.ExecuteOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.ExecuteOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.ExecuteOrderResponse.displayName = 'proto.executor.ExecuteOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.CancelOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.CancelOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.CancelOrderRequest.displayName = 'proto.executor.CancelOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.executor.CancelOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.executor.CancelOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.executor.CancelOrderResponse.displayName = 'proto.executor.CancelOrderResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorHealthStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorHealthStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorHealthStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorHealthStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.executor.ExecutorBase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorHealthStatusRequest}
 */
proto.executor.ExecutorHealthStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorHealthStatusRequest;
  return proto.executor.ExecutorHealthStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorHealthStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorHealthStatusRequest}
 */
proto.executor.ExecutorHealthStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.executor.ExecutorBase;
      reader.readMessage(value,proto.executor.ExecutorBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorHealthStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorHealthStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorHealthStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorHealthStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.executor.ExecutorBase.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExecutorBase base = 1;
 * @return {?proto.executor.ExecutorBase}
 */
proto.executor.ExecutorHealthStatusRequest.prototype.getBase = function() {
  return /** @type{?proto.executor.ExecutorBase} */ (
    jspb.Message.getWrapperField(this, proto.executor.ExecutorBase, 1));
};


/**
 * @param {?proto.executor.ExecutorBase|undefined} value
 * @return {!proto.executor.ExecutorHealthStatusRequest} returns this
*/
proto.executor.ExecutorHealthStatusRequest.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.ExecutorHealthStatusRequest} returns this
 */
proto.executor.ExecutorHealthStatusRequest.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.ExecutorHealthStatusRequest.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorHealthStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorHealthStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorHealthStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorHealthStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.executor.ExecutorBase.toObject(includeInstance, f),
    alive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorHealthStatusResponse}
 */
proto.executor.ExecutorHealthStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorHealthStatusResponse;
  return proto.executor.ExecutorHealthStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorHealthStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorHealthStatusResponse}
 */
proto.executor.ExecutorHealthStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.executor.ExecutorBase;
      reader.readMessage(value,proto.executor.ExecutorBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorHealthStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorHealthStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorHealthStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorHealthStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.executor.ExecutorBase.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional ExecutorBase base = 1;
 * @return {?proto.executor.ExecutorBase}
 */
proto.executor.ExecutorHealthStatusResponse.prototype.getBase = function() {
  return /** @type{?proto.executor.ExecutorBase} */ (
    jspb.Message.getWrapperField(this, proto.executor.ExecutorBase, 1));
};


/**
 * @param {?proto.executor.ExecutorBase|undefined} value
 * @return {!proto.executor.ExecutorHealthStatusResponse} returns this
*/
proto.executor.ExecutorHealthStatusResponse.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.ExecutorHealthStatusResponse} returns this
 */
proto.executor.ExecutorHealthStatusResponse.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.ExecutorHealthStatusResponse.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool alive = 2;
 * @return {boolean}
 */
proto.executor.ExecutorHealthStatusResponse.prototype.getAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorHealthStatusResponse} returns this
 */
proto.executor.ExecutorHealthStatusResponse.prototype.setAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorBase.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketname: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorBase}
 */
proto.executor.ExecutorBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorBase;
  return proto.executor.ExecutorBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorBase}
 */
proto.executor.ExecutorBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.harvester.Market} */ (reader.readEnum());
      msg.setMarketname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketname();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional harvester.Market marketName = 1;
 * @return {!proto.harvester.Market}
 */
proto.executor.ExecutorBase.prototype.getMarketname = function() {
  return /** @type {!proto.harvester.Market} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.harvester.Market} value
 * @return {!proto.executor.ExecutorBase} returns this
 */
proto.executor.ExecutorBase.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    disableexpansion: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allowfutureexecution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    btcvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    usdtvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    busdvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    hold: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowZigZag: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    levelsPerCoinMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    levelsPerCoinMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    levelsPerCoinSafetyFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    percentInvestedMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    percentInvestedMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorConfigsRequest}
 */
proto.executor.ExecutorConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorConfigsRequest;
  return proto.executor.ExecutorConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorConfigsRequest}
 */
proto.executor.ExecutorConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableexpansion(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowfutureexecution(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBtcvalueperlevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUsdtvalueperlevel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBusdvalueperlevel(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHold(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowZigZag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinMin(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinSafetyFactor(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentInvestedMax(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentInvestedMin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisableexpansion();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllowfutureexecution();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBtcvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getUsdtvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBusdvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHold();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowZigZag();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLevelsPerCoinMax();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLevelsPerCoinMin();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLevelsPerCoinSafetyFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPercentInvestedMax();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPercentInvestedMin();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional bool disableExpansion = 1;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsRequest.prototype.getDisableexpansion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setDisableexpansion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool allowFutureExecution = 2;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsRequest.prototype.getAllowfutureexecution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setAllowfutureexecution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional double btcValuePerLevel = 3;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getBtcvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setBtcvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double usdtValuePerLevel = 4;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getUsdtvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setUsdtvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double busdValuePerLevel = 5;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getBusdvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setBusdvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool hold = 6;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsRequest.prototype.getHold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setHold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allow_zig_zag = 7;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsRequest.prototype.getAllowZigZag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setAllowZigZag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional double levels_per_coin_max = 8;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getLevelsPerCoinMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setLevelsPerCoinMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double levels_per_coin_min = 9;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getLevelsPerCoinMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setLevelsPerCoinMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double levels_per_coin_safety_factor = 10;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getLevelsPerCoinSafetyFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setLevelsPerCoinSafetyFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double percent_invested_max = 11;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getPercentInvestedMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setPercentInvestedMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double percent_invested_min = 12;
 * @return {number}
 */
proto.executor.ExecutorConfigsRequest.prototype.getPercentInvestedMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsRequest} returns this
 */
proto.executor.ExecutorConfigsRequest.prototype.setPercentInvestedMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    disableexpansion: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allowfutureexecution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    btcvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    usdtvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    busdvalueperlevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    hold: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowZigZag: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    levelsPerCoinMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    levelsPerCoinMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    levelsPerCoinSafetyFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    percentInvestedMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    percentInvestedMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorConfigsResponse}
 */
proto.executor.ExecutorConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorConfigsResponse;
  return proto.executor.ExecutorConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorConfigsResponse}
 */
proto.executor.ExecutorConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableexpansion(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowfutureexecution(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBtcvalueperlevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUsdtvalueperlevel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBusdvalueperlevel(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHold(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowZigZag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinMin(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelsPerCoinSafetyFactor(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentInvestedMax(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentInvestedMin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisableexpansion();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllowfutureexecution();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBtcvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getUsdtvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBusdvalueperlevel();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHold();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowZigZag();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLevelsPerCoinMax();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLevelsPerCoinMin();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLevelsPerCoinSafetyFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPercentInvestedMax();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPercentInvestedMin();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional bool disableExpansion = 1;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsResponse.prototype.getDisableexpansion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setDisableexpansion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool allowFutureExecution = 2;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsResponse.prototype.getAllowfutureexecution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setAllowfutureexecution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional double btcValuePerLevel = 3;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getBtcvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setBtcvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double usdtValuePerLevel = 4;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getUsdtvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setUsdtvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double busdValuePerLevel = 5;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getBusdvalueperlevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setBusdvalueperlevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool hold = 6;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsResponse.prototype.getHold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setHold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allow_zig_zag = 7;
 * @return {boolean}
 */
proto.executor.ExecutorConfigsResponse.prototype.getAllowZigZag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setAllowZigZag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional double levels_per_coin_max = 8;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getLevelsPerCoinMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setLevelsPerCoinMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double levels_per_coin_min = 9;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getLevelsPerCoinMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setLevelsPerCoinMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double levels_per_coin_safety_factor = 10;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getLevelsPerCoinSafetyFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setLevelsPerCoinSafetyFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double percent_invested_max = 11;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getPercentInvestedMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setPercentInvestedMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double percent_invested_min = 12;
 * @return {number}
 */
proto.executor.ExecutorConfigsResponse.prototype.getPercentInvestedMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorConfigsResponse} returns this
 */
proto.executor.ExecutorConfigsResponse.prototype.setPercentInvestedMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorPNLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorPNLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorPNLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorPNLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysbefore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    againstnow: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorPNLRequest}
 */
proto.executor.ExecutorPNLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorPNLRequest;
  return proto.executor.ExecutorPNLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorPNLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorPNLRequest}
 */
proto.executor.ExecutorPNLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysbefore(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgainstnow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorPNLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorPNLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorPNLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorPNLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysbefore();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAgainstnow();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 daysBefore = 1;
 * @return {number}
 */
proto.executor.ExecutorPNLRequest.prototype.getDaysbefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLRequest} returns this
 */
proto.executor.ExecutorPNLRequest.prototype.setDaysbefore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool againstNow = 2;
 * @return {boolean}
 */
proto.executor.ExecutorPNLRequest.prototype.getAgainstnow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.ExecutorPNLRequest} returns this
 */
proto.executor.ExecutorPNLRequest.prototype.setAgainstnow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecutorPNLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecutorPNLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecutorPNLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorPNLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    previoustotalusdtvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currenttotalusdtvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pnltotalusdtpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pnltotalusdt: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    previousbtcvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currentbtcvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    pnlbtcpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    pnlbtc: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    previoustotalbusdvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    currenttotalbusdvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    pnltotalbusdpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    pnltotalbusd: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    currentspotusdt: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    pnlspotusdtpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    currentfutureusdt: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    pnlfutureusdtpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    currentspotbtc: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    pnlspotbtcpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    currentfuturebtc: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    currentspotbusd: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    pnlspotbusdpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    currentfuturebusd: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    pnlfuturebusdpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    currentspotinvestedpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    morethan5percentoftheinvestedamountMap: (f = msg.getMorethan5percentoftheinvestedamountMap()) ? f.toObject(includeInstance, undefined) : [],
    fromtime: jspb.Message.getFieldWithDefault(msg, 26, ""),
    totime: jspb.Message.getFieldWithDefault(msg, 27, ""),
    futureunrealizedpnl: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    spotinvestedagainsttotalbalancepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    futureinvestedagainsttotalbalancepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    exposurepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecutorPNLResponse}
 */
proto.executor.ExecutorPNLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecutorPNLResponse;
  return proto.executor.ExecutorPNLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecutorPNLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecutorPNLResponse}
 */
proto.executor.ExecutorPNLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrevioustotalusdtvalue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrenttotalusdtvalue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnltotalusdtpercentage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnltotalusdt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPreviousbtcvalue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentbtcvalue(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlbtcpercentage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlbtc(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrevioustotalbusdvalue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrenttotalbusdvalue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnltotalbusdpercentage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnltotalbusd(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentspotusdt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlspotusdtpercentage(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentfutureusdt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlfutureusdtpercentage(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentspotbtc(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlspotbtcpercentage(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentfuturebtc(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentspotbusd(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlspotbusdpercentage(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentfuturebusd(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnlfuturebusdpercentage(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentspotinvestedpercentage(value);
      break;
    case 25:
      var value = msg.getMorethan5percentoftheinvestedamountMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromtime(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotime(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFutureunrealizedpnl(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpotinvestedagainsttotalbalancepercentage(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFutureinvestedagainsttotalbalancepercentage(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExposurepercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecutorPNLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecutorPNLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecutorPNLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecutorPNLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrevioustotalusdtvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrenttotalusdtvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPnltotalusdtpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPnltotalusdt();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPreviousbtcvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrentbtcvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPnlbtcpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPnlbtc();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPrevioustotalbusdvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCurrenttotalbusdvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPnltotalbusdpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPnltotalbusd();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCurrentspotusdt();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getPnlspotusdtpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getCurrentfutureusdt();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getPnlfutureusdtpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getCurrentspotbtc();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPnlspotbtcpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getCurrentfuturebtc();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getCurrentspotbusd();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getPnlspotbusdpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getCurrentfuturebusd();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getPnlfuturebusdpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getCurrentspotinvestedpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getMorethan5percentoftheinvestedamountMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(25, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getFromtime();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTotime();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getFutureunrealizedpnl();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getSpotinvestedagainsttotalbalancepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getFutureinvestedagainsttotalbalancepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getExposurepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
};


/**
 * optional double previousTotalUsdtValue = 1;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPrevioustotalusdtvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPrevioustotalusdtvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double currentTotalUsdtValue = 2;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrenttotalusdtvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrenttotalusdtvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double pnlTotalUsdtPercentage = 3;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnltotalusdtpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnltotalusdtpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double pnlTotalUsdt = 4;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnltotalusdt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnltotalusdt = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double previousBtcValue = 5;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPreviousbtcvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPreviousbtcvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double currentBtcValue = 6;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentbtcvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentbtcvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double pnlBtcPercentage = 7;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlbtcpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlbtcpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double pnlBtc = 8;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlbtc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlbtc = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double previousTotalBusdValue = 9;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPrevioustotalbusdvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPrevioustotalbusdvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double currentTotalBusdValue = 10;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrenttotalbusdvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrenttotalbusdvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double pnlTotalBusdPercentage = 11;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnltotalbusdpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnltotalbusdpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double pnlTotalBusd = 12;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnltotalbusd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnltotalbusd = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double currentSpotUSDT = 13;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentspotusdt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentspotusdt = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double pnlSpotUsdtPercentage = 14;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlspotusdtpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlspotusdtpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double currentFutureUSDT = 15;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentfutureusdt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentfutureusdt = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double pnlFutureUsdtPercentage = 16;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlfutureusdtpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlfutureusdtpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double currentSpotBTC = 17;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentspotbtc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentspotbtc = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double pnlSpotBtcPercentage = 18;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlspotbtcpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlspotbtcpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double currentFutureBTC = 19;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentfuturebtc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentfuturebtc = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double currentSpotBUSD = 20;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentspotbusd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentspotbusd = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double pnlSpotBusdPercentage = 21;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlspotbusdpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlspotbusdpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double currentFutureBUSD = 22;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentfuturebusd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentfuturebusd = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double pnlFutureBusdPercentage = 23;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getPnlfuturebusdpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setPnlfuturebusdpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double currentSpotInvestedPercentage = 24;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getCurrentspotinvestedpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setCurrentspotinvestedpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * map<string, double> moreThan5PercentOfTheInvestedAmount = 25;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.executor.ExecutorPNLResponse.prototype.getMorethan5percentoftheinvestedamountMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 25, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.clearMorethan5percentoftheinvestedamountMap = function() {
  this.getMorethan5percentoftheinvestedamountMap().clear();
  return this;};


/**
 * optional string fromTime = 26;
 * @return {string}
 */
proto.executor.ExecutorPNLResponse.prototype.getFromtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setFromtime = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string toTime = 27;
 * @return {string}
 */
proto.executor.ExecutorPNLResponse.prototype.getTotime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setTotime = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional double futureUnrealizedPnl = 28;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getFutureunrealizedpnl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setFutureunrealizedpnl = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double spotInvestedAgainstTotalBalancePercentage = 29;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getSpotinvestedagainsttotalbalancepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setSpotinvestedagainsttotalbalancepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double futureInvestedAgainstTotalBalancePercentage = 30;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getFutureinvestedagainsttotalbalancepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setFutureinvestedagainsttotalbalancepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double exposurePercentage = 31;
 * @return {number}
 */
proto.executor.ExecutorPNLResponse.prototype.getExposurepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecutorPNLResponse} returns this
 */
proto.executor.ExecutorPNLResponse.prototype.setExposurepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetRunningSymbolListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetRunningSymbolListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetRunningSymbolListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetRunningSymbolListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetRunningSymbolListRequest}
 */
proto.executor.GetRunningSymbolListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetRunningSymbolListRequest;
  return proto.executor.GetRunningSymbolListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetRunningSymbolListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetRunningSymbolListRequest}
 */
proto.executor.GetRunningSymbolListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetRunningSymbolListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetRunningSymbolListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetRunningSymbolListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetRunningSymbolListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.GetRunningSymbolListResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetRunningSymbolListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetRunningSymbolListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetRunningSymbolListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetRunningSymbolListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsUsdtList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    symbolsBtcList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    symbolsBusdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    futureSymbolsUsdtList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetRunningSymbolListResponse}
 */
proto.executor.GetRunningSymbolListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetRunningSymbolListResponse;
  return proto.executor.GetRunningSymbolListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetRunningSymbolListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetRunningSymbolListResponse}
 */
proto.executor.GetRunningSymbolListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsUsdt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsBtc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbolsBusd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFutureSymbolsUsdt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetRunningSymbolListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetRunningSymbolListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetRunningSymbolListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetRunningSymbolListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsUsdtList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSymbolsBtcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSymbolsBusdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFutureSymbolsUsdtList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string symbols_usdt = 1;
 * @return {!Array<string>}
 */
proto.executor.GetRunningSymbolListResponse.prototype.getSymbolsUsdtList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.setSymbolsUsdtList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.addSymbolsUsdt = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.clearSymbolsUsdtList = function() {
  return this.setSymbolsUsdtList([]);
};


/**
 * repeated string symbols_btc = 2;
 * @return {!Array<string>}
 */
proto.executor.GetRunningSymbolListResponse.prototype.getSymbolsBtcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.setSymbolsBtcList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.addSymbolsBtc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.clearSymbolsBtcList = function() {
  return this.setSymbolsBtcList([]);
};


/**
 * repeated string symbols_busd = 3;
 * @return {!Array<string>}
 */
proto.executor.GetRunningSymbolListResponse.prototype.getSymbolsBusdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.setSymbolsBusdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.addSymbolsBusd = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.clearSymbolsBusdList = function() {
  return this.setSymbolsBusdList([]);
};


/**
 * repeated string future_symbols_usdt = 4;
 * @return {!Array<string>}
 */
proto.executor.GetRunningSymbolListResponse.prototype.getFutureSymbolsUsdtList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.setFutureSymbolsUsdtList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.addFutureSymbolsUsdt = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.GetRunningSymbolListResponse} returns this
 */
proto.executor.GetRunningSymbolListResponse.prototype.clearFutureSymbolsUsdtList = function() {
  return this.setFutureSymbolsUsdtList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.AddSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.AddSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.AddSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.AddSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.AddSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.AddSymbolsRequest}
 */
proto.executor.AddSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.AddSymbolsRequest;
  return proto.executor.AddSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.AddSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.AddSymbolsRequest}
 */
proto.executor.AddSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.AddSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.AddSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.AddSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.AddSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.executor.AddSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.AddSymbolsRequest} returns this
 */
proto.executor.AddSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.AddSymbolsRequest} returns this
 */
proto.executor.AddSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.AddSymbolsRequest} returns this
 */
proto.executor.AddSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.RemoveSymbolRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.RemoveSymbolRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.RemoveSymbolRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.RemoveSymbolRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.RemoveSymbolRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.RemoveSymbolRequest}
 */
proto.executor.RemoveSymbolRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.RemoveSymbolRequest;
  return proto.executor.RemoveSymbolRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.RemoveSymbolRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.RemoveSymbolRequest}
 */
proto.executor.RemoveSymbolRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.RemoveSymbolRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.RemoveSymbolRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.RemoveSymbolRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.RemoveSymbolRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.executor.RemoveSymbolRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.RemoveSymbolRequest} returns this
 */
proto.executor.RemoveSymbolRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.RemoveSymbolRequest} returns this
 */
proto.executor.RemoveSymbolRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.RemoveSymbolRequest} returns this
 */
proto.executor.RemoveSymbolRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.IgnoreSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.IgnoreSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.IgnoreSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.IgnoreSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.IgnoreSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.IgnoreSymbolsRequest}
 */
proto.executor.IgnoreSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.IgnoreSymbolsRequest;
  return proto.executor.IgnoreSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.IgnoreSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.IgnoreSymbolsRequest}
 */
proto.executor.IgnoreSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.IgnoreSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.IgnoreSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.IgnoreSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.IgnoreSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.executor.IgnoreSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.IgnoreSymbolsRequest} returns this
 */
proto.executor.IgnoreSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.IgnoreSymbolsRequest} returns this
 */
proto.executor.IgnoreSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.IgnoreSymbolsRequest} returns this
 */
proto.executor.IgnoreSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.RemoveFromIgnoreSymbolsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.RemoveFromIgnoreSymbolsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.RemoveFromIgnoreSymbolsRequest}
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.RemoveFromIgnoreSymbolsRequest;
  return proto.executor.RemoveFromIgnoreSymbolsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.RemoveFromIgnoreSymbolsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.RemoveFromIgnoreSymbolsRequest}
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.RemoveFromIgnoreSymbolsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.RemoveFromIgnoreSymbolsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbols = 1;
 * @return {!Array<string>}
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.executor.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.executor.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.RemoveFromIgnoreSymbolsRequest} returns this
 */
proto.executor.RemoveFromIgnoreSymbolsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.Symbol.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.Symbol.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.Symbol} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.Symbol.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseasset: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quoteasset: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.Symbol}
 */
proto.executor.Symbol.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.Symbol;
  return proto.executor.Symbol.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.Symbol} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.Symbol}
 */
proto.executor.Symbol.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseasset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteasset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.Symbol.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.Symbol.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.Symbol} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.Symbol.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseasset();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuoteasset();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string symbolName = 1;
 * @return {string}
 */
proto.executor.Symbol.prototype.getSymbolname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.Symbol} returns this
 */
proto.executor.Symbol.prototype.setSymbolname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string baseAsset = 2;
 * @return {string}
 */
proto.executor.Symbol.prototype.getBaseasset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.Symbol} returns this
 */
proto.executor.Symbol.prototype.setBaseasset = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string quoteAsset = 3;
 * @return {string}
 */
proto.executor.Symbol.prototype.getQuoteasset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.Symbol} returns this
 */
proto.executor.Symbol.prototype.setQuoteasset = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetOpenOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetOpenOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetOpenOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetOpenOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteasset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetOpenOrdersRequest}
 */
proto.executor.GetOpenOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetOpenOrdersRequest;
  return proto.executor.GetOpenOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetOpenOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetOpenOrdersRequest}
 */
proto.executor.GetOpenOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteasset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetOpenOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetOpenOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetOpenOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetOpenOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteasset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string quoteAsset = 1;
 * @return {string}
 */
proto.executor.GetOpenOrdersRequest.prototype.getQuoteasset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetOpenOrdersRequest} returns this
 */
proto.executor.GetOpenOrdersRequest.prototype.setQuoteasset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetPnlCalculatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetPnlCalculatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetPnlCalculatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteasset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetPnlCalculatorRequest}
 */
proto.executor.GetPnlCalculatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetPnlCalculatorRequest;
  return proto.executor.GetPnlCalculatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetPnlCalculatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetPnlCalculatorRequest}
 */
proto.executor.GetPnlCalculatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteasset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetPnlCalculatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetPnlCalculatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetPnlCalculatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteasset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string quoteAsset = 1;
 * @return {string}
 */
proto.executor.GetPnlCalculatorRequest.prototype.getQuoteasset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetPnlCalculatorRequest} returns this
 */
proto.executor.GetPnlCalculatorRequest.prototype.setQuoteasset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.NOOP.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.NOOP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.NOOP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.NOOP.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.NOOP}
 */
proto.executor.NOOP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.NOOP;
  return proto.executor.NOOP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.NOOP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.NOOP}
 */
proto.executor.NOOP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.NOOP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.NOOP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.NOOP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.NOOP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.executor.NOOP.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.NOOP} returns this
 */
proto.executor.NOOP.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.OpenOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.OpenOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.OpenOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.OpenOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: (f = msg.getSymbol()) && proto.executor.Symbol.toObject(includeInstance, f),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientorderid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    originalquantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    executedquantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    icebergquantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    cumulativequotequantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    originalquoteorderquantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    timeinforce: jspb.Message.getFieldWithDefault(msg, 13, ""),
    type: jspb.Message.getFieldWithDefault(msg, 14, ""),
    side: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stopprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    isworking: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    currenttime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    executedtime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatetime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.OpenOrder}
 */
proto.executor.OpenOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.OpenOrder;
  return proto.executor.OpenOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.OpenOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.OpenOrder}
 */
proto.executor.OpenOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketname(value);
      break;
    case 3:
      var value = new proto.executor.Symbol;
      reader.readMessage(value,proto.executor.Symbol.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientorderid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOriginalquantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExecutedquantity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIcebergquantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCumulativequotequantity(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOriginalquoteorderquantity(value);
      break;
    case 12:
      var value = /** @type {!proto.executor.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeinforce(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStopprice(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsworking(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrenttime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutedtime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatetime(value);
      break;
    case 21:
      var value = /** @type {!proto.executor.OrderType} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.OpenOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.OpenOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.OpenOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.OpenOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.executor.Symbol.serializeBinaryToWriter
    );
  }
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientorderid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOriginalquantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getExecutedquantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIcebergquantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCumulativequotequantity();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getOriginalquoteorderquantity();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getTimeinforce();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStopprice();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getIsworking();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCurrenttime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getExecutedtime();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUpdatetime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string marketName = 2;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getMarketname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Symbol symbol = 3;
 * @return {?proto.executor.Symbol}
 */
proto.executor.OpenOrder.prototype.getSymbol = function() {
  return /** @type{?proto.executor.Symbol} */ (
    jspb.Message.getWrapperField(this, proto.executor.Symbol, 3));
};


/**
 * @param {?proto.executor.Symbol|undefined} value
 * @return {!proto.executor.OpenOrder} returns this
*/
proto.executor.OpenOrder.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.OpenOrder.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string OrderID = 4;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ClientOrderID = 5;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getClientorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setClientorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double Price = 6;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double OriginalQuantity = 7;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getOriginalquantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setOriginalquantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double ExecutedQuantity = 8;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getExecutedquantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setExecutedquantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double IcebergQuantity = 9;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getIcebergquantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setIcebergquantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double CumulativeQuoteQuantity = 10;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getCumulativequotequantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setCumulativequotequantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double OriginalQuoteOrderQuantity = 11;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getOriginalquoteorderquantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setOriginalquoteorderquantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional Status Status = 12;
 * @return {!proto.executor.Status}
 */
proto.executor.OpenOrder.prototype.getStatus = function() {
  return /** @type {!proto.executor.Status} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.executor.Status} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string TimeInForce = 13;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getTimeinforce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setTimeinforce = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string Type = 14;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Side = 15;
 * @return {string}
 */
proto.executor.OpenOrder.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double StopPrice = 16;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getStopprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setStopprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional bool IsWorking = 17;
 * @return {boolean}
 */
proto.executor.OpenOrder.prototype.getIsworking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setIsworking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int64 CurrentTime = 18;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getCurrenttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setCurrenttime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 ExecutedTime = 19;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getExecutedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setExecutedtime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 UpdateTime = 20;
 * @return {number}
 */
proto.executor.OpenOrder.prototype.getUpdatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setUpdatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional OrderType orderType = 21;
 * @return {!proto.executor.OrderType}
 */
proto.executor.OpenOrder.prototype.getOrdertype = function() {
  return /** @type {!proto.executor.OrderType} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.executor.OrderType} value
 * @return {!proto.executor.OpenOrder} returns this
 */
proto.executor.OpenOrder.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.executor.GetOpenOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetOpenOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetOpenOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetOpenOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetOpenOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    openordersList: jspb.Message.toObjectList(msg.getOpenordersList(),
    proto.executor.OpenOrder.toObject, includeInstance),
    noop: (f = msg.getNoop()) && proto.executor.NOOP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetOpenOrdersResponse}
 */
proto.executor.GetOpenOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetOpenOrdersResponse;
  return proto.executor.GetOpenOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetOpenOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetOpenOrdersResponse}
 */
proto.executor.GetOpenOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.executor.OpenOrder;
      reader.readMessage(value,proto.executor.OpenOrder.deserializeBinaryFromReader);
      msg.addOpenorders(value);
      break;
    case 2:
      var value = new proto.executor.NOOP;
      reader.readMessage(value,proto.executor.NOOP.deserializeBinaryFromReader);
      msg.setNoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetOpenOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetOpenOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetOpenOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetOpenOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenordersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.executor.OpenOrder.serializeBinaryToWriter
    );
  }
  f = message.getNoop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.executor.NOOP.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpenOrder openOrders = 1;
 * @return {!Array<!proto.executor.OpenOrder>}
 */
proto.executor.GetOpenOrdersResponse.prototype.getOpenordersList = function() {
  return /** @type{!Array<!proto.executor.OpenOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.executor.OpenOrder, 1));
};


/**
 * @param {!Array<!proto.executor.OpenOrder>} value
 * @return {!proto.executor.GetOpenOrdersResponse} returns this
*/
proto.executor.GetOpenOrdersResponse.prototype.setOpenordersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.executor.OpenOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.executor.OpenOrder}
 */
proto.executor.GetOpenOrdersResponse.prototype.addOpenorders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.executor.OpenOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.executor.GetOpenOrdersResponse} returns this
 */
proto.executor.GetOpenOrdersResponse.prototype.clearOpenordersList = function() {
  return this.setOpenordersList([]);
};


/**
 * optional NOOP noop = 2;
 * @return {?proto.executor.NOOP}
 */
proto.executor.GetOpenOrdersResponse.prototype.getNoop = function() {
  return /** @type{?proto.executor.NOOP} */ (
    jspb.Message.getWrapperField(this, proto.executor.NOOP, 2));
};


/**
 * @param {?proto.executor.NOOP|undefined} value
 * @return {!proto.executor.GetOpenOrdersResponse} returns this
*/
proto.executor.GetOpenOrdersResponse.prototype.setNoop = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.GetOpenOrdersResponse} returns this
 */
proto.executor.GetOpenOrdersResponse.prototype.clearNoop = function() {
  return this.setNoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.GetOpenOrdersResponse.prototype.hasNoop = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetPnlCalculatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetPnlCalculatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetPnlCalculatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: (f = msg.getSymbol()) && proto.executor.Symbol.toObject(includeInstance, f),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientorderid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    executedquantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    cumulativequantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    cumulativemoneypaid: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currenttime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    executedtime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    updatetime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 14, 0),
    investment: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    pnl: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    noop: (f = msg.getNoop()) && proto.executor.NOOP.toObject(includeInstance, f),
    maxinvestment: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    investment30: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    pnl30: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    maxinvestment30: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    price30: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    desiredInvestment: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    cumulativeMoneyPaid30: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    turnoverDaily: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    turnoverDaily30: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    redFlagged: jspb.Message.getBooleanFieldWithDefault(msg, 27, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetPnlCalculatorResponse}
 */
proto.executor.GetPnlCalculatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetPnlCalculatorResponse;
  return proto.executor.GetPnlCalculatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetPnlCalculatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetPnlCalculatorResponse}
 */
proto.executor.GetPnlCalculatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketname(value);
      break;
    case 3:
      var value = new proto.executor.Symbol;
      reader.readMessage(value,proto.executor.Symbol.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientorderid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExecutedquantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCumulativequantity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCumulativemoneypaid(value);
      break;
    case 10:
      var value = /** @type {!proto.executor.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrenttime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutedtime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatetime(value);
      break;
    case 14:
      var value = /** @type {!proto.executor.OrderType} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInvestment(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnl(value);
      break;
    case 17:
      var value = new proto.executor.NOOP;
      reader.readMessage(value,proto.executor.NOOP.deserializeBinaryFromReader);
      msg.setNoop(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxinvestment(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInvestment30(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPnl30(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxinvestment30(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice30(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDesiredInvestment(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCumulativeMoneyPaid30(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverDaily(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverDaily30(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRedFlagged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetPnlCalculatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetPnlCalculatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetPnlCalculatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.executor.Symbol.serializeBinaryToWriter
    );
  }
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientorderid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getExecutedquantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCumulativequantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCumulativemoneypaid();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getCurrenttime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getExecutedtime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getUpdatetime();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getInvestment();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getPnl();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getNoop();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.executor.NOOP.serializeBinaryToWriter
    );
  }
  f = message.getMaxinvestment();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getInvestment30();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getPnl30();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getMaxinvestment30();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getPrice30();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getDesiredInvestment();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getCumulativeMoneyPaid30();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getTurnoverDaily();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getTurnoverDaily30();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getRedFlagged();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string marketName = 2;
 * @return {string}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getMarketname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setMarketname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Symbol symbol = 3;
 * @return {?proto.executor.Symbol}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getSymbol = function() {
  return /** @type{?proto.executor.Symbol} */ (
    jspb.Message.getWrapperField(this, proto.executor.Symbol, 3));
};


/**
 * @param {?proto.executor.Symbol|undefined} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
*/
proto.executor.GetPnlCalculatorResponse.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.GetPnlCalculatorResponse.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string OrderID = 4;
 * @return {string}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ClientOrderID = 5;
 * @return {string}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getClientorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setClientorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double Price = 6;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double ExecutedQuantity = 7;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getExecutedquantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setExecutedquantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double CumulativeQuantity = 8;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getCumulativequantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setCumulativequantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double CumulativeMoneyPaid = 9;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getCumulativemoneypaid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setCumulativemoneypaid = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional Status Status = 10;
 * @return {!proto.executor.Status}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getStatus = function() {
  return /** @type {!proto.executor.Status} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.executor.Status} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int64 CurrentTime = 11;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getCurrenttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setCurrenttime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 ExecutedTime = 12;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getExecutedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setExecutedtime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 UpdateTime = 13;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getUpdatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setUpdatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional OrderType orderType = 14;
 * @return {!proto.executor.OrderType}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getOrdertype = function() {
  return /** @type {!proto.executor.OrderType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.executor.OrderType} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional double investment = 15;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getInvestment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setInvestment = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double pnl = 16;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getPnl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setPnl = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional NOOP noop = 17;
 * @return {?proto.executor.NOOP}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getNoop = function() {
  return /** @type{?proto.executor.NOOP} */ (
    jspb.Message.getWrapperField(this, proto.executor.NOOP, 17));
};


/**
 * @param {?proto.executor.NOOP|undefined} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
*/
proto.executor.GetPnlCalculatorResponse.prototype.setNoop = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.clearNoop = function() {
  return this.setNoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.GetPnlCalculatorResponse.prototype.hasNoop = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional double maxInvestment = 18;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getMaxinvestment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setMaxinvestment = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double investment30 = 19;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getInvestment30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setInvestment30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double pnl30 = 20;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getPnl30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setPnl30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double maxInvestment30 = 21;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getMaxinvestment30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setMaxinvestment30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double price30 = 22;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getPrice30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setPrice30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double desired_investment = 23;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getDesiredInvestment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setDesiredInvestment = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double cumulative_money_paid30 = 24;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getCumulativeMoneyPaid30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setCumulativeMoneyPaid30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double turnover_daily = 25;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getTurnoverDaily = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setTurnoverDaily = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double turnover_daily30 = 26;
 * @return {number}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getTurnoverDaily30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setTurnoverDaily30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional bool red_flagged = 27;
 * @return {boolean}
 */
proto.executor.GetPnlCalculatorResponse.prototype.getRedFlagged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.executor.GetPnlCalculatorResponse} returns this
 */
proto.executor.GetPnlCalculatorResponse.prototype.setRedFlagged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.GetPnlCalculatorMapsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.GetPnlCalculatorMapsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorMapsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotMap: (f = msg.getSpotMap()) ? f.toObject(includeInstance, proto.executor.GetPnlCalculatorResponse.toObject) : [],
    futureMap: (f = msg.getFutureMap()) ? f.toObject(includeInstance, proto.executor.GetPnlCalculatorResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.GetPnlCalculatorMapsResponse}
 */
proto.executor.GetPnlCalculatorMapsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.GetPnlCalculatorMapsResponse;
  return proto.executor.GetPnlCalculatorMapsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.GetPnlCalculatorMapsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.GetPnlCalculatorMapsResponse}
 */
proto.executor.GetPnlCalculatorMapsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSpotMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.executor.GetPnlCalculatorResponse.deserializeBinaryFromReader, "", new proto.executor.GetPnlCalculatorResponse());
         });
      break;
    case 2:
      var value = msg.getFutureMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.executor.GetPnlCalculatorResponse.deserializeBinaryFromReader, "", new proto.executor.GetPnlCalculatorResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.GetPnlCalculatorMapsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.GetPnlCalculatorMapsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.GetPnlCalculatorMapsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.executor.GetPnlCalculatorResponse.serializeBinaryToWriter);
  }
  f = message.getFutureMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.executor.GetPnlCalculatorResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, GetPnlCalculatorResponse> spot = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.executor.GetPnlCalculatorResponse>}
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.getSpotMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.executor.GetPnlCalculatorResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.executor.GetPnlCalculatorResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.executor.GetPnlCalculatorMapsResponse} returns this
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.clearSpotMap = function() {
  this.getSpotMap().clear();
  return this;};


/**
 * map<string, GetPnlCalculatorResponse> future = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.executor.GetPnlCalculatorResponse>}
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.getFutureMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.executor.GetPnlCalculatorResponse>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.executor.GetPnlCalculatorResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.executor.GetPnlCalculatorMapsResponse} returns this
 */
proto.executor.GetPnlCalculatorMapsResponse.prototype.clearFutureMap = function() {
  this.getFutureMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecuteOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecuteOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecuteOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecuteOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: (f = msg.getSymbol()) && proto.executor.Symbol.toObject(includeInstance, f),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecuteOrderRequest}
 */
proto.executor.ExecuteOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecuteOrderRequest;
  return proto.executor.ExecuteOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecuteOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecuteOrderRequest}
 */
proto.executor.ExecuteOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderType(value);
      break;
    case 2:
      var value = new proto.executor.Symbol;
      reader.readMessage(value,proto.executor.Symbol.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {!proto.executor.OrderType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecuteOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecuteOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecuteOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecuteOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.executor.Symbol.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string order_type = 1;
 * @return {string}
 */
proto.executor.ExecuteOrderRequest.prototype.getOrderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.ExecuteOrderRequest} returns this
 */
proto.executor.ExecuteOrderRequest.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Symbol symbol = 2;
 * @return {?proto.executor.Symbol}
 */
proto.executor.ExecuteOrderRequest.prototype.getSymbol = function() {
  return /** @type{?proto.executor.Symbol} */ (
    jspb.Message.getWrapperField(this, proto.executor.Symbol, 2));
};


/**
 * @param {?proto.executor.Symbol|undefined} value
 * @return {!proto.executor.ExecuteOrderRequest} returns this
*/
proto.executor.ExecuteOrderRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.ExecuteOrderRequest} returns this
 */
proto.executor.ExecuteOrderRequest.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.ExecuteOrderRequest.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.executor.ExecuteOrderRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.executor.ExecuteOrderRequest} returns this
 */
proto.executor.ExecuteOrderRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional OrderType type = 4;
 * @return {!proto.executor.OrderType}
 */
proto.executor.ExecuteOrderRequest.prototype.getType = function() {
  return /** @type {!proto.executor.OrderType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.executor.OrderType} value
 * @return {!proto.executor.ExecuteOrderRequest} returns this
 */
proto.executor.ExecuteOrderRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.ExecuteOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.ExecuteOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.ExecuteOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecuteOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.executor.OpenOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.ExecuteOrderResponse}
 */
proto.executor.ExecuteOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.ExecuteOrderResponse;
  return proto.executor.ExecuteOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.ExecuteOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.ExecuteOrderResponse}
 */
proto.executor.ExecuteOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.executor.OpenOrder;
      reader.readMessage(value,proto.executor.OpenOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.ExecuteOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.ExecuteOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.ExecuteOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.ExecuteOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.executor.OpenOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional OpenOrder order = 1;
 * @return {?proto.executor.OpenOrder}
 */
proto.executor.ExecuteOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.executor.OpenOrder} */ (
    jspb.Message.getWrapperField(this, proto.executor.OpenOrder, 1));
};


/**
 * @param {?proto.executor.OpenOrder|undefined} value
 * @return {!proto.executor.ExecuteOrderResponse} returns this
*/
proto.executor.ExecuteOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.ExecuteOrderResponse} returns this
 */
proto.executor.ExecuteOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.ExecuteOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.CancelOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.CancelOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.CancelOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.CancelOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: (f = msg.getSymbol()) && proto.executor.Symbol.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.CancelOrderRequest}
 */
proto.executor.CancelOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.CancelOrderRequest;
  return proto.executor.CancelOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.CancelOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.CancelOrderRequest}
 */
proto.executor.CancelOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.executor.Symbol;
      reader.readMessage(value,proto.executor.Symbol.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.CancelOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.CancelOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.CancelOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.CancelOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.executor.Symbol.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.executor.CancelOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.executor.CancelOrderRequest} returns this
 */
proto.executor.CancelOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Symbol symbol = 2;
 * @return {?proto.executor.Symbol}
 */
proto.executor.CancelOrderRequest.prototype.getSymbol = function() {
  return /** @type{?proto.executor.Symbol} */ (
    jspb.Message.getWrapperField(this, proto.executor.Symbol, 2));
};


/**
 * @param {?proto.executor.Symbol|undefined} value
 * @return {!proto.executor.CancelOrderRequest} returns this
*/
proto.executor.CancelOrderRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.executor.CancelOrderRequest} returns this
 */
proto.executor.CancelOrderRequest.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.executor.CancelOrderRequest.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.executor.CancelOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.executor.CancelOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.executor.CancelOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.CancelOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.executor.CancelOrderResponse}
 */
proto.executor.CancelOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.executor.CancelOrderResponse;
  return proto.executor.CancelOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.executor.CancelOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.executor.CancelOrderResponse}
 */
proto.executor.CancelOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.executor.CancelOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.executor.CancelOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.executor.CancelOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.executor.CancelOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.executor.Status = {
  UNKNOWN_ACTION: 0,
  STATUS_NEW: 1,
  PARTIALLY_FILLED: 2,
  FILLED: 3,
  CANCELED: 4,
  PENDING_CANCEL: 5,
  REJECTED: 6,
  EXPIRED: 7,
  STARTED: 8,
  NEW_INSURANCE: 9,
  NEW_ADL: 10
};

/**
 * @enum {number}
 */
proto.executor.OrderType = {
  UNKNOWN_ORDER_TYPE: 0,
  SPOT: 1,
  FUTURE: 2
};

goog.object.extend(exports, proto.executor);
