// @generated by protoc-gen-es v0.2.0 with parameter "target=js+ts+dts"
// @generated from file auth/auth_types.proto (package auth, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {proto3} from "@bufbuild/protobuf";

/**
 * @generated from message auth.LoginRequest
 */
export const LoginRequest = proto3.makeMessageType(
  "auth.LoginRequest",
  () => [
    { no: 1, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roleId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.LoginResponse
 */
export const LoginResponse = proto3.makeMessageType(
  "auth.LoginResponse",
  () => [
    { no: 1, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "needsProfileSetup", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message auth.RegisterRequest
 */
export const RegisterRequest = proto3.makeMessageType(
  "auth.RegisterRequest",
  () => [
    { no: 1, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fullName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nickName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.RegisterResponse
 */
export const RegisterResponse = proto3.makeMessageType(
  "auth.RegisterResponse",
  () => [
    { no: 1, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.ProfileRequest
 */
export const ProfileRequest = proto3.makeMessageType(
  "auth.ProfileRequest",
  () => [
    { no: 1, name: "fullName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.ProfileResponse
 */
export const ProfileResponse = proto3.makeMessageType(
  "auth.ProfileResponse",
  [],
);

/**
 * @generated from message auth.ListAccountsDetailsRequest
 */
export const ListAccountsDetailsRequest = proto3.makeMessageType(
  "auth.ListAccountsDetailsRequest",
  () => [
    { no: 1, name: "accountIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message auth.ListAllAccountsRequest
 */
export const ListAllAccountsRequest = proto3.makeMessageType(
  "auth.ListAllAccountsRequest",
  () => [
    { no: 1, name: "accountIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message auth.Account
 */
export const Account = proto3.makeMessageType(
  "auth.Account",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.AccountAndRoles
 */
export const AccountAndRoles = proto3.makeMessageType(
  "auth.AccountAndRoles",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "roles", kind: "message", T: RoleDto, repeated: true },
  ],
);

/**
 * @generated from message auth.ListAccountsDetailsResponse
 */
export const ListAccountsDetailsResponse = proto3.makeMessageType(
  "auth.ListAccountsDetailsResponse",
  () => [
    { no: 1, name: "accounts", kind: "message", T: Account, repeated: true },
  ],
);

/**
 * @generated from message auth.ListAllAccountsResponse
 */
export const ListAllAccountsResponse = proto3.makeMessageType(
  "auth.ListAllAccountsResponse",
  () => [
    { no: 1, name: "accounts", kind: "message", T: AccountAndRoles, repeated: true },
  ],
);

/**
 * @generated from message auth.GetDefaultRolesRequest
 */
export const GetDefaultRolesRequest = proto3.makeMessageType(
  "auth.GetDefaultRolesRequest",
  [],
);

/**
 * @generated from message auth.RoleDto
 */
export const RoleDto = proto3.makeMessageType(
  "auth.RoleDto",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.GetDefaultRolesResponse
 */
export const GetDefaultRolesResponse = proto3.makeMessageType(
  "auth.GetDefaultRolesResponse",
  () => [
    { no: 1, name: "roles", kind: "message", T: RoleDto, repeated: true },
  ],
);

/**
 * @generated from message auth.GetAllRolesRequest
 */
export const GetAllRolesRequest = proto3.makeMessageType(
  "auth.GetAllRolesRequest",
  [],
);

/**
 * @generated from message auth.GetAllRolesResponse
 */
export const GetAllRolesResponse = proto3.makeMessageType(
  "auth.GetAllRolesResponse",
  () => [
    { no: 1, name: "roles", kind: "message", T: RoleDto, repeated: true },
  ],
);

/**
 * @generated from message auth.TenantCreateApiKeyRequest
 */
export const TenantCreateApiKeyRequest = proto3.makeMessageType(
  "auth.TenantCreateApiKeyRequest",
  () => [
    { no: 1, name: "accountId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.TenantCreateApiKeyResponse
 */
export const TenantCreateApiKeyResponse = proto3.makeMessageType(
  "auth.TenantCreateApiKeyResponse",
  () => [
    { no: 1, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.UpdateAccountRoleRequest
 */
export const UpdateAccountRoleRequest = proto3.makeMessageType(
  "auth.UpdateAccountRoleRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "message", T: RoleDto },
  ],
);

/**
 * @generated from message auth.UpdateAccountRoleResponse
 */
export const UpdateAccountRoleResponse = proto3.makeMessageType(
  "auth.UpdateAccountRoleResponse",
  () => [
    { no: 1, name: "account", kind: "message", T: Account },
    { no: 2, name: "roles", kind: "message", T: RoleDto, repeated: true },
  ],
);

