import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-simple-toasts';
import TimePicker from 'react-time-picker';
import Toggle from 'react-toggle';

import { selectAccessToken } from '../../../app/redux/authorization.slice.reducer';
import { deleteFile, selectFile } from '../../../app/redux/file.slice.reducer';
import {
  addPnlMaps,
  addPnlTrade,
  pnlTradesInterface,
  selectPnlCalcTrades,
} from '../../../app/redux/pnlCalcTrades.slice.reducer';
import { pricesInterface, selectPrices, setPrices } from '../../../app/redux/prices.slice.reducer';
import { selectRedFlags, setRedFlags } from '../../../app/redux/redFlags.slice.reducer';
import { logger } from '../../../config/Logger';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { ExecutorHelper } from '../../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../../pkg/apiHelpers/harvesterHelper';
import { GetPnlCalculatorResponse } from '../../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import { PriceTickerResponse } from '../../../pkg/protobuf/v2/harvester/tr_harvester_types_pb';
import styles from './PnlCalculatorFragment.module.scss';

type Sorting = 'coin' | 'investment' | 'desired' | 'absoluteT' | 'pnl' | 'price' | 'disabled';

const PnlCalculatorFragment = (props: { isRightSectionOpen: any; setIsRightSectionOpen: any; account: any }) => {
  const pnlTrades = useSelector(selectPnlCalcTrades);
  const redFlags = useSelector(selectRedFlags);
  const userToken = useSelector(selectAccessToken);
  const prices = useSelector(selectPrices);
  const file = useSelector(selectFile);
  const [downloadUrl, setDownloadUrl] = useState('');
  const tradesMap = pnlTrades?.find((t: any) => {
    return t.accountKey === props.account.name;
  });
  const [sortedSpot, setSortedSpot] = useState<any>(tradesMap?.spot);
  const [ascSpot, setAscSpot] = useState<-1 | 1>(1);
  const [ascFuture, setAscFuture] = useState<-1 | 1>(1);
  const [sortSpot, setSortSpot] = useState<Sorting>('coin');
  const [sortFuture, setSortFuture] = useState<Sorting>('coin');
  const [sortedFuture, setSortedFuture] = useState<any>(tradesMap?.future);
  const [is30MinutePnl, setIs30MinutePnl] = useState<boolean>(false);
  const [pnlSum, setPnlSum] = useState<number>(0);
  const [pnlSumFuture, setPnlSumFuture] = useState<number>(0);
  const [absoluteTurnoverSum, setAbsoluteTurnoverSum] = useState<number>(0);
  const [absoluteTurnoverSumFuture, setAbsoluteTurnoverSumFuture] = useState<number>(0);
  const [redFlagsState, setRedFlagsState] = useState<{ spot: string[]; future: string[] }>({ spot: [], future: [] });
  const [disabledCoinsState, setDisabledCoinsState] = useState<string[]>([]);
  const [copied, setCopied] = useState(false);
  const [top5Pnl, setTop5Pnl] = useState(true);
  const [top5Inv, setTop5Inv] = useState(false);
  const [daysBefore, setDaysBefore] = useState<number | string>(0);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState<string | null>(null);
  const dispatch = useDispatch();
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  const NumFormatter1 = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 });
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', dateOptions);
  const pnlCalcStreams: Array<(() => void | null) | undefined> = [];

  const getPnls = async () => {
    const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(props.account?.executor, userToken);
    let asset = '';
    if (executorSymbolsList && executorSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
    else if (executorSymbolsList && executorSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
    else if (executorSymbolsList && executorSymbolsList.symbolsBusd.length > 0) asset = 'BUSD';
    const pnlCalcResponse = await ExecutorHelper.getPnlCalculator(props.account?.executor, userToken);
    dispatch(
      addPnlMaps({
        accountKey: props.account?.name,
        spot: pnlCalcResponse?.spot || [],
        future: pnlCalcResponse?.future || [],
      } as unknown as pnlTradesInterface),
    );
    pnlCalcStreams.push(
      await ExecutorHelper.getPnlCalculatorStream(
        dispatch,
        addPnlTrade,
        props.account?.executor,
        props.account?.name,
        asset,
        userToken,
      ),
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    let redFlagsStream: (() => void | null) | undefined;
    const getPrices = async () => {
      const pricesTicker = await HarvesterHelper.getPriceTickerPromise(2, userToken);
      const pricesTickerInterface: pricesInterface = {
        response: pricesTicker || new PriceTickerResponse(),
      };
      dispatch(setPrices(pricesTickerInterface));
    };
    const init = async () => {
      const tempRedFlags = await BrainHelper.getRedFlags(props.account.brain, userToken);
      if (tempRedFlags && tempRedFlags.spotSymbols && tempRedFlags.futureSymbols) {
        setRedFlagsState({ spot: tempRedFlags.spotSymbols, future: tempRedFlags.futureSymbols });
      }
    };
    const runRedFlagsStream = async () => {
      redFlagsStream = await BrainHelper.getRedFlagsStream(dispatch, setRedFlags, props.account.brain, userToken);
    };

    runRedFlagsStream();
    init();
    getPrices();
    getDisabledCoins();
    getPnls();
    return () => {
      try {
        if (pnlCalcStreams.length > 0) {
          for (const pnlCalcStream of pnlCalcStreams) {
            if (pnlCalcStream) {
              pnlCalcStream();
            }
          }
        }
      } catch (e: any) {
        logger.debug({ message: e });
      }
      try {
        if (redFlagsStream) redFlagsStream();
      } catch (e: any) {
        logger.debug({ message: 'Abort ' + e.message });
      }
      abortController.abort();
    };
  }, [props.account]);

  useEffect(() => {
    if (redFlags && redFlags.spotSymbols && redFlags.futureSymbols) {
      setRedFlagsState({ spot: redFlags.spotSymbols, future: redFlags.futureSymbols });
    }
  }, [redFlags]);

  const getDisabledCoins = async () => {
    const disabledCoins = await ExecutorHelper.GetDisabledCoins(props.account.executor, userToken);
    if (disabledCoins && disabledCoins.symbols) setDisabledCoinsState(disabledCoins.symbols);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const tradesMap = pnlTrades?.find((t: any) => {
      return t.accountKey === props.account.name;
    });
    if (tradesMap) {
      let spotMap = JSON.parse(JSON.stringify(tradesMap.spot, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      if (top5Pnl) {
        const top5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (a[1]?.pnl - (is30MinutePnl ? a[1]?.pnl30 : 0) > b[1]?.pnl - (is30MinutePnl ? b[1]?.pnl30 : 0))
              return -1;
            else return 1;
          })
          .slice(0, 5)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        const last5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (a[1]?.pnl - (is30MinutePnl ? a[1]?.pnl30 : 0) > b[1]?.pnl - (is30MinutePnl ? b[1]?.pnl30 : 0)) return 1;
            else return -1;
          })
          .slice(0, 5)
          .reverse()
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        spotMap = { ...top5, ...last5 };
      } else if (top5Inv) {
        const top5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (
              a[1]?.investment - (is30MinutePnl ? a[1]?.investment30 : 0) >
              b[1]?.investment - (is30MinutePnl ? b[1]?.investment30 : 0)
            )
              return -1;
            else return 1;
          })
          .slice(0, 5)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        const last5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (
              a[1]?.investment - (is30MinutePnl ? a[1]?.investment30 : 0) >
              b[1]?.investment - (is30MinutePnl ? b[1]?.investment30 : 0)
            )
              return 1;
            else return -1;
          })
          .slice(0, 5)
          .reverse()
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        spotMap = { ...top5, ...last5 };
      }
      updateSorting('spot', sortSpot, spotMap, ascSpot, setSortedSpot);
      updateSorting('futures', sortFuture, tradesMap.future, ascFuture, setSortedFuture);
    }
    getDisabledCoins();

    let spotSum = 0;
    let absoluteTurnoverSpotSum = 0;
    if (tradesMap?.spot && Object.keys(tradesMap.spot).length > 0)
      Object.keys(tradesMap.spot)?.forEach((spot) => {
        if (tradesMap.spot[spot]?.pnl) spotSum += tradesMap.spot[spot]?.pnl;
        if (tradesMap.spot[spot]?.turnoverDaily) absoluteTurnoverSpotSum += tradesMap.spot[spot]?.turnoverDaily;
      });
    setPnlSum(spotSum);
    setAbsoluteTurnoverSum(absoluteTurnoverSpotSum);
    let futuresSum = 0;
    let absoluteTurnoverFuturesSum = 0;
    if (tradesMap?.future && Object.keys(tradesMap.future).length > 0)
      Object.keys(tradesMap.future)?.forEach((future) => {
        if (tradesMap.future[future]?.pnl) futuresSum += tradesMap.future[future]?.pnl;
        if (tradesMap.future[future]?.turnoverDaily)
          absoluteTurnoverFuturesSum += tradesMap.future[future]?.turnoverDaily;
      });
    setPnlSumFuture(futuresSum);
    setAbsoluteTurnoverSumFuture(absoluteTurnoverFuturesSum);

    return () => {
      abortController.abort();
    };
  }, [props.account, pnlTrades]);

  useEffect(() => {
    if (tradesMap) {
      let spotMap = JSON.parse(JSON.stringify(tradesMap.spot, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      if (top5Pnl) {
        const top5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (a[1]?.pnl - (is30MinutePnl ? a[1]?.pnl30 : 0) > b[1]?.pnl - (is30MinutePnl ? b[1]?.pnl30 : 0))
              return -1;
            else return 1;
          })
          .slice(0, 5)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        const last5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (a[1]?.pnl - (is30MinutePnl ? a[1]?.pnl30 : 0) > b[1]?.pnl - (is30MinutePnl ? b[1]?.pnl30 : 0)) return 1;
            else return -1;
          })
          .slice(0, 5)
          .reverse()
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        spotMap = { ...top5, ...last5 };
      } else if (top5Inv) {
        const top5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (
              a[1]?.investment - (is30MinutePnl ? a[1]?.investment30 : 0) >
              b[1]?.investment - (is30MinutePnl ? b[1]?.investment30 : 0)
            )
              return -1;
            else return 1;
          })
          .slice(0, 5)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        const last5 = Object.entries(spotMap)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
            if (
              a[1]?.investment - (is30MinutePnl ? a[1]?.investment30 : 0) >
              b[1]?.investment - (is30MinutePnl ? b[1]?.investment30 : 0)
            )
              return 1;
            else return -1;
          })
          .slice(0, 5)
          .reverse()
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        spotMap = { ...top5, ...last5 };
      }
      updateSorting('spot', sortSpot, spotMap, ascSpot, setSortedSpot);
    }
  }, [sortSpot, ascSpot, is30MinutePnl, top5Pnl, top5Inv]);

  useEffect(() => {
    if (tradesMap) {
      updateSorting('futures', sortFuture, tradesMap.future, ascFuture, setSortedFuture);
    }
  }, [sortFuture, ascFuture, is30MinutePnl]);

  useEffect(() => {
    if (file.shouldDownload) {
      const blob = new Blob([new Uint8Array(file.chunk, 0, file.size)], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.filename + '.' + file.extension);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      if (file?.filename && file.filename.trim() !== '') link.click();
      else toast('No file to download');

      // Clean up and remove the link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.parentNode.removeChild(link);
      dispatch(deleteFile());
    }
  }, [file]);

  const updateSorting = (type: string, sortSwitch: Sorting, object: any, sort: -1 | 1, setSortObject: any) => {
    switch (sortSwitch) {
      case 'coin':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  if (
                    a[1]?.symbol?.symbolName &&
                    b[1]?.symbol?.symbolName &&
                    a[1]?.symbol?.symbolName < b[1]?.symbol?.symbolName
                  )
                    return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'investment':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  if (
                    a[1]?.investment - (is30MinutePnl ? a[1]?.investment30 : 0) >
                    b[1]?.investment - (is30MinutePnl ? b[1]?.investment30 : 0)
                  )
                    return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'desired':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  if (a[1]?.desiredInvestment > b[1]?.desiredInvestment) return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'absoluteT':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  if (
                    a[1]?.turnoverDaily - (is30MinutePnl ? a[1]?.turnoverDaily30 : 0) >
                    b[1]?.turnoverDaily - (is30MinutePnl ? b[1]?.turnoverDaily30 : 0)
                  )
                    return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'pnl':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  if (a[1]?.pnl - (is30MinutePnl ? a[1]?.pnl30 : 0) > b[1]?.pnl - (is30MinutePnl ? b[1]?.pnl30 : 0))
                    return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'price':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  const x = Object.entries(
                    type === 'spot' ? prices?.response?.spotPriceMap : prices?.response?.futurePriceMap,
                  ).filter((price: any) => {
                    return price[0] === a[1].symbol?.symbolName;
                  })[0];
                  const y = Object.entries(
                    type === 'spot' ? prices?.response?.spotPriceMap : prices?.response?.futurePriceMap,
                  ).filter((price: any) => {
                    return price[0] === b[1].symbol?.symbolName;
                  })[0];
                  const pricePerCoinA: any = x && x.length > 0 ? x[1] : 0;
                  const pricePerCoinB: any = y && y.length > 0 ? y[1] : 0;
                  const changePercentA =
                    (a[1].Price - (is30MinutePnl ? a[1].price30 : pricePerCoinA.openPrice)) /
                    (is30MinutePnl ? a[1].price30 : pricePerCoinA.openPrice);
                  const changePercentB =
                    (b[1].Price - (is30MinutePnl ? b[1].price30 : pricePerCoinB.openPrice)) /
                    (is30MinutePnl ? b[1].price30 : pricePerCoinB.openPrice);
                  if (changePercentA > changePercentB) return -1 * sort;
                  else return sort;
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      case 'disabled':
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .sort((a: [string, GetPnlCalculatorResponse], b: [string, GetPnlCalculatorResponse]) => {
                  return compareObjects(a, b, disabledCoinsState, sort);
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
      default:
        setSortObject(
          JSON.parse(
            JSON.stringify(
              Object.entries(object)
                .sort()
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
              (_, v) => (typeof v === 'bigint' ? v.toString() : v),
            ),
          ),
        );
        break;
    }
  };

  function compareObjects(
    a: [string, GetPnlCalculatorResponse],
    b: [string, GetPnlCalculatorResponse],
    arr2: string[],
    sort: -1 | 1,
  ): number {
    // Find the index of the first string in arr2 that matches a name property of object a
    const aIndex = arr2.findIndex((str) => a[1]?.symbol?.symbolName === str);

    // Find the index of the first string in arr2 that matches a name property of object b
    const bIndex = arr2.findIndex((str) => b[1]?.symbol?.symbolName === str);

    // If a or b is not in arr2, it will be sorted to the end of the array
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return sort;
    } else if (bIndex === -1) {
      return -1 * sort;
    }

    // If a is in arr2 but b is not, a should come first
    if (aIndex < bIndex) {
      return -1 * sort;
    } else if (aIndex > bIndex) {
      return sort;
    } else {
      return 0;
    }
  }

  const handleDownload = async (id: string, daysBefore: number, time: string | undefined) => {
    try {
      const response = await ExecutorHelper.Download(props.account.executor, id, daysBefore, time, userToken);
      if (response) {
        const blob = new Blob([response.content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.parentNode.removeChild(link);
      } else {
        toast('No file to download');
      }
    } catch (e) {
      logger.error({ message: 'PnlCalculatorFragment.handleDownload' + e });
    }
  };

  const handleCopyCURL = () => {
    const text = `curl --location --request GET '${props.account.executorRest}v1/executor/download?id=yesterday_pnl' \\
--header 'Origin: localhost' \\
--header 'Authorization: Bearer ${userToken}' \\
--output ~/downloads/yesterday_pnl.yml`;
    const input = document.body.appendChild(document.createElement('input'));
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode?.removeChild(input);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div
      className={
        styles.sideSection + ' ' + (props.isRightSectionOpen ? styles.sideSectionOpened : styles.sideSectionClosed)
      }
    >
      <h3>Investments & PNLs</h3>
      <button className={styles.closeBtn} onClick={() => props.setIsRightSectionOpen(false)}>
        X
      </button>
      <div className={styles.accountProfile}>
        <div style={{ justifyContent: 'space-between' }}>
          <div className={styles.name}>
            <img src="/img/profile.svg" alt="profile" />
            <h4>{props.account.name}</h4>
          </div>
          <div className={styles.inputRow}>
            <h4>Toggle 30 minutes Pnl</h4>
            <Toggle
              className={styles.reactToggle}
              checked={is30MinutePnl}
              icons={false}
              onChange={async () => {
                setIs30MinutePnl((prevState) => !prevState);
              }}
            />
          </div>
        </div>
        <h4>Spot PNL sum: {NumFormatter.format(pnlSum || 0)}</h4>
        <h4>Future PNL sum: {NumFormatter.format(pnlSumFuture || 0)}</h4>
        <h4>Spot Absolute Turnover sum: {NumFormatter.format(absoluteTurnoverSum || 0)}</h4>
        <h4>Future Absolute Turnover sum: {NumFormatter.format(absoluteTurnoverSumFuture || 0)}</h4>
        <div className={styles.smallColumn}>
          <h4>
            Last restarted:{' '}
            {tradesMap?.spot &&
              Object.entries(tradesMap?.spot)[0] &&
              Object.entries(tradesMap?.spot)[0].length > 0 &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dateFormatter.format(Number(Object.entries(tradesMap?.spot)[0][1]?.ExecutedTime))}
          </h4>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginBottom: 16 }}>
            <button
              className={styles.button}
              style={{ alignSelf: 'end' }}
              onClick={async () => {
                await handleDownload('yesterday_pnl', 0, undefined);
              }}
            >
              Download Yesterday{"'"}s Pnl File
            </button>
            <div style={{ display: 'flex', gap: 16 }}>
              <div className={styles.datePicker + ' floatingInputContainer'} style={{ display: 'flex' }}>
                <DatePicker
                  className={styles.datePicker}
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date: Date) => {
                    const currentDate = new Date();
                    const timeDiff = currentDate.getTime() - date.getTime();
                    const daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24));
                    setDaysBefore(daysPassed);
                    setStartDate(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <input type={'text'} className={'field-input'} style={{ padding: 0, textAlign: 'center' }} />
                  }
                />
              </div>
              <div className={'floatingInputContainer'} style={{ display: 'flex' }}>
                <TimePicker
                  className={styles.timePicker}
                  clockIcon={null}
                  disableClock={true}
                  onChange={(value) => {
                    setStartTime(value !== null ? value.toString() : null);
                  }}
                  value={startTime}
                />
              </div>
              <button
                className={styles.button}
                style={copied ? { border: '1px solid #39C7D1', color: '#39C7D1' } : {}}
                onClick={async () => {
                  await handleDownload('latest_pnl', Number(daysBefore || 0), startTime?.slice(0, 2));
                }}
              >
                Download pnl file
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4>Spot</h4>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginRight: 24 }}>
            <h4>Toggle By Pnl</h4>
            <Toggle
              className={styles.reactToggle}
              checked={top5Pnl}
              icons={false}
              onChange={async () => {
                setTop5Inv(false);
                setTop5Pnl((prevState) => !prevState);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <h4>Toggle By Investment</h4>
            <Toggle
              className={styles.reactToggle}
              checked={top5Inv}
              icons={false}
              onChange={async () => {
                setTop5Pnl(false);
                setTop5Inv((prevState) => !prevState);
              }}
            />
          </div>
        </div>
        <hr />
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'coin')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('coin');
            }}
          >
            Symbol <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'investment')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('investment');
            }}
          >
            Investment <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'desired')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('desired');
            }}
          >
            Desired Investment <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          <h4>Max Investment</h4>
          <h4>Turnover</h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'absoluteT')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('absoluteT');
            }}
          >
            Absolute Turnover <img width={12} src="/img/sort.png" alt="sort" />
          </h4>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'pnl')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('pnl');
            }}
          >
            Pnl <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'price')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('price');
            }}
          >
            Price % <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          <h4>Flag</h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortSpot === 'disabled')
                setAscSpot((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscSpot(1);
              setSortSpot('disabled');
            }}
          >
            Disabled <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
        </div>
        {sortedSpot &&
        prices?.response?.spotPriceMap &&
        Object.keys(prices?.response?.spotPriceMap).length > 0 &&
        Object.keys(sortedSpot).length > 0 ? (
          Object.keys(sortedSpot)?.map((spotPnls: any, key: number) => {
            if (sortedSpot[spotPnls] && prices?.response?.spotPriceMap) {
              const x = Object.entries(prices?.response?.spotPriceMap).filter((price: any) => {
                return price[0] === sortedSpot[spotPnls].symbol?.symbolName;
              })[0];
              const pricePerCoin: any = x && x.length > 0 ? x[1] : 0;
              const changePercent =
                (sortedSpot[spotPnls].Price - (is30MinutePnl ? sortedSpot[spotPnls].price30 : pricePerCoin.openPrice)) /
                (is30MinutePnl ? sortedSpot[spotPnls].price30 : pricePerCoin.openPrice);
              return (
                <div key={key} className={styles.coinLine}>
                  <h5>{spotPnls}</h5>
                  <h5>{NumFormatter.format(sortedSpot[spotPnls]?.investment)}</h5>
                  <h5>{NumFormatter.format(sortedSpot[spotPnls]?.desiredInvestment)}</h5>
                  <h5>{NumFormatter.format(sortedSpot[spotPnls]?.maxInvestment)}</h5>
                  <h5>
                    {NumFormatter.format(
                      sortedSpot[spotPnls]?.CumulativeMoneyPaid -
                        (is30MinutePnl ? sortedSpot[spotPnls]?.cumulativeMoneyPaid30 : 0),
                    )}
                  </h5>
                  <h5>
                    {NumFormatter.format(
                      sortedSpot[spotPnls]?.turnoverDaily - (is30MinutePnl ? sortedSpot[spotPnls]?.turnoverDaily30 : 0),
                    )}
                  </h5>
                  <h5>
                    {NumFormatter.format(sortedSpot[spotPnls]?.pnl - (is30MinutePnl ? sortedSpot[spotPnls]?.pnl30 : 0))}
                  </h5>
                  <h5>{NumFormatter1.format(changePercent)}</h5>
                  <h5>{redFlagsState?.spot.includes(sortedSpot[spotPnls].symbol?.symbolName) ? 'True' : 'False'}</h5>
                  <h5>{disabledCoinsState.includes(sortedSpot[spotPnls].symbol?.symbolName) ? 'True' : 'False'}</h5>
                </div>
              );
            }
          })
        ) : (
          <div className={styles.accountProfile}>
            <h4>No Positions yet</h4>
          </div>
        )}
        <hr />
        <h4>Future</h4>
        <hr />
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'coin')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('coin');
            }}
          >
            Symbol <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'investment')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('investment');
            }}
          >
            Investment <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'desired')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('desired');
            }}
          >
            Desired Investment <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          <h4>Max Investment</h4>
          <h4>Turnover</h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'absoluteT')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('absoluteT');
            }}
          >
            Absolute Turnover <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'pnl')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('pnl');
            }}
          >
            Pnl <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <h4
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (sortFuture === 'price')
                setAscFuture((prevState) => {
                  return prevState === 1 ? -1 : 1;
                });
              else setAscFuture(1);
              setSortFuture('price');
            }}
          >
            Price % <img width={12} src="/img/sort.png" alt="sort" />
          </h4>
          <h4>Flag</h4>
        </div>
        {sortedFuture &&
        prices?.response?.futurePriceMap &&
        Object.keys(prices?.response?.futurePriceMap).length > 0 &&
        Object.keys(sortedFuture).length > 0 ? (
          Object.keys(sortedFuture)?.map((futurePnls: any, key: number) => {
            if (
              sortedFuture[futurePnls] &&
              (sortedFuture[futurePnls].desiredInvestment !== 0 || sortedFuture[futurePnls].pnl !== 0) &&
              prices?.response?.futurePriceMap
            ) {
              const x = Object.entries(prices?.response?.futurePriceMap).filter((price: any) => {
                return price[0] === sortedFuture[futurePnls].symbol?.symbolName;
              })[0];
              const pricePerCoin: any = x && x.length > 0 ? x[1] : 0;
              const invPercentage: number =
                sortedFuture[futurePnls]?.desiredInvestment / sortedFuture[futurePnls]?.weight;
              const changePercent =
                (sortedFuture[futurePnls].Price -
                  (is30MinutePnl ? sortedFuture[futurePnls].price30 : pricePerCoin.openPrice)) /
                (is30MinutePnl ? sortedFuture[futurePnls].price30 : pricePerCoin.openPrice);
              return (
                <div key={key} className={styles.coinLine}>
                  <h5>{futurePnls}</h5>
                  <h5>{NumFormatter.format(sortedFuture[futurePnls]?.investment)}</h5>
                  <h5
                    className={
                      invPercentage > 0.5 ? (invPercentage > 0.8 ? styles.error : styles.warning) : styles.active
                    }
                  >
                    {NumFormatter.format(sortedFuture[futurePnls]?.desiredInvestment)}
                  </h5>
                  <h5>{NumFormatter.format(sortedFuture[futurePnls]?.maxInvestment)}</h5>
                  <h5>
                    {NumFormatter.format(
                      sortedFuture[futurePnls]?.CumulativeMoneyPaid -
                        (is30MinutePnl ? sortedFuture[futurePnls]?.cumulativeMoneyPaid30 : 0),
                    )}
                  </h5>
                  <h5>
                    {NumFormatter.format(
                      sortedFuture[futurePnls]?.turnoverDaily -
                        (is30MinutePnl ? sortedFuture[futurePnls]?.turnoverDaily30 : 0),
                    )}
                  </h5>
                  <h5>
                    {NumFormatter.format(
                      sortedFuture[futurePnls]?.pnl - (is30MinutePnl ? sortedFuture[futurePnls]?.pnl30 : 0),
                    )}
                  </h5>
                  <h5>{NumFormatter1.format(changePercent)}</h5>
                  <h5>
                    {redFlagsState?.future.includes(sortedFuture[futurePnls].symbol?.symbolName) ? 'True' : 'False'}
                  </h5>
                </div>
              );
            }
          })
        ) : (
          <div className={styles.accountProfile}>
            <h4>No Positions yet</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default PnlCalculatorFragment;
