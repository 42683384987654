import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

import { selectAccessToken, user } from '../../app/redux/authorization.slice.reducer';
import styles from './Navbar.module.scss';

const Navbar = withRouter(({ history }) => {
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const userToken = useSelector(selectAccessToken);
  const decoded: user = jwt_decode(userToken);

  useEffect(() => {
    const roleIndex = decoded.roles?.findIndex((value) => value.name === 'superuser');
    setIsViewOnly(roleIndex < 0);
  }, []);

  return (
    <div className={styles.navbarWrapper}>
      {/*NAVBAR*/}
      <div
        role={'button'}
        tabIndex={0}
        className={styles.logoWrapper}
        onMouseDown={(e) => {
          if (e.nativeEvent.which === 1) history.push('/dashboard');
        }}
      >
        <img src="/img/logo.svg" alt="logo" />
      </div>
      <div
        role={'button'}
        tabIndex={0}
        className={styles.sideNavbar + ' ' + (showMobileNav ? styles.sideNavbarShow : '')}
        onMouseDown={() => setShowMobileNav(false)}
      >
        <div
          role={'button'}
          tabIndex={0}
          className={styles.navItem + ' ' + (location.pathname === '/dashboard' ? styles.navItemActive : '')}
          style={{ backgroundImage: 'url(/img/navbar_dashboard.svg)' }}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) history.push('/dashboard');
          }}
        />
        <div
          role={'button'}
          tabIndex={0}
          className={styles.navItem + ' ' + (location.pathname === '/orders' ? styles.navItemActive : '')}
          style={{ backgroundImage: 'url(/img/navbar_open_orders.svg)' }}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) history.push('/orders');
          }}
        />
        <div
          role={'button'}
          tabIndex={0}
          className={styles.navItem + ' ' + (location.pathname === '/prices' ? styles.navItemActive : '')}
          style={{ backgroundImage: 'url(/img/navbar_prices.svg)' }}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) history.push('/prices');
          }}
        />
        <div
          role={'button'}
          tabIndex={0}
          className={styles.navItem + ' ' + (location.pathname === '/users' ? styles.navItemActive : '')}
          style={{ backgroundImage: 'url(/img/users.svg)' }}
          onMouseDown={(e) => {
            if (e.nativeEvent.which === 1) history.push('/users');
          }}
        />
        <div className={styles.navItem} />
      </div>
      <div
        role={'button'}
        tabIndex={0}
        className={styles.burgerWrapper}
        onMouseDown={() => {
          setShowMobileNav(!showMobileNav);
        }}
      >
        <img src="/img/burger.svg" alt="burger" />
      </div>
    </div>
  );
});

export default Navbar;
