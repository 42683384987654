import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import toast from 'react-simple-toasts';
import Toggle from 'react-toggle';

import {
  accountInt,
  addAccount,
  addBrainHealth,
  addConfigs,
  addExHealth,
  addFutureAlgoParams,
  addPnls,
  selectAccounts,
  selectPNLDays,
  setAccounts,
} from '../../app/redux/applicationAccount.slice.reducer';
import {
  selectAccessToken,
  setAccessToken,
  setAccessTokenAfterOTP,
  setLoggedIn,
} from '../../app/redux/authorization.slice.reducer';
import { accounts, accountsLocal } from '../../config/accounts';
import { logger } from '../../config/Logger';
import { pnlNames } from '../../config/pnlNames';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { HarvesterHelper } from '../../pkg/apiHelpers/harvesterHelper';
import { BrainFutureAlgoSource, HedgeType } from '../../pkg/protobuf/v2/brain/tr_brain_types_pb';
import {
  ExecutorConfigsRequest,
  ExecutorConfigsResponse,
  GetActualAPIPositionsResponse,
  GetPnlCalculatorMapsResponse,
} from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import InputWithSaveBtn from '../inputs/InputWithSaveBtn';
import CoinSellerModal from '../modal/CoinSellerModal';
import DisableExpansionSymbolsModal from '../modal/DisableExpansionSymbolsModal';
import ErrorModal from '../modal/ErrorModal';
import GenericModal from '../modal/GenericModal';
import RefreshModal from '../modal/RefreshModal';
import SymbolsModal from '../modal/SymbolsModal';
import Navbar from '../navbar/Navbar';
import styles from './Profile.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Profile = withRouter(({ history }) => {
  const [harvesterHealth, setHarvesterHealth] = useState(1);
  const key = history.location.search.replace('?key=', '');
  const account: accountInt = useSelector((state: any) => {
    const allAccounts = selectAccounts(state);
    // all post interactions
    const currentAccount = allAccounts?.filter((acc: accountInt) => acc?.key === key);
    return currentAccount[0];
  });
  const [length, setLength] = useState<string | number>(
    account?.futurealgoparams?.futureAlgoParams ? account.futurealgoparams.futureAlgoParams.length : 0,
  );
  const [lookback, setLookback] = useState<string | number>(
    account?.futurealgoparams?.futureAlgoParams ? account.futurealgoparams.futureAlgoParams.lookBack : 0,
  );
  const [source, setSource] = useState<BrainFutureAlgoSource>(
    account?.futurealgoparams?.futureAlgoParams ? account.futurealgoparams.futureAlgoParams.source : 1,
  );
  const [hedgeType, setHedgeType] = useState<HedgeType>(
    account?.futurealgoparams?.futureAlgoParams ? account.futurealgoparams.futureAlgoParams.hedgeType : 1,
  );
  const [numberOfLevels, setNumberOfLevels] = useState<string | number>(
    account?.futurealgoparams ? account.futurealgoparams.numberOfLevels : 0,
  );
  const [redFlagsNumberOfLevels, setRedFlagsNumberOfLevels] = useState<string | number>(
    account?.futurealgoparams ? account.futurealgoparams.redFlagsNumberOfLevels : 0,
  );
  const [quarterlyTradeOffset, setQuarterlyTradeOffset] = useState<string | number>(
    account?.futurealgoparams ? account.futurealgoparams.quarterlyTradeOffset : 0,
  );
  const [allowFutureExecution, setAllowFutureExecution] = useState<boolean>(
    account?.futurealgoparams?.futureAlgoParams
      ? account.futurealgoparams.futureAlgoParams.allowFutureExecution
      : false,
  );
  const [allowNegativeSignal, setAllowNegativeSignal] = useState<boolean>(
    account?.futurealgoparams?.allowNegativeSignal ? account.futurealgoparams.allowNegativeSignal : false,
  );
  const [disabledQuarterlyLevels, setDisabledQuarterlyLevels] = useState<boolean>(
    account?.futurealgoparams?.disabledQuarterlyLevels ? account.futurealgoparams.disabledQuarterlyLevels : false,
  );
  const [disabledRedFlags, setDisabledRedFlags] = useState<boolean>(
    account?.futurealgoparams?.disabledRedFlags ? account.futurealgoparams.disabledRedFlags : false,
  );
  const [discountFactor, setDiscountFactor] = useState<string | number>(
    account?.futurealgoparams ? account.futurealgoparams.discountFactor : 0.9999,
  );
  const [tradeOffset, setTradeOffset] = useState<string | number>(
    account?.futurealgoparams ? account.futurealgoparams.tradeOffset : 0.0,
  );
  const [btcValue, setBtcValue] = useState<string | number>(
    account?.configs ? account.configs.btcValuePerLevel : 0.0001,
  );
  const [usdtValue, setUsdtValue] = useState<string | number>(
    account?.configs ? account.configs.usdtValuePerLevel : 0.0001,
  );
  const [busdValue, setBusdValue] = useState<string | number>(
    account?.configs ? account.configs.busdValuePerLevel : 0.0001,
  );
  const [lpcMinValue, setLpcMinValue] = useState<string | number>(
    account?.configs ? account.configs.levelsPerCoinMin : 0.0001,
  );
  const [lpcMaxValue, setLpcMaxValue] = useState<string | number>(
    account?.configs ? account.configs.levelsPerCoinMax : 0.0001,
  );
  const [lpcSafetyValue, setLpcSafetyValue] = useState<string | number>(
    account?.configs ? account.configs.levelsPerCoinSafetyFactor : 0.0001,
  );
  const [percentInvestedMax, setPercentInvestedMax] = useState<string | number>(
    account?.configs ? account.configs.percentInvestedMax : 90.0,
  );
  const [percentInvestedMin, setPercentInvestedMin] = useState<string | number>(
    account?.configs ? account.configs.percentInvestedMin : 50.0,
  );
  const [notionalMultiplier, setNotionalMultiplier] = useState<string | number>(
    account?.configs ? account.configs.notionalMultiplier : 0,
  );
  const [targetSpotInvestedPct, setTargetSpotInvestedPct] = useState<string | number>(
    account?.configs ? account.configs.targetSpotInvestedPct : 0,
  );
  const [allowNegativeSignalExec, setAllowNegativeSignalExec] = useState<boolean>(
    account?.configs?.allowNegativeSignal ? account.configs.allowNegativeSignal : false,
  );
  const [allowAboveNotional, setAllowAboveNotional] = useState<boolean>(
    account?.configs?.allowAboveNotional ? account.configs.allowAboveNotional : false,
  );
  const [sourceToBeSaved, setSourceToBeSaved] = useState(false);
  const [isExpansion, setIsExpansion] = useState(account?.configs ? account.configs.disableExpansion : false);
  const [isExecution, setIsExecution] = useState(account?.configs ? account.configs.allowFutureExecution : false);
  const [isHold, setIsHold] = useState(account?.configs ? account.configs.hold : false);
  const [allowZigZag, setAllowZigZag] = useState(account?.configs ? account.configs.allowZigZag : false);
  const [boostVpl, setBoostVpl] = useState(account?.configs ? account.configs.boostVpl : false);
  const [maximizeInvestment, setMaximizeInvestment] = useState(
    account?.configs ? account.configs.maximizeInvestment : false,
  );
  const [isMobileVersionShow, setIsMobileVersionShow] = useState(false);
  const [showSymbolModal, setShowSymbolModal] = useState(false);
  const [showExpansionListModal, setShowExpansionListModal] = useState(false);
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  //states for running symbol list
  const [synchronized, setSynchronized] = useState<boolean>(true);
  const [synchronizedF, setSynchronizedF] = useState<boolean>(true);
  const [symbolsList, setSymbolsList] = useState<string[]>([]);
  const [dESymbolsList, setDESymbolsList] = useState<string[]>([]);
  const [futureSymbolsList, setFutureSymbolsList] = useState<string[]>([]);
  const [brainSymbolsList, setBrainSymbolsList] = useState<string[]>([]);
  const [brainFutureSymbolsList, setBrainFutureSymbolsList] = useState<string[]>([]);
  const [executorSymbolsList, setExecutorSymbolsList] = useState<string[]>([]);
  const [executorWithoutDESymbolsList, setExecutorWithoutDESymbolsList] = useState<string[] | undefined>([]);
  const [executorFutureSymbolsList, setExecutorFutureSymbolsList] = useState<string[]>([]);
  const [searchedSymbolsList, setSearchedSymbolsList] = useState<string[]>([]);
  const [searchedWithoutDESymbolsList, setSearchedWithoutDESymbolsList] = useState<string[]>([]);
  const [searchedFutureSymbolsList, setSearchedFutureSymbolsList] = useState<string[]>([]);
  //Running symbol list that is shown on the screen
  const [runningRenderedSymbolsList, setRenderedRunningSymbolsList] = useState<string[]>([]);
  const [runningRenderedWithoutDESymbolsList, setRenderedRunningWithoutDESymbolsList] = useState<string[]>([]);
  const [runningRenderedFutureSymbolsList, setRenderedRunningFutureSymbolsList] = useState<string[]>([]);
  //Real Running list
  const [runningSymbolsList, setRunningSymbolsList] = useState<string[]>([]);
  const [runningWithoutDESymbolsList, setRunningWithoutDESymbolsList] = useState<string[]>([]);
  const [runningFutureSymbolsList, setRunningFutureSymbolsList] = useState<string[]>([]);
  const [removedSymbolsList, setRemovedSymbolsList] = useState<string[]>([]);
  const [removedWithoutDESymbolsList, setRemovedWithoutDESymbolsList] = useState<string[]>([]);
  const [removedFutureSymbolsList, setRemovedFutureSymbolsList] = useState<string[]>([]);
  const [addedSymbolsList, setAddedSymbolsList] = useState<string[]>([]);
  const [addedWithoutDESymbolsList, setAddedWithoutDESymbolsList] = useState<string[]>([]);
  const [addedFutureSymbolsList, setAddedFutureSymbolsList] = useState<string[]>([]);
  const [asset, setAsset] = useState<string>('');

  const [showRestartModal, setShowRestartModal] = useState<boolean>(false);

  const [restartProgress, setRestartProgress] = useState('');

  //State for Coin Seller Modal
  const [coinsMap, setCoinsMap] = useState<GetPnlCalculatorMapsResponse | undefined>(undefined);
  const [positionsMap, setPositionsMap] = useState<GetActualAPIPositionsResponse | undefined>(undefined);
  const userToken = useSelector(selectAccessToken);
  const days = useSelector(selectPNLDays);
  const dispatch = useDispatch();
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

  const accountsMap = process.env.NODE_ENV === 'production' ? accounts : accountsLocal;

  const sourceOptions = [
    { value: 0, label: 'UNKNOWN' },
    { value: 1, label: 'OPEN' },
    { value: 2, label: 'CLOSE' },
    { value: 3, label: 'HIGH' },
    { value: 4, label: 'LOW' },
  ];

  const hedgeOptions = [
    { value: 0, label: 'UNKNOWN' },
    { value: 1, label: 'SMA' },
    { value: 2, label: 'Always On' },
  ];

  const fetchSymbolLists = async () => {
    try {
      const brainSymbolsList = await BrainHelper.getRunningSymbolList(account?.brain, userToken);
      const executorSymbolsList = await ExecutorHelper.getRunningSymbolList(account?.executor, userToken);
      const executorWithoutDESymbolsList = await ExecutorHelper.getRunningWithoutDESymbolList(
        account?.executor,
        userToken,
      );
      let asset = '';
      if (brainSymbolsList && brainSymbolsList.symbolsBtc.length > 0) asset = 'BTC';
      else if (brainSymbolsList && brainSymbolsList.symbolsUsdt.length > 0) asset = 'USDT';
      // else if (brainSymbolsList && brainSymbolsList.symbolsBusdList.length > 0) asset = 'BUSD';
      setAsset(asset);
      let x = []; //list for symbols from brain
      if (brainSymbolsList && executorSymbolsList && asset === 'BTC') {
        setBrainSymbolsList(brainSymbolsList.symbolsBtc);
        setExecutorSymbolsList(executorSymbolsList.symbolsBtc);
        x = brainSymbolsList.symbolsBtc;
        //y -> list of symbols from executor
        const y = executorSymbolsList.symbolsBtc.filter((e) => !brainSymbolsList.symbolsBtc.includes(e));
        setSynchronized(y.length !== 0);
        setRunningSymbolsList([...x, ...y].sort());
        setRenderedRunningSymbolsList([...x, ...y].sort());
      } else if (brainSymbolsList && executorSymbolsList && asset === 'USDT') {
        setBrainSymbolsList(brainSymbolsList.symbolsUsdt);
        setExecutorSymbolsList(executorSymbolsList.symbolsUsdt);
        x = brainSymbolsList.symbolsUsdt;
        //y -> list of symbols from executor
        const y = executorSymbolsList.symbolsUsdt.filter((e) => !brainSymbolsList.symbolsUsdt.includes(e));
        setSynchronized(y.length !== 0);
        //initializing both running list with brain and executor list
        setRunningSymbolsList([...x, ...y].sort());
        setRenderedRunningSymbolsList([...x, ...y].sort());
        if (executorWithoutDESymbolsList) {
          setExecutorWithoutDESymbolsList(executorWithoutDESymbolsList?.symbolsUsdt);
          setRenderedRunningWithoutDESymbolsList([...executorWithoutDESymbolsList.symbolsUsdt].sort());
          setRunningWithoutDESymbolsList([...executorWithoutDESymbolsList.symbolsUsdt].sort());
          setSearchedWithoutDESymbolsList(executorWithoutDESymbolsList.symbolsUsdt.sort());
        }
      } else if (brainSymbolsList && executorSymbolsList && asset === 'BUSD') {
        setBrainSymbolsList(brainSymbolsList.symbolsBusd);
        setExecutorSymbolsList(executorSymbolsList.symbolsBusd);
        x = brainSymbolsList.symbolsBusd;
        //y -> list of symbols from executor
        const y = executorSymbolsList.symbolsBusd.filter((e) => !brainSymbolsList.symbolsBusd.includes(e));
        setSynchronized(y.length !== 0);
        //initializing both running list with brain and executor list
        setRunningSymbolsList([...x, ...y].sort());
        setRenderedRunningSymbolsList([...x, ...y].sort());
      }
      const supportedListSymbols = await HarvesterHelper.getAllSupportedSymbols(account.server, asset, userToken);
      const dESymbols = await ExecutorHelper.getDESymbolList(account.executor, userToken);
      setSymbolsList(supportedListSymbols.symbols.sort());
      setSearchedSymbolsList(supportedListSymbols.symbols.sort());

      if (dESymbols) {
        setDESymbolsList(dESymbols.symbolsUsdt.sort());
        setSearchedWithoutDESymbolsList((prev) => [...prev, ...(dESymbols?.symbolsUsdt ?? [])].sort());
      }
      //futures
      if (brainSymbolsList && executorSymbolsList) {
        setBrainFutureSymbolsList(brainSymbolsList.futureSymbolsUsdt);
        setExecutorFutureSymbolsList(executorSymbolsList.futureSymbolsUsdt);
        x = brainSymbolsList.futureSymbolsUsdt;
        //y -> list of symbols from executor
        const y = executorSymbolsList.futureSymbolsUsdt.filter((e) => !brainSymbolsList.futureSymbolsUsdt.includes(e));
        setSynchronizedF(y.length !== 0);
        //initializing both running list with brain and executor list
        setRunningFutureSymbolsList([...x, ...y].sort());
        setRenderedRunningFutureSymbolsList([...x, ...y].sort());
      }
      setFutureSymbolsList(supportedListSymbols.futureSymbols.sort());
      setSearchedFutureSymbolsList(supportedListSymbols.futureSymbols.sort());
    } catch (e) {
      logger.error({ message: e });
    }
  };

  const logout = () => {
    dispatch(setLoggedIn(false));
    dispatch(setAccessToken(''));
    dispatch(setAccessTokenAfterOTP(''));
    window.localStorage.clear();
    window.location.pathname = '/';
  };

  const fetchAccount = async () => {
    //deep copied the account object to let me manipulate it
    const x = JSON.parse(JSON.stringify(account, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    //Get executor's health status
    ExecutorHelper.getExecutorHealth(x.executor, userToken)
      .then(async (value) => {
        if (value) x.executorHealth = value;
        dispatch(addExHealth(x));
        //Get pnl data
        const pnlResp = await ExecutorHelper.getPNL(account.executor, days || 0, true, userToken);
        if (pnlResp) {
          x.pnl = pnlResp;
        }
        dispatch(addPnls(x));
        //Get remote configs
        const configsResp = await ExecutorHelper.getRemoteConfigs(account.executor, userToken);
        if (configsResp) x.configs = configsResp;
        dispatch(addConfigs(x));
      })
      .catch((e: any) => {
        if (e.message.includes('permission denied')) setShowErrorModal(true);
        if (e.message.includes('access token is invalid')) logout();
        logger.error({ message: e });
      });
    //Get brain's health status
    BrainHelper.getBrainHealth(account.brain, userToken)
      .then(async (value) => {
        if (value) x.brainHealth = value;
        dispatch(addBrainHealth(x));

        const futureAlgoParamsResp = await BrainHelper.getRemoteConfigs(account.brain, userToken);
        if (futureAlgoParamsResp) {
          x.futurealgoparams.futurealgoparams = futureAlgoParamsResp.futureAlgoParams;
          x.futurealgoparams.allowNegativeSignal = futureAlgoParamsResp.allowNegativeSignal;
          x.futurealgoparams.discountFactor = futureAlgoParamsResp.discountFactor;
          x.futurealgoparams.tradeOffset = futureAlgoParamsResp.tradeOffset;
          x.futurealgoparams.disabledQuarterlyLevels = futureAlgoParamsResp.disabledQuarterlyLevels;
          x.futurealgoparams.disabledRedFlags = futureAlgoParamsResp.disabledRedFlags;
          x.futurealgoparams.numberOfLevels = futureAlgoParamsResp.numberOfLevels;
          x.futurealgoparams.redFlagsNumberOfLevels = futureAlgoParamsResp.redFlagsNumberOfLevels;
          x.futurealgoparams.signalPercentage = futureAlgoParamsResp.quarterlyTradeOffset;
        }
        dispatch(addFutureAlgoParams(x));
      })
      .catch((e: any) => {
        if (e.message.includes('permission denied')) setShowErrorModal(true);
        if (e.message.includes('access token is invalid')) logout();
        logger.error({ message: e });
      });
  };

  async function fetchHarvesterHealth() {
    try {
      const health = await HarvesterHelper.getHarvesterHealth(account.server, userToken);
      if (health) setHarvesterHealth(3);
      else setHarvesterHealth(1);
    } catch (e) {
      logger.error({ message: e });
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (account) {
      fetchAccount();
      fetchSymbolLists();
      fetchHarvesterHealth();
    }
    const accountsInterval = setInterval(() => {
      fetchAccount();
      fetchSymbolLists();
      fetchHarvesterHealth();
    }, 90000);
    return () => {
      clearInterval(accountsInterval);
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!account || key.trim() === '') history.push('/');
  }, [account]);

  const onParameterChange = (e: ChangeEvent<HTMLInputElement>, setToBeSaved: any, setValue: any) => {
    if (setToBeSaved !== undefined) setToBeSaved(true);
    const x = e.target.value;
    if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
    setValue(e.target.value.trim());
  };

  const onSelectOptionChange = (e: any, setToBeSaved: any, setValue: any) => {
    if (setToBeSaved !== undefined) setToBeSaved(true);
    const x = e !== null ? e.value : 0;
    setValue(x);
  };

  const onSaveFutureAlgoParameters = async (saveType?: string) => {
    let allowedSignal = allowNegativeSignal;
    let disableQuarterly = disabledQuarterlyLevels;
    let disableRedFlags = disabledRedFlags;
    if (saveType && saveType === 'allowNegativeSignal') {
      await onUpdateRemoteConfigs('allowNegativeSignal');
      allowedSignal = !allowedSignal;
    }
    if (saveType && saveType === 'disabledQuarterlyLevels') disableQuarterly = !disableQuarterly;
    if (saveType && saveType === 'disabledRedFlags') disableRedFlags = !disableRedFlags;
    try {
      const x = await BrainHelper.setOrUpdateRemoteConfigs(
        account?.brain,
        Number(length),
        Number(lookback),
        source,
        hedgeType,
        allowFutureExecution,
        allowedSignal,
        disableQuarterly,
        disableRedFlags,
        Number(discountFactor),
        Number(tradeOffset),
        Number(numberOfLevels),
        Number(redFlagsNumberOfLevels),
        Number(quarterlyTradeOffset),
        userToken,
      );
      const futureAlgoParams = await BrainHelper.getRemoteConfigs(account.brain, userToken);
      const newAccount: accountInt = JSON.parse(
        JSON.stringify(account, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      newAccount.futurealgoparams = futureAlgoParams;
      if (String(length).trim() === '') setLength(0);
      if (String(lookback).trim() === '') setLookback(0);
      if (String(source).trim() === '') setSource(0);
      if (String(hedgeType).trim() === '') setHedgeType(0);
      if (String(numberOfLevels).trim() === '') setNumberOfLevels(0);
      if (String(redFlagsNumberOfLevels).trim() === '') setRedFlagsNumberOfLevels(0);
      if (String(quarterlyTradeOffset).trim() === '') setQuarterlyTradeOffset(0);
      setAllowNegativeSignal(futureAlgoParams?.allowNegativeSignal || false);
      setDisabledQuarterlyLevels(futureAlgoParams?.disabledQuarterlyLevels || false);
      setDisabledRedFlags(futureAlgoParams?.disabledRedFlags || false);
      dispatch(addAccount(newAccount));
      setSourceToBeSaved(false);
    } catch (e) {
      logger.error({ message: e });
      toast('Future parameters not updated!');
    }
  };

  const onAllowFutureExecution = async () => {
    const allowFuture = !isExecution;
    try {
      await BrainHelper.setOrUpdateRemoteConfigs(
        account?.brain,
        Number(length),
        Number(lookback),
        source,
        hedgeType,
        allowFuture,
        allowNegativeSignal,
        disabledQuarterlyLevels,
        disabledRedFlags,
        Number(discountFactor),
        Number(tradeOffset),
        Number(numberOfLevels),
        Number(redFlagsNumberOfLevels),
        Number(quarterlyTradeOffset),
        userToken,
      );
      const futurealgoparams = await BrainHelper.getRemoteConfigs(account.brain, userToken);
      const newAccount: any = JSON.parse(JSON.stringify(account), (_, v) => (typeof v === 'bigint' ? v.toString() : v));
      newAccount.futureAlgoparams = futurealgoparams?.futureAlgoParams;
      if (String(length).trim() === '') setLength(0);
      if (String(lookback).trim() === '') setLookback(0);
      if (String(source).trim() === '') setSource(0);
      if (String(hedgeType).trim() === '') setHedgeType(0);
      if (String(numberOfLevels).trim() === '') setNumberOfLevels(0);
      if (String(redFlagsNumberOfLevels).trim() === '') setRedFlagsNumberOfLevels(0);
      if (String(quarterlyTradeOffset).trim() === '') setQuarterlyTradeOffset(0);
      dispatch(addAccount(newAccount));
      if (allowFuture !== futurealgoparams?.futureAlgoParams?.allowFutureExecution)
        toast("Brain's allow future execution didn't update. Manual Restart needed! ");
      else setIsExecution(allowFuture);
    } catch (e) {
      logger.error({ message: e });
      toast('Future parameters not updated!');
    }
  };

  const onUpdateRemoteConfigs = async (key: string) => {
    try {
      const executorConfigs: ExecutorConfigsRequest = JSON.parse(
        JSON.stringify(account.configs, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      switch (key) {
        case 'hold':
          executorConfigs.hold = !isHold;
          break;
        case 'allowZigZag':
          executorConfigs.allowZigZag = !allowZigZag;
          break;
        case 'boostVpl':
          executorConfigs.boostVpl = !boostVpl;
          break;
        case 'maximizeInvestment':
          executorConfigs.maximizeInvestment = !maximizeInvestment;
          break;
        case 'allowFutureExecution':
          executorConfigs.allowFutureExecution = !isExecution;
          break;
        case 'disableExpansion':
          executorConfigs.disableExpansion = !isExpansion;
          break;
        case 'btcValuePerLevel':
          executorConfigs.btcValuePerLevel = Number(btcValue);
          break;
        case 'usdtValuePerLevel':
          executorConfigs.usdtValuePerLevel = Number(usdtValue);
          break;
        case 'busdValuePerLevel':
          executorConfigs.busdValuePerLevel = Number(busdValue);
          break;
        case 'lpcMinValue':
          executorConfigs.levelsPerCoinMin = Number(lpcMinValue);
          break;
        case 'lpcMaxValue':
          executorConfigs.levelsPerCoinMax = Number(lpcMaxValue);
          break;
        case 'lpcSafetyValue':
          executorConfigs.levelsPerCoinSafetyFactor = Number(lpcSafetyValue);
          break;
        case 'percentInvestedMax':
          executorConfigs.percentInvestedMax = Number(percentInvestedMax);
          break;
        case 'percentInvestedMin':
          executorConfigs.percentInvestedMin = Number(percentInvestedMin);
          break;
        case 'allowNegativeSignal':
          executorConfigs.allowNegativeSignal = !allowNegativeSignalExec;
          break;
        case 'allowAboveNotional':
          executorConfigs.allowAboveNotional = !allowAboveNotional;
          break;
        case 'notionalMultiplier':
          executorConfigs.notionalMultiplier = Number(notionalMultiplier);
          break;
        case 'targetSpotInvestedPct':
          executorConfigs.targetSpotInvestedPct = Number(targetSpotInvestedPct);
          break;
      }
      await ExecutorHelper.setOrUpdateRemoteConfigs(account.executor, executorConfigs, userToken);
      const configs = await ExecutorHelper.getRemoteConfigs(account.executor, userToken);
      const newAccount: any = JSON.parse(JSON.stringify(account, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      newAccount.configs = configs;
      dispatch(addAccount(newAccount));
      setIsExpansion(configs?.disableExpansion || false);
      setIsExecution(configs?.allowFutureExecution || false);
      setIsHold(configs?.hold || false);
      setAllowZigZag(configs?.allowZigZag || false);
      setBoostVpl(configs?.boostVpl || false);
      setMaximizeInvestment(configs?.maximizeInvestment || false);
      setAllowAboveNotional(configs?.allowAboveNotional || false);
      setAllowNegativeSignalExec(configs?.allowNegativeSignal || false);
    } catch (e) {
      logger.error({ message: e });
    }
  };

  const checkDropletAction = async (dropletId: number, actionId: number) => {
    const url = `https://api.digitalocean.com/v2/droplets/${dropletId}/actions/${actionId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_DIGITAL_OCEAN_API_TOKEN}`,
      },
    });
    if (!response.ok) {
      logger.error({ message: JSON.stringify(response) });
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    logger.log({ message: 'Droplet restart progress:' + data, params: undefined });
    setRestartProgress(data.action.status);
    if (data.action.status === 'in-progress') setTimeout(() => checkDropletAction(dropletId, data.action.id), 3000);
    else {
      toast(
        'Digital Ocean restart action finished for account: ' + account.name + ' with status: ' + data.action.status,
        {
          time: 5000,
        },
      );
      setTimeout(() => setRestartProgress(''), 10000);
    }
  };
  const restartDroplet = async (dropletId: number) => {
    const url = `https://api.digitalocean.com/v2/droplets/${dropletId}/actions`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_DIGITAL_OCEAN_API_TOKEN}`,
        },
        body: JSON.stringify({ type: 'reboot' }), // Use 'power_cycle' for a hard restart
      });

      if (!response.ok) {
        logger.error({ message: JSON.stringify(response) });
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      logger.log({ message: 'Droplet restart initiated:' + data, params: undefined });
      checkDropletAction(dropletId, data.action.id);
    } catch (error) {
      logger.error({ message: 'Error restarting the droplet:' + error });
      toast('Error restarting account: ' + account.name + ' with error: ' + error, { time: 10000 });
    }
  };

  return (
    <div className={styles.profileWrapper}>
      {showRestartModal && (
        <GenericModal
          show={showRestartModal}
          height={'50%'}
          component={() => (
            <div>
              <h4>
                Are you sure you want to restart Server: <strong>{account.name}</strong>
              </h4>
              <div style={{ display: 'flex', gap: 16, marginTop: 32 }}>
                <button
                  className={'button buttonCautious'}
                  onClick={async () => {
                    const index = accountsMap.findIndex((a) => a.key === account.key);
                    if (index > -1 && typeof accountsMap[index].dropletID === 'number') {
                      await restartDroplet(accountsMap[index].dropletID);
                      setTimeout(() => setShowRestartModal(false), 500);
                    } else toast('Droplet ID was not found!');
                  }}
                >
                  Restart
                </button>
                <button className={'button'} onClick={() => setShowRestartModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        />
      )}
      <Navbar />
      <div className={styles.profileContent}>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 16, alignItems: 'center' }}>
          <div className={styles.harvesterHealth}>
            <p>{account.server === 1 ? 'Contabo ' : 'Digital Ocean '}Harvester Health</p>
            <div className={styles.harvesterHealthStatus}>
              <h4
                className={
                  harvesterHealth > 1 ? styles.harvesterHealthStatusActive : styles.harvesterHealthStatusFailure
                }
              >
                {harvesterHealth > 1 ? 'Alive' : 'Failure'}
              </h4>
              {Array(harvesterHealth)
                .fill(0)
                .map((_, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        styles.harvesterHealthLevel +
                        ' ' +
                        (harvesterHealth > 1 ? styles.harvesterHealthLevelActive : styles.harvesterHealthLevelFailure)
                      }
                    />
                  );
                })}
            </div>
          </div>
          <div className={styles.restart}>
            <button onClick={() => setShowRestartModal(true)}>Restart Server</button>
          </div>
          <div
            role={'button'}
            tabIndex={0}
            className={styles.logout}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) logout();
            }}
          >
            <a>Logout</a>
          </div>
        </div>
        {restartProgress !== '' && (
          <div className={styles.progress}>
            <p>Digital Ocean restart progress:</p>
            <p className={restartProgress === 'in-progress' ? styles.inProgress : styles[restartProgress]}>
              {restartProgress}
            </p>
          </div>
        )}
        <div className={styles.mainSection}>
          <div
            className={styles.mobileHeader}
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1) setIsMobileVersionShow(!isMobileVersionShow);
            }}
          >
            <p>Brain and Executor</p>
            <img className={isMobileVersionShow ? styles.arrowUp : ''} src="/img/arrow_down.svg" alt="arrow" />
          </div>
          <div className={styles.profileAndResults}>
            <div className={styles.profile}>
              <div>
                <div className={styles.profileInfo}>
                  <img src="/img/profile.svg" alt="profile" />
                  <h3>{account?.name}</h3>
                </div>
                <div className={styles.globalSymbolsSection}>
                  <button onClick={() => setShowSymbolModal(true)}>
                    <img src="/img/bitcoin.svg" alt="bitcoin" /> <h4>Brain and Executor List</h4>
                  </button>
                  <button onClick={() => setShowExpansionListModal(true)}>
                    <h4>Disable Expansion List</h4>
                    <h5>DE</h5>
                  </button>
                </div>
              </div>
              <hr />
              <div className={styles.profileInvestment}>
                {account?.pnl ? (
                  account.pnl.moreThan5PercentOfTheInvestedAmount ? (
                    <div className={styles.status}>
                      <p>Top 5 Invested</p>
                      {/*{account.pnl.moreThan5PercentOfTheInvestedAmount.length > 0 ? (*/}
                      {/*  <div>*/}
                      {/*    <img src="/img/error.svg" alt="error" />*/}
                      {/*    <span className={styles.over}>Over-invested</span>*/}
                      {/*  </div>*/}
                      {/*) : (*/}
                      {/*  <div>*/}
                      {/*    <span className={styles.normal}>Normal</span>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <button
                        onClick={async () => {
                          const pnlCalcResponse = await ExecutorHelper.getPnlCalculator(account.executor, userToken);
                          const positionsResponse = await ExecutorHelper.GetActualAPIPositions(
                            account.executor,
                            userToken,
                          );
                          setCoinsMap(pnlCalcResponse);
                          setPositionsMap(positionsResponse);
                          setShowSellerModal(true);
                        }}
                      >
                        <h4>Open to sell coin</h4>
                      </button>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {account?.pnl && Object.keys(account.pnl.moreThan5PercentOfTheInvestedAmount).length > 0 ? (
                  <div className={styles.overInvestedCoins}>
                    <div className={styles.columns}>
                      <h4>Invested in</h4>
                      <h4>Amount</h4>
                    </div>
                    {Object.keys(account?.pnl.moreThan5PercentOfTheInvestedAmount).map((coin, index: number) => {
                      return account?.pnl?.moreThan5PercentOfTheInvestedAmount[coin] !== 0 ? (
                        <div key={index} className={styles.coin}>
                          <h5>{coin}</h5>
                          <h5>
                            {NumFormatter.format(account?.pnl?.moreThan5PercentOfTheInvestedAmount[coin] || 0)} {asset}
                          </h5>
                        </div>
                      ) : (
                        ''
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles.results}>
              <div className={styles.resultsTimeSection}>
                <div className={styles.resultsTime}>
                  <p>Sharing results time</p>
                  <h5>From: {account?.pnl?.fromTime}</h5>
                  <h5>To: {account?.pnl?.toTime}</h5>
                </div>
                <div className={styles.refresh}>
                  <h4>Refresh Results</h4>
                  <button className={styles.refreshBtn} onClick={() => setShowRefreshModal(true)}>
                    <img src="/img/refresh.svg" alt="refresh" />
                  </button>
                </div>
              </div>
              <hr />
              <div className={styles.resultsValues}>
                {account && account?.pnl ? (
                  <div className={styles.resultsValue}>
                    <h5>{pnlNames['currentspotinvestedpercentage']}</h5>
                    <h4>{NumFormatter.format(account.pnl.currentSpotInvestedPercentage)}</h4>
                    <div />
                  </div>
                ) : (
                  ''
                )}
                {account && account?.pnl
                  ? Object.keys(Object(account?.pnl)).map((u, key) => {
                      const x: any = account.pnl;
                      if (u.toLowerCase().includes(asset.toLowerCase()) || u.toLowerCase().includes('btc'))
                        return (
                          <div key={key} className={styles.resultsValue}>
                            <h5>{pnlNames[u.toLowerCase()]}</h5>
                            <h4>{NumFormatter.format(x[u])}</h4>
                            <div />
                          </div>
                        );
                    })
                  : ''}
              </div>
            </div>
          </div>
          <div className={styles.brainAndExecutor + ' ' + (isMobileVersionShow ? styles.brainAndExecutorShow : '')}>
            <div className={styles.brain}>
              <div className={styles.brainContent}>
                <img src="/img/brain.svg" alt="brain" />
                <h3>Brain</h3>
              </div>
              <div className={styles.harvesterHealthStatus}>
                <p
                  className={
                    account && account.brainHealth
                      ? styles.harvesterHealthStatusActive
                      : styles.harvesterHealthStatusFailure
                  }
                >
                  {account?.brainHealth ? 'Alive' : 'Failure'}
                </p>
              </div>
              <hr />
              <div className={styles.futureAlg}>
                <p>Future Algorithm Parameters</p>
                <div className={styles.inputRow1}>
                  <div className={'floatingInputContainer floatingInputContainerLighter'}>
                    <input
                      disabled={!isExecution}
                      className="field-input"
                      id="btcValueInput"
                      name="btcValueInput"
                      type="text"
                      placeholder=" "
                      value={length}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onParameterChange(e, setSourceToBeSaved, setLength)
                      }
                    />
                    <label className="floatingInputPlaceholder" htmlFor="btcValueInput">
                      Length
                    </label>
                  </div>
                  <div className={'floatingInputContainer floatingInputContainerLighter'}>
                    <input
                      disabled={!isExecution}
                      className="field-input"
                      id="btcValueInput"
                      name="btcValueInput"
                      type="text"
                      placeholder=" "
                      value={lookback}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onParameterChange(e, setSourceToBeSaved, setLookback)
                      }
                    />
                    <label className="floatingInputPlaceholder" htmlFor="btcValueInput">
                      Lookback
                    </label>
                  </div>
                </div>
                <div className={styles.inputRow2}>
                  <div className={styles.selectWrapper}>
                    <Select
                      isDisabled={!isExecution}
                      className={'react-select'}
                      classNamePrefix={'react-select'}
                      options={sourceOptions}
                      value={sourceOptions[Number(source)]}
                      isClearable={true}
                      isSearchable={true}
                      onChange={(e) => onSelectOptionChange(e, setSourceToBeSaved, setSource)}
                    />
                  </div>
                </div>
                <div className={styles.inputRow3}>
                  <div className={styles.selectWrapper}>
                    <Select
                      isDisabled={!isExecution}
                      className={'react-select'}
                      classNamePrefix={'react-select'}
                      options={hedgeOptions}
                      value={hedgeOptions[Number(hedgeType)]}
                      isClearable={true}
                      isSearchable={true}
                      onChange={(e) => onSelectOptionChange(e, setSourceToBeSaved, setHedgeType)}
                    />
                  </div>
                  <div className={styles.btnWrapper}>
                    <button
                      disabled={!isExecution}
                      className={styles.saveBtn + ' ' + (sourceToBeSaved ? styles.saveBtnActive : '')}
                      onClick={() => onSaveFutureAlgoParameters()}
                    >
                      <img src="/img/save_btn.svg" alt="save" />
                    </button>
                  </div>
                </div>
                <div className={styles.inputRow4}>
                  <InputWithSaveBtn
                    value={discountFactor}
                    setValue={setDiscountFactor}
                    onSaveFunction={onSaveFutureAlgoParameters}
                    saveType={'discountFactor'}
                    labelText={'Discount Factor'}
                    inputId={'discountFactor'}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <InputWithSaveBtn
                    value={tradeOffset}
                    setValue={setTradeOffset}
                    onSaveFunction={onSaveFutureAlgoParameters}
                    saveType={'tradeOffset'}
                    labelText={'Trade Offset'}
                    inputId={'tradeOffset'}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <InputWithSaveBtn
                    value={numberOfLevels}
                    setValue={setNumberOfLevels}
                    onSaveFunction={onSaveFutureAlgoParameters}
                    saveType={'numberOfLevels'}
                    labelText={'Number Of Levels'}
                    inputId={'numberOfLevels'}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <InputWithSaveBtn
                    value={redFlagsNumberOfLevels}
                    setValue={setRedFlagsNumberOfLevels}
                    onSaveFunction={onSaveFutureAlgoParameters}
                    saveType={'redFlagsNumberOfLevels'}
                    labelText={'Red Flags Number of Levels'}
                    inputId={'redFlagsNumberOfLevels'}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <InputWithSaveBtn
                    value={quarterlyTradeOffset}
                    setValue={setQuarterlyTradeOffset}
                    onSaveFunction={onSaveFutureAlgoParameters}
                    saveType={'quarterlyTradeOffset'}
                    labelText={'Quarterly Trade Offset'}
                    inputId={'quarterlyTradeOffset'}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <div>
                    <h4>Allow Negative Signal</h4>
                    <span className={styles.harvesterHealthStatusFailure}>
                      {allowNegativeSignal === allowNegativeSignalExec
                        ? ''
                        : `(Brain: ${allowNegativeSignal} & Executor: ${allowNegativeSignalExec})`}
                    </span>
                  </div>
                  <Toggle
                    checked={allowNegativeSignal}
                    icons={false}
                    onChange={() => {
                      onSaveFutureAlgoParameters('allowNegativeSignal');
                    }}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Disable Quarterly Levels</h4>
                  <Toggle
                    checked={disabledQuarterlyLevels}
                    icons={false}
                    onChange={() => {
                      onSaveFutureAlgoParameters('disabledQuarterlyLevels');
                    }}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Disable Red Flags</h4>
                  <Toggle
                    checked={disabledRedFlags}
                    icons={false}
                    onChange={() => {
                      onSaveFutureAlgoParameters('disabledRedFlags');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.executor}>
              <div className={styles.executorContent}>
                <img src="/img/executor.svg" alt="executor" />
                <h3>Executor</h3>
              </div>
              <div className={styles.harvesterHealthStatus}>
                <p
                  className={
                    account?.executorHealth ? styles.harvesterHealthStatusActive : styles.harvesterHealthStatusFailure
                  }
                >
                  {account?.executorHealth ? 'Alive' : 'Failure'}
                </p>
              </div>
              <button
                className={styles.configSignalBtn + ' button'}
                onClick={async () => {
                  const response = await ExecutorHelper.ResetConfigSignal(account.executor, userToken);
                  if (response?.ok) toast('Resetting signal config completed successfully');
                  else toast('Resetting config had an error: ' + response?.errorMessage);
                }}
              >
                <h4>Reset Config Signal</h4>
              </button>
              <hr />
              <div className={styles.executorInputs}>
                <InputWithSaveBtn
                  value={btcValue}
                  setValue={setBtcValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'btcValuePerLevel'}
                  labelText={'BTC value per level'}
                  inputId={'btcValueInput'}
                />
                <InputWithSaveBtn
                  value={usdtValue}
                  setValue={setUsdtValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'usdtValuePerLevel'}
                  labelText={'USDT value per level'}
                  inputId={'usdtValueInput'}
                />
                <InputWithSaveBtn
                  value={busdValue}
                  setValue={setBusdValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'busdValuePerLevel'}
                  labelText={'BUSD value per level'}
                  inputId={'busdValueInput'}
                />
                <InputWithSaveBtn
                  value={lpcMinValue}
                  setValue={setLpcMinValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'lpcMinValue'}
                  labelText={'Levels Per Coin Min'}
                  inputId={'lpcMinValue'}
                  disabled={allowZigZag}
                />
                <InputWithSaveBtn
                  value={lpcMaxValue}
                  setValue={setLpcMaxValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'lpcMaxValue'}
                  labelText={'Levels Per Coin Max'}
                  inputId={'lpcMaxValue'}
                  disabled={allowZigZag}
                />
                <InputWithSaveBtn
                  value={lpcSafetyValue}
                  setValue={setLpcSafetyValue}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'lpcSafetyValue'}
                  labelText={'Levels Per Coin Safety Factor'}
                  inputId={'lpcSafetyValue'}
                  disabled={allowZigZag}
                />
                <InputWithSaveBtn
                  value={percentInvestedMax}
                  setValue={setPercentInvestedMax}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'percentInvestedMax'}
                  labelText={'Percent Invested Max'}
                  inputId={'percentInvestedMax'}
                />
                <InputWithSaveBtn
                  value={percentInvestedMin}
                  setValue={setPercentInvestedMin}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'percentInvestedMin'}
                  labelText={'Percent Invested Min'}
                  inputId={'percentInvestedMin'}
                />
                <InputWithSaveBtn
                  value={notionalMultiplier}
                  setValue={setNotionalMultiplier}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'notionalMultiplier'}
                  labelText={'Notional Multiplier'}
                  inputId={'notionalMultiplier'}
                />
                <InputWithSaveBtn
                  value={targetSpotInvestedPct}
                  setValue={setTargetSpotInvestedPct}
                  onSaveFunction={onUpdateRemoteConfigs}
                  saveType={'targetSpotInvestedPct'}
                  labelText={'Target Spot Invested Pct'}
                  inputId={'targetSpotInvestedPct'}
                />
                <div className={styles.inputRow2}>
                  <h4>Disable Expansion</h4>
                  <Toggle
                    checked={isExpansion}
                    icons={false}
                    onChange={() => onUpdateRemoteConfigs('disableExpansion')}
                  />
                </div>
                <div className={styles.inputRow3}>
                  <h4>Allow Future Execution</h4>
                  <Toggle
                    checked={isExecution}
                    icons={false}
                    onChange={() => {
                      onUpdateRemoteConfigs('allowFutureExecution');
                      onAllowFutureExecution();
                    }}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <h4>HOLD</h4>
                  <Toggle checked={isHold} icons={false} onChange={() => onUpdateRemoteConfigs('hold')} />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Allow Zig Zag</h4>
                  <Toggle checked={allowZigZag} icons={false} onChange={() => onUpdateRemoteConfigs('allowZigZag')} />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Boost VPL</h4>
                  <Toggle checked={boostVpl} icons={false} onChange={() => onUpdateRemoteConfigs('boostVpl')} />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Maximize Investment</h4>
                  <Toggle
                    checked={maximizeInvestment}
                    icons={false}
                    onChange={() => onUpdateRemoteConfigs('maximizeInvestment')}
                  />
                </div>
                <div className={styles.inputRow4}>
                  <h4>Allow Above Notional</h4>
                  <Toggle
                    checked={allowAboveNotional}
                    icons={false}
                    onChange={() => onUpdateRemoteConfigs('allowAboveNotional')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSymbolModal && (
        <GenericModal
          component={SymbolsModal}
          show={showSymbolModal}
          setEdit={setShowSymbolModal}
          width={'65vw'}
          account={account}
          synchronized={synchronized}
          synchronizedF={synchronizedF}
          symbolsList={symbolsList}
          futureSymbolsList={futureSymbolsList}
          brainSymbolsList={brainSymbolsList}
          brainFutureSymbolsList={brainFutureSymbolsList}
          executorSymbolsList={executorSymbolsList}
          executorFutureSymbolsList={executorFutureSymbolsList}
          searchedSymbolsList={searchedSymbolsList}
          searchedFutureSymbolsList={searchedFutureSymbolsList}
          runningRenderedSymbolsList={runningRenderedSymbolsList}
          runningRenderedFutureSymbolsList={runningRenderedFutureSymbolsList}
          runningSymbolsList={runningSymbolsList}
          runningFutureSymbolsList={runningFutureSymbolsList}
          removedSymbolsList={removedSymbolsList}
          removedFutureSymbolsList={removedFutureSymbolsList}
          addedSymbolsList={addedSymbolsList}
          addedFutureSymbolsList={addedFutureSymbolsList}
          setSynchronized={setSynchronized}
          setSynchronizedF={setSynchronizedF}
          setSymbolsList={setSymbolsList}
          setFutureSymbolsList={setFutureSymbolsList}
          setBrainSymbolsList={setBrainSymbolsList}
          setBrainFutureSymbolsList={setBrainFutureSymbolsList}
          setExecutorSymbolsList={setExecutorSymbolsList}
          setExecutorFutureSymbolsList={setExecutorFutureSymbolsList}
          setSearchedSymbolsList={setSearchedSymbolsList}
          setSearchedFutureSymbolsList={setSearchedFutureSymbolsList}
          setRenderedRunningSymbolsList={setRenderedRunningSymbolsList}
          setRenderedRunningFutureSymbolsList={setRenderedRunningFutureSymbolsList}
          setRunningSymbolsList={setRunningSymbolsList}
          setRunningFutureSymbolsList={setRunningFutureSymbolsList}
          setRemovedSymbolsList={setRemovedSymbolsList}
          setRemovedFutureSymbolsList={setRemovedFutureSymbolsList}
          setAddedSymbolsList={setAddedSymbolsList}
          setAddedFutureSymbolsList={setAddedFutureSymbolsList}
        />
      )}
      {showExpansionListModal && (
        <GenericModal
          component={DisableExpansionSymbolsModal}
          show={showExpansionListModal}
          setEdit={setShowExpansionListModal}
          width={'65vw'}
          account={account}
          symbolsList={symbolsList}
          executorSymbolsList={executorWithoutDESymbolsList}
          searchedSymbolsList={searchedWithoutDESymbolsList}
          runningRenderedSymbolsList={runningRenderedWithoutDESymbolsList}
          runningSymbolsList={runningWithoutDESymbolsList}
          removedSymbolsList={removedWithoutDESymbolsList}
          addedSymbolsList={addedWithoutDESymbolsList}
          dESymbolsList={dESymbolsList}
          setSymbolsList={setSymbolsList}
          setExecutorSymbolsList={setExecutorWithoutDESymbolsList}
          setSearchedSymbolsList={setSearchedWithoutDESymbolsList}
          setRenderedRunningSymbolsList={setRenderedRunningWithoutDESymbolsList}
          setRunningSymbolsList={setRunningWithoutDESymbolsList}
          setRemovedSymbolsList={setRemovedWithoutDESymbolsList}
          setAddedSymbolsList={setAddedWithoutDESymbolsList}
          setDESymbolsList={setDESymbolsList}
        />
      )}
      {showRefreshModal && (
        <GenericModal
          component={RefreshModal}
          show={showRefreshModal}
          setEdit={setShowRefreshModal}
          width={'200px'}
          top={'20%'}
          left={'30%'}
          account={account}
        />
      )}
      {showSellerModal && (
        <GenericModal
          component={CoinSellerModal}
          show={showSellerModal}
          setEdit={setShowSellerModal}
          coinsMap={coinsMap}
          positionsMap={positionsMap}
          width={'70vw'}
          top={'20%'}
          left={'30%'}
          account={account}
        />
      )}
      {showErrorModal && <GenericModal component={ErrorModal} show={showErrorModal} width={'65vw'} />}
    </div>
  );
});
export default Profile;
