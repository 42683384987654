import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { selectAccessToken, selectIsLoggedIn, user } from '../app/redux/authorization.slice.reducer';

const ProtectedRoute = ({ component, ...rest }: any) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userToken = useSelector(selectAccessToken);
  const decoded: user | undefined = userToken !== undefined ? jwt_decode(userToken) : undefined;
  const roleIndex = decoded?.roles?.findIndex((value) => value.name === 'superuser');
  const isViewOnly =
    roleIndex !== undefined &&
    roleIndex < 0 &&
    window.location.pathname !== '/dashboard' &&
    window.location.pathname !== '/login';

  const routeComponent = (props: any) =>
    isLoggedIn && !isViewOnly ? React.createElement(component, props) : <Redirect to={{ pathname: '/login' }} />;
  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
