import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface AuthorizationState {
  loggedIn: boolean;
  streamFired: boolean;
  accessToken: string;
  accessTokenAfterOTP: string;
  tokenExpiryDate: string;
  marriageCode: string;
  user: user;
}

const initialState: any = {
  loggedIn: false,
  user: {
    accountId: '',
    apiId: '',
    connectedAccountId: '',
    email: '',
    exp: 0,
    firstName: '',
    lastName: '',
    nickName: '',
    phoneNumber: '',
    roles: [],
    tenantName: '',
  },
};

interface permissions {
  admin: string;
  affiliate: string;
  connectedService: string;
  host: string;
  superTenantAdmin: boolean;
  visitor: string[];
}

interface roles {
  archived: boolean;
  id: string;
  name: string;
  permissions: permissions[];
}

export interface user {
  accountId: string;
  apiId: string;
  connectedAccountId: string;
  email: string;
  exp: number;
  firstName: string;
  lastName: string;
  nickName: string;
  phoneNumber: string;
  roles: roles[];
  tenantName: string;
}

const initialUserState: user = {
  accountId: '',
  apiId: '',
  connectedAccountId: '',
  email: '',
  exp: 0,
  firstName: '',
  lastName: '',
  nickName: '',
  phoneNumber: '',
  roles: [],
  tenantName: '',
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setAccessTokenAfterOTP: (state, action: PayloadAction<string>) => {
      state.accessTokenAfterOTP = action.payload;
    },
    setTokenExpiryDate: (state, action: PayloadAction<number>) => {
      const date = new Date();
      date.setSeconds(date.getSeconds() + action.payload);
      state.tokenExpiryDate = date.toISOString();
    },
  },
});

export const { setLoggedIn, setAccessToken, setAccessTokenAfterOTP, setTokenExpiryDate } = authorizationSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.authorization.loggedIn;
export const selectAccessToken = (state: RootState) => state.authorization.accessToken;

export default authorizationSlice.reducer;
