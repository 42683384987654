import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface FileInt {
  filename: string;
  extension: string;
  size: number;
  chunk: Array<any>;
  shouldDownload: boolean;
}

export const initialState: any = {
  file: new Object(),
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<FileInt> | undefined) => {
      state.file = action?.payload;
    },
    addChunk: (state, action: PayloadAction<FileInt | undefined>) => {
      const currentState = JSON.parse(JSON.stringify(state.file, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      if (currentState && currentState?.chunk?.length > 0) {
        currentState.chunk = currentState.chunk.concat(action?.payload?.chunk);
        state.file = currentState;
      } else {
        state.file = action?.payload;
      }
    },
    setDownload: (state, action: PayloadAction<boolean> | undefined) => {
      state.file.shouldDownload = action?.payload;
    },
    deleteFile: (state) => {
      state.file = new Object();
    },
  },
});

export const selectFile = (state: RootState) => state.file.file;
export const { setFile, addChunk, setDownload, deleteFile } = fileSlice.actions;

export default fileSlice.reducer;
