// package: harvester
// file: harvester/tr_harvester_service.proto

var harvester_tr_harvester_service_pb = require("../harvester/tr_harvester_service_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var harvester_tr_harvester_types_pb = require("../harvester/tr_harvester_types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var HarvesterService = (function () {
  function HarvesterService() {}
  HarvesterService.serviceName = "harvester.HarvesterService";
  return HarvesterService;
}());

HarvesterService.GetMarketHealthStatus = {
  methodName: "GetMarketHealthStatus",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.HarvHealthStatusRequest,
  responseType: harvester_tr_harvester_types_pb.HarvHealthStatusResponse
};

HarvesterService.GetSpotLastXohlcvData = {
  methodName: "GetSpotLastXohlcvData",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.HarvOHLCVRequest,
  responseType: harvester_tr_harvester_types_pb.HarvOHLCVResponse
};

HarvesterService.GetSpotLastTradeAsOHLCVData = {
  methodName: "GetSpotLastTradeAsOHLCVData",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.LastTradeRequest,
  responseType: harvester_tr_harvester_types_pb.HarvOHLCVResponse
};

HarvesterService.GetFutureLastXohlcvData = {
  methodName: "GetFutureLastXohlcvData",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.HarvOHLCVRequest,
  responseType: harvester_tr_harvester_types_pb.HarvOHLCVResponse
};

HarvesterService.GetFutureLastTradeAsOHLCVData = {
  methodName: "GetFutureLastTradeAsOHLCVData",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.LastTradeRequest,
  responseType: harvester_tr_harvester_types_pb.HarvOHLCVResponse
};

HarvesterService.GetAllSupportedSymbols = {
  methodName: "GetAllSupportedSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.GetAllSupportedSymbolsRequest,
  responseType: harvester_tr_harvester_types_pb.GetAllSupportedSymbolsResponse
};

HarvesterService.GetAllIgnoredSymbols = {
  methodName: "GetAllIgnoredSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.GetAllIgnoredSymbolsRequest,
  responseType: harvester_tr_harvester_types_pb.GetAllIgnoredSymbolsResponse
};

HarvesterService.ReEnableSymbols = {
  methodName: "ReEnableSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.ReEnableSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

HarvesterService.ReEnableFutureSymbols = {
  methodName: "ReEnableFutureSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.ReEnableSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

HarvesterService.RemoveSymbols = {
  methodName: "RemoveSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

HarvesterService.RemoveFutureSymbols = {
  methodName: "RemoveFutureSymbols",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

HarvesterService.GetUpdatedPriceTickerStream = {
  methodName: "GetUpdatedPriceTickerStream",
  service: HarvesterService,
  requestStream: false,
  responseStream: true,
  requestType: harvester_tr_harvester_types_pb.PriceTickerRequest,
  responseType: harvester_tr_harvester_types_pb.PriceTickerResponse
};

HarvesterService.GetUpdatedPriceTickerPeriodicallyStream = {
  methodName: "GetUpdatedPriceTickerPeriodicallyStream",
  service: HarvesterService,
  requestStream: false,
  responseStream: true,
  requestType: harvester_tr_harvester_types_pb.PriceTickerRequest,
  responseType: harvester_tr_harvester_types_pb.PriceTickerResponse
};

HarvesterService.GetPriceTicker = {
  methodName: "GetPriceTicker",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.PriceTickerRequest,
  responseType: harvester_tr_harvester_types_pb.PriceTickerResponse
};

HarvesterService.GetDiskUsage = {
  methodName: "GetDiskUsage",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.GetDiskUsageRequest,
  responseType: harvester_tr_harvester_types_pb.GetDiskUsageResponse
};

HarvesterService.LockBrainSync = {
  methodName: "LockBrainSync",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.BrainSyncRequest,
  responseType: harvester_tr_harvester_types_pb.BrainSyncResponse
};

HarvesterService.UnlockBrainSync = {
  methodName: "UnlockBrainSync",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.BrainSyncRequest,
  responseType: harvester_tr_harvester_types_pb.BrainSyncResponse
};

HarvesterService.GetBrainSync = {
  methodName: "GetBrainSync",
  service: HarvesterService,
  requestStream: false,
  responseStream: false,
  requestType: harvester_tr_harvester_types_pb.BrainSyncRequest,
  responseType: harvester_tr_harvester_types_pb.BrainSyncResponse
};

exports.HarvesterService = HarvesterService;

function HarvesterServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HarvesterServiceClient.prototype.getMarketHealthStatus = function getMarketHealthStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetMarketHealthStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getSpotLastXohlcvData = function getSpotLastXohlcvData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetSpotLastXohlcvData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getSpotLastTradeAsOHLCVData = function getSpotLastTradeAsOHLCVData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetSpotLastTradeAsOHLCVData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getFutureLastXohlcvData = function getFutureLastXohlcvData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetFutureLastXohlcvData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getFutureLastTradeAsOHLCVData = function getFutureLastTradeAsOHLCVData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetFutureLastTradeAsOHLCVData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getAllSupportedSymbols = function getAllSupportedSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetAllSupportedSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getAllIgnoredSymbols = function getAllIgnoredSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetAllIgnoredSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.reEnableSymbols = function reEnableSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.ReEnableSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.reEnableFutureSymbols = function reEnableFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.ReEnableFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.removeSymbols = function removeSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.RemoveSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.removeFutureSymbols = function removeFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.RemoveFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getUpdatedPriceTickerStream = function getUpdatedPriceTickerStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(HarvesterService.GetUpdatedPriceTickerStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getUpdatedPriceTickerPeriodicallyStream = function getUpdatedPriceTickerPeriodicallyStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(HarvesterService.GetUpdatedPriceTickerPeriodicallyStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getPriceTicker = function getPriceTicker(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetPriceTicker, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getDiskUsage = function getDiskUsage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetDiskUsage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.lockBrainSync = function lockBrainSync(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.LockBrainSync, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.unlockBrainSync = function unlockBrainSync(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.UnlockBrainSync, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HarvesterServiceClient.prototype.getBrainSync = function getBrainSync(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HarvesterService.GetBrainSync, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HarvesterServiceClient = HarvesterServiceClient;

