// package: executor
// file: executor/tr_executor_service.proto

var executor_tr_executor_service_pb = require("../executor/tr_executor_service_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var executor_tr_executor_types_pb = require("../executor/tr_executor_types_pb");
var brain_tr_brain_types_pb = require("../brain/tr_brain_types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ExecutorService = (function () {
  function ExecutorService() {}
  ExecutorService.serviceName = "executor.ExecutorService";
  return ExecutorService;
}());

ExecutorService.GetExecutorHealthStatus = {
  methodName: "GetExecutorHealthStatus",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.ExecutorHealthStatusRequest,
  responseType: executor_tr_executor_types_pb.ExecutorHealthStatusResponse
};

ExecutorService.SendSignal = {
  methodName: "SendSignal",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.SignalBase,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.SetOrUpdateRemoteConfigs = {
  methodName: "SetOrUpdateRemoteConfigs",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.ExecutorConfigsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.GetRemoteConfigs = {
  methodName: "GetRemoteConfigs",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: executor_tr_executor_types_pb.ExecutorConfigsResponse
};

ExecutorService.GetPNL = {
  methodName: "GetPNL",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.ExecutorPNLRequest,
  responseType: executor_tr_executor_types_pb.ExecutorPNLResponse
};

ExecutorService.GetRunningSymbolList = {
  methodName: "GetRunningSymbolList",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.GetRunningSymbolListRequest,
  responseType: executor_tr_executor_types_pb.GetRunningSymbolListResponse
};

ExecutorService.GetRunningWithoutDESymbolList = {
  methodName: "GetRunningWithoutDESymbolList",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.GetRunningSymbolListRequest,
  responseType: executor_tr_executor_types_pb.GetRunningSymbolListResponse
};

ExecutorService.GetDESymbolList = {
  methodName: "GetDESymbolList",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.GetRunningSymbolListRequest,
  responseType: executor_tr_executor_types_pb.GetRunningSymbolListResponse
};

ExecutorService.AddSymbols = {
  methodName: "AddSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.AddSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.AddDESymbols = {
  methodName: "AddDESymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.AddSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.AddFutureSymbols = {
  methodName: "AddFutureSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.AddSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.RemoveSymbols = {
  methodName: "RemoveSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.RemoveFromIgnoreSymbols = {
  methodName: "RemoveFromIgnoreSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.RemoveFromIgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.IgnoreSymbols = {
  methodName: "IgnoreSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.IgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.RemoveFromFutureIgnoreSymbols = {
  methodName: "RemoveFromFutureIgnoreSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.RemoveFromIgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.IgnoreFutureSymbols = {
  methodName: "IgnoreFutureSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.IgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.RemoveDESymbols = {
  methodName: "RemoveDESymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.RemoveFutureSymbols = {
  methodName: "RemoveFutureSymbols",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ExecutorService.GetOpenOrders = {
  methodName: "GetOpenOrders",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.GetOpenOrdersRequest,
  responseType: executor_tr_executor_types_pb.GetOpenOrdersResponse
};

ExecutorService.GetOpenOrdersStream = {
  methodName: "GetOpenOrdersStream",
  service: ExecutorService,
  requestStream: false,
  responseStream: true,
  requestType: executor_tr_executor_types_pb.GetOpenOrdersRequest,
  responseType: executor_tr_executor_types_pb.GetOpenOrdersResponse
};

ExecutorService.GetPnlCalculatorStream = {
  methodName: "GetPnlCalculatorStream",
  service: ExecutorService,
  requestStream: false,
  responseStream: true,
  requestType: executor_tr_executor_types_pb.GetPnlCalculatorRequest,
  responseType: executor_tr_executor_types_pb.GetPnlCalculatorResponse
};

ExecutorService.GetPnlCalculator = {
  methodName: "GetPnlCalculator",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.GetPnlCalculatorRequest,
  responseType: executor_tr_executor_types_pb.GetPnlCalculatorMapsResponse
};

ExecutorService.ExecuteOrder = {
  methodName: "ExecuteOrder",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.ExecuteOrderRequest,
  responseType: executor_tr_executor_types_pb.ExecuteOrderResponse
};

ExecutorService.CancelOrder = {
  methodName: "CancelOrder",
  service: ExecutorService,
  requestStream: false,
  responseStream: false,
  requestType: executor_tr_executor_types_pb.CancelOrderRequest,
  responseType: executor_tr_executor_types_pb.CancelOrderResponse
};

exports.ExecutorService = ExecutorService;

function ExecutorServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ExecutorServiceClient.prototype.getExecutorHealthStatus = function getExecutorHealthStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetExecutorHealthStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.sendSignal = function sendSignal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.SendSignal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.setOrUpdateRemoteConfigs = function setOrUpdateRemoteConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.SetOrUpdateRemoteConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getRemoteConfigs = function getRemoteConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetRemoteConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getPNL = function getPNL(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetPNL, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getRunningSymbolList = function getRunningSymbolList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetRunningSymbolList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getRunningWithoutDESymbolList = function getRunningWithoutDESymbolList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetRunningWithoutDESymbolList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getDESymbolList = function getDESymbolList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetDESymbolList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.addSymbols = function addSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.AddSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.addDESymbols = function addDESymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.AddDESymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.addFutureSymbols = function addFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.AddFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.removeSymbols = function removeSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.RemoveSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.removeFromIgnoreSymbols = function removeFromIgnoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.RemoveFromIgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.ignoreSymbols = function ignoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.IgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.removeFromFutureIgnoreSymbols = function removeFromFutureIgnoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.RemoveFromFutureIgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.ignoreFutureSymbols = function ignoreFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.IgnoreFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.removeDESymbols = function removeDESymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.RemoveDESymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.removeFutureSymbols = function removeFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.RemoveFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getOpenOrders = function getOpenOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetOpenOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getOpenOrdersStream = function getOpenOrdersStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ExecutorService.GetOpenOrdersStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getPnlCalculatorStream = function getPnlCalculatorStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ExecutorService.GetPnlCalculatorStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.getPnlCalculator = function getPnlCalculator(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.GetPnlCalculator, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.executeOrder = function executeOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.ExecuteOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ExecutorServiceClient.prototype.cancelOrder = function cancelOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ExecutorService.CancelOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ExecutorServiceClient = ExecutorServiceClient;

