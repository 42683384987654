// @generated by protoc-gen-es v0.2.0 with parameter "target=js+ts+dts"
// @generated from file brain/tr_brain_types.proto (package brain, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {proto3} from "@bufbuild/protobuf";

/**
 * @generated from enum brain.Action
 */
export const Action = proto3.makeEnum(
  "brain.Action",
  [
    {no: 0, name: "UNKNOWN_ACTION"},
    {no: 1, name: "SHORT_ACTION"},
    {no: 2, name: "LONG_ACTION"},
    {no: 3, name: "NOTHING_ACTION"},
  ],
);

/**
 * @generated from enum brain.HedgeType
 */
export const HedgeType = proto3.makeEnum(
  "brain.HedgeType",
  [
    {no: 0, name: "HedgeTypeUnknown"},
    {no: 1, name: "HedgeTypeSMA"},
    {no: 2, name: "HedgeTypeAlwaysOn"},
  ],
);

/**
 * supported algorithms
 *
 * @generated from enum brain.Algorithm
 */
export const Algorithm = proto3.makeEnum(
  "brain.Algorithm",
  [
    {no: 0, name: "UNKNOWN_ALGO"},
    {no: 1, name: "ALGO_C"},
    {no: 2, name: "ALGO_A"},
    {no: 3, name: "ALGO_A_FUTURE"},
  ],
);

/**
 * @generated from enum brain.BrainFutureAlgoSource
 */
export const BrainFutureAlgoSource = proto3.makeEnum(
  "brain.BrainFutureAlgoSource",
  [
    {no: 0, name: "UNKNOWN_SOURCE"},
    {no: 1, name: "OPEN"},
    {no: 2, name: "CLOSE"},
    {no: 3, name: "HIGH"},
    {no: 4, name: "LOW"},
  ],
);

/**
 * @generated from message brain.BrainHealthStatusRequest
 */
export const BrainHealthStatusRequest = proto3.makeMessageType(
  "brain.BrainHealthStatusRequest",
  () => [
    { no: 1, name: "base", kind: "message", T: BrainBase },
  ],
);

/**
 * @generated from message brain.BrainHealthStatusResponse
 */
export const BrainHealthStatusResponse = proto3.makeMessageType(
  "brain.BrainHealthStatusResponse",
  () => [
    { no: 1, name: "base", kind: "message", T: BrainBase },
    { no: 2, name: "alive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.BrainSignalRequest
 */
export const BrainSignalRequest = proto3.makeMessageType(
  "brain.BrainSignalRequest",
  () => [
    { no: 1, name: "base", kind: "message", T: BrainBase },
  ],
);

/**
 * @generated from message brain.BrainSignalResponse
 */
export const BrainSignalResponse = proto3.makeMessageType(
  "brain.BrainSignalResponse",
  () => [
    { no: 1, name: "health", kind: "message", T: BrainHealthStatusResponse },
    { no: 2, name: "data", kind: "message", T: SignalBase, repeated: true },
  ],
);

/**
 * @generated from message brain.SignalBase
 */
export const SignalBase = proto3.makeMessageType(
  "brain.SignalBase",
  () => [
    { no: 1, name: "spotSignal", kind: "message", T: SpotSignal, oneof: "signalElement" },
    { no: 2, name: "futureSignal", kind: "message", T: FutureSignal, oneof: "signalElement" },
    { no: 3, name: "levelsSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "calculationTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "algorithm", kind: "enum", T: proto3.getEnumType(Algorithm) },
    { no: 6, name: "ignored", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "dynamicIgnored", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.SpotSignal
 */
export const SpotSignal = proto3.makeMessageType(
  "brain.SpotSignal",
  () => [
    { no: 1, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "priceSymbol", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "signal", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "ewsor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.FutureSignal
 */
export const FutureSignal = proto3.makeMessageType(
  "brain.FutureSignal",
  () => [
    { no: 1, name: "baseSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "spotPriceSymbol", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "futurePriceSymbol", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "spotPricePerSymbol", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
    { no: 5, name: "signal", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "action", kind: "enum", T: proto3.getEnumType(Action) },
    { no: 7, name: "hedgeType", kind: "enum", T: proto3.getEnumType(HedgeType) },
    { no: 8, name: "delete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.BrainBase
 */
export const BrainBase = proto3.makeMessageType(
  "brain.BrainBase",
  () => [
    { no: 1, name: "brainUUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "brainDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "algorithm", kind: "enum", T: proto3.getEnumType(Algorithm) },
  ],
);

/**
 * @generated from message brain.BrainConfigsRequest
 */
export const BrainConfigsRequest = proto3.makeMessageType(
  "brain.BrainConfigsRequest",
  () => [
    { no: 1, name: "futureAlgoParams", kind: "message", T: BrainFutureAlgoParams },
    { no: 2, name: "allow_negative_signal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "discount_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "trade_offset", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "disabled_quarterly_levels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "disabled_red_flags", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "number_of_levels", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "red_flags_number_of_levels", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "quarterly_trade_offset", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.BrainConfigsResponse
 */
export const BrainConfigsResponse = proto3.makeMessageType(
  "brain.BrainConfigsResponse",
  () => [
    { no: 1, name: "futureAlgoParams", kind: "message", T: BrainFutureAlgoParams },
    { no: 2, name: "allow_negative_signal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "discount_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "trade_offset", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "disabled_quarterly_levels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "disabled_red_flags", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "number_of_levels", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "red_flags_number_of_levels", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "quarterly_trade_offset", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.BrainFutureAlgoParams
 */
export const BrainFutureAlgoParams = proto3.makeMessageType(
  "brain.BrainFutureAlgoParams",
  () => [
    { no: 1, name: "length", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "lookBack", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(BrainFutureAlgoSource) },
    { no: 4, name: "allowFutureExecution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "hedgeType", kind: "enum", T: proto3.getEnumType(HedgeType) },
  ],
);

/**
 * @generated from message brain.GetRunningSymbolListRequest
 */
export const GetRunningSymbolListRequest = proto3.makeMessageType(
  "brain.GetRunningSymbolListRequest",
  [],
);

/**
 * @generated from message brain.GetRunningSymbolListResponse
 */
export const GetRunningSymbolListResponse = proto3.makeMessageType(
  "brain.GetRunningSymbolListResponse",
  () => [
    { no: 1, name: "symbols_usdt", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "symbols_btc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "symbols_busd", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "future_symbols_usdt", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.AddSymbolsRequest
 */
export const AddSymbolsRequest = proto3.makeMessageType(
  "brain.AddSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.RemoveSymbolRequest
 */
export const RemoveSymbolRequest = proto3.makeMessageType(
  "brain.RemoveSymbolRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.IgnoreSymbolsRequest
 */
export const IgnoreSymbolsRequest = proto3.makeMessageType(
  "brain.IgnoreSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.RemoveFromIgnoreSymbolsRequest
 */
export const RemoveFromIgnoreSymbolsRequest = proto3.makeMessageType(
  "brain.RemoveFromIgnoreSymbolsRequest",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.GetRedFlagsRequest
 */
export const GetRedFlagsRequest = proto3.makeMessageType(
  "brain.GetRedFlagsRequest",
  () => [
    { no: 1, name: "quote_asset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetRedFlagsResponse
 */
export const GetRedFlagsResponse = proto3.makeMessageType(
  "brain.GetRedFlagsResponse",
  () => [
    { no: 1, name: "spot_symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "future_symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "noop", kind: "message", T: NOOP },
  ],
);

/**
 * @generated from message brain.NOOP
 */
export const NOOP = proto3.makeMessageType(
  "brain.NOOP",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

