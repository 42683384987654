// package: auth
// file: auth/auth_service.proto

var auth_auth_service_pb = require("../auth/auth_service_pb");
var auth_auth_types_pb = require("../auth/auth_types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AuthService = (function () {
  function AuthService() {}
  AuthService.serviceName = "auth.AuthService";
  return AuthService;
}());

AuthService.PublicLoginAndRegister = {
  methodName: "PublicLoginAndRegister",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.LoginRequest,
  responseType: auth_auth_types_pb.LoginResponse
};

AuthService.PublicGetDefaultRoles = {
  methodName: "PublicGetDefaultRoles",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.GetDefaultRolesRequest,
  responseType: auth_auth_types_pb.GetDefaultRolesResponse
};

AuthService.GetAllRoles = {
  methodName: "GetAllRoles",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.GetAllRolesRequest,
  responseType: auth_auth_types_pb.GetAllRolesResponse
};

AuthService.UpdateProfile = {
  methodName: "UpdateProfile",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.ProfileRequest,
  responseType: auth_auth_types_pb.ProfileResponse
};

AuthService.ListAccountsDetails = {
  methodName: "ListAccountsDetails",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.ListAccountsDetailsRequest,
  responseType: auth_auth_types_pb.ListAccountsDetailsResponse
};

AuthService.ListAllAccounts = {
  methodName: "ListAllAccounts",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.ListAllAccountsRequest,
  responseType: auth_auth_types_pb.ListAllAccountsResponse
};

AuthService.TenantCreateApiKey = {
  methodName: "TenantCreateApiKey",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.TenantCreateApiKeyRequest,
  responseType: auth_auth_types_pb.TenantCreateApiKeyResponse
};

AuthService.AddAccountRole = {
  methodName: "AddAccountRole",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.UpdateAccountRoleRequest,
  responseType: auth_auth_types_pb.UpdateAccountRoleResponse
};

AuthService.RemoveAccountRole = {
  methodName: "RemoveAccountRole",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: auth_auth_types_pb.UpdateAccountRoleRequest,
  responseType: auth_auth_types_pb.UpdateAccountRoleResponse
};

exports.AuthService = AuthService;

function AuthServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AuthServiceClient.prototype.publicLoginAndRegister = function publicLoginAndRegister(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.PublicLoginAndRegister, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.publicGetDefaultRoles = function publicGetDefaultRoles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.PublicGetDefaultRoles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getAllRoles = function getAllRoles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetAllRoles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.updateProfile = function updateProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.UpdateProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.listAccountsDetails = function listAccountsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.ListAccountsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.listAllAccounts = function listAllAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.ListAllAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.tenantCreateApiKey = function tenantCreateApiKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.TenantCreateApiKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.addAccountRole = function addAccountRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.AddAccountRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.removeAccountRole = function removeAccountRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.RemoveAccountRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AuthServiceClient = AuthServiceClient;

