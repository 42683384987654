import { Backend } from '../../config/backend';
import { logger } from '../../config/Logger';
import {
  GetAllRolesRequest,
  GetAllRolesResponse,
  GetDefaultRolesRequest,
  GetDefaultRolesResponse,
  ListAllAccountsRequest,
  ListAllAccountsResponse,
  LoginRequest,
  LoginResponse,
  UpdateAccountRoleRequest,
} from '../protobuf/v2/auth/auth_types_pb';

export class AuthHelper {
  private static client = Backend.authClientV2;

  public static async loginRegisterFirebaseUser(loginRequest: LoginRequest): Promise<LoginResponse | null> {
    return await AuthHelper.client.publicLoginAndRegister(loginRequest);
  }
  public static async publicGetDefaultRolesPromise(
    requestMessage: GetDefaultRolesRequest,
  ): Promise<GetDefaultRolesResponse | null> {
    return await AuthHelper.client.publicGetDefaultRoles(requestMessage);
  }
  public static async listAllAccountsPromise(
    requestMessage: ListAllAccountsRequest,
    userToken: string,
  ): Promise<ListAllAccountsResponse | undefined> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await AuthHelper.client.listAllAccounts(requestMessage, { headers });
    } catch (e: any) {
      logger.error({ message: e.message });
      return new ListAllAccountsResponse();
    }
  }
  public static async getAllRolesPromise(
    requestMessage: GetAllRolesRequest,
    userToken: string,
  ): Promise<GetAllRolesResponse | undefined> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await AuthHelper.client.getAllRoles(requestMessage, { headers });
    } catch (e: any) {
      logger.error({ message: e.message });
      return new GetAllRolesResponse();
    }
  }
  public static async addAccountRolePromise(
    requestMessage: UpdateAccountRoleRequest,
    userToken: string,
  ): Promise<boolean | null> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await AuthHelper.client.addAccountRole(requestMessage, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e.message });
      return false;
    }
  }
  public static async removeAccountRolePromise(
    requestMessage: UpdateAccountRoleRequest,
    userToken: string,
  ): Promise<boolean | null> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await AuthHelper.client.removeAccountRole(requestMessage, { headers });
      return true;
    } catch (e: any) {
      logger.error({ message: e.message });
      return false;
    }
  }
}
