// import Logger from 'logdna';
//
// const log = Logger.createLogger('f794dd3364b2925f83f669480f9d3994', {
//   hostname: 'app.orangequest.dashboard',
//   app: 'app.orangequest.dashboard',
//   index_meta: true,
// });

interface MessageParams {
  message: any;
}

interface LogParams {
  message: any;
  params: any;
}

export class LoggerClass {
  debug({ message }: MessageParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.debug(message);
  }

  info({ message }: MessageParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.info(message);
  }

  warn({ message }: MessageParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.warn(message);
  }

  error({ message }: MessageParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.error(message);
  }

  fatal({ message }: MessageParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.fatal(message);
  }

  //params can be simple string for logging level or a json {level: 'WARN', app: 'APPNAME'}
  log({ message, params }: LogParams): void {
    if (process.env.NODE_ENV === 'development') console.log(message);
    // log.log(message, params);
  }
}

export const logger = new LoggerClass();

// export default { log };
