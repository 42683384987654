import { AuthServiceClient } from './auth_service_pb_service';
import { promisify } from 'es6-promisify';
import {
  GetAllRolesRequest,
  GetAllRolesResponse,
  ListAccountsDetailsRequest,
  ListAccountsDetailsResponse, ListAllAccountsRequest, ListAllAccountsResponse,
  LoginRequest,
  LoginResponse,
  ProfileRequest,
  ProfileResponse, UpdateAccountRoleRequest, UpdateAccountRoleResponse,
} from './auth_types_pb';
import { grpc } from '@improbable-eng/grpc-web';

export class AuthServiceClientPromisified extends AuthServiceClient {
  publicLoginAndRegisterPromise = promisify(this.publicLoginAndRegister);
  publicGetDefaultRolesPromise = promisify(this.publicGetDefaultRoles);

  getAllRolesPromise: (
    param1: GetAllRolesRequest,
    metadata?: grpc.Metadata,
  ) => Promise<GetAllRolesResponse | null> = promisify(this.getAllRoles);

  updateProfilePromise: (
    param1: ProfileRequest,
    metadata?: grpc.Metadata,
  ) => Promise<ProfileResponse | null> = promisify(this.updateProfile);

  listAccountsDetailsPromise: (
    param1: ListAccountsDetailsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<ListAccountsDetailsResponse | null> = promisify(this.listAccountsDetails);

  listAllAccountsPromise: (
    param1: ListAllAccountsRequest,
    metadata?: grpc.Metadata,
  ) => Promise<ListAllAccountsResponse | null> = promisify(this.listAllAccounts);

  addAccountRolePromise: (
    param1: UpdateAccountRoleRequest,
    metadata?: grpc.Metadata,
  ) => Promise<UpdateAccountRoleResponse | null> = promisify(this.addAccountRole);

  removeAccountRolePromise: (
    param1: UpdateAccountRoleRequest,
    metadata?: grpc.Metadata,
  ) => Promise<UpdateAccountRoleResponse | null> = promisify(this.removeAccountRole);
}
