import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { accountInt, setAccount } from '../../app/redux/applicationAccount.slice.reducer';
import styles from '../dashboard/Dashboard.module.scss';

const AccountComponent = (props: {
  history: any;
  account: any;
  isMultiSelect: boolean;
  isExpansionBtnDisabled: boolean;
  assetsList: any;
  multiSelectedAccounts: accountInt[];
  setMultiSelectedAccounts: any;
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

  useEffect(() => {
    setIsSelected(false);
  }, [props.isMultiSelect]);

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={styles.accountContent + ' ' + (props.isMultiSelect ? styles.accountContentMultiSelect : '')}
      onMouseDown={(e) => {
        if (e.nativeEvent.which === 1 && !props.isExpansionBtnDisabled && !props.isMultiSelect) {
          props.history.push({
            pathname: '/profile',
            search: `?key=${props.account.key}`,
          });
          dispatch(setAccount(props.account));
        } else if (props.isMultiSelect) {
          const accountsSelected = JSON.parse(JSON.stringify(props.multiSelectedAccounts));
          if (!isSelected && accountsSelected.length <= 0) accountsSelected.push(props.account);
          else if (accountsSelected.length > 0) {
            const index = accountsSelected.findIndex((v: accountInt) => v.key === props.account.key);
            if (index > -1) accountsSelected.splice(index, 1);
            else accountsSelected.push(props.account);
          }
          props.setMultiSelectedAccounts(accountsSelected);
          setIsSelected(!isSelected);
        }
      }}
    >
      <div className={styles.accountInfo}>
        {props.isMultiSelect ? (
          <div>
            <button className={styles.multiSelect}>{isSelected ? <img src="/img/tick.svg" alt="tick" /> : ''}</button>
          </div>
        ) : (
          ''
        )}
        <div className={styles.accountProfile}>
          <div className={styles.name}>
            <img src="/img/profile.svg" alt="profile" />
            <h4>{props.account.name}</h4>
          </div>
          {/*<div className={styles.warnings}>*/}
          {/*  <p data-tip="Not synchronized" data-text-color="#F8810A">*/}
          {/*    <img src="/img/warning.svg" alt="warning" />*/}
          {/*  </p>*/}
          {/*  <p data-tip="More than 5% of the invested amount in BTC" data-text-color="#FFFFFF">*/}
          {/*    <img src="/img/error.svg" alt="error" />*/}
          {/*  </p>*/}
          {/*</div>*/}
        </div>
        <div>
          <div className={styles.brain}>
            <img src="/img/brain.svg" alt="brain" />
            <h4>Brain</h4>
            <h5 className={props.account.brainHealth ? styles.alive : styles.failure}>
              {props.account.brainHealth ? 'Alive' : 'Failure'}
            </h5>
          </div>
          <div className={styles.executor}>
            <img src="/img/executor.svg" alt="executor" />
            <h4>Executor</h4>
            <h5 className={props.account.executorHealth ? styles.alive : styles.failure}>
              {props.account.executorHealth ? 'Alive' : 'Failure'}
            </h5>
          </div>
          <div className={styles.executor}>
            <h4>Expansion</h4>
            <h5
              className={
                props.account.configs
                  ? props.account.configs.disableExpansion
                    ? styles.failure
                    : styles.alive
                  : styles.failure
              }
            >
              {props.account.configs ? (props.account.configs.disableExpansion ? 'Disabled' : 'Enabled') : 'Disabled'}
            </h5>
          </div>
          <div className={styles.executor}>
            <h4>HOLD</h4>
            <h5
              className={
                props.account.configs ? (props.account.configs.hold ? styles.failure : styles.alive) : styles.failure
              }
            >
              {props.account.configs ? (props.account.configs.hold ? 'Enabled' : 'Disabled') : 'Disabled'}
            </h5>
          </div>
          <div className={styles.executor}>
            <h4>Zig Zag</h4>
            <h5
              className={
                props.account.configs
                  ? props.account.configs.allowZigZag
                    ? styles.alive
                    : styles.failure
                  : styles.failure
              }
            >
              {props.account.configs ? (props.account.configs.allowZigZag ? 'Enabled' : 'Disabled') : 'Disabled'}
            </h5>
          </div>
        </div>
      </div>
      <hr />
      {props.account.pnl && (
        <div className={styles.accountInvestment}>
          <div className={styles.total}>
            <h5>Current Total {props.assetsList[props.account.key]?.asset} Value</h5>
            <h4>
              {NumFormatter.format(
                props.account.pnl[
                  'currentTotal' +
                    props.assetsList[props.account.key]?.asset.charAt(0) +
                    props.assetsList[props.account.key]?.asset.slice(1).toLowerCase() +
                    'Value'
                ] || 0,
              )}
            </h4>
          </div>
          {/*<div className={styles.btcValue}>*/}
          {/*  <h5>Current BTC Value</h5>*/}
          {/*  <h4>{NumFormatter.format(account.pnl.currentbtcvalue || 0)}</h4>*/}
          {/*</div>*/}
          <div className={styles.spot}>
            <h5>Current Spot Invested %</h5>
            <h4
              className={
                (props.account.pnl.currentSpotInvestedPercentage >= 80 &&
                props.account.pnl.currentSpotInvestedPercentage < 90
                  ? styles.warning
                  : '') + (props.account.pnl.currentSpotInvestedPercentage >= 90 ? styles.failure : '')
              }
            >
              {NumFormatter.format(props.account.pnl.currentSpotInvestedPercentage || 0)}
            </h4>
          </div>
          <div className={styles.spot}>
            <h5>Current Future Invested %</h5>
            <h4
              className={
                (props.account.pnl.futureInvestedPercentage >= 80 && props.account.pnl.futureInvestedPercentage < 90
                  ? styles.warning
                  : '') + (props.account.pnl.futureInvestedPercentage >= 90 ? styles.failure : '')
              }
            >
              {NumFormatter.format(props.account.pnl.futureInvestedPercentage || 0)}
            </h4>
          </div>
          <div className={styles.spot}>
            <h5>Pnl {props.assetsList[props.account.key]?.asset} %</h5>
            <h4>
              {NumFormatter.format(
                props.account.pnl[
                  'pnlTotal' +
                    props.assetsList[props.account.key]?.asset.charAt(0) +
                    props.assetsList[props.account.key]?.asset.slice(1).toLowerCase() +
                    'Percentage'
                ] || 0,
              )}
            </h4>
          </div>
          <div className={styles.spot}>
            <h5>Spot Invested Against Total Balance %</h5>
            <h4>{NumFormatter.format(props.account.pnl.spotInvestedAgainstTotalBalancePercentage || 0)}</h4>
          </div>
          <div className={styles.spot}>
            <h5>Future Invested Against Total Balance %</h5>
            <h4>{NumFormatter.format(props.account.pnl.futureInvestedAgainstTotalBalancePercentage || 0)}</h4>
          </div>
          <div className={styles.spot}>
            <h5>Exposure %</h5>
            <h4
              className={
                props.account.pnl.exposurePercentage > 10 || props.account.pnl.exposurePercentage < -10
                  ? styles.failure
                  : styles.active
              }
            >
              {NumFormatter.format(props.account.pnl.exposurePercentage || 0)}
            </h4>
          </div>
          <div className={styles.spot}>
            <h5>Vpl {props.assetsList[props.account.key]?.asset}</h5>
            <h4
              className={
                props.account.configs[props.assetsList[props.account.key]?.asset.toLowerCase() + 'ValuePerLevel']
                  ? styles.active
                  : styles.failure
              }
            >
              {NumFormatter.format(
                props.account.configs[props.assetsList[props.account.key]?.asset.toLowerCase() + 'ValuePerLevel'] || 0,
              )}
            </h4>
          </div>
          {/*<div className={styles.spot}>*/}
          {/*  <h5>Vpl BTC</h5>*/}
          {/*  <h4>{account.configs?.btcvalueperlevel}</h4>*/}
          {/*</div>*/}
        </div>
      )}
    </div>
  );
};

export default AccountComponent;
