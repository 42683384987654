// package: brain
// file: brain/tr_brain_service.proto

var brain_tr_brain_service_pb = require("../brain/tr_brain_service_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var brain_tr_brain_types_pb = require("../brain/tr_brain_types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BrainService = (function () {
  function BrainService() {}
  BrainService.serviceName = "brain.BrainService";
  return BrainService;
}());

BrainService.GetBrainHealthStatus = {
  methodName: "GetBrainHealthStatus",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.BrainHealthStatusRequest,
  responseType: brain_tr_brain_types_pb.BrainHealthStatusResponse
};

BrainService.GetLastAvailableSignal = {
  methodName: "GetLastAvailableSignal",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.BrainSignalRequest,
  responseType: brain_tr_brain_types_pb.BrainSignalResponse
};

BrainService.SetOrUpdateRemoteConfigs = {
  methodName: "SetOrUpdateRemoteConfigs",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.BrainConfigsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.GetRemoteConfigs = {
  methodName: "GetRemoteConfigs",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: brain_tr_brain_types_pb.BrainConfigsResponse
};

BrainService.GetRunningSymbolList = {
  methodName: "GetRunningSymbolList",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.GetRunningSymbolListRequest,
  responseType: brain_tr_brain_types_pb.GetRunningSymbolListResponse
};

BrainService.AddSymbols = {
  methodName: "AddSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.AddSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.AddFutureSymbols = {
  methodName: "AddFutureSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.AddSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.RemoveSymbols = {
  methodName: "RemoveSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.RemoveFutureSymbols = {
  methodName: "RemoveFutureSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.RemoveSymbolRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.RemoveFromIgnoreSymbols = {
  methodName: "RemoveFromIgnoreSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.RemoveFromIgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.IgnoreSymbols = {
  methodName: "IgnoreSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.IgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.RemoveFromFutureIgnoreSymbols = {
  methodName: "RemoveFromFutureIgnoreSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.RemoveFromIgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.IgnoreFutureSymbols = {
  methodName: "IgnoreFutureSymbols",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.IgnoreSymbolsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BrainService.GetRedFlags = {
  methodName: "GetRedFlags",
  service: BrainService,
  requestStream: false,
  responseStream: false,
  requestType: brain_tr_brain_types_pb.GetRedFlagsRequest,
  responseType: brain_tr_brain_types_pb.GetRedFlagsResponse
};

BrainService.GetRedFlagsStream = {
  methodName: "GetRedFlagsStream",
  service: BrainService,
  requestStream: false,
  responseStream: true,
  requestType: brain_tr_brain_types_pb.GetRedFlagsRequest,
  responseType: brain_tr_brain_types_pb.GetRedFlagsResponse
};

exports.BrainService = BrainService;

function BrainServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BrainServiceClient.prototype.getBrainHealthStatus = function getBrainHealthStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.GetBrainHealthStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.getLastAvailableSignal = function getLastAvailableSignal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.GetLastAvailableSignal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.setOrUpdateRemoteConfigs = function setOrUpdateRemoteConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.SetOrUpdateRemoteConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.getRemoteConfigs = function getRemoteConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.GetRemoteConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.getRunningSymbolList = function getRunningSymbolList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.GetRunningSymbolList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.addSymbols = function addSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.AddSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.addFutureSymbols = function addFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.AddFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.removeSymbols = function removeSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.RemoveSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.removeFutureSymbols = function removeFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.RemoveFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.removeFromIgnoreSymbols = function removeFromIgnoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.RemoveFromIgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.ignoreSymbols = function ignoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.IgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.removeFromFutureIgnoreSymbols = function removeFromFutureIgnoreSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.RemoveFromFutureIgnoreSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.ignoreFutureSymbols = function ignoreFutureSymbols(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.IgnoreFutureSymbols, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.getRedFlags = function getRedFlags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BrainService.GetRedFlags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BrainServiceClient.prototype.getRedFlagsStream = function getRedFlagsStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(BrainService.GetRedFlagsStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.BrainServiceClient = BrainServiceClient;

