import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-simple-toasts';

import { selectAccessToken } from '../../app/redux/authorization.slice.reducer';
import { ExecutorHelper } from '../../pkg/apiHelpers/executorHelper';
import { ExecuteOrderResponse, GetPnlCalculatorResponse } from '../../pkg/protobuf/v2/executor/tr_executor_types_pb';
import styles from './CoinSeller.module.scss';

const CoinSellerModal = (props: any) => {
  const [pricesMap, setPricesMap] = useState<Array<[string, number]>>([]);
  const [pricesFutureMap, setPricesFutureMap] = useState<Array<[string, number]>>([]);
  const [otherSpotCoin, setOtherSpotCoin] = useState<string>('');
  const [otherSpotCoinPrice, setOtherSpotCoinPrice] = useState<number | string>(0);
  const [otherSpotCoinQuantity, setOtherSpotCoinQuantity] = useState<number | string>(0);
  const [usdtSpotCoinPrice, setUsdtSpotCoinPrice] = useState<number | string>(0);
  const [responseSpotOrder, setResponseSpotOrder] = useState<ExecuteOrderResponse | undefined>();
  const [otherFutureCoin, setOtherFutureCoin] = useState<string>('');
  const [otherFutureCoinPrice, setOtherFutureCoinPrice] = useState<number | string>(0);
  const [otherFutureCoinQuantity, setOtherFutureCoinQuantity] = useState<number | string>(0);
  const [responseFutureOrder, setResponseFutureOrder] = useState<ExecuteOrderResponse | undefined>();
  const userToken = useSelector(selectAccessToken);

  return (
    <div className={styles.mainWrapper}>
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Spot Coins</h2>
        <div style={{ display: 'flex' }}>
          <div className={'floatingInputContainer'} style={{ width: '100px', minWidth: '100px' }}>
            <input
              type="text"
              className="field-input"
              value={usdtSpotCoinPrice}
              onChange={(e) => {
                const x = e.target.value;
                if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
                setUsdtSpotCoinPrice(e.target.value.trim());
              }}
            />
          </div>
          <button
            onClick={async () => {
              if (usdtSpotCoinPrice > 0) {
                const response = await ExecutorHelper.ExecuteOrder(
                  props.account.executor,
                  userToken,
                  'BUSDUSDT',
                  Number(usdtSpotCoinPrice),
                  1,
                  0,
                  'BUYBUSD',
                );
                if (!response) toast(`Sell USDT at price: ${usdtSpotCoinPrice} didn't work!`);
                else {
                  setResponseSpotOrder(response);
                  toast(`Sell USDT at price: ${usdtSpotCoinPrice} send normally!`);
                }
              }
            }}
          >
            SELL USDT
          </button>
        </div>
      </header>
      <div className={styles.coinTable}>
        <p>Symbol</p>
        <p>Price</p>
        <p>Volume</p>
        <p>Position</p>
        <p>Action</p>
      </div>
      {props.coinsMap?.spot &&
        Object.entries(props.coinsMap?.spot)
          .sort()
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          .map((v: [string, GetPnlCalculatorResponse], k: number) => {
            return (
              <CoinRow
                key={k}
                v={v}
                pricesMap={pricesMap}
                setPricesMap={setPricesMap}
                account={props.account}
                userToken={userToken}
                type={1}
                position={props.positionsMap.spot[v[0]]}
              />
            );
          })}
      <div className={styles.coinTable}>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            value={otherSpotCoin}
            className="field-input"
            placeholder={'Other Coin'}
            onChange={(e) => {
              setOtherSpotCoin(e.target.value.toUpperCase());
            }}
          />
        </div>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            className="field-input"
            value={otherSpotCoinPrice}
            onChange={(e) => {
              const x = e.target.value;
              if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
              setOtherSpotCoinPrice(e.target.value.trim());
            }}
          />
        </div>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            className="field-input"
            value={otherSpotCoinQuantity}
            onChange={(e) => {
              const x = e.target.value;
              if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
              setOtherSpotCoinQuantity(e.target.value.trim());
            }}
          />
        </div>
        <p />
        <div style={{ display: 'flex', gap: '16px' }}>
          <button
            onClick={async () => {
              if (otherSpotCoin.toLowerCase() !== 'other coin' && otherSpotCoinPrice > 0) {
                const response = await ExecutorHelper.ExecuteOrder(
                  props.account.executor,
                  userToken,
                  otherSpotCoin.trim(),
                  Number(otherSpotCoinPrice),
                  1,
                  Number(otherSpotCoinQuantity),
                );
                if (!response) toast(`Close for coin: ${otherSpotCoin} at price: ${otherSpotCoinPrice} didn't work!`);
                else {
                  setResponseSpotOrder(response);
                  toast(`Close for coin: ${otherSpotCoin} at price: ${otherSpotCoinPrice} send normally!`);
                }
              }
            }}
          >
            Close
          </button>
          <button
            disabled={responseSpotOrder === undefined}
            onClick={async () => {
              if (responseSpotOrder?.order?.OrderID && responseSpotOrder?.order?.symbol?.symbolName) {
                const response = await ExecutorHelper.CancelOrder(
                  props.account.executor,
                  userToken,
                  responseSpotOrder?.order?.OrderID,
                  responseSpotOrder?.order?.symbol?.symbolName,
                );
                if (response) {
                  setResponseSpotOrder(undefined);
                  toast(`Canceled`);
                }
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>

      <header>
        <h2>Future Coins</h2>
      </header>
      <div className={styles.coinTable}>
        <p>Symbol</p>
        <p>Price</p>
        <p>Volume</p>
        <p>Position</p>
        <p>Action</p>
      </div>
      {props.coinsMap?.future &&
        Object.entries(props.coinsMap?.future)
          .sort()
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          .map((v: [string, GetPnlCalculatorResponse], k: number) => {
            return (
              <CoinRow
                key={k}
                v={v}
                pricesMap={pricesFutureMap}
                setPricesMap={setPricesFutureMap}
                account={props.account}
                userToken={userToken}
                type={2}
                position={props.positionsMap.futures[v[0]]}
              />
            );
          })}
      <div className={styles.coinTable}>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            value={otherFutureCoin}
            placeholder={'Other Coin'}
            className="field-input"
            onChange={(e) => {
              setOtherFutureCoin(e.target.value);
            }}
          />
        </div>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            className="field-input"
            value={otherFutureCoinPrice}
            onChange={(e) => {
              const x = e.target.value;
              if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
              setOtherFutureCoinPrice(e.target.value.trim());
            }}
          />
        </div>
        <div className={'floatingInputContainer'}>
          <input
            type="text"
            className="field-input"
            value={otherFutureCoinQuantity}
            onChange={(e) => {
              const x = e.target.value;
              if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
              setOtherFutureCoinQuantity(e.target.value.trim());
            }}
          />
        </div>
        <p />
        <div style={{ display: 'flex', gap: '16px' }}>
          <button
            onClick={async () => {
              if (otherFutureCoin.toLowerCase() !== '' && otherFutureCoinPrice > 0) {
                const response = await ExecutorHelper.ExecuteOrder(
                  props.account.executor,
                  userToken,
                  otherFutureCoin.trim(),
                  Number(otherFutureCoinPrice),
                  2,
                  Number(otherFutureCoinQuantity),
                );
                if (!response)
                  toast(`Close for coin: ${otherFutureCoin} at price: ${otherFutureCoinPrice} didn't work!`);
                else {
                  setResponseFutureOrder(response);
                  toast(`Close for coin: ${otherSpotCoin} at price: ${otherSpotCoinPrice} send normally!`);
                }
              }
            }}
          >
            Close
          </button>
          <button
            disabled={responseFutureOrder === undefined}
            onClick={async () => {
              if (responseFutureOrder?.order?.OrderID && responseFutureOrder?.order?.symbol?.symbolName) {
                const response = await ExecutorHelper.CancelOrder(
                  props.account.executor,
                  userToken,
                  responseFutureOrder?.order?.OrderID,
                  responseFutureOrder?.order?.symbol?.symbolName,
                );
                if (response) {
                  setResponseFutureOrder(undefined);
                  toast(`Canceled`);
                }
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default CoinSellerModal;

const CoinRow = (props: any) => {
  const [responseOrder, setResponseOrder] = useState<ExecuteOrderResponse | undefined>();
  const [quantity, setQuantity] = useState<number>(0);
  const NumFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 });

  const onClose = async (value: [string, GetPnlCalculatorResponse]) => {
    if (value[1] && value[1].symbol && value[1].symbol.symbolName && value[1].Price > 0) {
      const price = props.pricesMap[value[0]] || value[1].Price;
      const response = await ExecutorHelper.ExecuteOrder(
        props.account.executor,
        props.userToken,
        value[1]?.symbol?.symbolName,
        price,
        props.type,
        quantity,
      );
      if (!response) toast(`Close for coin: ${value[1]?.symbol?.symbolName} at price: ${price} didn't work!`);
      else {
        setResponseOrder(response);
        toast(`Close for coin: ${value[1]?.symbol?.symbolName} at price: ${price} send normally!`);
      }
    }
  };

  return (
    <div className={styles.coinTable}>
      <p>{props.v[1]?.symbol?.symbolName}</p>
      <div className={'floatingInputContainer'}>
        <input
          type="text"
          className="field-input"
          value={props.pricesMap[props.v[0]] || props.v[1]?.Price}
          onChange={(e) => {
            const x = e.target.value;
            if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
            const map = JSON.parse(JSON.stringify(props.pricesMap));
            map[props.v[0]] = e.target.value.trim();
            props.setPricesMap(map);
          }}
        />
      </div>
      <div className={'floatingInputContainer'}>
        <input
          type="text"
          className="field-input"
          value={quantity}
          onChange={(e) => {
            const x = e.target.value;
            if ((!Number(x) && x.trim() !== '' && Number(x) !== 0) || Number(x) < 0) return;
            setQuantity(Number(e.target.value) || 0);
          }}
        />
      </div>
      {props.position ? <div>{NumFormatter.format(props.position.free)}</div> : <div />}
      <div style={{ display: 'flex', gap: '16px' }}>
        <button onClick={() => onClose(props.v)}>Close</button>
        <button
          disabled={responseOrder === undefined}
          onClick={async () => {
            if (responseOrder?.order?.OrderID && responseOrder?.order?.symbol?.symbolName) {
              const response = await ExecutorHelper.CancelOrder(
                props.account.executor,
                props.userToken,
                responseOrder?.order?.OrderID,
                responseOrder?.order?.symbol?.symbolName,
              );
              if (response) {
                setResponseOrder(undefined);
                toast(`Canceled`);
              }
            }
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
