import './App.scss';

import { IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import Dashboard from './components/dashboard/Dashboard';
import { Login } from './components/login/Login';
import Orders from './components/orders/Orders';
import Prices from './components/prices/Prices';
import Profile from './components/profile/Profile';
import Users from './components/userManagement/Users';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './routes/ProtectedRoute';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const App = () => {
  // init
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setToastMessage('there are some new updates');
    setShowToast(true);
    setWaitingWorker(registration.waiting);
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowToast(false);
    window.location.reload();
  };

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onRefreshPage = (): Promise<void> => {
    return new Promise((res) => {
      setTimeout(() => {
        res(window.location.reload());
      }, 800);
    });
  };

  return (
    <PullToRefresh onRefresh={onRefreshPage} isPullable={width <= 768}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path={['/', '/login']} component={Login} />
            <ProtectedRoute exact path={'/dashboard'} component={Dashboard} />
            <ProtectedRoute exact path={'/orders'} component={Orders} />
            <ProtectedRoute exact path={'/prices'} component={Prices} />
            <ProtectedRoute exact path={'/profile'} component={Profile} />
            <ProtectedRoute exact path={'/users'} component={Users} />
            <Route path={''} component={Dashboard} />
          </Switch>
        </BrowserRouter>
        <div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            position="top"
            buttons={[
              {
                side: 'end',
                icon: 'refresh',
                text: 'Refresh',
                handler: () => {
                  reloadPage();
                },
              },
              {
                text: 'Done',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                },
              },
            ]}
          />
        </div>
      </div>
    </PullToRefresh>
  );
};
