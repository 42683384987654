export const Status = {
  0: 'UNKNOWN_MARKET',
  1: 'NEW',
  2: 'PARTIALLY_FILLED',
  3: 'FILLED',
  4: 'CANCELED',
  5: 'PENDING_CANCEL',
  6: 'REJECTED',
  7: 'EXPIRED',
  8: 'STARTED',
  9: 'NEW_INSURANCE',
  10: 'NEW_ADL',
};
