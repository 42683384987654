import { useEffect } from 'react';

import styles from './Error.module.scss';

const ErrorModal = (props: any) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);
  return (
    <div className={styles.errorModal}>
      <h2>Permission denied</h2>
      <h3>Please ask an Administrator to accept your invitation</h3>
      <button
        onClick={() => {
          window.localStorage.clear();
          window.location.pathname = '/';
        }}
      >
        Logout
      </button>
    </div>
  );
};
export default ErrorModal;
